<template>
  <div class="component-wrapper">
    <div
      class="overlay clear"
      v-if="showRandomMenu"
      @click="showRandomMenu = false"
    ></div>
    <div class="sidebar-buttons">
      <div
        class="sidebar-button"
        @click="$emit('addCard'), (showRandomMenu = false)"
      >
        <i class="fas fa-plus-circle"></i>Add Item
      </div>
      <div class="sidebar-button" @click="$emit('showRandomMenu')">
        <i class="fas fa-dice-d20"></i>Random
      </div>
      <div
        class="sidebar-button"
        @click="$emit('showCollections'), (showRandomMenu = false)"
      >
        <i class="fas fa-folder"></i>Collections
      </div>

        <div
          class="button-divider noMobile"
        ></div>
        <div
          class="sidebar-button printview-button noMobile"
          @click="deselectAll"
          v-if="selectedCards.length > 0"
        >
          <i class="fa-solid fa-square-dashed"></i>Deselect All
        </div>
        <div
          class="sidebar-button printview-button noMobile"
          @click="selectAll"
          v-else
        >
          <i class="fa-solid fa-square-dashed"></i>Select All
        </div>
        <div
          class="sidebar-button printview-button noMobile"
          @click="$emit('printSheet')"
          v-if="selectedCards.length > 0"
        >
          <i class="fas fa-print"></i>Print Selected
          {{ "(" + selectedCards.length + ")" }}
        </div>
        <div
          class="sidebar-button printview-button noMobile"
          @click="flipCards"
          v-if="selectedCards.length > 0"
        >
          <i class="fas fa-sync"></i>Flip Selected
          {{ "(" + selectedCards.length + ")" }}
        </div>
        <div
          class="sidebar-button printview-button noMobile"
          @click="deleteCards"
          v-if="selectedCards.length > 0"
        >
          <i class="fas fa-trash"></i>Delete Selected
          {{ "(" + selectedCards.length + ")" }}
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardEditorButtons",
  emits: ["printSheet", "showCollections", "addCard", "showRandomMenu"],
  computed: {
    selectedCards() {
      return this.$store.state.selectedCards;
    },
  },
  methods: {
    flipCards() {
      let cardsToFlip = [...this.selectedCards];
      cardsToFlip.forEach((cardID) => {
        this.$store.commit("updateBack", {
          cardID: cardID,
        });
      });
    },
    deleteCards() {
      let cardsToDelete = [...this.selectedCards];
      cardsToDelete.forEach((cardID) => {
        this.$store.commit("updateSelectedCards", cardID);
        this.$store.dispatch("deleteCard", { cardID: cardID });
      });
    },
    deselectAll() {
      let cardsToDeselect = [...this.selectedCards];
      cardsToDeselect.forEach((cardID) => {
        console.log(this.selectedCards);
        this.$store.commit("updateSelectedCards", cardID);
      });
    },
    selectAll() {
      let cardsToSelect = [...this.$store.state.cards];
      cardsToSelect.forEach((card) => {
        this.$store.commit("updateSelectedCards", card.cardID);
      });
    }
  },
  data() {
    return {
      showRandomMenu: false,
    };
  },
};
</script>

<style scoped>
.sidebar-buttons {
  position: absolute;
  right: -110px;
  top: 0;
  display: flex;
  flex-flow: column nowrap;
  user-select: none;
}

.sidebar-button {
  position: relative;
  margin: 0px 0px 10px 10px;
  padding: 10px;
  background: var(--dark3);
  color: white;
  border-radius: 5px;
  font-size: 10px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content:center;
  width: 100px;
  min-height:67px;
  text-align: center;
  font-family: montserrat, Helvetica, Arial, sans-serif;
  z-index: 100;
}

.sidebar-button i {
  font-size: 1.5rem;
}

.button-divider {
  width: 100%;
  height: 3px;
  background: whitesmoke;
  margin: 5px 5px 15px 5px;
}

@media only screen and (max-width: 1079px) {
  .sidebar-buttons {
    position: fixed;
    right: unset;
    top: unset;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    background: var(--dark2);
  }

  .sidebar-button {
    margin: 0px;
    padding: 10px;
    border-radius: 0px;
    font-size: 12px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    text-align: center;
    z-index: 100;
    min-height:unset;
  }

  .column-menu {
    position: absolute;
    bottom: unset;
    left: -100%;
    top: -20px;
    transform: unset;
    transform: translateX(25%) translateY(-100%);
    background: var(--dark2);
    z-index: 100;
    width: 200%;
  }

  .column-menu h3 {
    font-size: 1.5rem;
  }

  .column-option {
    padding: 10px;
    margin: 5px 0px;
    font-size: 1rem;
    border-radius: 5px;
  }

  .printview-button {
    display: none;
  }

  .noMobile {
    display: none;
  }
}
</style>
