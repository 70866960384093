<template>
  <BaseHeader></BaseHeader>
  <CultEditor></CultEditor>
</template>

<script>

import BaseHeader from "../components/Header/BaseHeader.vue";
import CultEditor from "../components/CultGenerator/CultEditor.vue";

export default {
    name: "CultGenerator",
    components: {CultEditor, BaseHeader}

}
</script>

<style scoped>

</style>