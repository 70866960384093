<template>
  <div class="pantheon-output background">

    <h3 contenteditable="true" @blur="updatePantheon('name')">{{ pantheon.name }}</h3>
    <div class="floaty-buttons"><i class="fas fa-dice-d20" @click="generatePantheon('sameSize')"></i><i
        class="fas fa-save" v-if="pantheon.name != 'Create Pantheon'" @click="$emit('savePantheon')"></i></div>
    <div class="pantheon-statistics">
      <div class="statistic-block" v-for="(pantheonStatistic, index) in pantheon.statistics" :key="index">
        <div class="statistic-name">{{ pantheonStatistic.name }}</div>
        <div class="statistic-value">{{ pantheonStatistic.value }}</div>
      </div>
    </div>
    <div contenteditable="true" @blur="updatePantheon('text')">{{ pantheon.text }}</div>
  </div>
</template>

<script>

export default {
  name: "PantheonEditorPantheon",
  props: ["pantheonFilters", "pantheonSizeN"],
  emits: ["pantheonUpdated", "savePantheon"],
  data() {
    return {
      pantheon: {
        name: "Create Pantheon",
        statistics: {
          pantheonOrganization: "",
          pantheonHierarchy: "",
          pantheonRelationships: "",
        },
        text: "Click Generate Pantheon or the d20 symbol to create a pantheon.",
        size: 0,
      },
    };
  },
  methods: {
    // ///// GENERATE PANTHEON /////

    generatePantheon(size) {
      if (size != "sameSize") {
        this.pantheon.id = Math.random().toString(36).substr(2, 9) + Date.now();


      }
      let selectedFilters = [];

      /// SET ALL THE FILTERS RANDOMLY

      for (let filter of this.pantheonFilters) {
        let filterOptions = filter.options.filter((option) => option.selected);
        if (filterOptions.length === 0) {
          filterOptions = filter.options;
        }
        let filterName = filter.name;
        let randomOption =
          filterOptions[Math.floor(Math.random() * filterOptions.length)];
        selectedFilters.push({ name: filterName, value: randomOption.value });
      }

      this.pantheon.statistics = selectedFilters.reduce((obj, item) => {
        obj[item.name.toLowerCase()] = item.value;
        return obj;
      }, {});


      /// SET THE PANTHEON SIZE

      if (size != "sameSize") {

        let pantheonMinSize = 5;
        let pantheonMaxSize = 30;

        if (
          selectedFilters.find((filter) => filter.name === "Size").value ===
          "Small"
        ) {
          pantheonMinSize = 5;
          pantheonMaxSize = 10;
        } else if (
          selectedFilters.find((filter) => filter.name === "Size").value ===
          "Medium"
        ) {
          pantheonMinSize = 10;
          pantheonMaxSize = 20;
        } else if (
          selectedFilters.find((filter) => filter.name === "Size").value ===
          "Large"
        ) {
          pantheonMinSize = 15;
          pantheonMaxSize = 30;
        }

        let pantheonSize =
          Math.floor(Math.random() * (pantheonMaxSize - pantheonMinSize + 1)) +
          pantheonMinSize;

        this.pantheon.size = pantheonSize;

      } else {
        this.pantheon.size = this.pantheonSizeN;
      }

      // SET THE PANTHEON NAME RANDOMLY

      let pantheonName =
        this.$store.state.pantheonData.pantheonNames[
          Math.floor(
            Math.random() * this.$store.state.pantheonData.pantheonNames.length
          )
        ].name;

      this.pantheon.name = pantheonName;


      /// SET THE PANTHEON TEXT

      this.generatePantheonText();

      /// SEND STUFF BACK


      this.$emit("pantheonUpdated", this.pantheon);

    },
    generatePantheonText() {
      /// SET THE PANTHEON ORGANIZATION

      let selectedOrganization = this.pantheon.statistics.organization;
      let pantheonOrganization = "";

      if (selectedOrganization == "None") {
        pantheonOrganization = "that are independent of each other.";
      } else if (selectedOrganization == "Grouped") {

        /// FIND RANDOM NUMBER BETWEEN 1 AND 2

        let randomGroupNumber = Math.floor(Math.random() * 2) + 1;

        if (randomGroupNumber == 1) {
          pantheonOrganization =
            "that are divided into groups based on their Divine Domains or alignments.";
        } else if (randomGroupNumber == 2) {
          pantheonOrganization =
            "that are divided into two opposing factions.";
        }

      } else if (selectedOrganization == "Mixed") {
        pantheonOrganization =
          "where some are grouped and some are more independent.";
      }

      /// SET THE PANTHEON HIERARCHY

      let selectedHierarchy = this.pantheon.statistics.hierarchy;
      let pantheonHierarchy = "";

      if (selectedHierarchy == "Flat") {
        pantheonHierarchy =
          "a flat hierarchy, where all gods have equal authority.";
      } else if (selectedHierarchy == "Hierarchical") {
        pantheonHierarchy =
          "a formal hierarchy, where some gods have more authority than others.";
      } else if (selectedHierarchy == "Supreme Deity") {
        pantheonHierarchy =
          "a supreme deity, who governs over all the other gods.";
      } else if (selectedHierarchy == "Mixed") {
        pantheonHierarchy =
          "a supreme deity that governs over all the lesser gods, who also have a formal hierarchy among themselves.";
      }

      /// SET THE PANTHEON RELATIONSHIPS

      let selectedRelationships = this.pantheon.statistics.relationships;

      let pantheonRelationships = "";

      if (selectedRelationships == "None") {
        pantheonRelationships =
          "The gods have little to no interaction with each other.";
      } else if (selectedRelationships == "Some") {
        pantheonRelationships =
          "The gods have sporadic interaction with each other and may form close relationships.";
      } else if (selectedRelationships == "Frequent") {
        pantheonRelationships =
          "There are frequent relationships between the gods, which may include formal councilmeetings.";
      }

      let pantheonText = this.pantheon.name + " is a pantheon of gods " +
        pantheonOrganization +
        " The pantheon has " +
        pantheonHierarchy +
        " " +
        pantheonRelationships;

      this.pantheon.text = pantheonText;
    },
    updatePantheon(property) {
      this.pantheon[property] = event.target.innerHTML
      this.$emit("pantheonUpdated", this.pantheon);

    },
  },
};
</script>

<style scoped>
.background {
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--dark3);
}

.pantheon-output {
  position: relative;
  width: 100%;
  margin: 0px 0px 10px 0px;
  align-items: center;
  justify-items: center;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 10px;
  padding: 10px;
}

.floaty-buttons {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  flex-flow: row nowrap;

}

.floaty-buttons i {
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0px 5px;
}


.floaty-buttons i:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.pantheon-output h3 {
  font-size: 1.1rem;
  text-transform: uppercase;
}


.pantheon-statistics {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px;
}

.statistic-block {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
}

.statistic-name {
  font-size: 0.75rem;
  font-weight: 600;
}

@media screen and (max-width: 600px) {

  .pantheon-output {
    align-items:flex-start !important;
    justify-items:left;
    text-align:left;
  }

.pantheon-output {
  font-size:0.8rem;
}
  .pantheon-output h3 {
    font-size: 1rem;
  }

  .floaty-buttons i {
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0px 5px;
  }

}
</style>