<template>
  <div class="menu-row column">
    <div class="menu-row">
      <h3>Design:</h3>
            <select class="rounded" v-model="cardLayout">
        <option>Auto</option>
        <option v-for="layout in layouts" :key="layout.index">
          {{ layout }}
        </option>
      </select>
      <h3>Decor:</h3>
            <select class="rounded" v-model="cardDecor">
        <option>Auto</option>
        <option v-for="decor in decors" :key="decor.index">
          {{ decor }}</option>
      </select>

    </div>
    <div class="menu-row">
      <h3>Gems:</h3>
      <select class="rounded" v-model="gemPosition">
        <option>Auto</option>
        <option v-for="position in gemPositions" :key="position.index">
          {{ position }}
        </option>
      </select>

      <h3>Color:</h3>
      <select class="rounded" v-model="gemColor">
        <option>Auto</option>
        <option v-for="color in gemColors" :key="color.index">
          <div class="color-dot" :class="{ color }"></div>
          {{ color }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutSelect",
  props: ["cardID", "card"],
  data() {
    return {
      layouts: ["Card", "Text"],
      decors: ["None", "Less", "Some", "More", "Full"],
      gemColors: ["Gray", "Green", "Blue", "Purple", "Orange", "Red", "White"],
      gemPositions: ["Corners", "All"],
    };
  },
  computed: {
    cardLayout: {
      get() {
        if (!this.card.cardLayout) {
          return "Auto";
        } else {
          return this.card.cardLayout;
        }
      },
      set(layout) {
        this.$store.commit("updateLayout", {
          cardID: this.cardID,
          layout: layout,
        });
      },
    },
    cardDecor: {
      get() {
        if (!this.card.cardDecor) {
          return "Auto";
        } else {
          return this.card.cardDecor;
        }
      },
      set(decor) {
        this.$store.commit("updateDecor", {
          cardID: this.cardID,
          decor: decor,
        });
      },
    },
    gemPosition: {
      get() {
        if (!this.card.gemsPosition) {
          return "Auto";
        } else {
          return this.card.gemsPosition;
        }
      },
      set(position) {
        this.$store.commit("updateGemsPosition", {
          cardID: this.cardID,
          position: position,
        });
      },
    },
    gemColor: {
      get() {
        if (!this.card.gemsColor) {
          return "Auto";
        } else {
          return this.card.gemsColor;
        }
      },
      set(color) {
        this.$store.commit("updateGemsColor", {
          cardID: this.cardID,
          color: color,
        });
      },
    },
  },
};
</script>

<style scoped>
.filter-element {
  font-size: 0.8rem;
  padding: 3px;
  max-height: 1.5rem;
  color: black;
  margin: 0px 2px;
}

.fas {
  margin-left: 0.25rem;
  height: 1.4rem;
  line-height: 1.2rem;
  padding: 0.1rem 0.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fa:hover,
.highlight {
  color: var(--main2);
  background-color: var(--dark);
}

.menu-row h3:nth-of-type(even) {
  margin-left: 5px;
}

.menu-row:nth-of-type(1) {
  margin-bottom: 0.4rem;
}

.color-dot {
  width: 1rem;
  height: 1rem;
  margin: 0.5rem;
  border-radius: 100%;
  cursor: pointer;
}
.gray {
  background-color: gray;
}
.green {
  background-color: green;
}
.blue {
  background-color: dodgerblue;
}
.purple {
  background-color: purple;
}
.orange {
  background-color: orange;
}
.red {
  background-color: darkred;
}
.white {
  background-color: #fff;
}

.selected {
  outline: 2px solid white;
}

input {
  margin-left: 0.25rem;
  width: 200%;
}

.width-enter-active {
  transition: all 0.2s ease;
}

.width-leave-active {
  transition: all 0.2s ease;
}

.width-enter-from,
.width-leave-to {
  width: 0;
}
</style>
