<template>
  <!-- <div>
    <transition name="fade">
      <div class="overlay clear" v-if="filters" @click="filters = false"></div
    ></transition>
  </div> -->
  <!-- <div
    class="overlay clear"
    v-if="showDropList"
    @click="showDropList = false"
  ></div> -->
  <div
    class="menu-row align-top space-between center-vertical rounded"
    @mouseleave="showDropList = false">
    <h3>Load:</h3>
    <div
      class="item-select rounded"
      @click="(showDropList = !showDropList), (filters = false)">
      <p>
        {{
          selectedItem.substring(0, 23) + (selectedItem.length > 23 ? ".." : "")
        }}
      </p>
      <transition name="height">
        <div class="drop-list" v-if="showDropList">
          <div
            class="item-option"
            v-if="card.selectedItem.name === 'Choose Item'">
            Choose Item
          </div>
          <div
            class="item-option"
            v-for="item in filteredItems"
            :key="item.name">
            <div class="menu-row" @click.stop="setItem(item)">
              <div class="item-name">
                {{ item.name }}
              </div>
              <div
                class="montserrat rounded uppercase"
                :class="{
                  wgmm: item.source == 'WGMM',
                  srd: item.source == 'SRD' || item.source == 'DMG',
                  hgdd: item.source == 'HGDD',
                  mgmm: item.source == 'MGMM',
                  custom: item.source == 'custom' || item.source == 'Custom',
                }">
                {{ item.source }}
              </div>
            </div>
            <i
              class="fas fa-trash"
              v-if="item.source == 'custom' || item.source == 'Custom'"
              @click.stop="deleteItem(item)"></i>
          </div>
        </div>
      </transition>
    </div>
    <div
      class="menu-btn rounded"
      :class="{ highlight: filters }"
      @mouseover="showTooltipFilters = true"
      @mouseleave="showTooltipFilters = false"
      @click="(filters = !filters), (showDropList = false)">
      <i class="fas fa-filter"></i>
      <transition name="tooltip">
        <div class="tooltip-top" v-if="showTooltipFilters">
          Filter Items in Collections
        </div>
      </transition>
    </div>
    <div class="menu-btn rounded" @click="pickRandom">
      <i class="fas fa-dice-d20"></i>
    </div>
    <transition name="menu">
      <div v-if="filters" class="rounded card-menu-modal">
        <div class="menu-row">
          <h3>Source:</h3>
          <select class="rounded" v-model="fSource">
            <option>Any</option>
            <option v-for="(source, index) in sources" :key="index">
              {{ source }}
            </option>
          </select>
        </div>
        <div class="menu-row">
          <h3>Type:</h3>
          <select class="rounded" v-model="fType">
            <option>Any</option>
            <option v-for="(type, index) in itemTypes" :key="index">
              {{ type }}
            </option>
          </select>
        </div>
        <div class="menu-row">
          <h3>Rarity:</h3>
          <select class="rounded rarity-select" v-model="fRarity">
            <option>Any</option>
            <option v-for="(rarity, index) in rarityChoices" :key="index">
              {{ rarity }}
            </option>
          </select>
        </div>
        <div class="menu-row">
          <h3>Attunement:</h3>
          <select class="rounded" v-model="fAttunement">
            <option>Any</option>
            <option>Requires Attunement</option>
            <option>No Attunement</option>
          </select>
        </div>
      </div>
    </transition>
  </div>
  <transition name="fade">
    <BaseDialog
      v-if="showPatreonSignup && !premium"
      title="Get Premium Access to use this feature"
      @close="showPatreonSignup = false"
      @okay="showPatreonSignup = false">
      <template #content>
        <b>Not a Patron?</b> Sign up to the
        <a href="https://patreon.com/eventyr" target="_blank"
          >Eventyr Games Patreon</a
        >
        to get a Patron Key and then
        <router-link to="/login">register an account</router-link> to get
        premium access.<br /><br />
        <div v-if="loggedIn">
          <b>Already a Patron?</b>
          Enter your Patron Key to get premium access.
        </div>
        <div class="menu-row flex-center" v-if="loggedIn">
          <input
            type="text"
            class="patreon-connect rounded"
            v-model="patreonKey" /><button
            class="rounded"
            @click="connectPatreon()">
            Submit
          </button>
        </div>
      </template>
    </BaseDialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import BaseDialog from "../../../BaseDialog.vue";

export default {
  name: "CardMenuItemSelect",
  props: ["cardID", "card"],
  components: { BaseDialog },
  data() {
    return {
      filters: false,
      showDelete: false,
      showDeleteTooltip: false,
      showSaveTooltip: false,
      showEdit: false,
      selected: false,
      rarityChoices: ["Common", "Uncommon", "Rare", "Very Rare", "Legendary"],
      fRarity: "Any",
      fType: "Any",
      fAttunement: "Any",
      fSource: "Any",
      showTooltipCustom: false,
      showTooltipSRD: false,
      showTooltipWGTMM: false,
      showTooltipFilters: false,
      showDropList: false,
      showPatronOnly: false,
      showPatreonSignup: false,
      patreonKey: "",
      sources: [
        "DMG/SRD",
        "Wanderer's Guide",
        "Milando's Guide",
        "Heretic's Guide",
        "Custom Items",
      ],
    };
  },
  methods: {
    deleteItem(item) {
      this.$store.dispatch("deleteUserItem", {
        item: item.name,
      });
    },
    pickRandom() {
      const selectedItem = Object.assign(
        {},
        this.filteredItems[
          Math.floor(Math.random() * Math.floor(this.filteredItems.length))
        ]
      );
      this.$store.dispatch("updateItem", {
        cardID: this.cardID,
        item: selectedItem,
      });
    },
    setItem(newItem) {
      const selectedItem = Object.assign(
        {},
        this.filteredItems.find((item) => item.name == newItem.name)
      );

      this.$store.dispatch("updateItem", {
        cardID: this.cardID,
        item: selectedItem,
      });
    },
    connectPatreon() {
      this.$store.dispatch("patreonConnect", this.patreonKey);
    },
  },
  mounted() {
    if (this.premium) {
      this.sources = [
        "DMG/SRD",
        "Wanderer's Guide",
        "Milando's Guide",
        "Custom Items",
      ];
    }
  },
  watch: {
    fSource: function (newValue) {
      if (!this.premium) {
        if (
          newValue == "Custom Items" ||
          newValue == "Wanderer's Guide" ||
          newValue == "Milando's Guide"
        ) {
          this.fSource = "DMG/SRD";
          this.showPatreonSignup = true;
        }
      }
    },
  },
  computed: {
    ...mapState([
      "itemTypes",
      "srd",
      "wgmm",
      "mgmm",
      "hgdd",
      "selectedCollection",
      "premium",
      "userItems",
    ]),
    selectedItem() {
      return this.card.selectedItem.name;
    },
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
    filteredItems: function () {
      var filteredItems = [];
      switch (this.fSource) {
        case "Any":
          filteredItems = filteredItems.concat([...this.userItems]);
          filteredItems = filteredItems.concat([...this.srd]);
          if (this.premium) {
            filteredItems = filteredItems.concat([...this.wgmm]);
            filteredItems = filteredItems.concat([...this.mgmm]);
            filteredItems = filteredItems.concat([...this.hgdd]);
          }
          break;
        case "DMG/SRD":
          filteredItems = filteredItems.concat([...this.srd]);
          break;
        case "Wanderer's Guide":
          filteredItems = filteredItems.concat([...this.wgmm]);
          break;
        case "Milando's Guide":
          filteredItems = filteredItems.concat([...this.mgmm]);
          break;
        case "Custom Items":
          filteredItems = filteredItems.concat([...this.userItems]);
          break;
        default:
          break;
      }
      filteredItems = [...new Set(filteredItems)];
      filteredItems = filteredItems.filter((item) => item.description != null);
      filteredItems.sort(function (x, y) {
        let a = x.name.toUpperCase(),
          b = y.name.toUpperCase();
        return a == b ? 0 : a > b ? 1 : -1;
      });
      const fType = this.fType;
      const fAttunement = this.fAttunement;
      const fRarity = this.fRarity;
      if (fType != "Any") {
        filteredItems = filteredItems.filter((item) => item.type == fType);
      }
      if (fAttunement == "Requires Attunement") {
        filteredItems = filteredItems.filter((item) => item.attunement != "No");
      } else if (fAttunement == "No Attunement") {
        filteredItems = filteredItems.filter((item) => item.attunement == "No");
      }
      if (fRarity != "Any") {
        filteredItems = filteredItems.filter((item) => item.rarity == fRarity);
      }
      const uniqueItems = Array.from(
        new Set(filteredItems.map((item) => item.name))
      ).map((name) => {
        return filteredItems.find((item) => item.name == name);
      });
      return uniqueItems;
    },
  },
};
</script>

<style scoped>
.menu-row {
  position: relative;
  width: 100%;
}

.menu-row.top {
  height: 1.5rem;
}

.card-menu-modal {
  position: absolute;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  font-family: "Montserrat", sans-serif;
  height: auto;
  padding: 5px 10px;
  color: #fff;
  background: #141418;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  transition: var(--transition-speed);
  width: 100%;
  top: calc(100% + 10px);
  padding-bottom: 15px;
  box-shadow: 0 0 5px rgb(0, 0, 0);
  z-index: 1000;
}

.card-menu-modal > div {
  margin: 5px 0px 0px 0px;
}

.item-select {
  line-height: 1.6rem;
  width: 100%;
  height: 30px;
  border: none;
  outline: none;
  transition: all 0.5s ease;
  font-size: 0.8rem;
  border: 1px solid rgb(117, 117, 117);
  position: relative;
  background: var(--dark);
  padding: 2.5px 0px;
}

.item-select.closed {
  overflow: hidden;
}

.menu-btn {
  background: transparent;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  text-align: center;
  transition: all 0.5s ease;
  cursor: pointer;
  color: #fff;
  z-index: 1;
}

.highlight,
.menu-btn:hover {
  filter: grayscale(0%) opacity(1);
  color: var(--main2);
}

.options {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.option {
  padding: 5px;
  background-color: var(--dark);
  border: 1px solid rgb(117, 117, 117);
  cursor: pointer;
  color: var(--main2);
  filter: grayscale(1) opacity(0.5);
  margin: 0rem 0rem 0.25rem 0.25rem;
  transition: all 0.3s ease;
}

.option:hover,
.option.selected {
  filter: grayscale(0) opacity(1);
  transition: all 0.5s ease;
}

.menu-btn:hover {
  color: var(--main2);
}

.item-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  line-height: 1.4;
  width: 100%;
}

.item-name:hover {
  color: var(--main2);
}

.item-option i:hover {
  color: red;
}

.patron-only {
  position: absolute;
  font-size: 0.8rem;
  top: -10%;
  left: 10%;
  line-height: 1;
  transform: rotate(-7.5deg);
  color: red;
  transition: all 0.3s ease;
  font-weight: 800;
}

.patreon-connect {
  width: 50%;
  height: 1.5rem;
  margin: 10px;
}

.item-select p {
  padding: 0 5px;
  cursor: pointer;
  user-select: none;
}

button {
  margin: 0;
  padding: 0.5rem 1rem;
}

.fas {
  font-size: 1.2rem;
}

.fa-trash {
  font-size: 0.8rem;
}

.center-vertical {
  display: flex;
  align-items: center;
}

.wgmm,
.srd,
.hgdd,
.mgmm,
.custom {
  font-size: 0.6rem;
  margin: 0.05rem 0.2rem;
  padding: 0.01rem 0.1rem;
}

.wgmm {
  background-color: rgb(47, 146, 153);
}

.hgdd {
  background-color: rgb(255, 174, 0);
}

.mgmm {
  background-color: rgb(83, 60, 138);
}

.srd {
  background-color: rgb(117, 117, 117);
}

.custom {
  background-color: olivedrab;
}

.drop-list {
  width: 130%;
}

@media screen and (max-width: 1080px) {
  .item-option {
    font-size: 1rem;
    padding: 2.5px;
  }
}
</style>
