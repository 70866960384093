<template>
  <transition name="menu">
    <div
      class="menu-row"
    >
      <h3>Name:</h3>
      <input type="text" v-model="name" />
    </div>
  </transition>
  <transition name="menu">
    <div
      class="menu-row"
    >
      <textarea v-model="description"></textarea>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EnchantmentEditSelect",
  data() {
    return {
      description: "",
      name: "",
      showEdit: false,
    };
  },
  props: {
    id: Number,
    cardID: Number,
    card: Object
  },
  watch: {
    description: function (newDesc, oldDesc) {
      if (oldDesc) {
        var selectedEnchantment = {};
        selectedEnchantment = Object.assign(
          {},
          this.enchantments.find(
            (enchantment) => enchantment.name == this.selectedEnchantment
          )
        );
        selectedEnchantment.description = this.description;
        selectedEnchantment.altName = this.name;
        this.$store.commit("updateEnchantment", {
          cardID: this.cardID,
          index: this.id,
          enchantment: selectedEnchantment,
        });
      }
    },
    name: function (newName, oldName) {
      if (oldName) {
        var selectedEnchantment = {};
        selectedEnchantment = Object.assign(
          {},
          this.enchantments.find(
            (enchantment) => enchantment.name == this.selectedEnchantment
          )
        );
        selectedEnchantment.description = this.description;
        selectedEnchantment.altName = this.name;
        this.$store.commit("updateEnchantment", {
          cardID: this.cardID,
          index: this.id,
          enchantment: selectedEnchantment,
        });
      }
    },
  },
  mounted() {
    if (typeof this.card.selectedEnchantments[this.id].altName != "undefined") {
      this.name = this.card.selectedEnchantments[this.id].altName;
    } else {
      this.name = this.selectedEnchantment;
    }
    this.description = this.card.selectedEnchantments[this.id].description;
  },
  computed: {
    ...mapState(["enchantments"]),
    selectedEnchantment() {
      return this.card.selectedEnchantments[this.id].name;
    },
  },
};
</script>

<style scoped>
.menu-btn {
  background: #1b1b1b;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  text-align: center;
  line-height: 25px !important;
  height: 25px;
  width: 25px;
  background: transparent;
  transition: all 0.5s ease;
  cursor: pointer;
  background: var(--dark);
  color: #f5fff8;
}

.toprow {
  transition: all 0.3s ease;
  cursor: pointer;
  background: var(--dark);
  padding-left:5px;
}

.toprow:hover,
.highlight {
  color: var(--main2);
  filter: grayscale(0%) opacity(1);
  background: #141418;
}

textarea {
  padding: 5px;
  width: 100%;
  height: 200px;
  font-family: "Montserrat", Helvetica, sans-serif;
}

.menu-enter-from,
.menu-leave-to {
  opacity: 0%;
}

.menu-enter-active {  transition: all 0.5s ease;
}


.menu-leave-active { 
transition: all 0.2s ease;}
</style>