<template>
  <div class="npc-generator-container">
    <!-- Shop Presentation -->

    <!-- FILTER BOX -->
    <!-- NPC FIELDS -->
    <div class="menu-row column settings-block npc">
      <div class="menu-row menu-header">
        <h2>NPC</h2>
        <div class="filter-random">
          <i class="fas fa-dice-d20" @click="shuffleNPC()"></i>
        </div>
      </div>
      <div class="menu-section">
        <div class="menu-row fields column">
          <div class="filter-row">
            <h3>Name:</h3>
            <input class="text-input" type="text" v-model="npcName" placeholder="NPC's Name" /><i class="fas fa-lock"
              @click="lockName = !lockName" :class="{ active: lockName }"></i>
          </div>
          <div class="filter-row">
            <h3>Race/Type:</h3>
            <select class="text-input" v-model="npcRace" placeholder="NPC's Race">
              <option v-for="race in npcRaces" v-bind:key="race">
                {{ race }}
              </option>
            </select><i class="fas fa-lock" @click="lockRace = !lockRace" :class="{ active: lockRace }"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- ADD SHOP BLOCK BUTTON -->

    <!-- SHOP BLOCK -->

    <div class="menu-row column settings-block shop" v-if="shop">
      <div class="menu-row menu-header">
        <h2>Shop</h2>
        <div class="filter-random">
          <i class="fas fa-dice-d20" @click="shuffleShop()"></i>
        </div>
      </div>
      <div class="menu-section">
        <div class="menu-row fields column">
          <div class="filter-row">
            <h3>Name:</h3>
            <input class="text-input" type="text" v-model="shopName" placeholder="Your Shop's Name" /><i
              class="fas fa-lock" @click="lockShopName = !lockShopName" :class="{ active: lockShopName }"></i>
          </div>
          <div class="filter-row">
            <h3>Location:</h3>
            <select class="text-input" v-model="shopLocation" placeholder="Shop Location">
              <option v-for="shopLocation in shopLocations" v-bind:key="shopLocation">
                {{ shopLocation }}
              </option>
            </select><i class="fas fa-lock" @click="lockShopLocation = !lockShopLocation"
              :class="{ active: lockShopLocation }"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- INVENTORY -->

    <div class="menu-row column settings-block inventory" v-if="shop">
      <div class="menu-row menu-header" @click.self="showInventoryFields = !showInventoryFields">
        <div class="menu-row" @click.self="showInventoryFields = !showInventoryFields">
          <h2 @click.self="showInventoryFields = !showInventoryFields">
            Inventory
          </h2>
        </div>
        <div class="filter preset-container menu-row">
          <h3>Preset:</h3>
          <select class="inventory-select preset" v-model="shopInventory" name="inventory" @change="onChange($event)">
            <option value="None">None</option>
            <option value="Town">Town</option>
            <option value="City">City</option>
            <option value="Metropolis">Metropolis</option>
          </select>
        </div>
        <div class="filter-random">
          <i class="fas fa-dice-d20" @click="shuffleInventory()"></i>
        </div>
      </div>

      <div class="menu-section column">
        <div class="menu-row fields">
          <div class="filter">
            <div class="menu-row left inventory-size">
              <h3>Items</h3>
            </div>
            <div class="number-items">
              <transition name="fade" mode="out-in" v-if="numberOptionDice">
                <div class="dice-options">
                  <select name="dice" v-model="dice" class="dice-select">
                    <option value="4">d4</option>
                    <option value="6">d6</option>
                    <option value="8">d8</option>
                    <option value="10">d10</option>
                    <option value="12">d12</option>
                    <option value="20">d20</option>
                  </select>
                  <select name="modifier" v-model="modifier" class="modifier-select" v-if="numberOptionDice">
                    <option value="+">+</option>
                    <option value="-">–</option>
                  </select>
                </div>
              </transition>
              <input type="number" class="text-input" v-model="nItems" min="0" max="20" /><i class="fas fa-lock"
                @click="lockItemsN = !lockItemsN" :class="{ active: lockItemsN }"></i>
            </div>
          </div>
        </div>
        <div class="menu-row fields">
          <div class="filter">
            <h3>Value</h3>
            <div class="menu-row centered number-inputs">
              <input type="number" class="number-input" v-model="value1" placeholder="0" />
              <h3 class="dash">–</h3>
              <input type="number" class="number-input" v-model="value2" placeholder="Any" /><i class="fas fa-lock"
                @click="lockValue = !lockValue" :class="{ active: lockValue }"></i>
            </div>
          </div>
        </div>
        <div class="menu-row fields">
          <div class="filter" @mouseleave="showTypeFilters = false">
            <div class="menu-row space-between">
              <h3>Item Types</h3>
              <i class="fas fa-lock" @click="lockType = !lockType" :class="{ active: lockType }"></i>
            </div>
            <div class="option-checkmarks">
              <div v-for="itemType in types" v-bind:key="itemType" @click="
                includedTypes.includes(itemType) && includedTypes.length > 1
                  ? (includedTypes = includedTypes.filter(
                    (includedType) => includedType != itemType
                  ))
                  : !includedTypes.includes(itemType)
                    ? includedTypes.push(itemType)
                    : null
              " class="filter-element" :class="{ unchecked: !includedTypes.includes(itemType) }">
                {{ itemType }}
              </div>
            </div>
          </div>
        </div>
        <div class="menu-row fields">
          <div class="filter" @mouseleave="showRarityFilters = false">
            <div class="menu-row space-between">
              <h3>Item Rarities</h3>
              <i class="fas fa-lock" @click="lockRarity = !lockRarity" :class="{ active: lockRarity }"></i>
            </div>
            <div class="option-checkmarks">
              <div v-for="rarity in rarities" v-bind:key="rarity" @click="
                includedRarities.includes(rarity) &&
                  includedRarities.length > 1
                  ? (includedRarities = includedRarities.filter(
                    (itemRarity) => itemRarity != rarity
                  ))
                  : !includedRarities.includes(rarity)
                    ? includedRarities.push(rarity)
                    : null
              " class="filter-element" :class="{
  unchecked: !includedRarities.includes(rarity),
}">
                {{ rarity }}
              </div>
            </div>
          </div>
        </div>
        <div class="menu-row fields">
          <div class="filter" @mouseleave="showSourceFilters = false">
            <div class="menu-row space-between">
              <h3>Item Sources</h3>
              <i class="fas fa-lock" @click="lockSource = !lockSource" :class="{ active: lockSource }"></i>
            </div>
            <div class="option-checkmarks">
              <div v-for="source in sources" v-bind:key="source" @click="
                includedSources.includes(source) && includedSources.length > 1
                  ? (includedSources = includedSources.filter(
                    (itemSource) => itemSource != source
                  ))
                  : !includedSources.includes(source)
                    ? includedSources.push(source)
                    : null
              " class="filter-element" :class="{
  unchecked: !includedSources.includes(source),
}">
                {{ source }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- NPC PRESENTATION -->

    <div class="shop-presentation-column npc">
      <h2 class="shop-presentation-header">NPC</h2>
      <NpcImage :type="'npc'" :npcRace="npcRace" @setImageSRC="setNpcImage"></NpcImage>
      <p class="npc-text" v-html="npcDescription"></p>
      <ul class="npc-traits">
        <li><span class="trait">Ideal</span> {{ npcIdeal }}</li>
        <li><span class="trait">Flaw</span> {{ npcFlaw }}</li>
        <li><span class="trait">Bond</span> {{ npcBond }}</li>
      </ul>
      <!-- BUTTONS -->
      <div class="buttons-container">
        <div class="button-standard" @click="(shop = !shop), shuffleShop()">
          <i class="fas" :class="{ 'fa-plus-circle': !shop, 'fa-minus-circle': shop }"></i>{{ shop ? "Remove Shop" : "Add Shop" }}
        </div>
        <div class="button-standard shuffle-button" @click="shuffleNPC(), shuffleShop(), shuffleInventory()">
          <i class="fas fa-dice-d20"></i>Roll Random
        </div>
        <div class="button-standard" @click="saveShop()">
          <i class="fas fa-save"></i>Save & Edit
        </div>
      </div>
    </div>

    <!--- SHOP PRESENTATION --->

    <div class="shop-presentation-column shop" v-show="shop">
      <h2 class="shop-presentation-header">Shop</h2>
      <NpcImage :type="'shop'" :shopLocation="shopLocation" @setImageSRC="setShopImage"></NpcImage>
      <p class="npc-text" v-html="shopDescription"></p>
    </div>

    <!-- INVENTORY -->

    <PriceListTable :generatorItems="inventory" :mode="'generator'" @filterItem="filterItem" class="inventory-table"
      v-if="shop" />

    <!-- WARNINGS -->

    <div class="menu-row centered" v-if="showNonameWarning || showTooManyShopsWarning">
      <div class="warning" v-if="showNonameWarning">Please input a name.</div>
      <div class="warning" v-if="showTooManyShopsWarning">
        You already have 10 shops. Delete some shops to create more.
      </div>
      <div class="warning" v-if="showDuplicateWarning">
        You already have a shop with that name. Please choose another name.
      </div>
    </div>
  </div>
</template>

<script>
import PriceListTable from "../PriceList/PriceListTable.vue";
import NpcImage from "./NpcImage.vue";
import { mapState } from "vuex";

export default {
  name: "NpcMenu",
  components: { PriceListTable, NpcImage },
  data() {
    return {
      shop: false,
      showNpcImageFilters: false,
      showShopImageFilters: false,
      lockName: false,
      lockRace: false,
      lockShopName: false,
      lockShopLocation: false,
      lockItemsN: false,
      lockValue: false,
      lockType: false,
      lockRarity: false,
      lockSource: false,
      inventoryTable: false,
      focusModifier: "",
      focusOptions: ["are exclusively", "are mostly", "includes"],
      focus: "spellcaster",
      rarityModifier1: "mostly",
      rarity1: "common",
      rarityModifier2: "some",
      rarity2: "uncommon",
      rarityModifier3: "few",
      rarity3: "rare",
      showNpcButtons: false,
      showShopButtons: false,
      maleActivated: false,
      femaleActivated: false,
      intersexActivated: true,
      npcLockActivated: true,
      shopLockActivated: true,
      showShopNameInput: false,
      showShopAppearance1Input: false,
      showShopAppearance2Input: false,
      showShopLocationInput: false,
      showShopQuirkInput: false,
      showShopProtectionInput: false,
      showDiceInput: false,
      showValueInput1: false,
      showValueInput2: false,
      showTypeInput: false,
      showTypesDrop: false,
      items: this.$store.state.items,
      shopName: "",
      shopInventory: "None",
      showTypeFilters: false,
      showRarityFilters: false,
      showSourceFilters: false,
      showNPCFields: false,
      showShopFields: false,
      showInventoryFields: true,
      value1: 0,
      value2: 0,
      numberOptionFixed: false,
      numberOptionDice: true,
      npcName: "",
      npcRace: "",
      npcBackground: "",
      npcBond: "",
      npcIdeal: "",
      npcFlaw: "",
      npcAppearance: "",
      npcPersonality1: "",
      npcPersonality2: "",
      npcQuirk: "",
      npcBias1: "",
      npcBias2: "",
      shopLocation: "",
      shopAppearance1: "",
      shopAppearance2: "",
      shopQuirk: "",
      shopQuirkLonger: "",
      shopProtectionLonger: "",
      shopProtection: "",
      sources: ["DMG", "WGMM", "XGTE", "TCOE", "Custom"],
      types: [
        "Armor",
        "Potion",
        "Ring",
        "Rod",
        "Scroll",
        "Staff",
        "Wand",
        "Weapon",
        "Wondrous Item",
      ],
      npcNames: [
        "Alerak",
        "Alos",
        "Doxith",
        "Erennith",
        "Ezziah",
        "Famil",
        "Harrizan",
        "Imellin",
        "Isoldir",
        "Lorrell",
        "Malin",
        "Markon",
        "Mithran",
        "Perrinar",
        "Qvinth",
        "Reshin",
        "Umês",
        "Xorellion",
        "Ylithis",
        "Zofir",
        "Aria",
        "Bree",
        "Cai",
        "Darix",
        "Eli",
        "Fae",
        "Gwensil",
        "Hux",
        "Ivy",
        "Jax",
        "Kai",
        "Lena",
        "Nix",
        "Rae",
        "Sky",
        "Tahlin",
        "Xanderil",
        "Yarael",
        "Zane",
      ],
      shopnamePrefixes: [
        "Awesome",
        "Magical",
        "Fantastic",
        "Mysterious",
        "Delightful",
        "Unique",
        "Curious",
        "Extraordinary",
        "Enchanted",
        "Exotic",
        "Arcane",
        "Bewitched",
        "Charmed",
        "Fabled",
        "Glamorous",
        "Legendary",
        "Otherworldly",
        "Spellbinding",
        "Unearthly",
        "Wondrous",
      ],
      shopnameSuffixes: [
        "Curiosities",
        "Shop",
        "Shoppe",
        "Dispensary",
        "Goods",
        "Artifacts",
        "Relics",
        "Discoveries",
      ],
      shopLocations: [
        "Shop",
        "House",
        "Market stall",
        "Workshop",
        "Laboratory",
        "Smithy",
        "Mansion",
        "Inn",
        "Tavern",
        "Temple",
        "Tower",
        "Castle",
        "Garden",
        "Ship",
        "Cave",
        "Carriage",
        "Hut",
        "Treehouse",
        "Gallery",
        "Ruins",
      ],
      shopAppearances1: [
        "Cluttered",
        "Minimalistic",
        "Dark",
        "Bright",
        "Decrepit",
        "Small",
        "Beautiful",
        "Large",
      ],
      shopAppearances2: [
        "Messy",
        "Organized",
        "Damp",
        "Colorful",
        "Dusty",
        "Cozy",
        "Luxurious",
        "Spacious",
      ],
      shopQuirks: [
        "Bad Smell",
        "Good Smell",
        "Invisible Servants",
        "Warm",
        "Cold",
        "Exotic Pet",
        "Clockwork Toy",
        "Repeating Parrot",
        "Silent",
        "Humid",
      ],
      shopQuirksLong: [
        "smells like <b>rotten eggs</b>",
        "smells like <b>fresh baking</b>",
        "has shelves stocked by <b>invisible servants</b>",
        "is <b>unnaturally warm</b>",
        "is <b>unnaturally cold</b>",
        "has a <b>dog that growls at bad offers</b>",
        "has a <b>clockwork toy that claps at good offers</b>",
        "has a <b>parrot that repeats the merchant’s offers</b>",
        "is <b>unnaturally silent</b>",
        "is as <b>humid as a jungle</b>",
      ],
      shopProtections: [
        "Illusory Items",
        "Cursed Items",
        "Extradimensional Items",
        "Item Alarms",
        "Self-destructing Items",
        "Antimagic Field",
        "Mercenaries",
        "Guardians",
      ],
      shopProtectionsLong: [
        "the fact that <b>all items on display are illusory</b>",
        "<b>dire curses</b> placed on the inventory",
        "the fact that the inventory is hidden in an <b>extradimensional space</b>",
        "the <b>loud noises</b> the inventory makes when touched",
        "the fact that <b>the inventory blows up</b> if the owner perishes",
        "an <b>antimagic field</b> that prevents spellcasting",
        "a band of <b>vigilant mercenaries</b> sworn to hunt down thieves",
        "<b>fearsome magical constructs</b>",
      ],
      npcBackgrounds: [
        "Adventurer",
        "Sailor",
        "Noble",
        "Priest",
        "Mercenary",
        "Urchin",
        "Burglar",
        "Wizard",
        "Scholar",
        "Author",
        "Musician",
        "Traveler",
        "Soldier",
        "Court advisor",
        "Gladiator",
        "Inventor",
        "Knight",
        "Art collector",
        "Diplomat",
      ],
      npcBonds: [
        "I am deeply loyal to my family and will do anything to protect them.",
        "I have a strong sense of duty to my community and will go to great lengths to help those in need.",
        "I am fiercely devoted to my faith and will not compromise my beliefs.",
        "I am fiercely protective of my friends and will do anything to keep them safe.",
        "I have a deep love for my homeland and yearn to return one day.",
        "I have a strong connection to a particular place, such as a childhood home or a natural setting, and feel a deep sense of longing to be there.",
        "I am deeply devoted to my craft or profession and take great pride in my work.",
        "I have a strong bond with an animal, such as a pet or a mount, and will go to great lengths to protect them.",
        "I am deeply connected to a particular object, such as a family heirloom or a sentimental gift, and will go to great lengths to keep it safe.",
        "I am deeply in love with a particular person and will do anything to be with them.",
        "I have a deep sense of loyalty to a particular organization or cause, and will go to great lengths to support it.",
        "I am deeply connected to a particular hobby or activity and derive great joy from it.",
        "I have a strong bond with a particular deity or spiritual entity and am deeply devoted to their teachings.",
        "I have a strong connection to my ancestors and seek to honor their legacy.",
        "I am deeply devoted to a particular person, such as a mentor or role model, and seek to live up to their example.",
        "I am deeply connected to a particular place, such as a city or region, and take great pride in its history and culture.",
        "I am fiercely protective of my reputation and will go to great lengths to maintain it.",
        "I am deeply devoted to a particular cause, such as justice or freedom, and will fight to defend it.",
        "I have a deep sense of loyalty to a particular group or organization and will go to great lengths to support it.",
        "I am deeply connected to my culture and traditions and seek to preserve them.",
        "I am deeply devoted to a particular artistic medium, such as music or literature, and seek to create meaningful works in it.",
        "I have a strong bond with a particular group of people, such as a guild or club, and take great pride in my membership.",
        "I am deeply connected to a particular person, such as a spouse or child, and will do anything to keep them safe.",
        "I am fiercely protective of a particular place, such as a home or village, and will go to great lengths to defend it.",
        "I am deeply devoted to a particular cause, such as equality or the environment, and will work tirelessly to promote it.",
        "I have a strong bond with a particular animal, such as a familiar or a magical beast, and seek to protect and care for it.",
        "I am deeply connected to a particular object, such as a magical artifact or a treasured possession, and will go to great lengths to keep it safe.",
      ],
      npcIdeals: [
        "Freedom. I will not be shackled by the rules or expectations of others.",
        "Loyalty. I will stand by my friends and allies, no matter what.",
        "Courage. I will face any challenge with bravery and determination.",
        "Justice. I will always seek to do what is right, even if it is difficult.",
        "Honesty. I will always speak the truth, even if it is inconvenient.",
        "Ambition. I will strive for greatness and success at all costs.",
        "Fairness. I will always treat others with respect and kindness.",
        "Piety. I will honor the gods and follow their teachings.",
        "Wisdom. I will seek knowledge and understanding in all things.",
        "Love. I will spread joy and happiness wherever I go.",
        "Charity. I will help those in need, whenever I can.",
        "Community. I will work to build strong, supportive relationships with those around me.",
        "Respect. I will show respect to all, regardless of their station in life.",
        "Responsibility. I will take charge of my own actions and be accountable for their consequences.",
        "Loyalty. I will be true to my word and my promises.",
        "Perseverance. I will never give up, no matter how difficult things may seem.",
        "Forgiveness. I will forgive those who have wronged me, and seek to be forgiven for my own mistakes.",
        "Compassion. I will show empathy and understanding towards others.",
        "Humility. I will recognize my own limitations and be modest in my achievements.",
        "Dignity. I will always hold myself to the highest standards of behavior.",
        "Patience. I will be calm and composed, even in the face of adversity.",
        "Generosity. I will share my blessings with others, freely and without reservation.",
        "Courage. I will not be afraid to stand up for what I believe in, even if it means facing difficult challenges.",
        "Gratitude. I will be thankful for all the good things in my life, and strive to pay them forward.",
        "Serenity. I will find peace and contentment within myself, no matter what the world throws my way.",
        "Selflessness. I will put the needs of others before my own.",
        "Bravery. I will not shrink from danger or hardship, but instead meet them head on.",
        "Fairness. I will always seek to be fair and unbiased in my dealings with others.",
        "Tolerance. I will respect the beliefs and opinions of others, even if they differ from my own.",
        "Forgiveness. I will be quick to forgive others for their mistakes, and work to repair damaged relationships.",
      ],
      npcFlaws: [
        "I am slow to trust other people and have a hard time letting my guard down.",
        "I am easily swayed by my emotions and have a hard time thinking logically in heated situations.",
        "I have a hot temper and struggle to control my anger.",
        "I am prone to selfishness and put my own interests above those of others.",
        "I am a bit of a perfectionist and have a hard time accepting anything less than the best.",
        "I am prone to procrastination and have a hard time following through on tasks and projects.",
        "I am easily manipulated and have a hard time standing up for myself.",
        "I have a tendency to be overly critical and find it hard to give praise or encouragement.",
        "I am a bit of a gossip and have a hard time keeping secrets or maintaining confidentiality.",
        "I am prone to jealousy and have a hard time seeing others succeed or be happy.",
        "I am stubborn and have a hard time admitting when I'm wrong or changing my mind.",
        "I have a tendency to be selfish and put my own needs above those of others.",
        "I have a hard time accepting responsibility for my mistakes and often try to deflect blame onto others.",
        "I am prone to greed and have a hard time resisting the temptation of wealth or power.",
        "I am a bit of a drama queen and have a tendency to overreact or make a big deal out of small issues.",
        "I am a bit of a control freak and have a hard time delegating tasks or relinquishing control.",
        "I am prone to laziness and have a hard time staying motivated or putting in the effort required.",
        "I have a tendency to be arrogant and think I'm always right, even when I'm not.",
        "I am a bit of a flirt and have a hard time respecting the boundaries of others.",
        "I have a hard time expressing my feelings and often bottle things up, leading to emotional outbursts.",
        "I am prone to envy and have a hard time being happy for others when they have something I want.",
        "I have a tendency to be manipulative and use others for my own gain.",
        "I am a bit of a coward and have a hard time standing up for what I believe in or facing challenges head on.",
        "I am prone to impulsiveness and have a hard time thinking things through before acting.",
        "I have a hard time being true to myself and often bend to the will of others or try to fit in.",
        "I am prone to dishonesty and have a hard time being truthful, even when it would be better to be honest.",
        "I have a tendency to be overbearing and have a hard time respecting the autonomy of others.",
        "I am a bit of a sloth and have a hard time finding the energy or motivation to do things.",
        "I am prone to greed and have a hard time being content with what I have.",
        "I am a bit of a glutton and have a hard time controlling my appetite or cravings.",
      ],
      npcPersonalities1: [
        "Inquisitive",
        "Friendly",
        "Irritable",
        "Arrogant",
        "Boastful",
        "Humorless",
        "Sesquipedalian",
        "Pompous",
        "Suspicious",
        "Naive",
      ],
      npcPersonalities2: [
        "Philosophical",
        "Flustered",
        "Rude",
        "Courteous",
        "Introverted",
        "Self-deprecating",
        "Excited",
        "Impatient",
        "Greedy",
        "Awkward",
      ],
      npcAppearances: [
        "Wooden Leg",
        "Eyepatch",
        "Missing Teeth",
        "Facial Scar",
        "Slackjawed",
        "Milky-white Eyes",
        "Poor Posture",
        "Haunting Beauty",
        "Colorful Hair",
        "Colorful Tattoos",
        "Piercings",
        "Tall and Lanky",
        "Short and Squat",
        "Brawny",
        "Malnourished",
        "Bulging Eyes",
        "Oversized Glasses",
        "Heavy Makeup",
        "Dressed in Rags",
        "Flamboyant",
      ],
      npcAppearancesLong: [
        "has a <b>wooden leg</b>, and is",
        "has an <b>eyepatch</b>, and is",
        "is <b>missing several teeth</b>, and is",
        "has a <b>gruesome facial scar</b>, and is",
        "has a <b>slackjawed expression</b>, and is",
        "has <b>milky-white eyes</b>, and is",
        "has a <b>poor and crooked posture</b>, and is",
        "is <b>eerily beautiful</b>,",
        "has <b>bright, Colorful hair</b>, and is",
        "has <b>colorful tattoos</b>, and is",
        "has <b>many piercings</b>, and is",
        "is <b>tall and lanky</b>,",
        "is <b>short, squat</b>,",
        "is <b>brawny</b>,",
        "is <b>gaunt, malnourished</b>,",
        "has <b>bulging eyes</b>, and is",
        "wears <b>oversized glasses</b>, and is",
        "wears <b>garish and clumsy makeup</b>, and is",
        "wears <b>ragged clothes</b>, and is",
        "wears <b>flamboyant clothes</b>, and is",
      ],
      npcQuirks: [
        "Stutters",
        "Lisps",
        "Speaks quickly",
        "Speaks slowly",
        "Speaks too softly",
        "Speaks too loudly",
        "Speaks nasally",
        "Stands too close",
        "Jokes a lot",
        "Mumbles a lot",
        "Coughs a lot",
        "Rhymes a lot",
        "Interrupts a lot",
        "Laughs a lot",
        "Fidgets a lot",
        "Forgets names",
        "Repeats words",
        "Hears poorly",
        "Gives nicknames",
        "Has nervous tics",
      ],
      npcBiases1: [
        "Humans",
        "Dwarves",
        "Gnomes",
        "Dragonborn",
        "Bards and musicians",
        "Druids and rangers",
        "Warlocks and sorcerers",
        "The Young",
        "The Attractive",
        "The Poor",
      ],
      npcBiases2: [
        "Elves",
        "Halflings",
        "Tieflings",
        "Barbarians and fighters",
        "Clerics and paladins",
        "Monks and rogues",
        "Wizards",
        "The Old",
        "The Rich",
        "The Pious",
      ],
      includedRarities: [
        "Common",
        "Uncommon",
        "Rare",
        "Very Rare",
        "Legendary",
        "Artifact",
      ],
      includedSources: ["DMG", "WGMM", "XGTE", "TCOE", "Custom"],
      includedTypes: [
        "Armor",
        "Potion",
        "Ring",
        "Rod",
        "Scroll",
        "Staff",
        "Wand",
        "Weapon",
        "Wondrous Item",
      ],
      indludedNpcRaces: [
        "Human",
        "Half-Elf",
        "Half-orc",
        "High Elf",
        "Wood Elf",
        "Dark Elf",
        "Dwarf",
        "Duergar",
        "Gnome",
        "Halfling",
        "Tiefling",
        "Dragonborn",
      ],
      includedShopLocations: [
        "Shop",
        "House",
        "Market stall",
        "Workshop",
        "Laboratory",
        "Smithy",
        "Mansion",
        "Inn",
        "Tavern",
        "Temple",
        "Tower",
        "Castle",
        "Garden",
        "Ship",
        "Cave",
        "Carriage",
        "Hut",
        "Treehouse",
        "Gallery",
        "Ruins",
      ],
      nItems: 1,
      showNonameWarning: false,
      showTooManyShopsWarning: false,
      showDuplicateWarning: false,
      dice: 6,
      modifier: "+",
      npcDesription: "",
      npcImageSRC: "",
      shopImageSRC: "",
      shopNameSuffix: "",
      shopNamePrefix: "",
      filteredItems: [],
      inventory: [],
      includedImageRaces: [
        "Human",
        "Half-Elf",
        "Half-orc",
        "High Elf",
        "Wood Elf",
        "Dark Elf",
        "Dwarf",
        "Duergar",
        "Gnome",
        "Halfling",
        "Tiefling",
        "Dragonborn",
      ],
    };
  },
  mounted() {
    this.shuffleNPC();
    this.shuffleShop();
    this.shuffleInventory();
  },
  watch: {
    npcName: function (newValue, oldValue) {
      if (newValue != oldValue) {
        if (newValue === "") {
          var npcName = "Your NPC";
        } else {
          npcName = newValue;
        }
        this.shopName =
          npcName + "'s " + this.shopNamePrefix + " " + this.shopNameSuffix;
      }
    },
  },
  methods: {
    setNpcImage(npcImageSRC) {
      this.npcImageSRC = npcImageSRC;
    },
    setShopImage(shopImageSRC) {
      this.shopImageSRC = shopImageSRC;
    },
    goToLogin() {
      this.$router.push("/login");
    },
    async saveShop() {
      if (!this.shopName || this.shopName.length < 1) {
        this.showNonameWarning = true;
      } else if (
        this.$store.state.customShops &&
        this.$store.state.customShops.length >= 24
      ) {
        this.showTooManyShopsWarning = true;
      } else if (
        this.$store.state.customShops.some((shop) => shop.name == this.shopName)
      ) {
        this.showDuplicateWarning = true;
      } else {
        this.$store
          .dispatch("saveShop", {
            name: this.shop ? this.shopName : this.npcName,
            npcName: this.npcName,
            shopName: this.shopName,
            inventory: this.shop ? this.inventory : null,
            npcDescription: this.npcDescription
              .replaceAll("<b>", "")
              .replaceAll("</b>", ""),
            shopDescription: this.shop
              ? this.shopDescription.replace("<b>", "").replace("</b>", "")
              : null,
            npcImageSRC: this.npcImageSRC,
            shopImageSRC: this.shop ? this.shopImageSRC : null,
            npcIdeal: "<b>IDEAL: </b>" + this.npcIdeal,
            npcFlaw: "<b>FLAW: </b>" + this.npcFlaw,
            npcBond: "<b>BOND: </b>" + this.npcBond,
          })
          .then((response) => {
            response
              ? this.$store.commit(
                "selectNpc",
                this.shop ? this.shopName : this.npcName
              )
              : "";
            this.$router.push("/npcoverview");
          });
      }
    },
    onChange(event) {
      let preset = event.target.value;
      switch (preset) {
        case "Town":
          this.numberOptionDice = true;
          this.numberOptionFixed = false;
          this.dice = 4;
          this.modifier = "+";
          this.nItems = 1;
          this.includedRarities = ["Common", "Uncommon"];
          this.includedTypes = ["Armor", "Potion", "Weapon", "Wondrous Item"];
          this.value1 = 0;
          this.value2 = 1000;
          break;
        case "City":
          this.numberOptionDice = true;
          this.numberOptionFixed = false;
          this.dice = 6;
          this.modifier = "+";
          this.nItems = 2;
          this.includedRarities = ["Common", "Uncommon", "Rare"];
          this.includedTypes = [
            "Armor",
            "Potion",
            "Scroll",
            "Wand",
            "Weapon",
            "Wondrous Item",
          ];
          this.value1 = 0;
          this.value2 = 5000;
          break;
        case "Metropolis":
          this.numberOptionDice = true;
          this.numberOptionFixed = false;
          this.dice = 12;
          this.modifier = "+";
          this.nItems = 5;
          this.includedRarities = ["Common", "Uncommon", "Rare", "Very Rare"];
          this.value1 = 0;
          this.value2 = 20000;
          break;
        default:
          break;
      }
    },
    shuffleInventory() {
      let diceOptions = [4, 6, 8, 10, 12, 20];
      if (!this.lockItemsN) {
        this.dice = diceOptions[Math.floor(Math.random() * diceOptions.length)];
        this.modifier = "+";
        this.nItems = Math.floor(Math.random() * 10);
      }
      if (!this.lockRarity) {
        let nRarities = Math.floor(Math.random() * 4) + 1;
        nRarities == 1
          ? (this.includedRarities = ["Common"])
          : nRarities == 2
            ? (this.includedRarities = ["Common", "Uncommon"])
            : nRarities == 3
              ? (this.includedRarities = ["Common", "Uncommon", "Rare"])
              : nRarities == 4
                ? (this.includedRarities = [
                  "Common",
                  "Uncommon",
                  "Rare",
                  "Very Rare",
                ])
                : (this.includedRarities = []);
      }
      if (!this.lockType) {
        let nTypes = Math.floor(Math.random() * 4) + 2;
        nTypes == 2
          ? (this.includedTypes = ["Armor", "Weapon"])
          : nTypes == 3
            ? (this.includedTypes = ["Potion", "Scroll", "Wondrous Item"])
            : nTypes == 4
              ? (this.includedTypes = ["Rod", "Scroll", "Staff", "Wand"])
              : nTypes == 5
                ? (this.includedTypes = [
                  "Armor",
                  "Potion",
                  "Ring",
                  "Weapon",
                  "Wondrous Item",
                ])
                : (this.includedTypes = []);
      }
      if (!this.lockValue) {
        this.value1 = 0;
        let highValues = [
          500, 1000, 2500, 5000, 10000, 15000, 25000, 50000, 100000,
        ];
        this.value2 = highValues[Math.floor(Math.random() * highValues.length)];
      }
      this.focusModifier =
        this.focusOptions[Math.floor(Math.random() * this.focusOptions.length)];
      this.generateInventory();
    },
    generateInventory() {
      let filteredItems = this.items.map(({ value, ...rest }) => ({
        ...rest,
        value: Number(value),
      }));
      if (this.$store.state.logged_in) {
        filteredItems = this.$store.state.customItems.concat(filteredItems);
      }
      const seen = new Set();
      filteredItems = filteredItems.filter((item) => {
        const duplicate = seen.has(item.name);
        seen.add(item.name);
        return !duplicate;
      });
      if (this.value1 != null && this.value1 != "") {
        filteredItems = filteredItems.filter(
          (item) => item.value >= this.value1
        );
      }
      if (this.value2 != null && this.value2 != "") {
        filteredItems = filteredItems.filter(
          (item) => item.value <= this.value2
        );
      }
      if (
        this.includedTypes &&
        this.includedTypes.length != this.types.length
      ) {
        filteredItems = filteredItems.filter((item) =>
          this.includedTypes.includes(item.type)
        );
      }
      if (
        this.includedRarities &&
        this.includedRarities.length != this.rarities.length
      ) {
        filteredItems = filteredItems.filter((item) =>
          this.includedRarities.includes(item.rarity)
        );
      }
      if (
        this.includedSources &&
        this.includedSources.length != this.sources.length
      ) {
        filteredItems = filteredItems.filter((item) =>
          this.includedSources.includes(item.source)
        );
      }

      const chooseRandom = (arr, num = 1) => {
        const res = [];
        for (let i = 0; i < num;) {
          const random = Math.floor(Math.random() * arr.length);
          if (res.indexOf(arr[random]) !== -1) {
            continue;
          }
          res.push(arr[random]);
          i++;
        }
        return res;
      };
      let inventorySize = 1;
      if (this.numberOptionDice) {
        if (this.modifier == "+") {
          inventorySize =
            Math.abs(this.nItems) +
            Math.floor(Math.random() * parseInt(this.dice) + 1);
        } else
          inventorySize =
            -Math.abs(this.nItems) +
            Math.floor(Math.random() * parseInt(this.dice) + 1);
      } else {
        inventorySize = this.nItems;
      }
      if (inventorySize > filteredItems.length) {
        inventorySize = filteredItems.length;
      }
      inventorySize < 1 ? (inventorySize = 1) : "";
      if (filteredItems.length > 0) {
        var inventory = chooseRandom(filteredItems, inventorySize);
      } else {
        inventory = [];
      }
      this.inventory = inventory;
    },
    shuffleShop() {
      if (!this.lockShopName) {
        this.shopNamePrefix =
          this.shopnamePrefixes[
          Math.floor(Math.random() * this.shopnamePrefixes.length)
          ];
        this.shopNameSuffix =
          this.shopnameSuffixes[
          Math.floor(Math.random() * this.shopnameSuffixes.length)
          ];
        this.shopName =
          this.npcName +
          "'s " +
          this.shopNamePrefix +
          " " +
          this.shopNameSuffix;
      }
      if (!this.lockShopLocation) {
        this.shopLocation =
          this.shopLocations[
          Math.floor(Math.random() * this.shopLocations.length)
          ];
      }
      this.shopAppearance1 =
        this.shopAppearances1[
        Math.floor(Math.random() * this.shopAppearances1.length)
        ];
      this.shopAppearance2 =
        this.shopAppearances2[
        Math.floor(Math.random() * this.shopAppearances2.length)
        ];
      this.shopQuirk = Math.floor(Math.random() * this.shopQuirks.length);
      this.shopProtection = Math.floor(
        Math.random() * this.shopProtections.length
      );
    },
    shuffleNPC() {
      if (!this.lockName) {
        this.npcName =
          this.npcNames[Math.floor(Math.random() * this.npcNames.length)];
        this.shopName =
          this.npcName +
          "'s " +
          this.shopNamePrefix +
          " " +
          this.shopNameSuffix;
      }
      if (!this.lockRace) {
        this.npcRace =
          this.npcRaces[Math.floor(Math.random() * this.npcRaces.length)];
      }
      this.npcBackground =
        this.npcBackgrounds[
        Math.floor(Math.random() * this.npcBackgrounds.length)
        ];
      this.npcAppearance = Math.floor(
        Math.random() * this.npcAppearances.length
      );
      this.npcIdeal =
        this.npcIdeals[Math.floor(Math.random() * this.npcIdeals.length)];
      this.npcBond =
        this.npcBonds[Math.floor(Math.random() * this.npcBonds.length)];
      this.npcFlaw =
        this.npcFlaws[Math.floor(Math.random() * this.npcFlaws.length)];
      this.npcPersonality1 =
        this.npcPersonalities1[
        Math.floor(Math.random() * this.npcPersonalities1.length)
        ];
      this.npcPersonality2 =
        this.npcPersonalities2[
        Math.floor(Math.random() * this.npcPersonalities2.length)
        ];
      this.npcBias1 =
        this.npcBiases1[Math.floor(Math.random() * this.npcBiases1.length)];
      this.npcBias2 =
        this.npcBiases2[Math.floor(Math.random() * this.npcBiases2.length)];
      this.npcQuirk = Math.floor(Math.random() * this.npcQuirks.length);
    },
    filterItem(filteredItem) {
      this.inventory = this.inventory.filter(
        (item) => item.name != filteredItem.name
      );
    },
  },
  computed: {
    ...mapState(["npcRaces", "rarities"]),
    loggedIn() {
      return this.$store.state.logged_in;
    },
    npcDescription() {
      if (
        (this.npcRace || this.npcRace === 0) &&
        (this.npcBackground || this.npcBackground === 0) &&
        (this.npcAppearance || this.npcAppearance === 0) &&
        (this.npcPersonality1 || this.npcPersonality1 === 0) &&
        (this.npcPersonality2 || this.npcPersonality2 === 0) &&
        (this.npcBias1 || this.npcBias1 === 0) &&
        (this.npcBias2 || this.npcBias2 === 0)
      ) {
        if (this.npcRace == "Any") {
          var npcRace =
            this.npcRaces[Math.floor(Math.random() * this.npcRaces.length)];
        } else {
          npcRace = this.npcRace;
        }
        if (this.npcName === "") {
          var npcName = "Your NPC";
        } else {
          npcName = this.npcName;
        }
        let npcDescription =
          "<b>" +
          npcName +
          "</b>" +
          " is a <b>" +
          npcRace.toLowerCase() +
          "</b> who gave up life as a <b>" +
          this.npcBackground.toLowerCase() +
          "</b> to become a merchant. <b>" +
          npcName +
          "</b> " +
          this.npcAppearancesLong[this.npcAppearance].toLowerCase() +
          " <b>" +
          this.npcPersonality1.toLowerCase() +
          "</b> and <b>" +
          this.npcPersonality2.toLowerCase() +
          "</b>. <b>" +
          npcName +
          "</b> dislikes <b>" +
          this.npcBias1.toLowerCase() +
          "</b> and favors <b>" +
          this.npcBias2.toLowerCase() +
          "</b>.";
        return npcDescription;
      } else return "";
    },
    shopDescription() {
      if (
        this.shopName &&
        this.shopAppearance1 &&
        this.shopAppearance2 &&
        this.shopLocation &&
        (this.shopQuirk || this.shopQuirk === 0) &&
        (this.shopProtection || this.shopProtection === 0)
      ) {
        let shopDescription =
          "<b>" +
          this.shopName +
          "</b> is a <b>" +
          this.shopAppearance1.toLowerCase() +
          "</b> and <b>" +
          this.shopAppearance2.toLowerCase() +
          "</b> <b>" +
          this.shopLocation.toLowerCase() +
          "</b>. The shop " +
          this.shopQuirksLong[this.shopQuirk].toLowerCase() +
          " and is protected by " +
          this.shopProtectionsLong[this.shopProtection].toLowerCase() +
          ".";
        return shopDescription;
      } else return "";
    },
    inventoryText() {
      var inventory = this.inventory.map((item) => item.name).sort();
      var inventoryText = inventory.join("</i>, <i>");
      inventoryText = "<b>Inventory:</b> <i>" + inventoryText + "</i>";
      return inventoryText.toString();
    },
  },
};
</script>

<style scoped>
.menu-row {
  margin-bottom: 5px;
}

.npc-generator-container {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 300px auto;
  grid-template-areas:
    "npc-settings npc"
    "shop-settings shop"
    "inventory-settings inventory";
  justify-content: center;
  align-items: start;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  width: 1000px;
  margin: 100px auto 20px auto;
  border-radius: 5px;
  z-index: 101;
  user-select: none;
  transition: all 1s ease;
}

h2 {
  font-size: 1rem !important;
}

.settings-menu {
  margin: 0px 30px 0px 0px;
  max-width: 300px;
  /* background: #282838; */
  border-radius: 5px;
  position: relative;
}

.settings-block {
  margin: 0px 0px 10px 0px;
  background: #282838;
  border-radius: 5px;
  transition: all 0s ease;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
}

.settings-block.npc {
  grid-area: npc-settings;
}

.settings-block.shop {
  grid-area: shop-settings;
}

.settings-block.inventory {
  grid-area: inventory-settings;
}

.npc-buttons {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.npc-buttons>div {
  width: 23%;
  margin: 0px 1%;
}

.npc-button {
  background-color: black;
  color: white;
  text-align: center;
  font-family: montserrat, sans-serif;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0 4px 6px #111111;
  border-radius: 5px;
  padding-top: 5px;
  transition: 0.5s;
}

.npc-button>i,
.npc-button p {
  width: 100%;
  transition: 0.3s;
}

.npc-button:hover>i,
.npc-button:hover p {
  transform: scale(1.1);
  transition: 0.3s;
}

.npc-button>p {
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
}

select {
  border-radius: 5px;
  min-height: 40px;
  background-color: white;
  box-shadow: inset 0 0 4px 0 rgb(138 177 198 / 75%);
  border: solid 1px #9c9898;
  box-sizing: border-box;
}

.menu-header {
  border-radius: 5px;
  background: #36365a;
  cursor: pointer;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 10px;
}

.menu-section {
  transition: all 0.5s ease;
  width: 100%;
}

.fields {
  align-items: flex-start;
}

.menu-header,
.menu-header .menu-row {
  margin-bottom: 0px !important;
}

.filter,
.selected-filters {
  width: 100%;
  font-family: montserrat, sans-serif;
  margin: 0px;
}

.filter-row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin: 10px !important;
  width: 280px;
}

.filter-row h3 {
  font-size: 1rem;
}

input {
  cursor: pointer;
}

.fields>div {
  margin: 0px 10px;
}

.inventory-select {
  width: 100%;
}

.inventory-size {
  margin: 0;
}

.number-items {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.number-items .text-input {
  margin-left: 5px;
  width: 33.3% !important;
}

.dice-options {
  display: flex;
  width: 66.6%;
}

.dice-select {
  margin-right: 5px;
  width: 60%;
  text-align: center;
}

.modifier-select {
  width: 40%;
  text-align: center;
  font-weight: 600;
  font-size: 0.8rem;
}

.filter-random {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 10px;
}

.filter-random i {
  font-size: 1.5rem;
  margin: 3px 0px;
  color: white;
  transition: all 0.3s ease;
}

.filter-random i:hover {
  color: darkgreen;
  transition: all 0.3s ease;
  transform: scale(1.1);
}

.filter-settings {
  position: absolute;
  width: 100%;
  bottom: 100%;
  left: -50%;
  padding: 10px 20px;
  transform: translate(50%, 0);
  display: flex;
  flex-flow: row wrap;
}

.filter- .number-inputs {
  align-items: center;
  margin-bottom: 0px;
}

.number-items .text-input,
.number-inputs,
.number-input,
.text-input,
select,
.selected-filters {
  width: 100%;
  min-height: 30px;
}

p {
  color: white;
}

.preset-container {
  width: 250px;
}

.preset {
  min-width: 60%;
}

.npc-text,
.inventory-text {
  grid-area: npc-text;
  font-size: 1rem;
  font-family: montserrat, sans-serif;
}

.inventory-text {
  margin: 0;
}

h3 {
  font-size: 0.8rem;
}

.filter-element {
  font-size: 0.8rem;
  padding: 3px;
  max-height: 1.5rem;
  color: black;
}

.filter-element .fas {
  font-size: inherit;
}

.fa-lock {
  font-size: 1.3rem;
  cursor: pointer;
  filter: opacity(0.5);
  color: white;
  margin-left: 5px;
}

.fa-lock.active {
  filter: opacity(1);
}

.deactive {
  filter: opacity(0.9);
}

.shop-presentation {
  border-radius: 5px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: min-content min-content max-content;
  grid-template-areas:
    "npc"
    "shop"
    "inventory";
  justify-items: stretch;
  grid-row-gap: 10px;
}

.shop-presentation-column {
  display: grid;
  position: relative;
  padding: 10px;
  margin: 0;
  background: #282838;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  grid-template-columns: 33% 67%;
  grid-template-rows: 40px auto auto;
  grid-template-areas:
    "npc-image npc-title"
    "npc-image npc-text"
    "npc-image npc-traits";
  grid-column-gap: 10px;
}

.shop-presentation-column.shop {
  grid-template-columns: 33% 67%;
  grid-template-rows: 40px max-content;
  grid-template-areas:
    "npc-image npc-title"
    "npc-image npc-text";
  grid-column-gap: 10px;
}

.inventory-table {
  margin-top: 0px;
  grid-area: inventory;
  width: 100%;
  max-width: 820px;
  align-self: start;
}

.shop-presentation-header {
  font-size: 1.5rem !important;
  border-bottom: white 2px solid;
  width: 100%;
  margin-bottom: 10px;
  grid-area: npc-title;
}

.image-container {
  grid-area: npc-image;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  aspect-ratio: 1/1;
}

.image-container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.image-container:hover img {
  transform: scale(1.1);
}

.npc-image,
.shop-image {
  width: 100%;
  max-width: 400px;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.shop-presentation-header i {
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 10px;
}

.shop-presentation-header i:hover {
  transform: scale(1.15);
}

.npc-traits {
  grid-area: npc-traits;
  color: white;
  font-size: 1rem;
  font-family: montserrat, sans-serif;
  list-style-type: none;
  margin-left: 10px;
  text-indent: -10px;
}

.npc-traits li {
  margin: 10px 0rem 0rem 0rem;
}

.trait {
  background: black;
  padding: 2px 5px;
  border-radius: 5px;
  margin-right: 2.5px;
  text-transform: uppercase;
  font-weight: 600;
}

.shop-presentation-column.npc {
  grid-area: npc;
  max-width: 800px;
}

.shop-presentation-column.shop {
  grid-area: shop;
  max-width: 800px;
}

.button-standard {
  margin-top: 10px;
}

.option-checkmarks {
  display: flex;
  flex-flow: row wrap;
}

.unchecked {
  filter: opacity(0.3) grayscale(1);
}

/* MEDIA QUERIES */

@media only screen and (max-width: 1280px) {
  .npc-generator-container {
    margin: 100px auto 20px 10px;
  }
}

@media only screen and (max-width: 1079px) {
  .npc-generator-container {
    width: 100vw;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 95vw;
    grid-template-areas:
      "npc"
      "npc-settings"
      "shop"
      "shop-settings"
      "inventory"
      "inventory-settings";
    justify-content: center;
    align-items: start;
    grid-row-gap: 10px;
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .settings-menu {
    max-width: 100%;
    width: 100%;
    margin: 0px;
  }

  .shop-presentation {
    grid-template-columns: auto;
    grid-template-rows: min-content min-content max-content;
    grid-template-areas:
      "npc"
      "shop"
      "inventory";
    justify-items: stretch;
    grid-row-gap: 10px;
  }

  .shop-presentation-column {
    grid-template-columns: 100%;
    grid-template-rows: 40px auto auto;
    grid-template-areas:
      "npc-title"
      "npc-image"
      "npc-text"
      "npc-traits";
    grid-column-gap: 10px;
  }

  .shop-presentation-column.shop {
    grid-template-columns: 100%;
    grid-template-rows: 40px max-content;
    grid-template-areas:
      "npc-title"
      "npc-image"
      "npc-text";
    grid-column-gap: 10px;
  }

  .shop-presentation-column {
    margin-right: 0px;
    margin-top: 10px;
  }

  .filter-row {
    width: 100%;
    margin: 0px !important;
    padding: 10px;
  }

  .buttons-container {
    background-color: var(--container);
  }

  .buttons-container .button-standard {
    padding-top: 0px;
  }
}
</style>
