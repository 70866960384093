<template>
  <div class="menu-row column">
    <div class="menu-row font-options">
      <h3>FONT</h3>
      <p>Type</p>
      <p>Size</p>
      <p>Style</p>
    </div>
    <div class="menu-row font-options">
      <h3>Head:</h3>
      <select class="rounded" v-model="nameFont">
        <option>Auto</option>
        <option v-for="font in fonts" :key="font.index">
          {{ font }}
        </option>
      </select>
      <select class="rounded" v-model="nameFontSize">
        <option>Auto</option>
        <option v-for="fontSize in fontSizes" :key="fontSize.index">
          {{ fontSize }}
        </option>
      </select>
      <select class="rounded" v-model="nameFontStyle">
        <option>Auto</option>
        <option v-for="fontStyle in fontStyles" :key="fontStyle.index">
          {{ fontStyle }}
        </option>
      </select>
    </div>
    <div class="menu-row font-options">
      <h3>Body:</h3>

      <select class="rounded" v-model="descriptionFont">
        <option>Auto</option>
        <option v-for="font in fonts" :key="font.index">
          {{ font }}
        </option>
      </select>
      <select class="rounded" v-model="descriptionFontSize">
        <option>Auto</option>
        <option v-for="fontSize in fontSizes" :key="fontSize.index">
          {{ fontSize }}
        </option>
      </select>
      <select class="rounded" v-model="descriptionFontStyle">
        <option>Auto</option>
        <option v-for="fontStyle in fontStyles" :key="fontStyle.index">
          {{ fontStyle }}
        </option>
      </select>
    </div>
    <div class="menu-row font-options">
      <h3>Foot:</h3>
      <select class="rounded" v-model="footerFont">
        <option>Auto</option>
        <option v-for="font in fonts" :key="font.index">
          {{ font }}
        </option>
      </select>
      <select class="rounded" v-model="footerFontSize">
        <option>Auto</option>
        <option v-for="fontSize in fontSizes" :key="fontSize.index">
          {{ fontSize }}
        </option>
      </select>
      <select class="rounded" v-model="footerFontStyle">
        <option>Auto</option>
        <option v-for="fontStyle in fontStyles" :key="fontStyle.index">
          {{ fontStyle }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardMenuFont",
  props: {
    card: Object,
    cardID: Number,
  },
  computed: {
    nameFont: {
      get() {
        if (this.card.nameFont && this.card.nameFont.length > 0) {
          return this.card.nameFont;
        } else {
          return "Auto";
        }
      },
      set(font) {
        this.$store.commit("updateNameFont", {
          cardID: this.cardID,
          font: font,
        });
      },
    },
    nameFontSize: {
      get() {
        if (typeof this.card.nameFontSize != "undefined") {
          return this.card.nameFontSize;
        } else {
          return "Auto";
        }
      },
      set(fontSize) {
        this.$store.commit("updateNameFontSize", {
          cardID: this.cardID,
          fontSize: fontSize,
        });
      },
    },
    nameFontStyle: {
      get() {
        if (typeof this.card.nameFontStyle != "undefined") {
          return this.card.nameFontStyle;
        } else {
          return "Auto";
        }
      },
      set(fontStyle) {
        this.$store.commit("updateNameFontStyle", {
          cardID: this.cardID,
          fontStyle: fontStyle,
        });
        console.log(this.card);
      },
    },
    descriptionFont: {
      get() {
        if (this.card.descriptionFont && this.card.descriptionFont.length > 0) {
          return this.card.descriptionFont;
        } else {
          return "Auto";
        }
      },
      set(font) {
        this.$store.commit("updateDescriptionFont", {
          cardID: this.cardID,
          font: font,
        });
      },
    },
    descriptionFontSize: {
      get() {
        if (typeof this.card.descriptionFontSize != "undefined") {
          return this.card.descriptionFontSize;
        } else {
          return "Auto";
        }
      },
      set(fontSize) {
        this.$store.commit("updateDescriptionFontSize", {
          cardID: this.cardID,
          fontSize: fontSize,
        });
      },
    },
    descriptionFontStyle: {
      get() {
        if (typeof this.card.descriptionFontStyle != "undefined") {
          return this.card.descriptionFontStyle;
        } else {
          return "Auto";
        }
      },
      set(fontStyle) {
        this.$store.commit("updateDescriptionFontStyle", {
          cardID: this.cardID,
          fontStyle: fontStyle,
        });
      },
    },
    footerFont: {
      get() {
        if (this.card.typeFont && this.card.typeFont.length > 0) {
          return this.card.typeFont;
        } else {
          return "Auto";
        }
      },
      set(font) {
        this.$store.commit("updateTypeFont", {
          cardID: this.cardID,
          font: font,
        });
        this.$store.commit("updateAttunementFont", {
          cardID: this.cardID,
          font: font,
        });
      },
    },
    footerFontSize: {
      get() {
        if (typeof this.card.typeFontSize != "undefined") {
          return this.card.typeFontSize;
        } else {
          return "Auto";
        }
      },
      set(fontSize) {
        this.$store.commit("updateTypeFontSize", {
          cardID: this.cardID,
          fontSize: fontSize,
        });
      },
    },
    footerFontStyle: {
      get() {
        if (typeof this.card.typeFontStyle != "undefined") {
          return this.card.typeFontStyle;
        } else {
          return "Auto";
        }
      },
      set(fontStyle) {
        this.$store.commit("updateTypeFontStyle", {
          cardID: this.cardID,
          fontStyle: fontStyle,
        });
        this.$store.commit("updateAttunementFontStyle", {
          cardID: this.cardID,
          fontStyle: fontStyle,
        });
      },
    },
  },
  methods: {
    updateNameFont(font) {
      this.$store.commit("updateNameFont", {
        cardID: this.cardID,
        font: font,
      });
    },
    updateDescriptionFont(font) {
      this.$store.commit("updateDescriptionFont", {
        cardID: this.cardID,
        font: font,
      });
    },
    updateFooterFont(font) {
      this.$store.commit("updateTypeFont", {
        cardID: this.cardID,
        font: font,
      });
      this.$store.commit("updateAttunementFont", {
        cardID: this.cardID,
        font: font,
      });
    },
  },
  beforeMount() {
    if (this.card.nameFont) {
      this.titleSelected = this.card.nameFont;
    }
    if (this.card.descriptionFont) {
      this.descriptionSelected = this.card.descriptionFont;
    }
    if (this.card.typeFont) {
      this.footerSelected = this.card.typeFont;
    }
  },
  data() {
    return {
      fonts: [
        "DomLovesMary",
        "Montserrat",
        "Bookmania",
        "kingscaslon",
        "Georgia",
      ],
      fontSizes: [
        6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
        25, 26, 27, 28, 29, 30,
      ],
      fontStyles: ["Uppercase", "Lowercase", "Small Caps"],
    };
  },
};
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0px 2.5px;
}

.font-options:nth-of-type(1) {
  margin-bottom:0px;
}

.font-options > * {
  width:28.66%;
  margin-right:5px;  
}

.font-options :first-child {
  width:15%;
}

.font-options :last-child {
  margin-right:0px;
}

.font-options p {
  font-size: 0.75rem;
  text-transform:uppercase;
  font-weight:bold;
  text-align:center;
}

select {
  width: 100%;
  height: 100%;
  margin: 2.5px 0px;
  padding:5px 2.5px;
}
</style>