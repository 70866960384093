<template>
  <div class="list-card" :class="{ selected: selected }">
    <div class="select-checkbox" :class="{ checked: selected}" @click="updateSelectedCards">
    </div>
    <div class="menu-row" :key="updateKey">
      <div
        class="menu-row column left"
        @click="$store.commit('editCard', { cardID: cardID })"
      >
        <div class="item-header">
          <div class="item-name">{{ card.itemName }}</div>
          <div class="item-header-divider">|</div>
          <div class="item-info">
            {{ card.selectedType }}, {{ card.selectedRarity }}
            <i class="fa-solid fa-circle-a" v-if="attunementRequired"></i>
          </div>
        </div>
        <!-- <div
          class="item-subheader"
        >
          {{ card.selectedType }}, {{ card.selectedRarity }}
        </div> -->
        <div class="menu-row">
          <div class="item-description" v-html="itemDescription"></div>
        </div>
      </div>
      <i
        class="fas fa-trash"
        @click="$store.dispatch('deleteCard', { cardID: cardID })"
      ></i>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SidebarItem",
  props: {
    cardID: Number,
    card: Object,
  },
  computed: {
    ...mapState([
      "selectedCard",
      "selectedCards",
      "selectedCollection",
      "cards",
      "collections",
      "premium",
    ]),
    itemDescription() {
      var description = this.card.selectedItem
        ? this.card.selectedItem.description
        : "";
      var snippet = description ? description.substring(0, 150) + "..." : "...";
      return snippet;
    },
    attunementRequired() {
      if (
        !this.card.selectedAttunement ||
        this.card.selectedAttunement == "No"
      ) {
        return false;
      } else {
        return true;
      }
    },
    selected() {
      if (!this.selectedCards || this.selectedCards.length == 0) {
        return false;
      }
      return this.selectedCards.includes(this.cardID);
    },
  },
  methods: {
    printCard() {
      this.print = true;
    },
    forceUpdateComponent() {
      this.updateKey = this.updateKey + 1;
    },
    updateSelectedCards() {
      this.$store.commit("updateSelectedCards", this.cardID);
    },
  },
  data() {
    return {
      print: false,
      deletePrompt: false,
      showDelete: false,
      updateKey: 0,
    };
  },
};
</script>

<style scoped>
.list-card {
  color: #ffffff;
  padding: 0.5rem;
  position: relative;
  background: rgb(17, 17, 17);
  margin: 5px 0px 5px 5px;
  border-radius: 10px;
  cursor: pointer;
}

.item-header {
  font-size: 0.8rem;
  font-family: Montserrat, Serif;
  margin-bottom: 0.4rem;
  display: flex;
  flex-flow: row nowrap;
}

.item-name {
  font-weight: 600;
}

.item-header-divider {
  color: #d6d6d6;
  margin: 0px 0.5rem;
}

.item-header i {
  margin-left: 0.25rem;
}

.item-subheader {
  font-size: 0.8rem;
  font-family: Kings-Caslon, sans-serif;
  color: #d6d6d6;
  margin-bottom: 0.3rem;
  font-weight: 600;
}

.item-description {
  font-size: 0.9rem;
  font-family: Kings-Caslon, sans-serif;
}

.fa-trash {
  font-size: 1.5rem;
  transition: all 0.5s ease;
}

.fa-trash:hover {
  color: firebrick;
  transition: all 0.5s ease;
}

.selected {
  outline: 2px solid rgb(0, 114, 99);
}

.select-checkbox {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  background-color: whitesmoke;
  width:20px;
  height:20px;
  border-radius: 50%;
  padding:2.5px;
}

.select-checkbox.checked {
  background-color: rgb(0, 114, 99);
  border-radius: 50%;
  border: 2px solid whitesmoke;
}

.select-checkbox > div {
  background-color: rgb(0, 68, 114);
  width:100%;
  height:100%;
  border-radius: 50%;
}


</style>
