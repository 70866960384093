<template>
  <BaseHeader></BaseHeader>
  <PantheonEditor></PantheonEditor>
</template>

<script>

import BaseHeader from "../components/Header/BaseHeader.vue";
import PantheonEditor from "../components/PantheonGenerator/PantheonEditor.vue";

export default {
    name: "PantheonGenerator",
    components: {PantheonEditor, BaseHeader}

}
</script>

<style scoped>

</style>