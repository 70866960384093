<template>
<div>
<div class="menu-row">
  <h3>Description</h3>
  <i class="fas fa-bold rounded" @click="formatText('bold')"></i
  ><i class="fas fa-italic rounded" @click="formatText('italic')"></i
  ><i class="fas fa-circle rounded" @click="formatText('bullet')"></i>
                        <!-- <i
      class="fas rounded"
       @click="(locked = !locked)"
      :class="{ highlight: locked, 'fa-lock': locked, 'fa-unlock': !locked  }"
    ></i> -->
</div>
  <div class="menu-row" >
    <textarea
      name="custom-description"
      ref="textArea"
      placeholder="Write the item's description here."
      v-model.trim="customDescription"
      @keyup.ctrl.b="formatText('bold')"
      @keyup.ctrl.i="formatText('italic')"
      :class="{small: small, medium: medium}"
      oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
      class="rounded"
    />
  </div>
  </div>
</template>

<script>
export default {
  name: "CardDescription",
  props: ["cardID", "card"],
  data() {
    return {
      locked: false
    };
  },
  mounted() {
    this.resize()
  },
  computed: {
    small() {
      if (this.card.cardType == "enchantments") {
        return true
      } else {return false}
    },
    medium() {
      if (this.card.selectedItem && this.card.selectedItem.options) {
        return true 
      } else {return false}
    },
    customDescription: {
      get() {
        if (this.card.cardType == "srd" && !this.card.customDescription) {
          return this.card.selectedItem.description;
        } else {
          return this.card.customDescription;
        }
      },
      set(customDescription) {
        if (this.card.cardType == "srd") {
          this.$store.commit("updateItemDescription", {
            cardID: this.cardID,
            itemDescription: customDescription,
          });
        } else {
          this.$store.commit("updateCustomDescription", {
            cardID: this.cardID,
            customDescription: customDescription,
          });
        }
      },
    },
  },
  methods: {
  resize() {
    this.$refs.textArea.style.height = "auto";
    this.$refs.textArea.style.height = this.$refs.textArea.scrollHeight + "px";
  },
    formatText: function (type) {
      var textArea = this.$refs.textArea;
      var text = "";
      if (type === "italic") {
        text =
          "<i>" +
          textArea.value.slice(textArea.selectionStart, textArea.selectionEnd) +
          "</i>";
      }
      if (type === "bold") {
        text =
          "<b>" +
          textArea.value.slice(textArea.selectionStart, textArea.selectionEnd) +
          "</b>";
      }
      if (type === "bullet") {
        text =
          "\n&#8226; " +
          textArea.value.slice(textArea.selectionStart, textArea.selectionEnd);
      }
      textArea.value =
        textArea.value.substring(0, textArea.selectionStart) +
        text +
        textArea.value.substring(textArea.selectionEnd, textArea.value.length);
               if (this.card.cardType == "srd") {
          this.$store.commit("updateItemDescription", {
            cardID: this.cardID,
            itemDescription: textArea.value,
          });
        } else {
          this.$store.commit("updateCustomDescription", {
            cardID: this.cardID,
            customDescription: textArea.value,
          });
        }
    },
  },
};
</script>

<style scoped>
div {
  width:100%;
}

textarea {
  resize: none;
  max-height:200px;
}

.medium {
  height:180px;
}

.small {
  height:150px;
}

h3 {
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.2rem;
}

.fas {
  position: relative;
  font-size: 0.6rem;
  line-height: 1.2rem;
  padding: 0rem 0.38rem 0rem 0.38rem;
  margin: 0rem 0rem 0rem 0.25rem;
  background-color: transparent;
  transition: all 0.5 ease;
  cursor: pointer;
}

.fa-circle {
  font-size: 0.4rem;
}

.fa:hover,
.highlight {
  color: var(--main2);
  background-color: var(--dark);
}

@media only screen and (max-width: 1080px) {
textarea {
  height:100%;
}

.medium {
  height:100%;
}

.small {
  height:100%;
}
}
</style>