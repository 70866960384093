<template>
  <div class="container">
    <div class="container-header">
        <div class="header-button" v-for="enchantment, index in enchantments" :key="index" :class="{selected:selectedEnchantment.name == enchantment.name}" @click="selectedEnchantment = enchantment">{{ "Enchantment " + (index + 1) }}</div>
    </div>
    <div class="container-body">
        <EnchantmentEditSelect :card="card" :cardID="cardID" :id="0" v-if="enchantments.findIndex(enchantment => { return enchantment.name == selectedEnchantment.name}) == 0"></EnchantmentEditSelect>
        <EnchantmentEditSelect :card="card" :cardID="cardID" :id="1" v-if="enchantments.findIndex(enchantment => { return enchantment.name == selectedEnchantment.name}) == 1"></EnchantmentEditSelect>
        <EnchantmentEditSelect :card="card" :cardID="cardID" :id="2" v-if="enchantments.findIndex(enchantment => { return enchantment.name == selectedEnchantment.name}) == 2"></EnchantmentEditSelect>
    </div>
  </div>
</template>

<script>
import EnchantmentEditSelect from './EnchantmentEditSelect.vue';

export default {
    name: "EnchantmentEditsBlockSelect",
    props: {
        cardID: Number,
        card: Object
    },
    components: {
        EnchantmentEditSelect,
    },
    data() {
        return {
            selectedEnchantment: "",
            showEdit: false,
        };
    },
    mounted() {
        this.selectedEnchantment = this.enchantments[0];
    },
    computed: {
        enchantments() {
            var enchantments = this.card.selectedEnchantments.filter(enchantment => enchantment.name != "Choose Enchantment");
            return enchantments;
        },
        selectedEnchantmentId() {
            return this.enchantments.findIndex(enchantment => { return enchantment.name == this.selectedEnchantment.name})
        }
    }

}
</script>

<style>
.container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #000;
    height: 30px;
}

.header-button {
    width:100%;
    height:100%;
    font-size:10px;
    background-color: var(--dark2);
    padding:5px;
    border-radius: 5px 5px 0px 0px;
    cursor:pointer;
}

.header-button.selected {
    background-color: var(--dark);
}
</style>