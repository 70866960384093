<template>
  <BaseHeader :priceList="true"/>
  <PriceListFull/>
</template>

<script>

import BaseHeader from "../components/Header/BaseHeader.vue";

// import PriceTable from "../components/PriceList/PriceTable.vue";
import PriceListFull from "../components/PriceList/PriceListFull.vue";



export default {
name: "PriceList",
components: {PriceListFull, BaseHeader}
}
</script>

<style scoped>

</style>