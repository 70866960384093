<template>
  <div class="menu-row">
    <h3>{{ optionName }}</h3>
    <select v-model="selectedOption" class="rounded">
      <option v-for="option in options" :key="option.name">
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "OptionsSelect",
  props: { id: Number, cardID: Number, card: Object },
  computed: {
    options() {
      return this.card.options[this.id];
    },
    optionName() {
      if (typeof this.card.selectedItem.optionName != "undefined") {
        return this.card.selectedItem.optionName
      } else return "Option"
    },
    selectedOption: {
      get: function () {
        return this.card.selectedOptions[this.id].name;
      },
      set: function (selectedOption) {
          const option = this.options.find(
            (option) => option.name == selectedOption
          )
        this.$store.commit("updateSelectedOption", {
          cardID: this.cardID,
          optionID: this.id,
          selectedOption: option
        });
      },
    },
  },
};
</script>

<style lang="css" scoped>


</style>