<template>
  <div class="card" ref="card" :id="cardID" :class="{ selected: selected, print: printView, text: text }"
    @click="editCard()">
    <img src="@/assets/texture1.jpg" class="base" v-if="text" />
    <img src="@/assets/base_back.jpg" class="base" v-if="cardBack && !text" />
    <img src="@/assets/base.jpg" class="base" v-else-if="!text && !cardBack" />
    <CardBackImage v-if="cardBack && cardBackImage && !text" :key="componentKey" :cardWidth="cardWidth" :card="card"
      :draggable="true"></CardBackImage>
    <CardOutputImage v-else-if="!cardBack && image && !text" :key="componentKey" :cardWidth="cardWidth" :card="card"
      :draggable="true" :printing="printing"></CardOutputImage>
    <img src="@/assets/withImage.png" class="base2" v-if="!cardBack && image && !text" />
    <img :src="require('@/assets/' + gems + '')" class="gems" v-if="!text" />
    <img :src="require('@/assets/' + decor + '')" class="gems" v-if="decor && !cardBack && !text && image" />
    <div class="name" @input="updateName" :class="{
      text: text,
      georgia: card.nameFont === 'Georgia',
      montserrat:
        card.nameFont === 'Montserrat' ||
        (!card.nameFont && text) ||
        (card.nameFont === 'Auto' && text),
      domlovesmary:
        card.nameFont === 'DomLovesMary' ||
        (!card.nameFont && text == false) ||
        (card.nameFont === 'Auto' && text == false),
      bookmania: card.nameFont === 'Bookmania',
      'small-caps': card.nameFontStyle === 'Small Caps',
      uppercase: card.nameFontStyle === 'Uppercase',
      lowercase: card.nameFontStyle === 'Lowercase',
    }" :contenteditable="!card.editingPreference || card.editingPreference == 'contenteditable'" ref="name"
      :style="{ fontSize: text ? '22px' : fontSizeName + 'px' }"></div>
    <div class="type" ref="type" :class="{
      italic: text,
      georgia: card.typeFont === 'Georgia',
      montserrat: card.typeFont === 'Montserrat',
      domlovesmary:
        card.typeFont === 'DomLovesMary' ||
        (card.typeFont === 'Auto' && !text) ||
        (!card.typeFont && !text),
      bookmania:
        card.typeFont === 'Bookmania' ||
        (card.typeFont === 'Auto' && text) ||
        (!card.typeFont && text),
      uppercase: card.typeFontStyle === 'Uppercase',
      lowercase: card.typeFontStyle === 'Lowercase',
    }" :style="{
  fontSize: text ? fontSizeDescription + 'px' : fontSizeType + 'px',
}">
      <p>{{ typeRarityText }}</p>
    </div>
    <div class="attunement" ref="attunement" v-show="!text" :class="{
      georgia: card.attunementFont === 'Georgia',
      montserrat: card.attunementFont === 'Montserrat',
      domlovesmary:
        card.attunementFont === 'DomLovesMary' || !card.attunementFont,
      bookmania: card.attunementFont === 'Bookmania',
      'small-caps': card.typeFontStyle === 'Small Caps',
      uppercase: card.typeFontStyle === 'Uppercase',
      lowercase: card.typeFontStyle === 'Lowercase',
    }" :style="{ fontSize: cardWidth / 35 + 'px' }">
      <p>{{ attunementText }}</p>
    </div>
    <div v-show="!cardBack" class="description"
      :contenteditable="!card.editingPreference || card.editingPreference == 'contenteditable'"
      @input="updateCustomDescription" :class="{
        text: text,
        'with-image': image,
        georgia: card.descriptionFont === 'Georgia',
        montserrat: card.descriptionFont === 'Montserrat',
        domlovesmary: card.descriptionFont === 'DomLovesMary',
        kingscaslon: card.descriptionFont === 'kingscaslon',
        bookmania:
          card.descriptionFont === 'Bookmania' ||
          !card.descriptionFont ||
          card.descriptionFont === 'Auto',
        'small-caps': card.descriptionFontStyle === 'Small Caps',
        uppercase: card.descriptionFontStyle === 'Uppercase',
        lowercase: card.descriptionFontStyle === 'Lowercase',
      }" ref="description" :style="{ fontSize: fontSizeDescription + 'px' }"></div>
    <div class="credit" :class="{ bottom: cardBack && image }" :style="{ fontSize: fontSizeCredit + 'px' }"
      v-if="artCredit && !text">
      {{ "Art: " + artCredit }}
    </div>
  </div>
</template>

<script>
import CardOutputImage from "./CardOutputImage.vue";
import CardBackImage from "./CardBackImage.vue";
import * as htmlToImage from "html-to-image";

export default {
  name: "CardOutput",
  components: {
    CardOutputImage,
    CardBackImage,
  },
  data() {
    return {
      fontSizeDescription: 20,
      fontSizeName: 24,
      fontSizeType: 18,
      fontSizeAttunement: 10,
      largeCardWidth: 320,
      smallCardWidth: 250,
      output: null,
      cardWidth: 0,
      componentKey: 0,
      gems: "common_image.png",
      decor: null,
      contenteditable: false,
    };
  },
  props: ["selected", "printView", "printing", "cardID", "cardType", "card"],
  emits: ["printed", "editCard"],
  mounted() {
    this.description && this.description.length > 1
      ? (this.$refs.description.innerHTML = this.description)
      : (this.$refs.description.innerHTML = "Write Here!");
    this.$refs.name.innerHTML = this.itemName;
    this.resizeDescription();
    this.resizeName();
    this.resizeType();
    this.resizeAttunement();
    this.setGems();
    this.cardWidth = this.$refs.card.offsetWidth;
  },
  watch: {
    "card.selectedOptions": {
      handler: function () {
        this.$refs.description.innerHTML = this.card.customOptionDescription;
      },
      deep: true,
    },
    "card.selectedEnchantments": {
      handler: function () {
        this.$refs.description.innerHTML = this.description;
      },
      deep: true,
    },
    bonusText: {
      handler: function () {
        this.$refs.description.innerHTML = this.description;
      },
      deep: true,
    },
    "card.selectedItem.name": {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          if (
          this.card.selectedItem &&  
          this.card.selectedItem.source == "DMG" &&
            !this.card.selectedItem.description
          ) {
            this.$refs.description.innerHTML =
              "This item is not part of 5E's Open Gaming License and System Reference Document.";
          } else {
            this.card.selectedItem &&
            this.card.selectedItem.description
              ? (this.$refs.description.innerHTML =
                this.card.selectedItem.description)
              : (this.$refs.description.innerHTML = "Write Here!");
          }
          this.$refs.name.innerHTML = this.itemName;
          this.resizeDescription();
          this.resizeName();
          this.setGems();
        }
      },
      deep: true,
    },
    text: function (newValue) {
      if (
        newValue &&
        (typeof this.descriptionFontSize == "undefined" ||
          this.descriptionFontSize == "Auto")
      ) {
        this.fontSizeDescription = this.selected ? 20 : 20 * 0.625;
      }
      this.$refs.type.innerHTML =
        this.text && this.attunement
          ? this.typeRarityText + this.attunementText
          : this.typeRarityText;
      this.$refs.name.innerHTML = this.itemName;
      this.$refs.attunement.innerHTML = this.attunementText;
      if (newValue) {
        this.$refs.description.style.height =
          this.$refs.card.clientHeight -
          this.$refs.name.scrollHeight -
          this.$refs.type.scrollHeight +
          "px";
      } else if (!newValue) {
        this.$refs.description.style.height = null;
      }
      this.$refs.description.innerHTML = this.description;
      this.setGems();
    },
    cardBack: function (newValue) {
      if (newValue) {
        this.setGems();
      }
    },
    nameFontSize: function (newValue) {
      if (newValue && newValue != "Auto") {
        this.fontSizeName = parseInt(newValue);
      } else if (newValue === "Auto") {
        this.fontSizeName = 24;
      }
    },
    descriptionFontSize: function (newValue) {
      if (newValue && newValue != "Auto") {
        this.fontSizeDescription = parseInt(newValue);
      } else if (newValue === "Auto") {
        this.fontSizeDescription = 20;
      }
    },
    typeFontSize: function (newValue) {
      if (newValue && newValue != "Auto") {
        this.fontSizeType = parseInt(newValue);
      } else if (newValue === "Auto") {
        this.fontSizeType = 18;
      }
    },
    attunementFontSize: function (newValue) {
      if (newValue && newValue != "Auto") {
        this.fontSizeAttunement = parseInt(newValue);
      } else if (newValue === "Auto") {
        this.fontSizeAttunement = 10;
      }
    },
    printing: function (newValue) {
      if (newValue) {
        this.print();
      }
    },
    description: function (newDesc, oldDesc) {
      if (newDesc.length != oldDesc.length) {
        if (this.card.editingPreference == 'input') {
          this.$refs.description.innerHTML = newDesc;
        }
        if (oldDesc.length != newDesc.length) {
          if (this.fontSizeDescription != 20) {
            this.fontSizeDescription = 20;
          } else {
            this.resizeDescription();
          }
        }
        if (this.cardType == "enchantments") {
          this.$store.commit("updateEnchantmentDescription", {
            cardID: this.cardID,
            enchantmentDescription: newDesc,
          });
        }
      }
    },
    itemName: function (newDesc, oldDesc) {
      if (this.card.editingPreference == 'input') {
        this.$refs.name.innerHTML = newDesc;
      }
      this.$store.commit("updateItemName", {
        cardID: this.cardID,
        itemName: this.itemName,
      });
      if (oldDesc.length != newDesc.length) {
        if (this.fontSizeName != 24) {
          this.fontSizeName = 24;
        } else {
          this.resizeName();
        }
      }
    },
    typeRarityText: function (newDesc, oldDesc) {
      if (oldDesc.length != newDesc.length) {
        this.fontSizeType = 18;
      }
    },
    attunementText: function (newDesc, oldDesc) {
      if (
        typeof oldDesc != "undefined" &&
        typeof newDesc != "undefined" &&
        oldDesc.length != newDesc.length
      ) {
        this.fontSizeAttunement = 10;
      }
    },
    rarity: function () {
      this.setGems();
    },
    attunement: function () {
      this.setGems();
    },
    gemsColor: function () {
      this.setGems();
    },
    gemsPosition: function () {
      this.setGems();
    },
    cardDecor: function () {
      this.setGems()
    },
    image: function () {
      this.setGems();
      this.fontSizeDescription = 24;
    },
  },
  methods: {
    updateCustomDescription() {
      const customDescription = this.$refs.description.innerHTML;
      this.$store.commit("updateCustomDescription", {
        cardID: this.cardID,
        customDescription: customDescription,
      });
      if (
        typeof this.descriptionFontSize != "undefined" &&
        this.descriptionFontSize != "Auto"
      ) {
        this.fontSizeDescription =
          this.descriptionFontSize * (this.cardWidth / this.largeCardWidth);
      } else if (
        !this.printing &&
        this.$refs.description.scrollHeight >
        this.$refs.description.offsetHeight
      ) {
        this.resizeDescription();
      }
    },
    updateName() {
      const name = this.$refs.name.innerHTML;
      this.$store.commit("updateCustomName", {
        cardID: this.cardID,
        customName: name,
      });
      if (
        typeof this.nameFontSize != "undefined" &&
        this.nameFontSize != "Auto"
      ) {
        this.fontSizeName =
          this.nameFontSize * (this.cardWidth / this.largeCardWidth);
      } else if (
        !this.printing &&
        this.$refs.name.scrollHeight > this.$refs.name.offsetHeight
      ) {
        this.resizeName();
      }
    },
    // updateTypeRarity() {
    //   const typeRarity = this.$refs.type.innerHTML;
    //   this.$store.commit("updateCustomTypeRarity", {
    //     cardID: this.cardID,
    //     typeRarity: typeRarity,
    //   });
    //   if (
    //     typeof this.typeFontSize != "undefined" &&
    //     this.typeFontSize != "Auto"
    //   ) {
    //     this.fontSizeType =
    //       this.cardWidth == 400 ? this.typeFontSize : this.typeFontSize * 0.625;
    //   } else if (
    //     !this.text &&
    //     !this.printing &&
    //     this.$refs.type.scrollHeight > this.$refs.type.offsetHeight
    //   ) {
    //     this.resizeType();
    //   }
    // },
    // updateAttunement() {
    //   const attunement = this.$refs.attunement.innerHTML;
    //   this.$store.commit("updateCustomAttunement", {
    //     cardID: this.cardID,
    //     attunement: attunement,
    //   });
    // },
    editCard() {
      this.$store.commit("editCard", { cardID: this.cardID });
    },
    async print() {
      const el = this.$refs.card;
      const itemName = this.itemName;
      htmlToImage.toJpeg(el, { pixelRatio: 3 }).then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = itemName + ".jpeg";
        link.href = dataUrl;
        link.click();
      });
      this.$emit("printed");
    },
    resizeDescription() {
      this.fontSizeDescription =
        parseInt(this.$refs.description.style.fontSize.slice(0, -2)) - 0.2;
      if (
        this.text &&
        (typeof this.typeFontSize == "undefined" || this.typeFontSize == "Auto")
      ) {
        this.fontSizeType = this.fontSizeDescription;
      }
    },
    resizeName() {
      if (!this.text) {
        this.fontSizeName =
          parseInt(this.$refs.name.style.fontSize.slice(0, -2)) - 0.25;
      }
    },
    resizeType() {
      if (!this.text) {
        this.fontSizeType =
          parseInt(this.$refs.type.style.fontSize.slice(0, -2)) - 0.25;
      }
    },
    resizeAttunement() {
      if (!this.text) {
        this.fontSizeAttunement =
          parseInt(this.$refs.attunement.style.fontSize.slice(0, -2)) - 0.25;
      }
    },
    setGems() {
      var attunement = this.attunement;
      var rarity = this.rarity;
      if (this.gemsColor || this.gemsColor != "Auto") {
        switch (this.card.gemsColor) {
          case "Gray":
            rarity = "Common";
            break;
          case "Green":
            rarity = "Uncommon";
            break;
          case "Blue":
            rarity = "Rare";
            break;
          case "Purple":
            rarity = "Very Rare";
            break;
          case "Red":
            rarity = "Legendary";
            break;
          case "Orange":
            rarity = "Artifact";
            break;
        }
      }
      if (this.gemsPosition || this.gemsPosition != "Auto") {
        switch (this.card.gemsPosition) {
          case "Corners":
            attunement = false;
            break;
          case "All":
            attunement = true;
            break;
        }
      }
      if (this.$store.state.rarities.includes(this.rarity)) {
        this.gems = attunement
          ? rarity.toLowerCase().replace(" ", "_") + "_attunement.png"
          : rarity.toLowerCase().replace(" ", "_") + ".png";
      } else {
        this.gems = attunement
          ? this.gems
            .replace("_attunement_image.png", "")
            .replace("_image.png", "")
            .replace("_attunement.png", "")
            .replace(".png", "") + "_attunement.png"
          : this.gems
            .replace("_attunement_image.png", "")
            .replace("_image.png", "")
            .replace("_attunement.png", "")
            .replace(".png", "") + ".png";
      }
      if (!this.cardDecor || this.cardDecor == "Auto") {
        switch (rarity) {
          case "Common":
          case "Uncommon":
            this.decor = null;
            break;
          case "Rare":
            this.decor = "rare_decor_image.png";
            break;
          case "Very Rare":
            this.decor = "very_rare_decor_image.png";
            break;
          case "Legendary":
            this.decor = "legendary_decor_image.png";
            break;
          case "Artifact":
            this.decor = "artifact_decor_image.png";
            break;
        }
      } else if (this.cardDecor || this.cardDecor != "Auto") {
        switch (this.cardDecor) {
          case "None":
            this.decor = null;
            break;
          case "Less":
            this.decor = "rare_decor_image.png";
            break;
          case "Some":
            this.decor = "very_rare_decor_image.png";
            break;
          case "More":
            this.decor = "legendary_decor_image.png";
            break;
          case "Full":
            this.decor = "artifact_decor_image.png";
            break;
        }
      }
    },
  },
  updated() {
    if (this.text) {
      this.$refs.description.style.height =
        this.$refs.card.clientHeight -
        this.$refs.name.scrollHeight -
        this.$refs.type.scrollHeight +
        "px";
    } else if (!this.text) {
      this.$refs.description.style.height = null;
    }
    if (
      typeof this.descriptionFontSize != "undefined" &&
      this.descriptionFontSize != "Auto"
    ) {
      this.fontSizeDescription =
        this.cardWidth == this.largeCardWidth
          ? this.descriptionFontSize
          : this.descriptionFontSize * 0.625;
    } else if (
      !this.printing &&
      this.$refs.description.scrollHeight > this.$refs.description.offsetHeight
    ) {
      this.resizeDescription();
    }
    if (
      typeof this.nameFontSize != "undefined" &&
      this.nameFontSize != "Auto"
    ) {
      this.fontSizeName =
        this.nameFontSize * (this.cardWidth / this.largeCardWidth);
    } else if (
      !this.text &&
      !this.printing &&
      this.$refs.name.scrollHeight > this.$refs.name.offsetHeight
    ) {
      this.resizeName();
    }
    if (
      typeof this.typeFontSize != "undefined" &&
      this.typeFontSize != "Auto"
    ) {
      this.fontSizeType =
        this.nameFontSize * (this.cardWidth / this.largeCardWidth);
    } else if (
      !this.text &&
      !this.printing &&
      this.$refs.type.scrollHeight > this.$refs.type.offsetHeight
    ) {
      this.resizeType();
    }
    if (
      typeof this.attunementFontSize != "undefined" &&
      this.attunementFontSize != "Auto"
    ) {
      this.fontSizeAttunement =
        this.attunementFontSize * (this.cardWidth / this.largeCardWidth);
    } else if (
      !this.text &&
      !this.printing &&
      this.$refs.attunement.scrollHeight > this.$refs.attunement.offsetHeight
    ) {
      this.resizeAttunement();
    }
  },
  computed: {
    cardBack() {
      return this.card.cardBack;
    },
    cardBackImage() {
      return this.card.cardBackImage;
    },
    fontSizeCredit() {
      return this.cardWidth / 40;
    },
    text() {
      if (this.card.cardLayout == "Text") {
        return true;
      } else {
        return false;
      }
    },
    nameFontSize() {
      return this.card.nameFontSize;
    },
    descriptionFontSize() {
      return this.card.descriptionFontSize;
    },
    typeFontSize() {
      return this.card.typeFontSize;
    },
    attunementFontSize() {
      return this.card.attunementFontSize;
    },
    gemsColor() {
      return this.card.gemsColor;
    },
    gemsPosition() {
      return this.card.gemsPosition;
    },
    cardDecor() {
      return this.card.cardDecor;
    },
    artCredit() {
      if (
        this.image &&
        (typeof this.card.imageURL == "undefined" ||
          this.card.imageURL == null ||
          this.card.imageURL.length <= 5) &&
        this.$store.state.artCredit.some(
          (img) => img.image === this.card.imageSRC.replace(".png", "")
        )
      ) {
        const artCredit = this.$store.state.artCredit.find(
          (img) => img.image === this.card.imageSRC.replace(".png", "")
        ).artist;
        return artCredit;
      } else return false;
    },
    image() {
      if (typeof this.card != "undefined") {
        return this.card.image;
      } else {
        return false;
      }
    },
    itemTypeName() {
      var selectedType = this.card.selectedType;
      var selectedSubtype = this.card.selectedSubtype;
      var itemTypeName = "";
      if (
        selectedType == "Ring" ||
        selectedType == "Staff" ||
        selectedType == "Rod" ||
        selectedType == "Wand"
      ) {
        itemTypeName = selectedType;
      } else if (selectedSubtype == "Any" || selectedSubtype == "") {
        if (selectedType == "Wondrous item") {
          itemTypeName = "Item";
        } else {
          itemTypeName = selectedType;
        }
      } else if (selectedSubtype == "Crossbow, Hand") {
        itemTypeName = "Hand Crossbow";
      } else if (selectedSubtype == "Crossbow, Light") {
        itemTypeName = "Light Crossbow";
      } else if (selectedSubtype == "Crossbow, Heavy") {
        itemTypeName = "Heavy Crossbow";
      } else {
        itemTypeName = selectedSubtype;
      }
      return itemTypeName;
    },
    attunement() {
      var selectedAttunement = this.card.selectedAttunement;
      var selectedEnchantments = this.card.selectedEnchantments;
      var attunement = false;
      if (this.card.customAttunement) {
        attunement = true;
      } else if (selectedAttunement === "Yes") {
        attunement = true;
      } else if (
        selectedAttunement === "Auto" &&
        typeof selectedEnchantments != "undefined"
      ) {
        if (
          (typeof selectedEnchantments[0] != "undefined" &&
            selectedEnchantments[0].name != "Choose Enchantment" &&
            selectedEnchantments[0].attunement != "No") ||
          (typeof selectedEnchantments[1] != "undefined" &&
            selectedEnchantments[1].name != "Choose Enchantment" &&
            selectedEnchantments[1].attunement != "No") ||
          (typeof selectedEnchantments[2] != "undefined" &&
            selectedEnchantments[2].name != "Choose Enchantment" &&
            selectedEnchantments[2].attunement != "No")
        ) {
          attunement = true;
        }
      }
      return attunement;
    },
    rarity() {
      var armorBonus = this.card.armorBonus;
      var weaponBonus = this.card.weaponBonus;
      var spellBonus = this.card.spellBonus;
      var saveBonus = this.card.saveBonus;
      var selectedEnchantments = this.card.selectedEnchantments;
      var selectedRarity = this.card.selectedRarity;
      var rarityValue =
        Number(isNaN(armorBonus) ? 0 : armorBonus) +
        Number(isNaN(weaponBonus) ? 0 : weaponBonus) +
        Number(isNaN(saveBonus) ? 0 : saveBonus) +
        Number(isNaN(spellBonus) ? 0 : spellBonus);

      if (typeof selectedEnchantments != "undefined") {
        for (var i = 0; i < selectedEnchantments.length; i++) {
          if (
            typeof selectedEnchantments[i].rarity != "undefined" &&
            !isNaN(selectedEnchantments[i].rarity)
          ) {
            rarityValue = rarityValue + Number(selectedEnchantments[i].rarity);
          }
        }
      }
      if (selectedRarity != "Auto") {
        return selectedRarity;
      } else if (rarityValue < 1) {
        return "Common";
      } else if (rarityValue < 2) {
        return "Uncommon";
      } else if (rarityValue < 3) {
        return "Rare";
      } else if (rarityValue < 7) {
        return "Very Rare";
      } else if (rarityValue < 10) {
        return "Legendary";
      } else {
        return "Artifact";
      }
    },
    bonusText() {
      var armorBonus = this.card.armorBonus;
      var weaponBonus = this.card.weaponBonus;
      var spellBonus = this.card.spellBonus;
      var saveBonus = this.card.saveBonus;
      var bonusText = "";
      switch (!isNaN(armorBonus)) {
        case true:
          switch (!isNaN(saveBonus)) {
            case true:
              switch (!isNaN(weaponBonus)) {
                case true:
                  switch (!isNaN(spellBonus)) {
                    case true:
                      switch (armorBonus == saveBonus) {
                        case true:
                          switch (armorBonus == spellBonus) {
                            case true:
                              bonusText =
                                "You have a +" +
                                armorBonus +
                                " bonus to AC, saving throws, spell attacks, and the saving throw DCs of your spells while carrying this magic item, and a +" +
                                weaponBonus +
                                " bonus to attack and damage rolls made with this magic item.";
                              break;
                            case false:
                              bonusText =
                                "You have a +" +
                                armorBonus +
                                " bonus to AC and saving throws, and a +" +
                                spellBonus +
                                " bonus to spell attacks and the save DCs of your spells while carrying this magic item, and a +" +
                                weaponBonus +
                                " bonus to attack and damage rolls made with this magic item.";
                          }
                          break;
                        case false:
                          switch (armorBonus == spellBonus) {
                            case true:
                              bonusText =
                                "You have a +" +
                                armorBonus +
                                " bonus to AC, spell attacks, and the saving throw DCs of your spells, and a +" +
                                saveBonus +
                                " bonus to saving throws while carrying this magic item, and a +" +
                                weaponBonus +
                                " bonus to attack and damage rolls made with this magic item.";
                              break;
                            case false:
                              bonusText =
                                "You have a +" +
                                armorBonus +
                                " bonus to AC, a +" +
                                saveBonus +
                                " bonus to saving throws, and a +" +
                                spellBonus +
                                " bonus to spell attacks, and the saving throw DCs of your spells while carrying this magic item, and a +" +
                                weaponBonus +
                                " bonus to attack and damage rolls made with this magic item.";
                          }
                      }
                      break;
                    case false:
                      switch (armorBonus == saveBonus) {
                        case true:
                          bonusText =
                            "You have a +" +
                            armorBonus +
                            " bonus to AC and saving throws while carrying this magic item, and a +" +
                            weaponBonus +
                            " bonus to attack and damage rolls made with this magic item.";
                          break;
                        case false:
                          bonusText =
                            "You have a +" +
                            armorBonus +
                            " bonus to AC and a +" +
                            saveBonus +
                            " bonus to saving throws while carrying this magic item, and a +" +
                            weaponBonus +
                            " bonus to attack and damage rolls made with this magic item.";
                      }
                  }
                  break;
                case false:
                  switch (!isNaN(spellBonus)) {
                    case true:
                      switch (armorBonus == saveBonus) {
                        case true:
                          switch (armorBonus == spellBonus) {
                            case true:
                              bonusText =
                                "You have a +" +
                                armorBonus +
                                " bonus to AC, saving throws, spell attacks, and the saving throw DCs of your spells while carrying this magic item.";
                              break;
                            case false:
                              bonusText =
                                "You have a +" +
                                armorBonus +
                                " bonus to AC and saving throws, and a +" +
                                spellBonus +
                                " bonus to spell attacks and the save DCs of your spells while carrying this magic item.";
                          }
                          break;
                        case false:
                          switch (armorBonus == spellBonus) {
                            case true:
                              bonusText =
                                "You have a +" +
                                armorBonus +
                                " bonus to AC, spell attacks, and the saving throw DCs of your spells, and a +" +
                                saveBonus +
                                " bonus to saving throws while carrying this magic item.";
                              break;
                            case false:
                              bonusText =
                                "You have a +" +
                                armorBonus +
                                " bonus to AC, a +" +
                                saveBonus +
                                " bonus to saving throws, and a +" +
                                spellBonus +
                                " bonus to spell attacks and the saving throw DCs of your spells while carrying this magic item.";
                          }
                      }
                      break;
                    case false:
                      switch (armorBonus == saveBonus) {
                        case true:
                          bonusText =
                            "You have a +" +
                            armorBonus +
                            " bonus to AC and saving throws while carrying this magic item.";
                          break;
                        case false:
                          bonusText =
                            "You have a +" +
                            armorBonus +
                            " bonus to AC and a +" +
                            saveBonus +
                            " bonus to saving throws while carrying this magic item.";
                          break;
                      }
                  }
              }
              break;
            case false:
              switch (!isNaN(weaponBonus)) {
                case true:
                  switch (!isNaN(spellBonus)) {
                    case true:
                      switch (armorBonus == spellBonus) {
                        case true:
                          bonusText =
                            "You have a +" +
                            armorBonus +
                            " bonus to AC, spell attacks, and the saving throw DCs of your spells while carrying this magic item, and a +" +
                            weaponBonus +
                            " bonus to attack and damage rolls made with this magic item.";
                          break;
                        case false:
                          bonusText =
                            "You have a +" +
                            armorBonus +
                            " bonus to AC and a +" +
                            spellBonus +
                            " bonus to spell attacks and the saving throw DCs of your spells while carrying this magic item, and a +" +
                            weaponBonus +
                            " bonus to attack and damage rolls made with this magic item.";
                      }
                      break;
                    case false:
                      bonusText =
                        "You have a +" +
                        armorBonus +
                        " bonus to AC while carrying this magic item and a +" +
                        weaponBonus +
                        " bonus to attack and damage rolls made with this magic item.";
                  }
                  break;
                case false:
                  switch (!isNaN(spellBonus)) {
                    case true:
                      switch (armorBonus == spellBonus) {
                        case true:
                          bonusText =
                            "You have a +" +
                            armorBonus +
                            " bonus to AC, spell attacks, and the saving throw DCs of your spells while carrying this magic item.";
                          break;
                        case false:
                          bonusText =
                            "You have a +" +
                            armorBonus +
                            " bonus to AC and a +" +
                            spellBonus +
                            " bonus to spell attacks and the saving throw DCs of your spells while carrying this magic item.";
                      }
                      break;
                    case false:
                      bonusText =
                        "You have a +" +
                        armorBonus +
                        " bonus to AC while carrying this magic item.";
                  }
              }
          }
          break;
        case false:
          switch (!isNaN(saveBonus)) {
            case true:
              switch (!isNaN(weaponBonus)) {
                case true:
                  switch (!isNaN(spellBonus)) {
                    case true:
                      switch (saveBonus == spellBonus) {
                        case true:
                          bonusText =
                            "You have a +" +
                            saveBonus +
                            " bonus to saving throws, spell attacks, and the saving throw DC of your spells while carrying this magic item, and a +" +
                            weaponBonus +
                            " bonus to attack and damage rolls made with this magic item.";
                          break;
                        case false:
                          bonusText =
                            "You have a +" +
                            saveBonus +
                            " bonus to saving throws and a +" +
                            spellBonus +
                            " bonus to spell attacks and the saving throw DC of your spells while carrying this magic item, and a +" +
                            weaponBonus +
                            " bonus to attack and damage rolls made with this magic item.";
                      }
                      break;
                    case false:
                      bonusText =
                        "You have a +" +
                        saveBonus +
                        " bonus to saving throws while carrying this magic item and a +" +
                        weaponBonus +
                        " bonus to attack and damage rolls made with this magic item.";
                  }
                  break;
                case false:
                  switch (!isNaN(spellBonus)) {
                    case true:
                      switch (saveBonus == spellBonus) {
                        case true:
                          bonusText =
                            "You have a +" +
                            saveBonus +
                            " bonus to saving throws, spell attacks, and the saving throw DC of your spells while carrying this magic item.";
                          break;
                        case false:
                          bonusText =
                            "You have a +" +
                            saveBonus +
                            " bonus to saving throws and a +" +
                            spellBonus +
                            " bonus to spell attacks and the saving throw DC of your spells while carrying this magic item.";
                      }
                      break;
                    case false:
                      bonusText =
                        "You have a +" +
                        saveBonus +
                        " bonus to saving throws while carrying this magic item.";
                      break;
                  }
              }
              break;
            case false:
              switch (!isNaN(weaponBonus)) {
                case true:
                  switch (!isNaN(spellBonus)) {
                    case true:
                      bonusText =
                        "You have a +" +
                        spellBonus +
                        " bonus to spell attacks and the saving throw DCs of your spells while carrying this item, and a +" +
                        weaponBonus +
                        " bonus to attack and damage rolls made with this magic item.";
                      break;
                    case false:
                      bonusText =
                        "You have a +" +
                        weaponBonus +
                        " bonus to attack and damage rolls made with this magic item.";
                  }
                  break;
                case false:
                  switch (!isNaN(spellBonus)) {
                    case true:
                      bonusText =
                        "You have a +" +
                        spellBonus +
                        " bonus to spell attacks and the saving throw DCs of your spells while carrying this magic item.";
                      break;
                    case false:
                      return "";
                  }
              }
          }
      }
      return bonusText;
    },
    typeRarityText: function () {
      if (
        typeof this.card.customTypeRarity != "undefined" &&
        this.card.customTypeRarity.length > 0
      ) {
        return this.card.customTypeRarity;
      } else {
        var selectedType = this.card.selectedType;
        var selectedSubtype = this.card.selectedSubtype;
        var typeRarityText = "";
        if (selectedType == "Armor" || selectedType == "Weapon") {
          typeRarityText =
            selectedType +
            (selectedSubtype.startsWith("(") ? " " : " (") +
            selectedSubtype +
            (selectedSubtype.endsWith(")") ? ", " : "), ") +
            this.rarity;
        } else if (selectedType == "Wondrous item") {
          typeRarityText = "Wondrous Item, " + this.rarity;
        } else {
          typeRarityText = selectedType + ", " + this.rarity;
        }
        return typeRarityText;
      }
    },
    attunementText: function () {
      var selectedEnchantments = this.card.selectedEnchantments;
      var attunement = this.card.selectedAttunement;
      var attunementText = "";
      if (this.card.customAttunement && this.card.customAttunement.length > 0) {
        return this.card.customAttunement;
      } else if (this.attunement) {
        switch (attunement) {
          case "Yes":
            if (
              typeof this.card.attunementBy != "undefined" &&
              this.card.attunementBy.length > 0
            ) {
              attunementText = "Requires attunement " + this.card.attunementBy;
            } else if (
              typeof this.card.selectedItem?.attunement != "undefined" &&
              this.card.selectedItem?.attunement != "No" && this.card.selectedItem?.attunement != ""
            ) {
              attunementText = this.card.selectedItem.attunement;
            } else {
              attunementText = "Requires attunement";
            }
            break;
          case "Auto":
            if (
              selectedEnchantments.some(
                (enchantment) => enchantment.attunement != "No"
              )
            ) {
              attunementText = selectedEnchantments.find(
                (enchantment) => enchantment.attunement != "No"
              ).attunement;
            }
            break;
          default:
            attunementText = "";
        }
      } else attunementText = "";
      if (this.text && attunementText != "") {
        attunementText = " (" + attunementText.toLowerCase() + ")";
      }
      return attunementText;
    },
    itemName: function () {
      if (
        typeof this.card.customName != "undefined" &&
        this.card.customName.length > 0
      ) {
        return this.card.customName;
      } else {
        if (
          typeof this.card.selectedItem != "undefined" &&
          this.card.cardType === "srd"
        ) {
          if (this.card.itemName && this.card.itemName.length != "") {
            var itemName = this.card.itemName;
            return itemName;
          }
          itemName = this.card.selectedItem.name;
          if (itemName == "Dragon Scale Mail") {
            itemName = this.card.selectedOptions[0].name + " Dragon Scale Mail";
          }
          if (itemName.startsWith("Spell Scroll (")) {
            itemName = "Spell Scroll of " + this.card.selectedOptions[0].name;
          }
          if (itemName.startsWith("Ring of Resistance")) {
            itemName =
              "Ring of " + this.card.selectedOptions[0].name + " Resistance";
          }
          if (itemName.startsWith("Armor of Resistance")) {
            itemName =
              "Armor of " + this.card.selectedOptions[0].name + " Resistance";
          }
          if (itemName.startsWith("Potion of Resistance")) {
            itemName =
              "Potion of " + this.card.selectedOptions[0].name + " Resistance";
          }
          if (itemName.startsWith("Dragonskull Helmet")) {
            itemName =
              this.card.selectedOptions[0].name + " Dragonskull Helmet";
          }
          // if (itemName.startsWith("Armor +") && !this.card.selectedSubtype.startsWith("Any")) {
          //   itemName =
          //     this.card.selectedOptions[0].name + " Dragonskull Helmet";
          // }
          return itemName;
        } else {
          var prefix = "";
          var suffix = "";
          if (typeof this.card.selectedEnchantments != "undefined") {
            var selectedEnchantments = this.card.selectedEnchantments;

            // Define Prefix
            if (
              typeof selectedEnchantments[0] != "undefined" &&
              (selectedEnchantments[0].type == "Prefix" ||
                selectedEnchantments[0].type == "Class")
            ) {
              prefix =
                selectedEnchantments[0].name.replace(", Greater", "") + " ";
            } else if (
              typeof selectedEnchantments[1] != "undefined" &&
              (selectedEnchantments[1].type == "Prefix" ||
                selectedEnchantments[1].type == "Class")
            ) {
              prefix =
                selectedEnchantments[1].name.replace(", Greater", "") + " ";
            } else if (
              typeof selectedEnchantments[2] != "undefined" &&
              (selectedEnchantments[2].type == "Prefix" ||
                selectedEnchantments[2].type == "Class")
            ) {
              prefix =
                selectedEnchantments[2].name.replace(", Greater", "") + " ";
            } else {
              prefix = "";
            }

            // Define Suffix

            if (
              typeof selectedEnchantments[0] != "undefined" &&
              selectedEnchantments[0].type === "Suffix"
            ) {
              suffix =
                " " +
                selectedEnchantments[0].ofthe +
                " " +
                selectedEnchantments[0].name.replace(", Greater", "");
            } else if (
              typeof selectedEnchantments[1] != "undefined" &&
              selectedEnchantments[1].type === "Suffix"
            ) {
              suffix =
                " " +
                selectedEnchantments[1].ofthe +
                " " +
                selectedEnchantments[1].name.replace(", Greater", "");
            } else if (
              typeof selectedEnchantments[2] != "undefined" &&
              selectedEnchantments[2].type === "Suffix"
            ) {
              suffix =
                " " +
                selectedEnchantments[2].ofthe +
                " " +
                selectedEnchantments[2].name.replace(", Greater", "");
            }

            if (prefix == "" && suffix == "") {
              if (
                typeof selectedEnchantments[0] != "undefined" &&
                selectedEnchantments[0].type === "Common"
              ) {
                prefix = selectedEnchantments[0].name + " ";
              } else if (
                typeof selectedEnchantments[1] != "undefined" &&
                selectedEnchantments[1].type === "Common"
              ) {
                prefix = selectedEnchantments[1].name + " ";
              } else if (
                typeof selectedEnchantments[2] != "undefined" &&
                selectedEnchantments[2].type === "Common"
              ) {
                prefix = selectedEnchantments[2].name + " ";
              } else {
                prefix = "";
              }
              if (
                typeof selectedEnchantments[0] != "undefined" &&
                selectedEnchantments[0].type === "Curse"
              ) {
                suffix = " of " + selectedEnchantments[0].name;
              } else if (
                typeof selectedEnchantments[1] != "undefined" &&
                selectedEnchantments[1].type === "Curse"
              ) {
                suffix = " of " + selectedEnchantments[1].name;
              } else if (
                typeof selectedEnchantments[2] != "undefined" &&
                selectedEnchantments[2].type === "Curse"
              ) {
                suffix = " of " + selectedEnchantments[2].name;
              } else {
                suffix = "";
              }
            }
          }
          return prefix + this.itemTypeName + suffix;
        }
      }
    },
    description: function () {
      var itemDescription = "";
      const cardType = this.card.cardType;
      switch (cardType) {
        case "Custom":
          if (
            typeof this.card.customDescription != "undefined" &&
            this.card.customDescription.length > 0
          ) {
            itemDescription = this.card.customDescription.replace(
              /(\n)+/g,
              "</p><p>"
            );
            itemDescription.startsWith("<p>")
              ? ""
              : (itemDescription = "<p>" + itemDescription);
            itemDescription.endsWith("</p>")
              ? ""
              : (itemDescription = itemDescription + "</p>");
            return itemDescription;
          }
          break;
        case "enchantments":
          if (typeof this.card.selectedEnchantments != "undefined") {
            var selectedType = this.card.selectedType;
            var selectedSubtype = this.card.selectedSubtype;
            var selectedEnchantments = this.card.selectedEnchantments;
            var descriptions = [];
            if (
              typeof this.card.customDescription != "undefined" &&
              this.card.customDescription.length > 0
            ) {
              this.card.customDescription.startsWith("<p>")
                ? descriptions.push(
                  this.card.customDescription.replace(/(\n)+/g, "</p><p>") +
                  " "
                )
                : descriptions.push(
                  "<p>" +
                  this.card.customDescription.replace(/(\n)+/g, "</p><p>") +
                  " "
                );
            }
            if (
              typeof this.bonusText != "undefined" &&
              this.bonusText.length > 0
            ) {
              if (descriptions.length == 0) {
                descriptions.push("<p>");
              }
              descriptions.push(this.bonusText + "</p>");
            }
            for (var i = 0; i < selectedEnchantments.length; i++) {
              if (
                typeof selectedEnchantments[i].description != "undefined" &&
                selectedEnchantments[i].description != ""
              ) {
                if (typeof selectedEnchantments[i].altName != "undefined") {
                  if (selectedEnchantments[i].altName.length > 0) {
                    descriptions.push(
                      "<p><b><i>" +
                      selectedEnchantments[i].altName +
                      ".</i></b> " +
                      selectedEnchantments[i].description +
                      "</p>"
                    );
                  } else {
                    descriptions.push(
                      "<p>" + selectedEnchantments[i].description + "</p>"
                    );
                  }
                } else {
                  descriptions.push(
                    "<p><b><i>" +
                    selectedEnchantments[i].name +
                    ".</i></b> " +
                    selectedEnchantments[i].description.replace("<p>", "") +
                    "</p>"
                  );
                }
              }
            }
            var keyVerb = "carry";
            var keyVerbing = "carrying";
            var plurality1 = "This";
            var plurality2 = "its";
            var plurality3 = "has";
            var plurality4 = "is";
            var plurality5 = "it";

            if (
              selectedType == "Armor" ||
              selectedType == "Ring" ||
              selectedSubtype == "Ring" ||
              selectedSubtype == "Amulet" ||
              selectedSubtype == "Pants" ||
              selectedSubtype == "Cloak" ||
              selectedSubtype == "Robe" ||
              selectedSubtype == "Shirt" ||
              selectedSubtype == "Necklace" ||
              selectedSubtype == "Boots" ||
              selectedSubtype == "Greaves" ||
              selectedSubtype == "Sandals" ||
              selectedSubtype == "Slippers" ||
              selectedSubtype == "Bracers" ||
              selectedSubtype == "Gauntlets" ||
              selectedSubtype == "Gloves" ||
              selectedSubtype == "Mittens" ||
              selectedSubtype == "Cap" ||
              selectedSubtype == "Circlet" ||
              selectedSubtype == "Crown" ||
              selectedSubtype == "Hat" ||
              selectedSubtype == "Headband" ||
              selectedSubtype == "Helmet" ||
              selectedSubtype == "Hood" ||
              selectedSubtype == "Mask" ||
              selectedSubtype == "Anklet" ||
              selectedSubtype == "Bracelet" ||
              selectedSubtype == "Brooch" ||
              selectedSubtype == "Earring" ||
              selectedSubtype == "Nose Ring" ||
              selectedSubtype == "Belt" ||
              selectedSubtype == "Cape"
            ) {
              keyVerb = "wear";
              keyVerbing = "wearing";
            }

            if (
              selectedSubtype == "Pants" ||
              selectedSubtype == "Boots" ||
              selectedSubtype == "Greaves" ||
              selectedSubtype == "Boots" ||
              selectedSubtype == "Greaves" ||
              selectedSubtype == "Sandals" ||
              selectedSubtype == "Slippers" ||
              selectedSubtype == "Bracers" ||
              selectedSubtype == "Gauntlets" ||
              selectedSubtype == "Gloves" ||
              selectedSubtype == "Mittens"
            ) {
              plurality1 = "These";
              plurality2 = "their";
              plurality3 = "have";
              plurality4 = "are";
              plurality5 = "them";
            }

            var thisItem = this.itemTypeName.toLowerCase();
            var descriptionText = descriptions.join("");
            descriptionText = descriptionText
              .replace(/you carry it/g, "you " + keyVerb + " " + plurality5)
              .replace(/you wear it/g, "you " + keyVerb + " " + plurality5)
              .replace(/you carry/g, "you " + keyVerb)
              .replace(/you wear/g, "you " + keyVerb)
              .replace(/carrying it/g, keyVerbing + " " + plurality5)
              .replace(/wearing it/g, keyVerbing + " " + plurality5)
              .replace(/carrying/g, keyVerbing)
              .replace(/wearing/g, keyVerbing)
              .replace(/cast into it/g, "cast into " + plurality5)
              .replace(/stored in it/g, "stored in " + plurality5)
              .replace(
                /this item has/g,
                plurality1.toLowerCase() + " " + thisItem + " " + plurality3
              )
              .replace(
                /this item is/g,
                plurality1.toLowerCase() + " " + thisItem + " " + plurality4
              )
              .replace(
                /this magic item/g,
                plurality1.toLowerCase() + " magic " + thisItem
              )
              .replace(/This magic item/g, plurality1 + " magic " + thisItem)
              .replace(/this item/g, plurality1.toLowerCase() + " " + thisItem)
              .replace(
                /This item has/g,
                plurality1 + " " + thisItem + " " + plurality3
              )
              .replace(
                /This item is/g,
                plurality1 + " " + thisItem + " " + plurality4
              )
              .replace(/This item/g, plurality1 + " " + thisItem)
              .replace(
                /this weapon has/g,
                plurality1.toLowerCase() + " " + thisItem + " " + plurality3
              )
              .replace(
                /this weapon is/g,
                plurality1.toLowerCase() + " " + thisItem + " " + plurality4
              )
              .replace(
                /this weapon/g,
                plurality1.toLowerCase() + " " + thisItem
              )
              .replace(
                /This weapon has/g,
                plurality1 + " " + thisItem + " " + plurality3
              )
              .replace(
                /This weapon is/g,
                plurality1 + " " + thisItem + " " + plurality4
              )
              .replace(/This weapon/g, plurality1 + " " + thisItem)
              .replace(
                /this armor has/g,
                plurality1.toLowerCase() + " " + thisItem + " " + plurality3
              )
              .replace(
                /this armor is/g,
                plurality1.toLowerCase() + " " + thisItem + " " + plurality4
              )
              .replace(/this armor/g, plurality1.toLowerCase() + " " + thisItem)
              .replace(
                /This armor has/g,
                plurality1 + " " + thisItem + " " + plurality3
              )
              .replace(
                /This armor is/g,
                plurality1 + " " + thisItem + " " + plurality4
              )
              .replace(/This armor/g, plurality1 + " " + thisItem)
              .replace(/its charges/g, plurality2 + " charges")
              .replace(/its command word/g, plurality2 + " command word")
              .replace(/item regains/g, thisItem + " regains")
              .replace(/weapon regains/g, thisItem + " regains")
              .replace(/armor regains/g, thisItem + " regains");
            return descriptionText;
          }
          break;
        case "DMG":
        case "srd":
          if (
            typeof this.card.selectedItem != "undefined" &&
            typeof this.card.selectedItem.description != "undefined" &&
            (typeof this.card.customDescription == "undefined" ||
              !this.card.customDescription) // if custom description is set, use it
          ) {
            itemDescription = this.card.selectedItem.description.replace(
              /(\n)+/g,
              "</p><p>"
            );
            if (typeof this.card.selectedOptions != "undefined") {
              for (let i = 0; i < this.card.selectedOptions.length; i++) {
                itemDescription = itemDescription.replace(
                  "[option]",
                  this.card.selectedOptions[i].value
                );
              }
            }
            return itemDescription;
          } else if (
            typeof this.card.customDescription != "undefined" &&
            this.card.customDescription.length > 0
          ) {
            itemDescription = this.card.customDescription;
            return itemDescription;
          }
          break;
        default:
          return " ";
      }
      return " ";
    },
  },
};
</script>

<style scoped>
.card {
  position: relative;
  user-select: none;
  display: block;
  width: 250px;
  height: 350px;
}

.card.text {
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0);
}

.card.text>div {
  margin-bottom: 5px;
}

.base {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  z-index: -2;
}

.base2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
  z-index: -1;
}

.gems {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
}

.name {
  position: absolute;
  width: 100%;
  top: 4%;
  height: 9%;
  padding: 0% 19% 0% 19%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
  pointer-events: none;
  text-align: center;
  color: white;
  text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000,
    0.5px 0.5px 0 #000;
  font-family: domlovesmary-text, serif;
  font-style: normal;
  margin: 0;
  line-height: 0.8;
}

.card.text .name {
  line-height: 1;
  position: relative;
  padding: unset;
  text-align: left;
  color: black;
  text-shadow: none;
  top: unset;
  left: unset;
  height: unset;
}

.type {
  position: absolute;
  width: 100%;
  top: 91.5%;
  height: 6%;
  padding: 0% 17.5% 0% 17.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  user-select: none;
  pointer-events: none;
  color: white;
  text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000,
    0.5px 0.5px 0 #000;
  font-family: domlovesmary-text, serif;
  font-weight: 400;
  font-style: normal;
}

.card.text .type {
  position: relative;
  padding: unset;
  text-align: left;
  color: black;
  text-shadow: none;
  top: unset;
  left: unset;
  height: auto;
  line-height: 1;
}

.attunement {
  position: absolute;
  width: 100%;
  top: 95.5%;
  height: 4%;
  padding: 0% 16% 0% 16%;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  user-select: none;
  pointer-events: none;
  color: white;
  text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000,
    0.5px 0.5px 0 #000;
  font-family: domlovesmary-text, serif;
  font-weight: 400;
  font-style: normal;
}

.description {
  position: absolute;
  top: 16%;
  font-family: bookmania, serif;
  font-weight: 400;
  font-style: normal;
  height: 74%;
  padding: 1% 6%;
  line-height: 1.1;
  overflow: hidden;
  outline: 0px solid transparent;
  user-select: none;
  pointer-events: none;
}

.card.text .description {
  position: relative;
  padding: unset;
  text-align: left;
  color: black;
  text-shadow: none;
  top: unset;
  left: unset;
  height: 100%;
}

.card.selected .description,
.card.selected .type,
.card.selected .name,
.card.selected .attunement {
  user-select: unset;
  pointer-events: unset;
  background-color: none !important;
  cursor: text;
  outline: 0px solid transparent;
}

.description.with-image {
  top: 54.5%;
  height: 35.5%;
  padding: 0.5% 6%;
}

.description :deep(p) {
  text-align: left;
}

.description :deep(p:nth-of-type(n + 2)) {
  text-indent: 0.4em;
}

.description.text {
  font-family: Bookmania, serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  width: 100%;
  height: 90%;
  padding: 0;
  user-select: none;
  pointer-events: none;
}

.selected .description.text,
.selected .name-text,
.selected .type-text {
  user-select: text;
  pointer-events: auto;
}

.selected {
  width: 20rem;
  height: 28rem;
  box-shadow: 0 -2px 10px rgb(0, 0, 0);
}

.selected.text {
  user-select: text;
}

/* .card {
  width:200px;
  height:280px;
} */

.card.print {
  width: 250px;
  height: 350px;
  box-shadow: none;
}



.credit {
  position: absolute;
  font-size: 0.4rem;
  color: rgb(0, 0, 0);
  top: 50.5%;
  left: 30%;
}

.credit.bottom {
  top: unset;
  bottom: 9%;
  left: 15%;
}

@media only screen and (max-width: 1079px) {
  .selected {
    max-width: 100%;
  }

  .selected {
    margin: 0;
  }
}
</style>
