<template>
  <transition name="fade">

    <div class="overlay dark" @click="$emit('opened', null)" v-if="opened"></div>
  </transition>
  <div class="item-container" @click="$emit('opened', index)">
    <div class="item-header">
      <div class="item-name">{{ item.name }}</div>
    </div>
    <div class="item-body">
      <div class="item-subheader">
        {{ item.subheader }}
      </div>
    </div>
  </div>
  <transition name="fade">
    <div class="item-container opened" v-if="opened == index">
      <div class="item-container-column left">
        <div class="menu-row item-header">
          <h3>Name:</h3><input type="text" class="item-name" v-model="name">
        </div>
        <div class="menu-row item-header">
          <h3>Type:</h3><input type="text" class="item-name" v-model="name">
        </div>
        <div class="item-body">
          <div class="item-subheader">
            {{ item.subheader }}
          </div>
          <div class="item-description" v-if="item.description">
            {{ item.description }}

          </div>
        </div>
      </div>
      <div class="item-container-column right">
        <img :src="item.src" alt="">
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CollectionItem",
  props: ["item", "opened", "index"],
  emits: ["opened"],
  data() {
    return {
      name: "",
    };
  },
  computed: {
    ...mapState(["user", "customItems", "userItems", "itemTypes", "armors", "weapons", "wonders"]),
    itemSubtypes: function () {
      switch (this.selectedType) {
        case "Armor":
          return this.armors;
        case "Weapon":
          return this.weapons;
        case "Wondrous item":
          return this.wonders;
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.item-container {
  font-family: montserrat, sans-serif;
  font-size: 0.8rem;
  width: 400px;
  max-width: 100%;
  background-color: var(--dark5);
  border-radius: 10px;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 5px 0px;
  padding: 5px 10px;
  color: whitesmoke;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  user-select: none;
}

.item-container.opened {
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 95lvw;
  z-index: 100;

}

.item-container-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.item-container-column.left {
  width: 80%;
}

.item-container-column.right {
  width: 20%;
}

.item-container img {
  max-height: 75px;
  max-width: 100%;
  transition: all 0.2s ease-in-out;
}

.item-container.open img {
  max-height: 100%;
  transition: all 0.2s ease-in-out;
}

.item-container.open {
  height: auto;
  max-height: 100%;
  transition: all 0.2s ease-in-out;
}

.item-header {
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: Uppercase;
  display: flex;
  flex-flow: row nowrap;
}

.item-header>div {
  margin: 0px 5px 0px 0px;
  padding: 5px;
  border-radius: 2.5px;
  background-color: rgb(75, 75, 75);
  font-size: 0.7rem;
}

.item-header>.item-name {
  padding: 5px 5px 5px 0px;
  background-color: unset;
  font-size: 0.8rem;
}

.item-subheader {
  font-style: italic;
  text-align: left;
}

/* .item-subheader > div {
  margin: 0px 5px 0px 0px;
} */

.item-description {
  margin-top: 5px;
  text-align: left;
}

/* RARITY COLORS */

.common {
  background-color: grey !important;
}

.uncommon {
  background-color: green !important;
}

.rare {
  background-color: blue !important;
}

.very-rare {
  background-color: purple !important;
}

.legendary {
  background-color: orange !important;
}

.artifact {
  background-color: red !important;
}
</style>
