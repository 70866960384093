<template>
  <!-- THE FILTER BAR AT THE TOP. PRESENT WHILE IN ITEM LIST VIEW -->

  <div :class="{ show: showFilters }" class="filter-container">
    <div class="filter search-item">
      <h3>Item</h3>
      <input
        class="text-input"
        type="text"
        v-model="search"
        @keyup="filterItems"
        autocomplete="off"
        list="autocompleteOff"
        placeholder="Search for Item Name" />
    </div>

    <!-- TYPE FILTER -->

    <!-- YOU ARE IN THE MIDDLE OF MAKING THE FILTER MENU SOMETHING YOU CAN OPEN. NEED TO PUT EVERYTHING BUT ITEM SEARCH INSIDE A BOX THAT SHOWS WHEN THINGY IS PULLED -->
    <div class="menu-row drop-filters" :class="{ dropped: dropped }">
      <div class="filter">
        <h3>Type</h3>
        <div class="option-checkmarks">
          <div
            v-for="type in types"
            v-bind:key="type"
            @click="
              includedTypes.includes(type)
                ? (includedTypes = includedTypes.filter(
                    (itemType) => itemType != type
                  ))
                : !includedTypes.includes(type)
                ? includedTypes.push(type)
                : null,
                filterItems()
            "
            class="filter-element"
            :class="{ unchecked: !includedTypes.includes(type) }">
            {{ type }}
          </div>
        </div>
      </div>
      <div class="filter">
        <h3>Rarity</h3>
        <div class="option-checkmarks">
          <div
            @click="
              includedRarities.includes(rarity)
                ? (includedRarities = includedRarities.filter(
                    (itemRarity) => itemRarity != rarity
                  ))
                : !includedRarities.includes(rarity)
                ? includedRarities.push(rarity)
                : null,
                filterItems()
            "
            class="filter-element"
            v-for="rarity in rarities"
            v-bind:key="rarity"
            :class="{
              unchecked: !includedRarities.includes(rarity),
            }">
            {{ rarity }}
          </div>
        </div>
      </div>
      <div class="filter" @mouseleave="showAttunementFilters = false">
        <h3>Attunement</h3>
        <div class="option-checkmarks">
          <div
            @click="
              includedAttunements.includes(attunement)
                ? (includedAttunements = includedAttunements.filter(
                    (itemAttunement) => itemAttunement != attunement
                  ))
                : !includedAttunements.includes(attunement)
                ? includedAttunements.push(attunement)
                : null,
                filterItems()
            "
            class="filter-element"
            v-for="attunement in attunements"
            v-bind:key="attunement"
            :class="{
              unchecked: !includedAttunements.includes(attunement),
            }">
            {{ attunement }}
          </div>
        </div>
      </div>
      <div class="filter" @mouseleave="showSourceFilters = false">
        <h3>Source</h3>
        <div class="option-checkmarks">
          <div
            v-for="source in sources"
            v-bind:key="source"
            @click="
              includedSources.includes(source)
                ? (includedSources = includedSources.filter(
                    (itemSource) => itemSource != source
                  ))
                : !includedSources.includes(source)
                ? includedSources.push(source)
                : null,
                filterItems()
            "
            class="filter-element"
            :class="{
              unchecked: !includedSources.includes(source),
            }">
            {{ source }}
          </div>
        </div>
      </div>

      <div class="filter">
        <h3>Value</h3>
        <div class="number-inputs">
          <input
            type="number"
            class="number-input"
            v-model="value1"
            placeholder="Min"
            @keyup="filterItems" />
          <input
            type="number"
            class="number-input"
            v-model="value2"
            placeholder="Max"
            @keyup="filterItems" />
        </div>
      </div>
    </div>
    <div class="mobile-open-close" :class="{ open: dropped }">
      <i class="fas fa-angle-double-down" @click="dropped = !dropped"></i>
    </div>
  </div>

  <div class="filter-warning" v-if="showWarning">
    Can't find any matches. Maybe your filters are too strict?
    <div class="reset" @click="resetFilters">Reset filters</div>
  </div>
</template>

<script>
export default {
  name: "PriceTable",
  emits: ["filterItems"],
  props: ["refreshFilter"],
  data() {
    return {
      showFilters: false,
      showTypeFilters: false,
      showRarityFilters: false,
      showSourceFilters: false,
      showAttunementFilters: false,
      showAddMenu: false,
      showSource: false,
      showSaveMenu: false,
      showOverlay: false,
      showAddTooltip: false,
      showSaveTooltip: false,
      showShopTooltip: false,
      showShopMenu: false,
      showShopDrop: false,
      showShopsList: false,
      showWarning: false,
      dropped: false,
      value1: null,
      value2: null,
      includedValues: [0, 50000],
      items: this.$store.state.items,
      customItems: this.$store.state.customItems,
      search: "",
      sources: ["DMG", "WGMM", "MGMM", "HGDD", "XGTE", "TCOE", "Custom"],
      rarities: [
        "Common",
        "Uncommon",
        "Rare",
        "Very Rare",
        "Legendary",
        "Artifact",
      ],
      types: [
        "Armor",
        "Potion",
        "Ring",
        "Rod",
        "Scroll",
        "Staff",
        "Wand",
        "Weapon",
        "Wondrous Item",
      ],
      attunements: ["Required", "Not Required"],
      includedRarities: [],
      includedTypes: [],
      includedSources: [],
      includedAttunements: [],
    };
  },
  watch: {
    customItemsLength(newValue, oldValue) {
      if (newValue != oldValue) {
        this.filterItems();
      }
    },
    refreshFilter(newValue, oldValue) {
      if (newValue != oldValue) {
        this.filterItems();
      }
    },
  },
  mounted() {
    this.filterItems();
  },
  computed: {
    customItemsLength() {
      return this.$store.state.customItems.length;
    },
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
  },
  methods: {
    actionFilter() {
      this.$store.state.filterItems({
        value1: this.value1,
        value2: this.value2,
        includedTypes: this.includedTypes,
      });
    },
    filterItems() {
      let filteredItems = this.$store.state.items.map(({ value, ...rest }) => ({
        ...rest,
        value: Number(value),
      }));
      if (this.$store.state.user.loggedIn) {
        filteredItems = this.$store.state.userItems.concat(filteredItems);
      }
      const seen = new Set();
      filteredItems = filteredItems.filter((item) => {
        const duplicate = seen.has(item.name);
        seen.add(item.name);
        return !duplicate;
      });
      if (this.search && this.search.length > 0) {
        filteredItems = filteredItems.filter((item) =>
          item.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      if (this.value1 != null && this.value1 != "") {
        filteredItems = filteredItems.filter(
          (item) => item.value >= this.value1
        );
      }
      if (this.value2 != null && this.value2 != "") {
        filteredItems = filteredItems.filter(
          (item) => item.value <= this.value2
        );
      }
      if (this.includedTypes && this.includedTypes.length > 0) {
        filteredItems = filteredItems.filter((item) =>
          this.includedTypes.includes(item.type)
        );
      }
      if (this.includedRarities && this.includedRarities.length > 0) {
        filteredItems = filteredItems.filter((item) =>
          this.includedRarities.includes(item.rarity)
        );
      }
      if (this.includedSources && this.includedSources.length > 0) {
        filteredItems = filteredItems.filter((item) =>
          this.includedSources.includes(item.source)
        );
      }
      if (this.includedAttunements && this.includedAttunements.length == 1) {
        this.includedAttunements.includes("Required")
          ? (filteredItems = filteredItems.filter(
              (item) => item.attunement && item.attunement.length > 1
            ))
          : (filteredItems = filteredItems.filter(
              (item) => !item.attunement || item.attunement.length < 2
            ));
      }
      if (filteredItems.length < 1) {
        this.showWarning = true;
      } else if (filteredItems.length > 0) {
        this.showWarning = false;
      }

      this.$emit("filterItems", filteredItems);
    },
    resetFilters() {
      this.includedSources = [];
      this.includedRarities = [];
      this.includedTypes = [];
      this.search = "";
      this.value1 = null;
      this.value2 = null;
      this.filterItems();
    },
    filterType(type) {
      if (!this.includedTypes.includes(type)) {
        this.includedTypes.push(type);
      } else {
        this.includedTypes = this.includedTypes.filter(
          (filteredType) => filteredType != type
        );
      }
    },
    filterAttunement(attunement) {
      if (!this.includedAttunements.includes(attunement)) {
        this.includedAttunements.push(attunement);
      } else {
        this.includedAttunements = this.includedAttunements.filter(
          (filteredAttunement) => filteredAttunement != attunement
        );
      }
    },
    filterRarity(rarity) {
      if (!this.includedRarities.includes(rarity)) {
        this.includedRarities.push(rarity);
      } else {
        this.includedRarities = this.includedRarities.filter(
          (filteredRarity) => filteredRarity != rarity
        );
      }
    },
    filterSources(source) {
      if (!this.includedSources.includes(source)) {
        this.includedSources.push(source);
      } else {
        this.includedSources = this.includedSources.filter(
          (filteredSource) => filteredSource != source
        );
      }
    },
  },
};
</script>

<style scoped>
h3 {
  margin-bottom: 5px;
  font-size: 0.9rem;
}

h4 {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 1rem;
}

.filter-container {
  width: 80%;
  max-width: 1200px;
  margin: 100px auto 0px auto;
  font-family: montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease;
  padding: 5px 10px;
  border-radius: 5px;
  background: var(--dark2);
}

.filter-container h3 {
  color: whitesmoke;
}

.option-checkmarks {
  display: flex;
  flex-flow: row wrap;
}

.filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 5px;
  position: relative;
}

.filter.search-item {
  width: 250px;
}

.filter-options {
  display: flex;
  flex-flow: row wrap;
  transition: all 0.2s ease;
  position: absolute;
  top: 100%;
  margin-left: -5px;
  padding: 5px;
  background: white;
  z-index: 2;
  width: calc(100%-5px);
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.144);
}
.filter-drop {
  position: absolute;
  background: #ffffff;
  top: 100%;
  -webkit-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-weight: 400;
  user-select: none;
}

.filter-drop div:hover {
  background: black;
  color: white;
}

.filtered {
  background: black;
  color: white;
}

.filter-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 1.2rem;
  color: white;
  font-family: montserrat, sans-serif;
}

.filter-button {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  padding: 10px;
  font-family: montserrat, sans-serif;
  font-size: 1.2rem;
  background: var(--dark);
  width: 6rem;
  border-radius: 0.6rem;
  color: white;
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.144);
  transition: all 0.3s ease;
  cursor: pointer;
}

.filter-button:hover {
  transform: scale(1.05);
  transition: all 0.3s ease;
}

.reset {
  color: darkred;
  font-size: 1.3rem;
  margin-top: 5px;
  cursor: pointer;
  font-weight: 600;
}

.source-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.show-source {
  display: flex;
  flex-direction: row;
}

.source-header p {
  font-size: 0.6rem;
  margin-right: 5px;
  text-align: end;
}

#source {
  cursor: pointer;
}

.text-input,
.number-input {
  border-radius: 5px;
  min-height: 40px;
  background-color: white;
  box-shadow: inset 0 0 4px 0 rgb(138 177 198 / 75%);
  border: solid 1px #9c9898;
  box-sizing: border-box;
  font-family: montserrat, sans-serif;
}

.number-input {
  min-height: 30px;
  width: 100%;
  margin: 0px;
}

.number-input:nth-of-type(1) {
  margin-bottom: 5px;
}

select {
  border-radius: 5px;
  min-height: 40px;
  background-color: white;
  box-shadow: inset 0 0 4px 0 rgb(138 177 198 / 75%);
  border: solid 1px #9c9898;
  box-sizing: border-box;
  font-family: montserrat, sans-serif;
}

input {
  background: white;
  color: black;
  padding: 5px;
}

.mobile-open-close {
  display: none;
}

.drop-filters {
  width: 100%;
  display: grid;
  grid-template-columns: 30% 25% 12.5% 17.5% 15%;
  align-items: flex-start;
}

.unchecked {
  filter: opacity(0.3) grayscale(1);
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1079px) {
  .filter-container {
    width: 100%;
    margin: 90px 0px 0px 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  /* .filter-container.show {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: var(--dark3);
    position: fixed;
    bottom: 12%;
    z-index: 100;
    width: 95vw;
    transition: all 0.3s ease;
    opacity: 1;
  } */

  .filter {
    flex-direction: row;
    align-items: center;
    justify-items: flex-start;
    align-content: flex-start;
    width: 100%;
    margin: 0px;
    padding: 5px 10px;
  }

  .filter h3 {
    margin-right: 5px;
    font-size: 1rem;
  }

  .filter .text-input {
    width: 100%;
  }

  .filter .number-input {
    width: 70%;
  }

  .number-inputs {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .show-source {
    display: none;
  }

  .mobile-open-close {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: center;
    transition: all 0.7s ease;
    color: white;
  }

  .mobile-open-close i {
    font-size: 2rem;
  }

  .mobile-open-close.open {
    transform: rotate(180deg);
    transition: all 0.7s ease;
  }

  .filter.search-item {
    width: 100%;
  }

  .filter-element {
    font-size: 1rem;
    margin: 2.5px;
    padding: 7.5px;
  }

  .drop-filters {
    max-height: 0px;
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    transition: all 0.7s ease;
  }

  .drop-filters.dropped {
    max-height: 500px;
    transition: all 0.7s ease;
  }
}
</style>
