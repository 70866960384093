<template>
  <div class="item-display-card">
    <h3 class="item-display-name">
      {{ item.name }}
    </h3>
    <div class="item-display-subheader">
      <span>
        {{ item.type }}
      </span>
      <span>
        {{ item.subtype ? " (" + item.subtype + "), " : ", " }}
      </span>
      <span>
        {{ item.rarity }}
      </span>
      <span>
        {{ item.attunement ? " (" + item.attunement.trim() + ")" : "" }}
      </span>
    </div>

    <!-- 
            If the item has a description, show it. 
            If the item doesn't have a description, but is from XGTE, TCOE, or DMG, show a message that it's not in the SRD.
            If the item doesn't have a description, and is from MGMM or WGMM, show a message that it's from a premium sourcebook.
         -->

    <div
      class="item-display-description"
      v-html="description"
      v-if="
        item.description &&
        (premium || (item.source != 'MGMM' && item.source != 'WGMM'))
      "></div>
    <div
      class="item-display-description"
      v-else-if="
        !item.description &&
        (item.source == 'XGTE' || item.source == 'TCOE' || item.source == 'DMG')
      ">
      <p>
        This item is not in the 5E SRD. You can find it on
        <a href="https://dndbeyond.com" target="_blank">dndbeyond.com</a>, if
        you have purchased a digital version of the appropriate sourcebook.
      </p>
    </div>
    <div
      class="item-display-description"
      v-else-if="
        item.source == 'MGMM' || item.source == 'WGMM' || item.source == 'HGDD'
      ">
      <span>This item is from the Eventyr Games' sourcebook </span>
      <span v-if="item.source == 'MGMM'">
        <a
          href="https://eventyrgames.store/products/milandos-guide-to-magical-marvels"
          target="_blank"
          >Milando's Guide to Magical Marvels</a
        >
      </span>
      <span v-if="item.source == 'WGMM'">
        <a
          href="https://eventyrgames.store/products/wanderers-guide-to-merchants-magic"
          target="_blank"
          >Wanderer's Guide to Merchants & Magic</a
        >
      </span>
      <span v-if="item.source == 'WGMM'">
        <a
          href="https://eventyrgames.store/products/bundle-heretics-guide-to-devotion-divinity"
          target="_blank"
          >Heretic's Guide to Devotion & Divinity</a
        >
      </span>
      <span
        >. To see its description, upgrade to premium or buy the source
        book.</span
      >
    </div>

    <div
      class="item-display-link"
      v-if="item.source != 'WGMM' && item.source != 'MGMM'">
      <div class="item-display-link-button">
        <a :href="item.url" target="_blank">
          See {{ item.name }} on DnD Beyond
        </a>
      </div>
    </div>
    <div
      class="item-display-link"
      v-if="item.source == 'WGMM' || item.source == 'MGMM'">
      <div class="item-display-link-button">
        <a
          :href="
            item.source == 'WGMM'
              ? 'https://eventyrgames.store/products/wanderers-guide-to-merchants-magic'
              : item.source == 'MGMM'
              ? 'https://eventyrgames.store/products/milandos-guide-to-magical-marvels'
              : 'https://eventyrgames.store/products/bundle-heretics-guide-to-devotion-divinity'
          "
          target="_blank">
          Get
          {{
            item.source == "WGMM"
              ? "Wanderer's Guide to Merchants & Magic"
              : item.source == "MGMM"
              ? "Milando's Guide to Magical Marvels"
              : "Heretic's Guide to Devotion & Divinity"
          }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceListItemModal",
  props: ["item"],
  computed: {
    description() {
      if (this.item.description) {
        let itemDescription = this.item.description.replace(
          /(\n)+/g,
          "</p><p>"
        );
        itemDescription.startsWith("<p>")
          ? ""
          : (itemDescription = "<p>" + itemDescription);
        itemDescription.endsWith("</p>")
          ? ""
          : (itemDescription = itemDescription + "</p>");
        return itemDescription;
      } else {
        return "";
      }
    },
    premium() {
      return this.$store.state.premium;
    },
  },
};
</script>

<style scoped>
.item-display-card {
  background-color: white;
  color: black;
  position: absolute;
  bottom: 50%;
  left: 50px;
  transform: translateY(50%);
  width: 450px;
  max-width: 90vw;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.75);
}

.item-display-name {
  font-family: montserrat, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: black;
}

.item-display-subheader {
  font-style: italic;
  padding-bottom: 2.5px;
  margin-bottom: 2.5px;
  border-bottom: 2px solid darkred;
  font-weight: 400;
}

.item-display-description {
  font-weight: 400;
  font-style: normal;
}

.item-display-description :deep(p) {
  text-align: left;
}

.item-display-description :deep(p:nth-of-type(n + 2)) {
  margin-top: 0.2em;
  text-indent: 0.5em;
}

.item-display-description a {
  color: darkred;
  font-weight: 600;
}

.item-display-link {
  text-align: center;
}

.item-display-link-button {
  background-color: darkred;
  color: white;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
}

@media only screen and (max-width: 1079px) {
  .item-display-card {
    position: fixed;
    bottom: unset;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
