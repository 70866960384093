<template>
    <div class="dropdown-wrapper">
  <div class="dropdown-container">
    <div class="dropdown-item" v-for="dropdownItem, index in dropdownItems" :key="index" @click="goToLink(dropdownItem.path)">{{ dropdownItem.name }}</div>
  </div>
</div>
  </template>
  
  <script>
  export default {
    name: "NpcDropList",
    emits: ["showNpcMenu", "close", "deselectNpc", "selectNpc"],
    props: { header: Boolean, dropdownItems: Array },
    methods: {
      goToLink(path) {
        this.$router.push(path);
        this.$emit("close");
      },
    },
    computed: {
      loggedIn() {
        return this.$store.state.user.loggedIn;
      },
    },
    data() {
      return {
      };
    },
  };
  </script>
  
  <style scoped>

.dropdown-wrapper {
    position: absolute;
    width:300px;
    top:100%;
    z-index:100;
    padding-top:10px;
}
.dropdown-container {
    background-color: var(--dark2);
    padding:10px;
    border-radius:5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    display:flex;
    flex-flow:column nowrap;
    align-items:flex-start;

  }
  
  .dropdown-item {
    padding: 5px 10px;
    font-weight: 600;
    font-size: 0.9rem;
    background: white;
    color: black;
    margin:5px 0px;
    border-radius: 5px;
    width: 100%;
    cursor:pointer;
    text-align:center;
  }
  
  @media only screen and (max-width: 1079px) {
  
    .dropdown-wrapper {
      width:100%;
      position:relative;
    }
    .dropdown-container{
      top: 0;
      width: 100%;
      left: 0;
      font-size:1.3rem;
      transform:unset;
      background:transparent;
      box-shadow:unset;
      padding:0px;
    }
  
    h3 {
      font-size:1.1rem;
      margin-top:10px;
    }

  
    .dropdown-item {
    margin:0px 0px 0px 0px;
    padding: 10px 10px;
    font-size:1.2rem;
    border-radius:0px;
    background:rgb(112, 112, 112);
    border:none;
    border-bottom:1px solid lightgrey;
    color:white;
    font-weight:500;
    height:3rem;
  }
  
  }
  
  </style>