<template>
  <div class="menu-row">
    <h3>Type:</h3>
    <select class="rounded" v-model="selectedType">
      <option v-for="type in itemTypesOptions" :key="type.index">
        {{ type }}
      </option>
    </select>
    <transition name="width">
      <div
        class="combobox"
        v-if="
          selectedType == 'Armor' ||
          selectedType == 'Weapon'
        "
      >
      
    <div class="overlay clear" v-if="dropList" @click="dropList=false"></div>
        <input
          class="rounded"
          type="text"
          v-model="selectedSubtype"
          @focus="dropList = true"
        />
        <!-- <i class="fas fa-angle-down"></i> -->
        <transition name="height">
          <div class="drop-list" v-if="dropList">
            <transition-group name="height">
            <div
              class="option"
              v-for="subtype in itemSubtypes"
              :key="subtype.name"
              @click="(selectedSubtype = subtype.name), (dropList = false)"
            >
              {{ subtype.name }}
            </div>
            </transition-group>
          </div>
        </transition>
      </div>
    </transition>
                  <!-- <i
      class="fas rounded"
       @click="(locked = !locked)"
      :class="{ highlight: locked, 'fa-lock': locked, 'fa-unlock': !locked  }"
    ></i> -->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CardMenuTypeSelect",
  data() {
    return {
      dropList: false,
      locked: false
    };
  },
  props: { cardType: String, cardID: Number, card: Object },
  watch: {
    selectedType(selectedType) {
      if (
        selectedType != "Armor" ||
        selectedType != "Weapon" ||
        selectedType != "Wondrous item"
      ) {
        this.dropList = false;
      }
    },
  },
  computed: {
    ...mapState(["itemTypes", "itemTypesE", "armors", "weapons", "wonders"]),
    itemSubtypes: function () {
      switch (this.selectedType) {
        case "Armor":
          return this.armors;
        case "Weapon":
          return this.weapons;
        case "Wondrous item":
          return this.wonders;
        default:
          return "";
      }
    },
    itemTypesOptions: function () {
      if (this.cardType == "enchantments") {
        return this.itemTypesE;
      } else {
        return this.itemTypes;
      }
    },
    selectedType: {
      get() {
        return this.card.selectedType;
      },
      set(itemType) {
        this.$store
          .dispatch("updateItemTypes", {
            cardID: this.cardID,
            itemType: itemType,
            itemSubtype: "Any",
          })
          .then(() => {
            this.$store
              .dispatch("randomImage", { cardID: this.cardID })
              .then((randomImage) => {
                this.$store.commit("updateImageSRC", {
                  cardID: this.cardID,
                  imageSRC: randomImage,
                });
              });
          });
      },
    },
    selectedSubtype: {
      get() {
        return this.card.selectedSubtype;
      },
      set(itemSubtype) {
        this.$store.dispatch("updateItemTypes", {
          cardID: this.cardID,
          itemType: this.selectedType,
          itemSubtype: itemSubtype,
        });
      },
    },
  },
};
</script>

<style scoped>

.fas {
  margin-left: 0.25rem;
  height: 1.4rem;
  line-height: 1.2rem;
  padding: 0.1rem 0.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fa:hover,
.highlight {
  color: var(--main2);
  background-color: var(--dark);
}

select {
  margin-left: 2.5px;
}

.width-enter-active {
  transition: all 0.2s ease;
}

.width-leave-active {
  transition: all 0.2s ease;
}

.width-enter-from,
.width-leave-to {
  width: 0;
}
</style>