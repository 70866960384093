<template>
  <div class="dialog-container">
    <div class="overlay clear" @click="$emit('close')"></div>
    <dialog open>
      <header>
        <slot name="title">
          <h2>
            {{ title }}
          </h2>
        </slot>
      </header>
      <section>
        <slot name="content"></slot>
      </section>
      <menu>
        <slot name="actions">
          <button @click="$emit('okay')" v-if="!noOkay">Okay</button>
          <button @click="$emit('close')" v-if="cancellable">Cancel</button>
        </slot>
      </menu>
    </dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {},
    cancellable: Boolean,
    noOkay: Boolean,
  },
  emits: ["close", "okay"],
};
</script>

<style scoped>
.dialog-container {
  position:relative;
  z-index:1000;
}

dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  width:400px;
  max-width:90vw;
  transform: translate(-50%, -50%);
  z-index: 100;
  border-radius: 10px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem;
  line-height: 1.5;
  z-index: 102;
}

header {
  background-color: var(--dark);
  color: white;
  width: 100%;
  padding: 1rem;
  text-align: center;
  line-height: 1;
}

header h2 {
  margin: 0;
}

section {
  padding: 0.5rem;
}

menu {
  display: flex;
  justify-content: center;
  margin: 0;
}

button {
  margin: 10px;
}

</style>
