<template>
  <PriceListFilters @filterItems="filterItems" :refreshFilter="refresher"/>
  <PriceListTable @refreshFilter="refreshFilter" @filterItem="filterItem" :items="items" :mode="'priceList'"/>
</template>

<script>
import PriceListFilters from "./PriceListFilters.vue";
import PriceListTable from "./PriceListTable.vue";

export default {
  name: "PriceList",
  data() {
    return {
      items: this.$store.state.items,
      refresher: 0
    };
  },
  methods: {
    filterItems(filteredItems) {
      this.items = filteredItems;
    },
    filterItem(filteredItem) {
      this.items = this.items.filter(
        (item) => item.name != filteredItem.name)
    },
    refreshFilter() {
      this.refresher += 1;
    }
  },
  components: { PriceListTable, PriceListFilters },
};
</script>

<style scoped>
</style>