<template>
  <BaseHeader @deselectNpc="$store.commit('deselectNpc')" @selectNpc="selectNpc" />
  <NpcBlocks @selectNpc="selectNpc" v-if="!npcSelected" />

  <div class="npc-presentation-container">
    <vue3-html2pdf :show-layout="true" :float-layout="false" :enable-download="true" :preview-modal="true"
      :paginate-elements-by-height="2000" filename="npcpresentation" :pdf-quality="2" :manual-pagination="false"
      pdf-format="a4" pdf-orientation="portrait" pdf-content-width="800px" ref="html2Pdf">
      <template v-slot:pdf-content>
        <NpcPresentation v-if="npcSelected" @printPDF="printPDF" />
      </template></vue3-html2pdf>
  </div>
</template>
<script>
import NpcBlocks from "../NpcGenerator/NpcBlocks.vue"
import BaseHeader from "../Header/BaseHeader.vue"
import NpcPresentation from "../NpcGenerator/NpcPresentation.vue"
import Vue3Html2pdf from "vue3-html2pdf";

export default {
  name: "NpcOverview",
  components: { NpcBlocks, BaseHeader, NpcPresentation, Vue3Html2pdf },
  methods: {
    selectNpc(shop) {
      this.$store.commit('selectNpc', shop.name);
    },
    printPDF() {
      this.$refs.html2Pdf.generatePdf();
    }
  },
  computed: {
    npcSelected() {
      return this.$store.state.npcSelected;
    }
  }
}

</script>
<style scoped>
.npc-presentation-container {
  margin: 0px auto;
  width: 1000px;
  max-width: 100vw;
}

@media screen and (max-width: 1000px) {
  .npc-presentation-container {
    width: 100vw;
    overflow: hidden;
  }
}
</style>