<template>
  <CardEditor/>
</template>

<script>


import CardEditor from "../components/CardEditor.vue";

export default {
name: "CardGenerator",
components: {CardEditor}
}
</script>

<style scoped>

</style>