<template>

<div class="top-buttons">
    <button class="top-button" @click="printPdfModal()">Print PDF</button>
    <button class="top-button" @click="$emit('closePrintPdfModal')">Exit Print View</button></div>
  <vue3-html2pdf
    :show-layout="true"
    :float-layout="false"
    :enable-download="true"
    :preview-modal="true"
    :paginate-elements-by-height="2000"
    filename="pricelist"
    :pdf-quality="2"
    :manual-pagination="false"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width="800px"
    ref="html2Pdf"
  >
    <template v-slot:pdf-content>
      <div class="pricelist-container montserrat-small">
        <!-- TABLE OF ITEMS -->
        <div class="pdf-page html2pdf__page-break" v-for="(page, index) in pages" v-bind:key="index">
          <div
            class="item-table menu-row column"
            v-if="filteredItems && filteredItems.length > 0"
          >
            <!--- TOP ROW -->

            <div class="item header">
              <div
                class="item-name"
                :class="{
                  long: nColumns == 1,
                  medium: nColumns == 2,
                  small: nColumns == 3,
                  tiny: nColumns == 4,
                }"
              >
                Name
              </div>
              <div
                class="item-type"
                v-if="listStyle == 'table' && showType"
                :class="{
                  long: nColumns == 1,
                  medium: nColumns == 2,
                  small: nColumns == 3,
                  tiny: nColumns == 4,
                }"
              >
                Type
              </div>
              <div
                class="item-rarity"
                v-if="listStyle == 'table' && showRarity"
                :class="{
                  long: nColumns == 1,
                  medium: nColumns == 2,
                  small: nColumns == 3,
                  tiny: nColumns == 4,
                }"
              >
                Rarity
              </div>
              <div
                class="item-value"
                v-if="showValue"
                :class="{
                  long: nColumns == 1,
                  medium: nColumns == 2,
                  small: nColumns == 3,
                  tiny: nColumns == 4,
                }"
              >
                Value
              </div>
            </div>

            <!--- ITEM ROWS --->
            <div
              class="item"
              v-for="item in page"
              v-bind:key="item.name"
            >
              <!-- ITEM NAME COLUMN -->
              <div
                class="item-name"
                :class="{
                  common: item.rarity == 'Common',
                  uncommon: item.rarity == 'Uncommon',
                  rare: item.rarity == 'Rare',
                  'very-rare': item.rarity == 'Very Rare',
                  legendary: item.rarity == 'Legendary',
                  artifact: item.rarity == 'Artifact',
                  long: nColumns == 1,
                  medium: nColumns == 2,
                  small: nColumns == 3,
                  tiny: nColumns == 4,
                }"
                @mouseleave="showAddShopMenu = null"
              >
                <a v-bind:href="item.url" target="_blank">{{ item.name }}</a
                ><i
                  v-if="
                    showAttunement &&
                    item.attunement &&
                    item.attunement.length > 1
                  "
                  class="fab fa-adn"
                ></i
                ><span
                  class="item-src"
                  v-if="showSource"
                  :class="{
                    gray: item.source == 'DMG' || item.source == 'SRD',
                    pink: item.source == 'TCOE',
                    darkgreen: item.source == 'XGTE',
                    teal: item.source == 'MGMM',
                    darkblue: item.source == 'WGMM',
                    red: item.source == 'Custom',
                  }"
                >
                  {{ item.source }}</span
                >
                <div v-if="listStyle == 'condensed'" class="item-subheader">
                  {{ showType ? item.type : ""
                  }}{{ item.subtype && showSubtype ? " " + item.subtype : ""
                  }}{{
                    (showType || (showSubtype && item.subtype)) && showRarity
                      ? ", "
                      : ""
                  }}
                  {{ showRarity ? item.rarity : "" }}
                </div>
              </div>

              <!--- ITEM TYPE COLUMN --->

              <div
                class="item-type"
                v-if="listStyle == 'table' && showType"
                :class="{
                  long: nColumns == 1,
                  medium: nColumns == 2,
                  small: nColumns == 3,
                  tiny: nColumns == 4,
                }"
              >
                {{ item.type
                }}{{ item.subtype && showSubtype ? " " + item.subtype : "" }}
              </div>

              <!--- ITEM RARITY COLUMN --->

              <div
                class="item-rarity"
                v-if="listStyle == 'table' && showRarity"
                :class="{
                  long: nColumns == 1,
                  medium: nColumns == 2,
                  small: nColumns == 3,
                  tiny: nColumns == 4,
                }"
              >
                {{ item.rarity }}
              </div>

              <!--- ITEM VALUE COLUMN --->

              <div
                class="item-value"
                v-if="showValue"
                :class="{
                  long: nColumns == 1,
                  medium: nColumns == 2,
                  small: nColumns == 3,
                  tiny: nColumns == 4,
                }"
              >
                <div>
                  {{
                    item.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}{{ item.subvalue ? "+" : "" }} gp
                </div>
                <div v-if="showSubvalue" class="item-subvalue">
                  {{ item.subvalue }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></template
    >
  </vue3-html2pdf>
</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";
export default {
  name: "PriceListTablePrintPDF",
  props: [
    "filteredItems",
    "listStyle",
    "showType",
    "showSubtype",
    "showRarity",
    "showValue",
    "showSubvalue",
    "showSource",
    "showAttunement",
    "nColumns",
  ],
  emits: ["closePrintPdfModal", "endPrintPdfModal"],
  components: {
    Vue3Html2pdf,
  },
  methods: {
    printPdfModal() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
  computed: {
    pages() {
      const chunkSize = 22;
      const pages = [];
      const items = this.filteredItems;
      for (let i = 0; i < items.length; i += chunkSize) {
        const chunk = items.slice(i, i + chunkSize);
        pages.push(chunk);
      }
      return pages;
    },
  },
};
</script>

<style scoped>
.price-list {
  position: relative;
  max-width: 1200px;
  font-size: 15px;
  font-family: montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.top-buttons {
  width:100%;
  display:flex;
  flex-flow:row nowrap;
  justify-content:center;
  background:white;
  border-radius: 10px 10px 0px 0px;
}

.top-button {
  margin:10px;
}

.item-table {
  background-color: white;
  padding: 20px !important;
}

.item-table > div {
  width:100%;
}

.item {
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  background: white;
  width: 100%;
  text-align: left;
  position: relative;
}

.item:nth-of-type(odd) {
  background: rgb(235, 238, 238);
}

.item:last-of-type {
  border-radius: 0px 0px 5px 5px;
}

.item-name {
  font-weight: 600;
}

.item-subheader {
  padding: 0px;
  font-style: italic;
  font-size: 0.8rem;
  font-weight: 400;
  color: black;
}

.item-source {
  text-align: right;
  justify-content: flex-end;
}

.item-value {
  text-align: right;
  justify-content: flex-end;
}

.item-subvalue {
  font-size: 0.8rem;
  font-style: italic;
}

.item-value,
.item-rarity,
.item-type,
.item-source {
  flex-grow: 1;
}

/* WIDTHS FOR TABLE COLUMNS */

.long {
  width: 40%;
}

.medium {
  width: 25%;
}
.small {
  width: 20%;
}
.tiny {
  width: 12.5%;
}

.item-name.long {
  width: 60%;
}
.item-name.medium {
  width: 60%;
}
.item-name.small {
  width: 45%;
}
.item-name.tiny {
  width: 50%;
}

/*  */

.header {
  background: #0a0a0a !important;
  color: white;
  position: relative;
  font-weight: 600;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
}

i {
  cursor: pointer;
  padding: 5px;
}

.item .fas,
.item-name .fas,
.item-name .add-shop {
  opacity: 0;
  transition: all 0.3s ease;
  position: relative;
}

.item .item-src {
  opacity: 1;
  transition: all 0.3s ease;
  position: relative;
}

.header .fas {
  color: white;
  opacity: 1;
}

.common {
  color: black;
}

.uncommon {
  color: #32a82e;
}

.rare {
  color: #5992d4;
}

.very-rare {
  color: #9810e0;
}

.legendary {
  color: #fea227;
}

.artifact {
  color: #be8972;
}

.fa-adn,
.item-list-item .fas {
  color: black;
  font-size: 0.8rem;
  margin: 0px;
  padding: 0px 2px 1px 5px;
}

.item-src {
  margin-left: 0.2rem;
  font-size: 0.7rem;
}

.gray {
  color: gray;
}

.pink {
  color: palevioletred;
}

.darkgreen {
  color: rgb(1, 158, 1);
}

.darkblue {
  color: darkblue;
}

.teal {
  color: aquamarine;
}

.red {
  color: crimson;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1079px) {
  .price-list {
    width: 100%;
    margin: 0px 0px 80px 0px;
  }

  .item .fas,
  .item-name .fas,
  .item-name .add-shop,
  .item .item-src {
    display: none;
  }

  .fa-caret-down {
    display: inline-block !important;
  }

  .item-name {
    width: 65%;
  }
}
</style>