<template>
  <BaseHeader :welcome="true" />
  <div class="main-container">
    <img class="logo-image" src="@/assets/eventyr_logo.png" href="https://eventyrgames.com" />
    <div class="page-button rounded shadow" @click="$router.push('/pricelist')">
      <div class="menu-row flex-center">
        <i class="fas fa-scroll"></i>
        <h2>5E Magic Item Price List</h2>
      </div>
    </div>
    <div class="page-button rounded shadow" @click="$router.push('/cardgenerator')">
      <div class="menu-row flex-center">
        <i class="fas fa-magic"></i>
        <h2>Magic Item Generator</h2>
      </div>
    </div>
    <div class="page-button rounded shadow" @click="$router.push('/shopgenerator')">
      <div class="menu-row flex-center">
        <i class="fas fa-store"></i>
        <h2>NPC/Shop Generator</h2>
      </div>
    </div>
    <div class="page-button rounded shadow" @click="$router.push('/pantheongenerator')">
      <div class="menu-row flex-center">
        <i class="fas fa-praying-hands"></i>
        <h2>Pantheon Generator</h2>
      </div>
    </div>

  </div>
</template>

<script>
import BaseHeader from "../components/Header/BaseHeader.vue";

export default {
  name: "WelcomePage",
  components: { BaseHeader },
};
</script>

<style scoped>
.main-container {
  width: 1200px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-image {
  width: 150px;
  margin-bottom: 50px;
}

.fas {
  font-size: 3rem;
  padding: 0rem 1rem;
  color: white;
  margin-right: 1rem;
  transition: all 0.5s ease;
  text-align: center;
}

.page-button {
  width: 400px;
  max-width: 50%;
  height: 100px;
  background-color: var(--dark2);
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  transition: all 0.5s ease;
  cursor: pointer;
}

h2 {
  transition: all 0.5s ease;
}

.page-button:hover {
  transform: scale(1.05);
  color: var(--main);
  transition: all 0.5s ease;
}

.page-button:hover i,
.page-button:hover h2 {
  color: var(--main2);
  transition: all 0.5s ease;
}


@media only screen and (max-width: 1080px) {

  .main-container {
    width: 100%;
    height: 100vh;
    margin: 0;
  }

  .page-button {
    width: 90%;
    max-width: 90%;
    padding: 10px;
    height: 100px;
    background-color: var(--dark2);
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    transition: all 0.5s ease;
    cursor: pointer;
  }


  .logo-image {
    display: none;
  }

}
</style>