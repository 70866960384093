import { createStore } from "vuex";
import actions from "./actions.js";
import mutations from "./mutations.js";
import data from "./data.js";
import getters from "./getters.js";
import dmg from "./json/dmgNew.json";
import tcoe from "./json/tcoe.json";
import xgte from "./json/xgte.json";
import wgmm from "./json/wgmm.json";
import mgmm from "./json/mgmm.json";
import hgdd from "./json/hgdd.json";
import cultData from "./json/cultData.js";
import pantheonData from "./json/pantheonData.js";
import srdtrial from "./json/srdtrial.json";

const store = createStore({
  state() {
    return {
      artCredit: data.artCredit,
      itemTypes: data.itemTypes,
      npcRaces: data.npcRaces,
      shopLocations: data.shopLocations,
      itemTypesE: data.itemTypesE,
      rarities: data.rarities,
      attunementChoices: data.attunementChoices,
      armors: data.armors,
      weapons: data.weapons,
      wonders: data.wonders,
      enchantments: data.enchantments,
      srd: dmg,
      wgmm: wgmm,
      mgmm: mgmm,
      hgdd: hgdd,
      items: dmg
        .concat(tcoe)
        .concat(xgte)
        .concat(wgmm)
        .concat(mgmm)
        .concat(hgdd),
      npc_images: data.npc_images,
      shop_images: data.shop_images,
      d20: data.d20,
      d20minusd4: data.d20minusd4,
      d20plusd4: data.d20plusd4,
      d6plusd4: data.d6plusd4,
      d6: data.d6,
      d4: data.d4,
      d6minusd4: data.d6minusd4,
      d6plusd6: data.d6plusd6,
      cultData: cultData,
      pantheonData: pantheonData,
      srdtrial: srdtrial,

      // MUTABLE DATA
      customEnchantments: [],
      customShops: [],
      selectedShop: null,
      selectedPantheon: null,
      npcSelected: false,
      loaded: false,
      patreonKey: "",
      cardCounter: 0,
      updateCounter: 0,
      editingCard: false,
      showGuide: false,
      pantheons: [],
      customItems: [],
      userItems: [],
      cards: [],
      collections: [],
      selectedCards: [],
      selectedCardID: null,
      selectedCollection: { cards: [] },
      selectedCollectionID: 0,
      user: {
        loggedIn: false,
        data: null,
      },
      tutorialDone: true,
    };
  },
  getters: getters,
  mutations: mutations,
  actions: actions,
});

export default store;
