<template>
  <div class="top-container menu-row" v-if="cardType == 'Custom'" :class="{ pulsing: tutorial && tutorialStep == 8 }">
    <transition name="tooltip">
      <div class="tooltip-top" v-if="tutorial && tutorialStep == 8">
        Select type of base item
      </div>
    </transition>
    <CardTypeSelect :cardID="cardID" :card="card"></CardTypeSelect>
  </div>
  <div class="top-container" v-else-if="cardType == 'srd'">
    <ItemSelect :cardID="cardID" :card="card"></ItemSelect>
  </div>
  <div class="top-container" v-else-if="cardType == 'enchantments'">
    <EnchantmentSelect :id="0" v-if="cardType == 'enchantments'" :cardID="cardID" :card="card"></EnchantmentSelect>
    <EnchantmentSelect :id="1" v-if="cardType == 'enchantments'" :cardID="cardID" :card="card"></EnchantmentSelect>
    <EnchantmentSelect :id="2" v-if="cardType == 'enchantments'" :cardID="cardID" :card="card"></EnchantmentSelect>
  </div>

  <div>
    <div class="top-line" v-if="cardType != 'collectionItem'">
      <div class="top-line-button" :class="{ selected: showTab == 'Item' }" @click="showTab = 'Item'">
        <h3>ITEM</h3>
      </div>

      <div class="top-line-button" :class="{ selected: showTab == 'Layout' }" @click="showTab = 'Layout'">
        <h3>LAYOUT</h3>
      </div>
    </div>
    <div class="editor-container">
      <transition name="fade" mode="out-in">
        <div class="editor-subcontainer" v-if="showTab == 'Item'">
          <transition-group name="slide-fade" mode="out-in" v-if="cardType != 'enchantments'">
            <OptionsSelect v-for="(options, index) in optionsArray" :key="index" :id="index" :cardID="cardID"
              :card="card"></OptionsSelect>
          </transition-group>

          <ImageSelect :cardID="cardID" :card="card"></ImageSelect>
          <TypeSelect :cardType="cardType" :cardID="cardID" :card="card"></TypeSelect>
          <RaritySelect :cardID="cardID" :card="card"></RaritySelect>
          <AttunementSelect :cardID="cardID" :card="card"></AttunementSelect>
          <div class="menu-row button-row" v-if="card.cardType != 'enchantments'"> <button
              @click="$store.commit('updateEditingPreference', { cardID: cardID, editingPreference: 'contenteditable' })"
              :class="{ selected: !card.editingPreference || card.editingPreference == 'contenteditable' }">EDIT ON
              CARD</button><button
              @click="$store.commit('updateEditingPreference', { cardID: cardID, editingPreference: 'input' })"
              :class="{ selected: card.editingPreference == 'input' }">USE EDITOR</button>
          </div>

          <NameSelect :cardID="cardID" :card="card" v-if="card.editingPreference == 'input'"></NameSelect>
          <CustomDescriptionSelect :cardID="cardID" :card="card"
            v-if="card.editingPreference == 'input'">
          </CustomDescriptionSelect>
          <EnchantmentEditsBlockSelect :id="0" :cardID="cardID" :card="card"
            v-if="card.editingPreference == 'input' && card.cardType == 'enchantments' && 0 == 1">
          </EnchantmentEditsBlockSelect>
          <BonusesSelect v-if="cardType === 'enchantments'" :cardID="cardID" :card="card"></BonusesSelect>
        </div>
        <div class="editor-subcontainer" v-else-if="showTab == 'Layout'">
          <FontSelect :cardID="cardID" :card="card"></FontSelect>
          <LayoutSelect :cardID="cardID" :card="card"></LayoutSelect>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import CardTypeSelect from "./CardMenuSelects/CardTypeSelect.vue";
import ItemSelect from "./CardMenuSelects/ItemSelect.vue";
import EnchantmentSelect from "./CardMenuSelects/EnchantmentSelect.vue";
import EnchantmentEditsBlockSelect from "./CardMenuSelects/EnchantmentEditsBlockSelect.vue";
import OptionsSelect from "./CardMenuSelects/OptionsSelect.vue";
import NameSelect from "./CardMenuSelects/NameSelect.vue";
import TypeSelect from "./CardMenuSelects/TypeSelect.vue";
import RaritySelect from "./CardMenuSelects/RaritySelect.vue";
import AttunementSelect from "./CardMenuSelects/AttunementSelect.vue";
import LayoutSelect from "./CardMenuSelects/LayoutSelect.vue";
import CustomDescriptionSelect from "./CardMenuSelects/CustomDescriptionSelect.vue";
import BonusesSelect from "./CardMenuSelects/BonusesSelect.vue";
import ImageSelect from "./CardMenuSelects/ImageSelect.vue";
import FontSelect from "./CardMenuSelects/FontSelect.vue";
export default {
  name: "CardMenuSettings",
  props: ["cardID", "card", "cardType", "tutorial", "tutorialStep"],
  components: {
    NameSelect,
    TypeSelect,
    RaritySelect,
    AttunementSelect,
    CustomDescriptionSelect,
    FontSelect,
    OptionsSelect,
    BonusesSelect,
    ItemSelect,
    EnchantmentSelect,
    ImageSelect,
    CardTypeSelect,
    LayoutSelect,
    EnchantmentEditsBlockSelect
  },
  emits: ["printCard", "deselect", "editingPreference"],
  data() {
    return {
      editingPreference: "contenteditable",
      showTab: "Item",
    };
  },
  mounted() {
    if (this.card.cardType == "enchantments") {
      this.$store.commit('updateEditingPreference', { cardID: this.cardID, editingPreference: 'input' })
    }
  },
  computed: {
    optionsArray() {
      return this.card.options;
    },
  },
  methods: {
    selectCard(cardID) {
      this.$store.commit("updateSelected", {
        cardID: cardID,
      });
    },
    printCard() {
      this.$emit("printCard");
    },
  },
};
</script>

<style scoped>
.editor-container {
  transition: all 0.5s ease;
  width: 100%;
  z-index: 1;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  /* box-shadow: 0 2px 5px rgb(0, 0, 0); */
  background-color: var(--dark3);
  padding: 0.5rem;
  border-radius: 0 0 5px 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.editor-subcontainer {
  width: 100%;
}

.editor-subcontainer>div {
  margin-top: 5px;
}

.editor-container>div:first-of-type {
  margin-top: 0rem;
}

.top-container {
  width: 100%;
  margin-bottom: 10px;
  padding: 0.5rem;
  box-shadow: 0 2px 5px rgb(0, 0, 0);
  background-color: var(--dark3);
  transition: filter 0.5s ease;
}

.front-button {
  margin-right: 10px;
}

.dual>div:nth-child(1) {
  margin-right: 5px;
  width: 55%;
}

.dual>div:nth-child(2) {
  width: 45%;
}

.front-button,
.back-button {
  cursor: pointer;
  filter: grayscale(1) opacity(0.5);
}

.top-container.selected {
  filter: grayscale(0) opacity(1);
  transition: filter 0.5s ease;
}

.top-line {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.top-line-button {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 5px 5px 0 0;
  transition: all 0.5s ease;
  width: 100%;
  background-color: var(--dark3);
  filter: opacity(0.5);
  text-align: center;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

.top-line-button.selected {
  filter: opacity(1);
}

.button-row button:nth-child(1) {
  margin-right: 10px;
}

button {
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  margin: 0px;
  filter: opacity(0.5) grayscale(1);
  transition: all 0.5s ease;
}

button.selected {
  filter: opacity(1) grayscale(0);
  transition: all 0.5s ease;
}

@media only screen and (max-width: 1080px) {
  .top-container {
    padding: 10px;
    width: 95vw;
    margin: 10px auto;
    background-color: var(--dark3);
  }

  .top-line {
    width: 95vw;
    margin: 0px auto 0px auto;
  }

  .editor-container {
    margin: unset;
    padding-top: 0;
    width: 95vw;
    margin: 0px auto 80px auto;
  }

  .editor-subcontainer>div {
    margin-top: 10px;
  }
}</style>
