<template>
<div class="component-wrapper">
    <div class="overlay clear" @click="$emit('close')"></div>
  <div class="add-item-menu">
    <h2 v-if="mode != 'priceList'">Add Existing Item to List</h2>
    <div class="menu-row add-item-row" v-if="mode != 'priceList'">
      <select
        class="add-item-drop"
        placeholder="Item Name"
        v-model="itemToSave"
      >
        <option
          v-for="item in $store.state.items"
          v-bind:key="item.name"
          :class="{
            deactive: filteredItems.some((object) => object.name == item.name),
          }"
        >
          {{ item.name }}
        </option>
      </select>
      <div
        class="inline-button"
        @click="
          addToShop(
            $store.state.items.find((item) => item.name == itemToSave),
            $store.state.selectedShop.name
          )
        "
      >
        Add
      </div>
    </div>
    <div class="menu-row centered">
      <h2>Add Custom Item to List</h2>
    </div>
    <div class="menu-row fields" v-if="loggedIn">
      <i class="fas fa-times close" @click="$emit('close')"></i>
      <div class="name">
        <h4>Item:</h4>
        <input
          class="text-input"
          v-model="name"
          placeholder="Magic Item Name"
        />
      </div>
            <div class="value short">
        <h4>Value:</h4>
        <input class="text-input" type="number" v-model="value" />
      </div>
      <div class="subvalue">
        <h4>Value Note:</h4>
        <input class="text-input" v-model="subvalue" />
      </div>
            <div class="value">
        <h4>Rarity:</h4>
        <select id="rarity" v-model="rarity">
          <option value="None">None</option>
          <option value="Common">Common</option>
          <option value="Uncommon">Uncommon</option>
          <option value="Rare">Rare</option>
          <option value="Very Rare">Very Rare</option>
          <option value="Legendary">Legendary</option>
          <option value="Artifact">Artifact</option>
        </select>
      </div>
      
    </div>
    <div class="menu-row fields" v-if="loggedIn">
              <div class="type">
        <h4>Type:</h4>
        <select id="type" v-model="type">
          <option value="Armor">Armor</option>
          <option value="Potion">Potion</option>
          <option value="Ring">Ring</option>
          <option value="Rod">Rod</option>
          <option value="Scroll">Scroll</option>
          <option value="Staff">Staff</option>
          <option value="Wand">Wand</option>
          <option value="Weapon">Weapon</option>
          <option value="Wondrous Item">Wondrous Item</option>
        </select>
      </div>
      <div class="subtype">
        <h4>Subtype:</h4>
        <input class="text-input" v-model="subtype" />
      </div>              <div class="type">
        <h4>Attunement:</h4>
        <select id="type" v-model="attunement">
          <option value="">None</option>
          <option value="Requires Attunement">Requires Attunement</option>
        </select>
      </div>
      

      <div
        class="inline-button"
        @click="mode != 'priceList' ? 
          addToShop(
            {
          name: name,
          type: type,
          subtype: subtype.length > 0 ? subtype : '',
          rarity: rarity,
          value: value,
          subvalue: subvalue,
          source: 'Custom',
        },
           $store.state.selectedShop.name 
          ) : saveItem()
        "
        v-if="loggedIn"
      >
        Add
      </div>
    </div>
    <div class="menu-row centered">
      <div class="warning" v-if="showNonameWarning">Please input a name.</div>
      <div v-if="showDuplicateWarning" class="warning">
        An item already exists with that name.
      </div>
      <div v-if="!loggedIn" class="warning">
        You must be logged in to add custom items to the database.
      </div>
    </div>
    <div class="menu-row centered">
      <div class="save-button" @click="goToLogin" v-if="!loggedIn">
        Login/Signup
      </div>
    </div>
  </div></div>
</template>

<script>
export default {
  name: "AddMenu",
  emits: ["close", "addToShop"],
  props: ["filteredItems","mode"],
  data() {
    return {
      name: "",
      type: "Armor",
      subtype: "Any",
      rarity: "Common",
      subvalue: "",
      attunement: "",
      value: 1,
      showDuplicateWarning: false,
      showNonameWarning: false,
      itemToSave: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
  },
  methods: {
    addToShop(item, shopName) {
      this.$emit("addToShop", item, shopName);
    },
    goToLogin() {
      this.$router.push("/login");
    },
    saveItem() {
      if (!this.name || this.name.length == 0) {
        this.showNonameWarning = true;
        this.showDuplicateWarning = false;
      } else if (
        this.$store.state.customItems.some((item) => item.name == this.name)
      ) {
        this.showNonameWarning = false;
        this.showDuplicateWarning = true;
      } else {
        this.showDuplicateWarning = false;
        this.showNonameWarning = false;
        const newItem = {
          name: this.name,
          type: this.type,
          subtype: this.subtype,
          rarity: this.rarity,
          value: this.value,
          subvalue: this.subvalue,
          attunement: this.attunement,
          source: "Custom",
          description: "",
          imageSRC: "",
        };
        this.$store.dispatch("saveToUserItems", newItem);
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped>
.add-item-menu {
  background: var(--dark2);
  position: fixed;
  z-index: 1000;
  width: 500px;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.5);
}

.add-item-row {
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  margin-top: 10px;
}

.add-item-drop {
  border-radius: 5px;
  padding: 5px;
  height: auto;
}

.inline-button {
  margin-left: 5px;
  color: black;
  padding: 9px 20px;
  display: flex;
  background-color: white;
  border-radius: 5px;
  border: 2px solid black;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  font-family:montserrat, sans-serif;
}

.inline-button:hover {
  color: white;
  background-color: black;
}

.deactive {
  color: rgb(211, 211, 211);
}

/* .menu-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    position: fixed;
    width:1000px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #282838;
    padding: 10px;
    border-radius: 5px;
    z-index: 101;
    user-select: none;
    box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.486);
    transition: all 1s;
  } */
h4,
h3,
h2 {
  color: white;
  margin-top: 10px;
}

.save-button {
  background: white;
  color: #0b0b16;
}

.menu-row {
  margin-bottom: 10px;
}

.menu-row > div {
  margin-right: 10px;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.5rem;
  color: white;
  transition:all 0.3s ease;
}

.close:hover {
  transform:scale(1.5);
}

select {
  border-radius: 5px;
  min-height: 40px;
  background-color: white;
  box-shadow: inset 0 0 4px 0 rgb(138 177 198 / 75%);
  border: solid 1px #9c9898;
  box-sizing: border-box;
  color: black;
  font-size: 0.8rem;
}

.value.short {  
  width: 75px;

}


.fields {
  align-items: flex-end;
}

@media only screen and (max-width: 1000px) {
  .add-item-menu {
    width: 90vw;
    font-size: 1.2rem;
  }

.menu-row {
  margin-bottom:0px;
}

  .menu-row > div {
    margin-right: 0px;
    margin-bottom: 5px;
  }

  .fields {
    flex-direction: column;
  }

  h2 {
    margin-bottom: 10px;
  }

  .add-item-row {
    margin:0;
  }
  
  .subvalue {
  margin-right:0px !important;
}

  .fields > div {
    width: 100%;
    display: flex;
  }

  .inline-button {
    justify-content: center;
  }

}
</style>
