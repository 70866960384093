<template>
    <BaseHeader></BaseHeader>
    <Collections></Collections>
  </template>
  
  <script>
  
  import BaseHeader from "../components/Header/BaseHeader.vue";
  import Collections from "../components/Collections/Collections.vue";
  
  export default {
      name: "MyCreations",
      components: {Collections, BaseHeader}
  
  }
  </script>
  
  <style scoped>
  
  </style>