<template>
  <nav>
    <div class="overlay clear" :class="{ show: showMobileMenu }" @click="showMobileMenu = false"></div>
    <transition name="fade">
      <BaseDialog v-if="showChangelog" title="Changelog & Bug Report" @close="showChangelog = false"
        @okay="showChangelog = false" :noOkay="true">
        <template #content>
          <b>CHANGELOG 2.3</b>
          <ul>
            <li>• Added List View for desktop</li>
            <li>• Added Card View for mobile</li>
            <li>• Item Cards can now be dragged and dropped</li>
            <li>• Item Cards can now be selected and added to print, flipped, or deleted in bulk</li>
            <li>• Collections no longer have pages but are scrollable</li>
            <li>• Created a my creations page</li>
            <li>• Various quality of life improvements</li>
          </ul>
          <br />
          <b>Found a Bug?</b> Help us fix it! Please email us at <a
            href="mailto:jvaleur@eventyrgames.com">jvaleur@eventyrgames.com</a> or <a href="https://discord.gg/nBtqwJN55a"
            target="_blank">visit the Discord</a> and give your input!
        </template>
      </BaseDialog>
    </transition>
    <div class="header-main">
      <div class="header-container header-section-1-3">
        <div class="menu-row flex-start">
          <img class="logo-image" src="@/assets/eventyr_logo.png" href="https://eventyrgames.com" style="cursor: pointer"
            @click="$router.push('/')" />
        </div>
      </div>
      <div class="header-container header-section-2-3">
        <div class="menu-row flex-center column">
          <div class="top-tagline">Want to Trade Magic Items in 5E?</div>
          <div class="wgmm-link">
            Check out
            <a href="https://eventyrgames.store/products/wanderers-guide-to-merchants-magic" target="_blank">
              Wanderer's Guide to Merchants & Magic!</a>
          </div>
        </div>
      </div>
      <div class="header-container header-section-3-3">
        <div class="mobile-menu">
          <i class="fas fa-bars" @click="showMobileMenu = !showMobileMenu"></i>
        </div>
        <div class="menu-row flex-right link-menu" :class="{ show: showMobileMenu }">
          <div class="overlay clear" v-if="showAccount || showNPCList"
            @click="(showAccount = false), (showNPCList = false)"></div>
          <div class="menu-row column menu-item" @click="showChangelog = true">
            <div class="icon-text">
              <i class="fas fa-bug"></i>
              <h3>BUGS & CHANGELOG</h3>
            </div>
          </div>

          <div class="menu-row column menu-item" @click="$router.push('/pricelist')">
            <div class="icon-text">
              <i class="fas fa-scroll"></i>
              <h3>5E MAGIC ITEM PRICES</h3>
            </div>
          </div>
          <div class="menu-row column menu-item" v-if="loggedIn" @mouseover="showCollectionsDropdown = true" @mouseleave="showCollectionsDropdown = false">
            <div class="icon-text" @click="$router.push('/mycreations')">
              <i class="fas fa-store"></i>
              <h3>MY CREATIONS</h3>
            </div>
            <transition name="fade">
              <BaseHeaderDropdown :dropdownItems="[{ name: 'NPCs & Shops', path: '/npccollection' },
              // { name: 'Saved Items', path: '/itemcollection' },
              { name: 'Pantheons', path: '/pantheoncollection' }
              ]" v-if="showCollectionsDropdown == true"></BaseHeaderDropdown>
            </transition>
          </div>
          <div class="menu-row column menu-item" @click="showGeneratorsDropdown = true" @mouseover="showGeneratorsDropdown = true"
            @mouseleave="showGeneratorsDropdown = false">
            <div class="icon-text">
              <i class="fas fa-magic"></i>
              <h3>5E TOOLS & GENERATORS</h3>
            </div>
            <transition name="fade">
              <BaseHeaderDropdown :dropdownItems="[{ name: 'Item Generator', path: '/cardgenerator' },
              { name: 'NPC/Shop Generator', path: '/shopgenerator' },
              { name: 'Pantheon Generator', path: '/pantheongenerator' }
              ]" v-if="showGeneratorsDropdown == true"></BaseHeaderDropdown>
            </transition>
          </div>

          <div class="menu-row column tutorial" v-if="cardGenerator" @mouseover="showTutorialTooltip = true"
            @mouseleave="showTutorialTooltip = false" @click="$emit('startTutorial')">
            <div class="icon-text">
              <i class="fas fa-question"> </i>
              <h3>TUTORIAL</h3>
            </div>

            <transition name="tooltip">
              <div class="tooltip-bottom" v-if="showTutorialTooltip">
                Take a guided tour of the Item Card Generator
              </div>
            </transition>
          </div>
          <div class="menu-row column menu-item" @mouseover="showAccount = true" @mouseleave="showAccount = false">
            <div class="icon-text" @click="loggedIn ? showAccount = true : $router.push('/login')">
              <i class="fas fa-user" :class="{active: loggedIn}"> </i>
              <h3>{{ loggedIn ? "PROFILE" : "LOG IN" }} </h3>
            </div>
            <transition name="fade">
              <BaseHeaderAccountMenu v-if="showAccount" @hide="showAccount = false"></BaseHeaderAccountMenu>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
// import firebase from "firebase/compat/app";
// import { 
//   getFirestore,
//   collection,
//   query,
//   where,
//   getDocs,
//  } from "firebase/firestore";
import "firebase/compat/auth";
import BaseHeaderAccountMenu from "./BaseHeaderAccountMenu";
import BaseDialog from "../BaseDialog";
import BaseHeaderDropdown from "./BaseHeaderDropdown";

export default {
  name: "BaseHeader",
  components: {
    BaseHeaderAccountMenu,
    BaseDialog,
    BaseHeaderDropdown,
  },
  props: ["priceList", "cardGenerator", "shopGenerator", "welcome"],
  emits: ["startTutorial", "selectNpc", "deselectNpc"],
  data() {
    return {
      patreonReady: false,
      showAccount: false,
      showTutorialTooltip: false,
      showNPCList: false,
      showMobileMenu: false,
      showChangelog: false,
      showGeneratorsDropdown: false,
      showCollectionsDropdown: false,
    };
  },
  // mounted() {
  //   this.fetchSubscription();
  // },
  computed: {
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
    email() {
      return this.$store.state.user.data.email;
    },
    premium() {
      return this.$store.state.premium;
    },
    subscription() {
      if (this.loggedIn && this.$store.state.user && this.$store.state.user.data && this.$store.state.user.data.subscription) {
        return this.$store.state.user.data.subscription;
      } else {
        return null;
      }
    },
  },
  // methods: {
  //     async fetchSubscription() {
  //     const db = getFirestore();
  //     const subsRef = collection(
  //       db,
  //       "customers", firebase.auth().currentUser.uid, "subscriptions"
  //     );
  //     const subsQuery = query(
  //       subsRef,
  //       where("status", "in", ["trialing", "active", "past_due", "unpaid"])
  //     );
  //     this.subscription = await getDocs(subsQuery)
  //       .then((sub) => 
  //           sub.docs.length > 0 ? sub.docs[0].data() : null);
  //       console.log(this.subscription, this.subscription.status);
  //   },
  //   selectNpc(npc) {
  //     this.$emit("selectNpc", npc);
  //   },
  //   signOut() {
  //     firebase
  //       .auth()
  //       .signOut()
  //       .then(() => {
  //         this.$router.push("/login");
  //       });
  //   },
  // },
};
</script>

<style scoped>
nav {
  position: fixed;
  top: 0;
  height: 5rem;
  width: 100vw;
  background: -webkit-linear-gradient(to right, var(--dark), #0b0b16);
  background: linear-gradient(to right, var(--dark), #0b0b16);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  box-shadow: -0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 2;
  font-family: montserrat, sans-serif;
}

.header-main {
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 10px;
}

.menu-row {
  position: relative;
}

h3 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 0.6rem;
  text-align: center;
  margin-right: 0;
  transition: all 0.3s ease;
}

h2 {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  text-align: center;
}

.header-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  padding: 0.5rem;
}

.rows {
  flex-direction: row nowrap;
}

button {
  text-decoration: none;
  border-radius: 20px;
  font-family: "Montserrat", sans-serif;
  border: 1px solid #ffffff;
  background-color: var(--blue);
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 5px;
  width: 100px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 5px 5px;
}

button:active,
i:active,
.patreon:active {
  transform: scale(0.95);
}

button:hover {
  background-color: #1ece7c;
}

button:focus {
  outline: none;
}

.header-section-1-3 {
  align-items: flex-start;
}


.header-section-3-3 {
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  justify-items: flex-end;
  height: 100%;
}

.mobile-menu {
  display: none;
}

.logo {
  width: 3rem;
}

.patreon {
  width: 6rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.patreon:hover,
i:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
  color: var(--main2);
}

i {
  color: #fff;
  font-size: 1.2rem;
  margin: 0.15rem 0.3rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.connected {
  color: var(--main2);
}

.logo-image {
  width: 60px;
  height: auto;
}

.menu-item,
.tutorial {
  width: 75px;
  height: 4rem;
  justify-content: flex-start;
}

.tutorial,
.menu-item {
  margin-right: 10px;
}

.tutorial:hover,
.tutorial:hover h3,
.menu-item:hover h3,
.tutorial:hover i,
.menu-item:hover i {
  color: var(--main2);
  transition: all 0.3s ease;
}

.top-tagline {
  font-weight: 600;
  color: white;
}

.wgmm-link {
  color: white;
  margin-top: 5px;
  margin-left: 5px;
  font-size: 0.8rem;
}

.wgmm-link a {
  font-weight: 600;
  color: goldenrod;
}

.overlay {
  display: none;
}

.link-menu {
  height: 100%;
}

.icon-text {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


a {
  color: #0097a1;
  font-weight: bold;
}

.active {
  color: var(--main2);
}


@media only screen and (max-width: 1079px) {
  .overlay.show {
    display: block;
  }

  .mobile-menu {
    display: block;
  }

  nav {
    height: 4rem;
    backface-visibility: hidden;
  }

  .header-section-1-3 a,
  .header-section-2-3 {
    display: none;
  }

  .tutorial {
    display: none;
  }

  .header-container {
    height: unset;
    width: 100%;
  }

  .header-section-1-3 {
    width: unset;
  }

  .header-section-3-3 {
    width: unset;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;
  }

  .logo-image {
    width: 40px;
  }

  .link-menu {
    display: flex;
    flex-flow: column nowrap;
    position: fixed;
    justify-content: flex-start;
    top: 0;
    right: -100%;
    margin-top: 4rem;
    height: auto;
    background: var(--dark2);
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .link-menu.show {
    width: 275px;
    right: 0;
    box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.5);
  }

  .patreon-connect {
    right: -100%;
    width: 0px;
    overflow: hidden;
    transition: all 0.5s ease;
    justify-content: center;
  }

  .patreon-connect.show {
    right: 0;
    width: 275px;
  }

  .patreon-connect h3 {
    white-space: nowrap;
  }

  .connected {
    font-size: 0.8rem;
  }

  .menu-item {
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
    border-bottom: 1px solid gray;
    margin: 0px;
    min-height: 4rem;
    height: auto;
    position: relative;
  }

  .icon-text {
    flex-flow: row nowrap;
    justify-content: flex-start;
    justify-items: flex-start;
    align-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    min-height: 4rem;
  }

  h3 {
    font-size: 1rem;
  }

  .fa-bars {
    font-size: 2rem;
  }
}
</style>
