<template>
  <div class="component-wrapper">
    <h1>Pantheon & Deity Generator</h1>

    <div class="generators-container">
      <!-- PANTHEON GENERATOR IS HERE -->

      <div class="pantheon-column-1">
        <div class="pantheon-output background">
          <h2>Generate Pantheon</h2>
          <div class="filter-container" v-for="filter in pantheonFilters" :key="filter.name">
            <div class="filter-name">{{ filter.name }}</div>
            <div class="filter-options">
              <div class="filter-option" v-for="option in filter.options" :key="option.value"
                :class="{ selected: option.selected }" @click="option.selected = !option.selected">
                {{ option.value }}
              </div>
            </div>
          </div>
          <div class="filter-container">
            <div class="filter-name">Deity Spread</div>
            <div class="filter-options">
              <div class="filter-option"
                :class="{ selected: deitySelection.alignments }" @click="deitySelection.alignments = !deitySelection.alignments">
                Alignments
              </div>
              <div class="filter-option"
                :class="{ selected: deitySelection.domains }" @click="deitySelection.domains = !deitySelection.domains">
                Domains
                </div>
            </div>
          </div>
        </div>

        <div class="generate-button" @click.self="generatePantheon()" @mouseover="showDeitySelectionDropdown = true"
          @mouseleave="showDeitySelectionDropdown = false">
          Generate
        </div>
        <div class="generate-button" @click.self="showLoadPantheonDropdown = !showLoadPantheonDropdown"
          @mouseleave="showLoadPantheonDropdown = false">Load Pantheon

          <!-- PANTHEON LOAD DROPDOWN -->

          <Transition name="fade">
            <div class="dropdown-container" v-if="showLoadPantheonDropdown">
              <div class="generate-dropdown" v-if="loggedIn">
                <div class="dropdown-select-option" v-for="pantheon, index in pantheons"
                  @click.self="loadPantheon(pantheon)" :key="index">
                  {{ pantheon.name }} <i class="fas fa-trash"
                    @click="$store.dispatch('deletePantheon', pantheon.id)"></i>
                </div>
                <div class="dropdown-header" v-if="!pantheons || pantheons.length == 0">
                  You have no saved pantheons.
                </div>
              </div>
              <div class="generate-dropdown" v-else>
                <div class="dropdown-header">
                  <h3>Log in to load pantheons</h3>
                  <p>
                    You can save and load pantheons if you log in. You can log in or sign up by clicking the button below.
                  </p>
                </div>
                <div class="dropdown-options">
                  <div class="dropdown-select-option" @click="$router.push('/login')">Log in</div>
                </div>
              </div>
            </div>
          </Transition>

        </div>
      </div>

      <div class="pantheon-column-2">
        <PantheonEditorPantheon :pantheonFilters="pantheonFilters" :pantheonSizeN="pantheon.size"
          ref="pantheonEditorPantheon" @pantheonUpdated="setPantheonData" @savePantheon="savePantheon"></PantheonEditorPantheon>

        <PantheonEditorDeities :deitySelection="deitySelection" :pantheonSize="pantheon.size"
          @deitiesGenerated="setDeitiesData" ref="pantheonEditorDeities"></PantheonEditorDeities>
      </div>
    </div>
  </div>
</template>

<script>
import PantheonEditorPantheon from "./PantheonEditorPantheon.vue";
import PantheonEditorDeities from "./PantheonEditorDeities.vue";

export default {
  name: "PantheonEditor",
  components: {
    PantheonEditorPantheon,
    PantheonEditorDeities,
  },
  data() {
    return {
      showDeityGenerator: false,
      showPantheonGenerator: false,
      showDeitySelectionDropdown: false,
      showLoadPantheonDropdown: false,
      deityGenerated: false,
      pantheon: {
        name: "Unnamed Pantheon",
        statistics: [],
        text: "Pantheon Text",
        size: 0,
        deities: [],
      },
      deitySelection: {
        alignments: true,
        domains: true,
      },
    };
  },
  methods: {
    generatePantheon() {
      // GENERATE PANTHEON

      this.$refs.pantheonEditorPantheon.generatePantheon("newPantheon");

      // GENERATE DEITIES

      this.$nextTick(() => {
        this.$refs.pantheonEditorDeities.generateDeities('pantheon')
      });
    },
    setDeitiesData(deities) {
      this.pantheon.deities = deities
      this.pantheon.size = deities.length;
    },
    setPantheonData(pantheonData) {
      this.pantheon.name = pantheonData.name;
      this.pantheon.statistics = pantheonData.statistics;
      this.pantheon.text = pantheonData.text;
      this.pantheon.size = pantheonData.size;
      this.pantheon.id = pantheonData.id;
    },
    loadPantheon(pantheon) {
      this.pantheon = pantheon;
      this.$refs.pantheonEditorPantheon.pantheon = pantheon;
      this.$refs.pantheonEditorDeities.deities = pantheon.deities;
    },
    savePantheon() {
      this.$store.dispatch("savePantheon", this.pantheon)
    }
  },
  computed: {
    pantheonFilters() {
      return this.$store.state.pantheonData.pantheonFilters;
    },
    pantheons() {
      return this.$store.state.pantheons;
    },
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
  },
  mounted() {
    this.selectedFilters = [...this.pantheonFilters];
    if (this.$store.state.selectedPantheon) {
      this.loadPantheon(this.$store.state.selectedPantheon);
    }
  },
  beforeUnmount() {
    this.$store.commit("deselectPantheon");
  },
};
</script>

<style scoped>
.component-wrapper {
  width: 1200px;
  max-width: 100vw;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin: 100px auto;
  font-family: montserrat, "sans-serif";
  color: whitesmoke;
}

.generators-container {
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
}

.background {
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--dark3);
}

.sub-container {
  width: 100%;
  flex: 1;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
}

.pantheon-output {
  position: relative;
  width: 100%;
  margin: 0px 0px 10px 0px;
  align-items: center;
  justify-items: center;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 10px;
  padding: 10px;
}

.pantheon-output .fa-dice-d20 {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pantheon-output .fa-dice-d20:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.pantheon-column-1 {
  width: 400px;
  max-width: 33%;
  flex: 1;
  margin: 10px;
  border-radius: 10px;
}

.pantheon-column-2 {
  width: 800px;
  max-width: 2;
  flex: 2;
  margin: 10px;
}


.filter-container {
  all: unset;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: 10px 0px;
}

.filter-name {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 5px;
}

.filter-options {
  all: unset;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.filter-options.square-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}

.filter-options.square-grid .filter-option {
  text-align: center;
}

.filter-option {
  all: unset;
  margin: 0px 5px;
  padding: 5px;
  background-color: black;
  border-radius: 5px;
  filter: opacity(0.5) grayscale(1);
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
  border: 1px solid whitesmoke;
}

.filter-option.selected {
  filter: opacity(1) grayscale(0);
  transition: all 0.2s ease;
}

.generate-button {
  background-color: var(--dark2);
  position: relative;
  color: white;
  font-size: 1.1rem;
  font-family: montserrat, "sans-serif";
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  z-index:1;
}

.generate-button:nth-of-type(1) {
  margin-right: 10px;
}

.dropdown-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index:100;
}

.generate-dropdown {
  padding: 10px;
  margin-top: 10px;
  display: flex;
  flex-flow: column nowrap;
  background-color: var(--dark5);
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.dropdown-select-option {
  display:flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding:10px;
  border-radius: 5px;
  background-color: var(--dark2);
  margin-top:10px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  font-size: 0.8rem;
}

.dropdown-select-option:hover {
  background-color: var(--dark3);
  transition: all 0.3s ease;
}

.dropdown-select-option:nth-of-type(1) {
  margin-top: 0px;
}

.generate-dropdown p {
  font-size: 0.9rem;
}

.dropdown-options {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.generate-button:hover {
  transform: scale(1.02);
  transition: all 0.2s ease;
}


/* MEDIA QUERIES */

@media only screen and (max-width: 1079px) {


  .component-wrapper {
    width: 95vw;
  }
  .generators-container {
    flex-flow: column nowrap;
  }

  .pantheon-column-1 {
    width: 95%;
    max-width: 100%;
    flex: 1;
    margin: 2.5%;
  }
  .pantheon-column-2 {
    width: 95%;
    max-width: 100%;
    flex: 1;
    margin: 2.5%;
  }
}


</style>