<template>
  <div
    class="card-container"
    :key="updateKey"
    :class="{ print: printView, selected: selected }"
    @contextmenu.prevent="handleContextMenu"
    @mouseleave="showInternalMenu = false"
    v-if="
      (printView && selected && cardsToShow && cardsToShow.includes(cardID)) ||
      !printView
    "
  >
    <CardOutput
      :printView="printView"
      :selected="false"
      :printing="print"
      :cardID="cardID"
      :cardType="cardType"
      :card="card"
      @printed="print = false"
    ></CardOutput>

    <transition name="fade">
      <div
        class="internal-menu rounded"
        :style="{ top: `${contextMenuY}px`, left: `${contextMenuX}px` }"
        v-if="showInternalMenu"
      >
        <div class="menu-row" @click="updateSelectedCards">
          <i class="fas fa-object-group"></i>
          {{ selected ? "Deselect" : "Select" }}
        </div>
        <div class="menu-row" @click="copyCard">
          <i class="fas fa-clone"></i> Copy
        </div>
        <div class="menu-row" @click="flipCard">
          <i class="fas fa-sync"></i> Flip
        </div>
        <div class="menu-row delete-button" @click.stop="deleteCard">
          <i class="fas fa-trash"> </i> Delete
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import CardOutput from "../ItemCardRelaunch/CardOutput.vue";
// import CardBack from "../ARCHIVE/CardBack.vue";

export default {
  name: "SmallCard",
  components: {
    CardOutput,
    // CardBack
  },
  emits: ["print"],
  props: {
    cardID: Number,
    cardType: String,
    printView: Boolean,
    card: Object,
    tutorial: Boolean,
    tutorialStep: Number,
    printBack: Boolean,
    cardsToShow: Array,
  },
  computed: {
    selectedCards() {
      return this.$store.state.selectedCards;
    },
    selected() {
      if (!this.selectedCards || this.selectedCards.length == 0) {
        return false;
      }
      return this.selectedCards.includes(this.cardID);
    },
    index() {
      let index = this.$store.state.cards
        .map((card) => card.cardID)
        .indexOf(this.cardID);
      console.log(index);
      return index;
    },
  },
  methods: {
    copyCard() {
      this.$store.dispatch("copyCard", { cardID: this.cardID });
    },
    flipCard() {
      this.$store.commit("updateBack", {
        cardID: this.cardID,
      });
    },
    updateSelectedCards() {
      this.$store.commit("updateSelectedCards", this.cardID);
      if (!this.selected) {
        this.showInternalMenu = false;
      }
    },
    forceUpdateComponent() {
      this.updateKey = this.updateKey + 1;
    },
    handleContextMenu(event) {
      if (
        this.selectedCards &&
        this.selectedCards.length > 0 &&
        !this.printView
      ) {
        console.log(this.printView);
        this.$store.commit("updateSelectedCards", this.cardID);
      } else {
        this.showInternalMenu = true;
        this.contextMenuX = event.clientX;
        this.contextMenuY = event.clientY;
      }
    },
    deleteCard() {
      this.$store.dispatch("deleteCard", { cardID: this.cardID });
    },
  },
  watch: {
    printBack(newValue) {
      if (newValue) {
        this.showFront = false;
      } else {
        this.showFront = true;
      }
    },
  },
  data() {
    return {
      print: false,
      showDelete: false,
      showFront: true,
      showInternalMenu: false,
      contextMenuX: 0,
      contextMenuY: 0,
      updateKey: 0,
    };
  },
};
</script>

<style scoped>
.card-container {
  position: relative;
  /* transition: all 1s ease-in-out; */
  cursor: pointer;
  display: inline-block;
}

.internal-menu {
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
  color: white;
  font-family: montserrat, "sans-serif";
  text-transform: uppercase;
  background: var(--dark3);
  padding: 5px 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.internal-menu > div {
  transition: all 0.5s ease;
  display: flex;
  flex-flow: row nowrap;
  margin: 5px 0px;
  font-size: 0.8rem;
}

.internal-menu > div:hover {
  transform: scale(1.05);
  transition: all 0.5s ease;
  color: var(--main2);
}

.internal-menu > .delete-button:hover {
  color: rgb(156, 1, 1);
}

.internal-menu i {
  cursor: pointer;
  font-size: 1rem;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.75);
  margin-right: 5px;
}

.internal-menu i:hover.prompt {
  color: rgb(156, 1, 1);
}

.selected {
  outline: 2px solid rgb(100, 255, 113);
}

.print {
  outline: none !important;
}

@media print {
  .card-container.print {
    box-shadow: none;
  }
}

/* FOR MOBILE */

@media screen and (max-width: 1079px) {
  .internal-menu {
    display: none;
  }
}
</style>
