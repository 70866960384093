<template>
  
  <BaseHeader />
  <div class="pantheon-overview">
    <div class="pantheon-block add-button" @click="$router.push('/pantheongenerator')">
      <i class="fas fa-plus-circle"></i>
      Add Pantheon
    </div>
    <div
      class="pantheon-block"
      v-for="pantheon in $store.state.pantheons"
      v-bind:key="pantheon.name"
    >
      <img
        class="pantheon-image"
        src="@/assets/hero_ooze.jpg"
      @click.self="$store.commit('selectPantheon', pantheon.id), $router.push('/pantheongenerator')"
      />
      <div class="pantheon-name">
        {{ pantheon.name }}
      </div>
      <i class="fas fa-trash"
          @click.prevent="$store.dispatch('deletePantheon', pantheon.id)"></i>
    </div>
  </div>
</template>

<script>

import BaseHeader from "@/components/Header/BaseHeader.vue";

export default {

  name: "PantheonCollection",
  components: {BaseHeader},

};
</script>

<style scoped>
.pantheon-overview {
  width: 1200px;
  max-width: 90vw;
  margin: 100px auto 0px auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  justify-items: start;
}

.pantheon-block {
  width: 225px;
  height: 225px;
  margin: 5px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
}

.add-button {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: white;
  background-color: var(--dark2);
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
  font-family:montserrat,'sans-serif';
}

.add-button i {
  font-size:3rem;
  transition: all 0.5s ease;
}

.add-button:hover {
  transform:scale(1.05);
  transition: all 0.5s ease;
}

.pantheon-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: all 0.5s ease;
}

.pantheon-block:hover .pantheon-image {
  transform: scale(1.05);
}

.pantheon-name {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.74);
  padding: 10px;
  border-radius: 0px 0px 5px 5px;
  color: white;
  font-family: montserrat, sans-serif;
  transition: all 0.5s ease;
  font-size: 0.8rem;
}
.pantheon-block:hover .pantheon-name {
  opacity: 0%;
}

.fa-trash {
  position:absolute;
  bottom: 0;
  right:10px;
  font-size:2rem;
  color:white;
  opacity:0;
  transition: all 0.5s ease;
  filter:opacity(0.5);
}
 .pantheon-block:hover .fa-trash {
  opacity:1;
 }

 
 .pantheon-block .fa-trash:hover {
  filter:opacity(1);
  transform:scale(1.1);
 }

@media only screen and (max-width: 1080px) {

.pantheon-overview {
  margin: 80px auto 0px auto;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-items:center;
}

  .pantheon-block {
    width:150px;
    height:150px;
  }
  
}
</style>