<template>
  <div class="cult-output background">
    <div class="floaty-buttons"><i class="fas fa-dice-d20" @click="generateCult('sameSize')"></i><i class="fas fa-save" v-if="cult.name != 'Create Cult'" @click="$emit('saveCult')"></i></div>
    <h3 contenteditable="true" @blur="updateCult('name')">{{ cult.name }}</h3>
    <div class="cult-statistics">
      <div class="statistic-block" v-for="(cultStatistic, index) in cult.statistics" :key="index">
        <div class="statistic-name">{{ cultStatistic.name }}</div>
        <div class="statistic-value">{{ cultStatistic.value }}</div>
      </div>
    </div>
    <div contenteditable="true" @blur="updateCult('text')">{{ cult.text }}</div>
  </div>
</template>

<script>

export default {
  name: "CultEditorCult",
  props: ["cultFilters", "cultSizeN"],
  emits: ["cultUpdated", "saveCult"],
  data() {
    return {
      cult: {
        name: "Create Cult",
        statistics: {
          cultOrganization: "",
          cultHierarchy: "",
          cultRelationships: "",
          cultDeity: "",
        },
        text: "Click Generate Cult or the d20 symbol to create a cult.",
        size: 0,
      },
    };
  },
  methods: {
    // ///// GENERATE CULT /////

    generateCult(size) {
      if (size != "sameSize") {
        this.cult.id = Math.random().toString(36).substr(2, 9) + Date.now();


      }
      let selectedFilters = [];

      /// SET ALL THE FILTERS RANDOMLY

      for (let filter of this.cultFilters) {
        let filterOptions = filter.options.filter((option) => option.selected);
        if (filterOptions.length === 0) {
          filterOptions = filter.options;
        }
        let filterName = filter.name;
        let randomOption =
          filterOptions[Math.floor(Math.random() * filterOptions.length)];
        selectedFilters.push({ name: filterName, value: randomOption.value });
      }

      this.cult.statistics = selectedFilters.reduce((obj, item) => {
        obj[item.name.toLowerCase()] = item.value;
        return obj;
      }, {});


      /// SET THE CULT SIZE

      if (size != "sameSize") {

        let cultMinSize = 5;
        let cultMaxSize = 30;

        if (
          selectedFilters.find((filter) => filter.name === "Size").value ===
          "Small"
        ) {
          cultMinSize = 5;
          cultMaxSize = 10;
        } else if (
          selectedFilters.find((filter) => filter.name === "Size").value ===
          "Medium"
        ) {
          cultMinSize = 10;
          cultMaxSize = 20;
        } else if (
          selectedFilters.find((filter) => filter.name === "Size").value ===
          "Large"
        ) {
          cultMinSize = 15;
          cultMaxSize = 30;
        }

        let cultSize =
          Math.floor(Math.random() * (cultMaxSize - cultMinSize + 1)) +
          cultMinSize;

        this.cult.size = cultSize;

      } else {
        this.cult.size = this.cultSizeN;
      }

      /// SET THE CULT DEITY

      let cultDeity = "";

      let divineDomain = this.$store.state.pantheonData.coreDivineDomains[Math.floor(Math.random() * this.$store.state.pantheonData.coreDivineDomains.length)].name;

      console.log (divineDomain)

      cultDeity = "God of " + divineDomain;

      this.cult.statistics.cultDeity = cultDeity;

      // SET THE CULT NAME RANDOMLY

      let cultName = "";



      let noun = this.$store.state.cultData.nouns[Math.floor(Math.random() * this.$store.state.cultData.nouns.length)];


      let randomNumber = Math.floor(Math.random() * 2);

      if (randomNumber == 0) {

        let adjective = this.$store.state.cultData.adjectives[Math.floor(Math.random() * this.$store.state.cultData.adjectives.length)];

        cultName = adjective + " " + noun;

      } else if ( randomNumber == 1){

        let descriptor = this.$store.state.cultData.descriptors[Math.floor(Math.random() * this.$store.state.cultData.descriptors.length)];

        cultName = noun + " " + descriptor;

      }

      console.log(cultName);

      this.cult.name = cultName;



      /// SET THE CULT TEXT

     this.generateCultText();

      /// SEND STUFF BACK


      this.$emit("cultUpdated", this.cult);

    },
    generateCultText() {

      /// SET THE CULT TYPE

      let cultType = this.cult.statistics.type.toLowerCase();


      /// SET THE CULT ORGANIZATION

      let selectedOrganization = this.cult.statistics.organization;
      let cultOrganization = "";

      if (selectedOrganization == "Centralized") {
        cultOrganization = "that are independent of each other.";
      } else if (selectedOrganization == "Grouped") {

        /// FIND RANDOM NUMBER BETWEEN 1 AND 2

        let randomGroupNumber = Math.floor(Math.random() * 2) + 1;

        if (randomGroupNumber == 1) {
          cultOrganization =
            "that are divided into groups based on their Divine Domains or alignments.";
        } else if (randomGroupNumber == 2) {
          cultOrganization =
            "that are divided into two opposing factions.";
        }

      } else if (selectedOrganization == "Mixed") {
        cultOrganization =
          "where some are grouped and some are more independent.";
      }

      /// SET THE CULT HIERARCHY

      let selectedHierarchy = this.cult.statistics.hierarchy;
      let cultHierarchy = "";

      if (selectedHierarchy == "Flat") {
        cultHierarchy =
          "a flat hierarchy, where there is no individual with greater authority than the others.";
      } else if (selectedHierarchy == "Hierarchical") {
        cultHierarchy =
          "a formal hierarchy, where there is a clear leader and a clear chain of command.";
      } else if (selectedHierarchy == "Mixed") {
        cultHierarchy =
          "is a mix of different leaders in different groups, with some groups having a clear leader and others not.";
      }

      /// SET THE CULT IDEALS

      let cultIdeal = "";

      let randomIdealNumber = Math.floor(Math.random() * 6) + 1;

      cultIdeal = this.$store.state.cultData.cultIdeals[randomIdealNumber];

      console.log(cultIdeal)


      // "Ruthless",
      //   "Eliminating Opposition",
      //   "Strict Rules",
      //   "Complete Devoted",
      //   "Prophecy"

      /// SET THE CULT TEXT



        let cultText = this.cult.name + " is a " + cultType + " devoted to the " + this.cult.statistics.cultDeity + " " +
          cultOrganization +
          " The cult has " +
          cultHierarchy

        this.cult.text = cultText;
    },
    updateCult(property) {
      this.cult[property] = event.target.innerHTML
      this.$emit("cultUpdated", this.cult);

    },
    },
  };
</script>

<style scoped>
.background {
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--dark3);
}

.cult-output {
  position: relative;
  width: 100%;
  margin: 0px 0px 10px 0px;
  align-items: center;
  justify-items: center;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 10px;
  padding: 10px;
}

.floaty-buttons {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  flex-flow: row nowrap;

}
.floaty-buttons i {
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin:0px 5px;
}


.floaty-buttons i:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.cult-output h3 {
  font-size: 1.1rem;
  text-transform: uppercase;
}


.cult-statistics {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 10px;
}

.statistic-block {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
}

.statistic-name {
  font-size: 0.75rem;
  font-weight: 600;
}
</style>