<template>
  <div class="menu-row">
    <div class="overlay clear" v-if="dropList" @click="dropList = false"></div>
    <h3>Rarity:</h3>
    <select class="rounded" v-model="selectedRarity">
      <option v-if="card.cardType == 'enchantments'">Auto</option>
      <option v-for="rarity in rarities" :key="rarity.index">
        {{ rarity }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "CardMenuRaritySelect",
  props: ["cardID", "card"],
  data() {
    return {
      rarities: this.$store.state.rarities,
      dropList: false,
      locked: false,
    };
  },
  computed: {
    selectedRarity: {
      get() {
        return this.card.selectedRarity;
      },
      set(rarity) {
        this.$store.commit("updateRarity", {
          cardID: this.cardID,
          rarity: rarity,
        });
      },
    },
  },
};
</script>

<style scoped>
.fas {
  margin-left: 0.25rem;
  height: 1.4rem;
  line-height: 1.2rem;
  padding: 0.1rem 0.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fa:hover,
.highlight {
  color: var(--main2);
  background-color: var(--dark);
}

.combobox i {
  transition: all 0.3s ease;
}

.combobox i:hover {
  color: var(--main2);
  transition: all 0.3s ease;
}
</style>