<template>
  <transition name="fade">
    <BaseDialog
      v-if="duplicateNamePrompt"
      title="There's Already an Item With That Name"
      :cancellable="true"
      @close="duplicateNamePrompt = false"
      @okay="(duplicateNamePrompt = false), saveCard(true)"
    >
      <template #content>
        {{ duplicateNamePromptText }}
      </template>
    </BaseDialog>
  </transition>
  <transition name="fade">
    <BaseDialog
      v-if="itemSavedPrompt"
      title="Item Saved!"
      @close="itemSavedPrompt = false"
      @okay="itemSavedPrompt = false"
    >
      <template #content> The item has been saved to your items! </template>
    </BaseDialog>
  </transition>
  <transition name="fade">
    <BaseDialog
      v-if="showPatreonSignup && !premium"
      title="Get Premium Access to use this feature"
      @close="showPatreonSignup = false"
      @okay="showPatreonSignup = false"
    >
      <template #content>
        <b>Not a Patron?</b> Sign up to the
        <a
          href="https://patreon.com/eventyr"
          target="_blank"
          style="color: blue; font-weight: bold"
          >Eventyr Games Patreon</a
        >
        to get a Patron Key and then
        <router-link to="/login" style="color: blue; font-weight: bold"
          >register an account</router-link
        >
        to get premium access.<br /><br />
        <div v-if="loggedIn">
          <b>Already a Patron?</b>
          Enter your Patron Key to get premium access.
        </div>
        <div class="menu-row flex-center" v-if="loggedIn">
          <input type="text" class="patreon-connect" v-model="patreonKey" />
        </div>
      </template>
    </BaseDialog>
  </transition>
  <div class="bottom-menu">
    <div class="container rounded" @click="flipCard()">
      <i class="fas fa-redo"></i>
      <p>Flip</p>
    </div>
    <div
      class="container rounded"
      @click="saveCard(false)"
      :class="{ pulsing: tutorial && tutorialStep == 11 }"
    >
      <i class="fas fa-save rounded"></i>
      <p>Save</p>
    </div>
    <div
      class="container rounded"
      @click="printCard"
      :class="{ pulsing: tutorial && tutorialStep == 12 }"
    >
      <i class="fas fa-download rounded"></i>
      <p>Get JPG</p>
    </div>
    <div class="container rounded delete" @click="deleteCard">
      <i class="fas fa-trash rounded"></i>
      <p>Delete</p>
    </div>

    <div class="container rounded close-editor" @click="$emit('closeEditor')">
      <i class="fas fa-times rounded"></i>
      <p>Exit</p>
    </div>
  </div>
</template>

<script>
import BaseDialog from "../../BaseDialog.vue";
import { mapState } from "vuex";

export default {
  name: "CardMenuSettingsBottom",
  props: {
    cardType: String,
    cardID: Number,
    card: Object,
    tutorial: Boolean,
    tutorialStep: Number,
  },
  data() {
    return {
      showAppearance: false,
      showGems: false,
      showFont: false,
      showFontSize: false,
      duplicateNamePrompt: false,
      duplicateNamePromptText: "",
      showPatreonSignup: false,
      itemSavedPrompt: false,
      patreonKey: "",
    };
  },
  computed: {
    ...mapState(["userItems", "srd", "wgmm", "premium", "loggedIn"]),
  },
  emits: ["printCard", "saveCard", "closeEditor", "flipCard"],
  components: { BaseDialog },
  methods: {
    connectPatreon() {
      this.$store.dispatch("patreonConnect", this.patreonKey);
    },
    flipCard() {
        this.$store.commit("updateBack", {
          cardID: this.cardID,
        });
    },
    saveCard(override) {
      if (this.premium) {
        if (!override) {
          if (
            this.userItems &&
            this.userItems.length > 0 &&
            (this.userItems.some((item) => item.name == this.card.itemName) ||
              this.userItems.some((item) => item.name == this.card.customName))
          ) {
            this.duplicateNamePromptText =
              "There is already an item with this name in your collection. If you save this item with the same name, it will overwrite the original item. Do you wish to proceed?";
            this.duplicateNamePrompt = true;
          } else if (
            this.srd.some((item) => item.name == this.card.itemName) ||
            this.srd.some((item) => item.name == this.card.customName)
          ) {
            this.duplicateNamePromptText =
              "There is already an item with this name in the SRD. If you save this item with the same name, it will overwrite the original item until you delete this item from your collection. Do you wish to proceed?";
            this.duplicateNamePrompt = true;
          } else if (
            (this.premium &&
              this.wgmm.some((item) => item.name == this.card.itemName)) ||
            this.wgmm.some((item) => item.name == this.card.customName)
          ) {
            this.duplicateNamePromptText =
              "There is already an item with this name in the Wanderer's Guide to Merchants & Magic. If you save this item with the same name, it will overwrite the original item until you delete this item from your collection. Do you wish to proceed?";
            this.duplicateNamePrompt = true;
          } else {
            this.actuallySaveCard();
            this.itemSavedPrompt = true;
          }
        } else {
          this.actuallySaveCard();
          this.itemSavedPrompt = true;
        }
      } else {
        this.showPatreonSignup = true;
      }
    },
    actuallySaveCard() {
      var item = {
        name: "",
        description: "",
        type: "",
        subtype: "",
        imageSRC: "",
        rarity: "",
        value: 0,
        subvalue: "",
      };
      item.name = this.card.customName
        ? this.card.customName
        : this.card.itemName;
      if (this.card.cardType == "enchantments") {
        item.description = this.card.enchantmentDescription
          ? this.card.enchantmentDescription
          : this.card.customDescription;
      } else {
        item.description = this.card.customDescription
          ? this.card.customDescription
          : this.card.selectedItem
          ? this.card.selectedItem.description
          : "";
      }
      item.type = this.card.selectedType;
      item.subtype = this.card.selectedSubtype;
      item.imageSRC = this.card.imageSRC;
      if (
        this.card.selectedRarity == "Auto" &&
        this.cardType == "enchantments"
      ) {
        var armorBonus = this.card.armorBonus;
        var weaponBonus = this.card.weaponBonus;
        var spellBonus = this.card.spellBonus;
        var saveBonus = this.card.saveBonus;
        var selectedEnchantments = this.card.selectedEnchantments;
        var rarityValue =
          Number(isNaN(armorBonus) ? 0 : armorBonus) +
          Number(isNaN(weaponBonus) ? 0 : weaponBonus) +
          Number(isNaN(saveBonus) ? 0 : saveBonus) +
          Number(isNaN(spellBonus) ? 0 : spellBonus);

        if (typeof selectedEnchantments != "undefined") {
          for (var i = 0; i < selectedEnchantments.length; i++) {
            if (
              typeof selectedEnchantments[i].rarity != "undefined" &&
              !isNaN(selectedEnchantments[i].rarity)
            ) {
              rarityValue =
                rarityValue + Number(selectedEnchantments[i].rarity);
            }
          }
        }
        if (rarityValue < 1) {
          item.rarity = "Common";
        } else if (rarityValue < 2) {
          item.rarity = "Uncommon";
        } else if (rarityValue < 3) {
          item.rarity = "Rare";
        } else if (rarityValue < 7) {
          item.rarity = "Very Rare";
        } else if (rarityValue < 10) {
          item.rarity = "Legendary";
        } else {
          item.rarity = "Artifact";
        }
      } else {
        item.rarity = this.card.selectedRarity;
      }
      item.attunement = this.card.selectedAttunement;
      item.source = "Custom";
      this.$store.dispatch("saveToUserItems", item);
    },
    printCard() {
      this.$emit("printCard");
    },
    deleteCard() {
      this.$store.dispatch("deleteCard", { cardID: this.cardID });
    },
    selectCard(cardID) {
      this.$store.commit("updateSelected", {
        cardID: cardID,
      });
    },
  },
};
</script>

<style scoped>
h3 {
  width: 100%;
  text-align: center;
}

h2 {
  text-shadow: 3px 3px 3px #000000;
}

.bottom-menu {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: space-between;
}

.container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: var(--dark3);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
  cursor: pointer;
  margin: 0 10px 0 0;
  flex-grow: 1;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  height: 50px;
  padding: 5px 0px;
  width: 25%;
}

.delete {
  margin: 0;
}

.container p {
  font-size: 0.8rem;
}

i {
  font-size: 1rem;
  padding: 0.4rem;
  color: #fff;
  text-shadow: 3px 3px 3px #000000;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.container:hover,
.container:hover i {
  color: var(--main2);
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.container.delete:hover,
.container.delete:hover i {
  color: rgb(156, 1, 1);
}

.promptgreen {
  color: var(--main2);
}

.close-editor {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .bottom-menu {
    position: fixed;
    bottom: 0px;
    left: 0px;
    margin-top: 0;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: space-between;
    z-index: 100;
    background-color: var(--dark3);
  }

  .container {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 4rem;
    margin: 0px 0px 0px 0px;
    box-shadow: unset;
  }

  .container.rounded {
    border-radius: 0px !important;
  }

  i {
    font-size: 2rem;
    padding: 1rem;
  }

  p {
    display: none;
  }

  .close-editor {
    display: flex;
  }
}
</style>
