<template>
  <div class="deity-container" @mouseover="showDeityButtons = true" @mouseleave="showDeityButtons = false">
    <!-- DEITY GENERATOR OUTPUT IS HERE -->

    <div class="menu-row space-between deity-header">
      <div class="deity-overview">
        <h3 contenteditable="true" @blur="updateDeity('name')">
          {{ deity.name }}
        </h3>
        <div class="deity-subheader" v-if="deity.domains.length > 0" @click="showDeitySubheaderEditor = true">
          <p v-if="!showDeitySubheaderEditor">
            {{
              deity.rank +
              " of " +
              deityDomainsString +
              " (" +
              deity.alignment +
              ")"
            }}
          </p>
          <div class="deity-subheader-editor" v-if="showDeitySubheaderEditor">
            <div class="editor-row">
              <div class="editor-select">
                <select v-model="deity.rank" @change="$emit('deityUpdated', deity)">
                  <option>Lesser God</option>
                  <option>God</option>
                  <option>Greater God</option>
                </select>
              </div>
              <div class="editor-select">
                <select v-model="deity.alignment" @change="$emit('deityUpdated', deity)">
                  <option>Lawful Good</option>
                  <option>Neutral Good</option>
                  <option>Chaotic Good</option>
                  <option>Lawful Neutral</option>
                  <option>True Neutral</option>
                  <option>Chaotic Neutral</option>
                  <option>Lawful Evil</option>
                  <option>Neutral Evil</option>
                  <option>Chaotic Evil</option>
                </select>
              </div>
            </div>
            <div class="editor-row">
              <div class="editor-select-label">Domain(s):</div>
              <div v-for="(domain, index) in deity.domains" :key="index" contenteditable="true"
                class="editor-select inline-editable" @blur="updateDomains(index)">
                {{ domain.name }}
              </div>
              <i class="fas fa-plus-circle add-button" @click="addDivineDomain"></i>
            </div>
          </div>
        </div>
      </div>
      <Transition name="fade">
        <div class="button-container" v-if="showDeityButtons" @mouseleave="showLockOptionsDropdown = false">
          <div class="generate-button small" @mouseover="showLockOptionsDropdown = true">
            <i class="fas fa-dice-d20" @click.self="
              generateDeity({
                alignment: deity.alignment,
                rank: deity.rank,
                coreDomains: deity.coreDomains,
                additionalDomains: deity.additionalDomains,
              })
            "></i>

            <!-- OPTIONS DROPDOWN -->

            <Transition name="tooltip">
              <div class="options-dropdown" v-if="showLockOptionsDropdown">
                <div class="options-dropdown-inner">
                  <div class="lock-option" v-for="(value, key, index) in lockedOptions" :key="index">
                    <div class="lock-option-label">{{ key.toUpperCase() }}</div>
                    <i class="fas" @click="lockedOptions[key] = !value"
                      :class="{ 'fa-lock': value, 'fa-lock-open': !value }"></i>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
          <div class="generate-button small">
            <i class="fas fa-times-circle" @click="$emit('deleteDeity')"></i>
          </div>
          <div class="generate-button small" @click="showDeityDetails = !showDeityDetails">
            <i class="fas" :class="{
              'fa-caret-down': showDeityDetails,
              'fa-caret-up': !showDeityDetails,
            }"></i>
          </div>
        </div>
      </Transition>
    </div>

    <div class="menu-row centered" v-if="showDeitySubheaderEditor">
      <div class="done-button" @click="showDeitySubheaderEditor = false">
        Done
      </div>
    </div>

    <div class="deity-details" v-if="showDeityDetails">
      <div class="deity-text" contenteditable="true" @blur="updateDeity('customText')">
        {{ deityText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PantheonEditorDeity",
  props: ["deitySource", "deityFilters", "deityAlignments", "generateProp"],
  emits: ["deleteDeity", "deityUpdated"],
  data() {
    return {
      deity: {
        name: "",
        alignment: "",
        rank: "",
        coreDomains: [],
        additionalDomains: [],
        domains: [],
        origin: "",
        motivation: "",
        customText: "",
        id: "",
      },
      lockedOptions: {
        name: false,
        rank: false,
        domains: false,
        alignment: false,
      },
      showDeityDetails: false,
      showDeitySubheaderEditor: false,
      showLockOptionsDropdown: false,
      showDeityButtons: false,
    };
  },
  mounted() {
    this.deity = this.deitySource;
  },
  watch: {
    generateProp: function (newValue) {
      if (newValue) {
        this.generateDeity();
        this.$emit("deityGenerated", this.deity);
      }
    },
  },
  methods: {
    addDivineDomain() {
      this.deity.domains.push({ name: "Domain" });
    },
    generateDeity() {


      /// SET VARIABLES
      let deityFilters = this.deityFilters;
      let deityAlignments = this.deityAlignments;
      let deityName = this.deity.name;
      let deityTitles = this.$store.state.pantheonData.deityTitles;
      let selectedFilters = [];
      let selectedDomains = [];

      /// SET ALL THE FILTERS RANDOMLY

      for (let filter of deityFilters) {
        let filterOptions = filter.options.filter((option) => option.selected);
        if (filterOptions.length === 0) {
          filterOptions = filter.options;
        }
        let filterName = filter.name;
        let randomOption =
          filterOptions[Math.floor(Math.random() * filterOptions.length)];
        selectedFilters.push({ name: filterName, value: randomOption.value });
      }

      /// SET THE DEITY ORIGIN

      let selectedOrigin = selectedFilters.find(
        (filter) => filter.name === "Origin"
      ).value;

      let deityOrigin = "";

      if (selectedOrigin == "Eternal") {
        deityOrigin = "has existed since the beginning of time.";
      } else if (selectedOrigin == "Created") {
        deityOrigin = "was created by another deity.";
      } else if (selectedOrigin == "Transformed") {
        deityOrigin = "was once mortal before it was transformed into a god ";
        let selectedTransformation = selectedFilters.find(
          (filter) => filter.name === "Transformation"
        ).value;

        if (selectedTransformation == "Magic") {
          deityOrigin = deityOrigin + "by powerful magic.";
        } else if (selectedTransformation == "Given") {
          deityOrigin = deityOrigin + "by another deity.";
        } else if (selectedTransformation == "Taken") {
          deityOrigin =
            deityOrigin +
            "because it took or stole the power of another deity.";
        }
      }

      this.deity.origin = deityOrigin;


      /// SET THE DEITY ALIGNMENT

      if (!this.lockedOptions.alignment || !this.deity.alignment) {
        let alignmentOptions = deityAlignments.options.filter(
          (option) => option.selected
        );

        if (alignmentOptions.length === 0) {
          alignmentOptions = deityAlignments;
        }

        let selectedAlignment =
          alignmentOptions[Math.floor(Math.random() * alignmentOptions.length)]
            .value;

        this.deity.alignment = selectedAlignment;
      }

      /// SET THE DEITY RANK

      if (!this.lockedOptions.rank || !this.deity.rank) {
        let selectedRank = selectedFilters.find(
          (filter) => filter.name === "Divine Rank"
        ).value;

        this.deity.rank = selectedRank;
      }

      /// SET THE DEITY DOMAINS

      if (!this.lockedOptions.domains || (!this.deity.domains)) {

        let additionalDomainsN = 0;
        let coreDomainsN = 0;
        let coreDivineDomains = [
          ...this.$store.state.pantheonData.coreDivineDomains,
        ];
        let additionalDivineDomains = [
          ...this.$store.state.pantheonData.additionalDivineDomains,
        ];

        let domainRoll = 1;

        if (this.deity.rank == "Lesser God") {
          domainRoll = Math.floor(Math.random() * 4) + 1;
        } else if (this.deity.rank == "God") {
          domainRoll = Math.floor(Math.random() * 6) + 1;
        } else if (this.deity.rank == "Greater God") {
          domainRoll = Math.floor(Math.random() * 8) + 1;
        }

        switch (domainRoll) {
          case 1:
            additionalDomainsN = 1;
            coreDomainsN = 0;
            break;
          case 2:
            additionalDomainsN = 0;
            coreDomainsN = 1;
            break;
          case 3:
            additionalDomainsN = 2;
            coreDomainsN = 0;
            break;
          case 4:
            additionalDomainsN = 1;
            coreDomainsN = 1;
            break;
          case 5:
            additionalDomainsN = 2;
            coreDomainsN = 1;
            break;
          case 6:
            additionalDomainsN = 0;
            coreDomainsN = 2;
            break;
          case 7:
          case 8:
            additionalDomainsN = 1;
            coreDomainsN = 2;
            break;
          default:
            break;
        }

        for (let i = 0; i < coreDomainsN; i++) {
          if (coreDivineDomains.length === 0) {
            coreDivineDomains = [
              ...this.$store.state.pantheonData.coreDivineDomains,
            ];
          }

          let randomDomain =
            coreDivineDomains[
            Math.floor(Math.random() * coreDivineDomains.length)
            ];
          coreDivineDomains.splice(coreDivineDomains.indexOf(randomDomain), 1);
          selectedDomains.push(randomDomain);
          this.deity.coreDomains.push(randomDomain);
        }

        for (let i = 0; i < additionalDomainsN; i++) {
          if (additionalDivineDomains.length === 0) {
            additionalDivineDomains = [
              ...this.$store.state.pantheonData.additionalDivineDomains,
            ];
          }

          let randomDomain =
            additionalDivineDomains[
            Math.floor(Math.random() * additionalDivineDomains.length)
            ];
          additionalDivineDomains.splice(
            additionalDivineDomains.indexOf(randomDomain),
            1
          );
          selectedDomains.push(randomDomain);
          this.deity.additionalDomains.push(randomDomain);
        }

        this.deity.domains = selectedDomains;
      }

      /// SET THE DEITY MOTIVATION

      let availableMotivations = [];
      if (selectedDomains.length === 0) {
        selectedDomains = this.deity.domains;
      }

      for (let domain of selectedDomains) {
        if (domain.motivations) {
          for (let motivation of domain.motivations)
            availableMotivations.push(motivation);
        }
      }

      this.deity.motivation =
        availableMotivations[
        Math.floor(Math.random() * availableMotivations.length)
        ];

      /// SET THE DEITY NAME

      if (!this.lockedOptions.name || !deityName) {
        let title =
          deityTitles[Math.floor(Math.random() * deityTitles.length)].name;

        let primaryDomain = selectedDomains[0];

        let randomNumber = Math.floor(Math.random() * 3) + 1;

        if (randomNumber == 1) {
          primaryDomain.suffixes
            ? (deityName =
              "The " +
              title +
              " " +
              primaryDomain.suffixes[
              Math.floor(Math.random() * primaryDomain.suffixes.length)
              ])
            : (deityName = "The " + title + " of " + primaryDomain.name);
        } else if (randomNumber == 2) {
          primaryDomain.prefixes
            ? (deityName =
              "The " +
              primaryDomain.prefixes[
              Math.floor(Math.random() * primaryDomain.prefixes.length)
              ] +
              " " +
              title)
            : (deityName = "The " + primaryDomain.name + " " + title);
        } else if (randomNumber == 3) {
          primaryDomain.fixed
            ? (deityName =
              primaryDomain.fixed[
              Math.floor(Math.random() * primaryDomain.fixed.length)
              ])
            : (deityName = "The " + primaryDomain.name + " " + title);
        }

        this.deity.name = deityName;

        
      this.$emit("deityUpdated", this.deity);

      }
    },

    updateDeity(property) {
      let value = event.target.innerHTML;
      this.deity[property] = value;

      this.$emit("deityUpdated", this.deity);
    },
    updateDomains(index) {
      let value = event.target.innerHTML;

      /// REMOVE DOMAIN IF BLANK

      if (!value || value == " " || value == "<div><br></div>") {
        this.deity.domains.splice(index, 1);
      } else {
        this.deity.domains[index].name = value;
      }


      this.$emit("deityUpdated", this.deity);
    },
  },
  computed: {
    deityText() {
      if (this.deity.customText) {
        return this.deity.customText;
      }
      let deityText = "";
      if (this.deity.name) {
        deityText =
          this.deity.name +
          " is a " +
          this.deity.rank +
          " that embodies " +
          this.deityDomainsString +
          ". " +
          this.deity.name +
          " " +
          this.deity.origin +
          " " +
          this.deity.name +
          " wishes " +
          this.deity.motivation +
          ".";
      }
      return deityText;
    },
    deityDomainsString() {
      let deityDomains = this.deity.domains.map((domain) => domain.name);

      if (deityDomains.length === 1) {
        return deityDomains[0];
      }

      // Check if array has only two elements
      if (deityDomains.length === 2) {
        return deityDomains.join(" and ");
      }

      // Place commas between each element and 'and' before the last one
      let last = deityDomains.pop();
      return deityDomains.join(", ") + ", and " + last;
    },
  },
};
</script>

<style scoped>
.deity-container {
  background-color: var(--dark3);
  margin: 0 1rem 1rem 0;
  display: inline-block;
  width: 300px;
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease;
  position: relative;
}

.deity-header {
  width: 100%;
}

.button-container {
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
}

.generate-button {
  background-color: var(--dark2);
  color: white;
  font-size: 1.1rem;
  font-family: montserrat, "sans-serif";
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
  margin-left: 5px;
  min-width: 50px;
  height: 100%;
}

.show-more {
  background-color: unset;
  box-shadow: unset;
  text-align: center;
}

.generate-button .fa-dice-d20 {
  width: 100%;
  height: 100%;
  text-align: center;
}

.generate-button:nth-of-type(1) {
  margin-left: 0;
}

.done-button {
  background-color: var(--dark2);
  color: white;
  font-size: 1.1rem;
  font-family: montserrat, "sans-serif";
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
  margin-top: 5px;
  min-width: 50px;
  height: 100%;
  padding: 5px 10px;
}

.options-dropdown {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  font-family: montserrat, "sans-serif";
  font-size: 0.8rem;
  z-index: 1000;
}

.options-dropdown-inner {
  background-color: var(--dark2);
  padding: 5px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
}

.options-dropdown-inner>div {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}


.fa-lock,
.fa-lock-open {
  cursor: pointer;
}

.fa-lock-open {
  filter: opacity(0.5);
}

.fas {
  transition: all 0.2s ease;
}

.fas:hover {
  transform: scale(1.2);
  transition: all 0.2s ease;
}

.fas:active {
  transform: scale(1);
  transition: all 0.1s ease;
}

.deity-subheader {
  font-style: italic;
  font-size: 0.8rem;
}


.deity-subheader-editor {
  font-style: normal;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 10px;
}

.deity-text {
  font-size: 0.8rem;
}

.editor-select {
  margin-right: 5px;
}

.editor-row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

.editor-select:last-of-type {
  margin-right: 0px;
}

select,
.inline-editable {
  font-style: normal;
  font-family: montserrat, "sans-serif";
  font-size: 0.8rem;
  border-radius: 5px;
  border: none;
  background-color: var(--dark4);
  border: 1px solid whitesmoke;
  color: whitesmoke;
  padding: 5px 5px;
  cursor: pointer;
  user-select: none;
  height: 100%;
}

.editor-select-label {
  font-style: normal;
}

.add-button {
  cursor: pointer;
  user-select: none;
  font-size: 1rem;
}
</style>