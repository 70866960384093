<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: "App",
}
</script>

<style>

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800" );
@import url("https://use.typekit.net/gjh3ixk.css");

:root {
  --dark: #373758;
  --dark2:  rgb(46, 48, 53);
  --dark3:  rgb(35, 39, 48);
  --dark4:  rgb(31, 32, 34);
  --dark5:  rgb(30, 34, 41);
  --main1: #00adf1;
  --main2: #31a554;
  --text: white;
  --container: #0a0a0a;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  
}

body {
  background-color: var(--dark4);
}



/* A, H#, P */

a {
  text-decoration:none;
  color: inherit;
}

.fas {
  padding:5px;
}

h1 {
  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

h2 {
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

h3 {
  font-size: 1rem;
  margin: 0.25rem 0.25rem 0.25rem 0;
  user-select:none;
  color: #fff;
  font-family:"Montserrat", serif
}

h4 {
  font-size: 0.8rem;
  margin: 0.25rem 0.25rem 0.25rem 0;
  user-select:none;
  color: #fff;
  font-family:"Montserrat", serif
}



/* IMPORTED FROM PRICE LIST */


.fixed-buttons {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 2rem;
  right: 2vw;
  z-index: 101;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.5rem;
  cursor:pointer;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease;
  margin: 15px 0px;
  padding: 5px 10px;
  border-radius: 5px;
  background: rgb(222, 231, 235);
}

.selected-filters {
  display: flex;
  flex-flow: row wrap;
  min-height: 40px;
  transition: all 0.2s ease;
  border-radius: 5px;
  background-color: white;
  box-shadow: inset 0 0 4px 0 rgb(138 177 198 / 75%);
  border: solid 1px #9c9898;
  box-sizing: border-box;
  position: relative;
  padding: 4px;
  cursor: pointer;
}

.filter {
  display: flex;
  flex-direction: column;
  width: 18%;
  margin: 5px;
  position: relative;
}

.filter-options {
  display: flex;
  flex-flow: row wrap;
  transition: all 0.2s ease;
  position: absolute;
  top: 100%;
  min-width:100%;
  margin-left: -5px;
  padding: 5px;
  background: white;
  z-index: 2;
  width: calc(100%-5px);
  box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.144);
  border-radius: 0px 0px 5px 5px;
  
}


.header-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 0.5rem;
}

.filter-element {
  padding: 5px;
  display:flex;
  align-items:center;
  margin: 0px 3px 3px 0px;
  border-radius: 5px;
  background: white;
  border: 1px solid black;
  cursor: pointer;
  font-size: 0.8rem;
  max-height: 1.8rem;
  user-select: none;
}

.filter-element .fa-times {
  font-size:1rem;
}

.filter-drop {
  position: absolute;
  background: #ffffff;
  top: 100%;
  -webkit-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-weight: 400;
  user-select: none;
}

.filtered {
  background: black;
  color: white;
}

.text-input,
.number-input {
  border-radius: 5px;
  min-height: 40px;
  background-color: white;
  box-shadow: inset 0 0 4px 0 rgb(138 177 198 / 75%);
  border: solid 1px #9c9898;
  box-sizing: border-box;
  font-family: montserrat, sans-serif;
  color:black;
}

.number-input {
  width: 50%;
}

.number-input:nth-of-type(1) {
  margin-right: 5px;
}

.number-input:nth-of-type(2) {
  margin-left: 5px;
}

/* select {
  border-radius: 5px;
  min-height: 40px;
  background-color: white;
  box-shadow: inset 0 0 4px 0 rgb(138 177 198 / 75%);
  border: solid 1px #9c9898;
  box-sizing: border-box;
  font-family: montserrat, sans-serif;
} */

.menu-container select {
  color:black;
  background:white;
    border-radius: 5px;
  min-height: 40px;
  background-color: white;
  box-shadow: inset 0 0 4px 0 rgb(138 177 198 / 75%);
  border: solid 1px #9c9898;
  box-sizing: border-box;
  font-family: montserrat, sans-serif;
}

/* FLEX MODIFIERS */

.menu-row {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.column {
  flex-flow: column nowrap;
}

.wrap {
  flex-flow: row wrap;
}

.space-between {
  justify-content: space-between !important;
}

.flex-around {
  justify-content: space-around;
}

.flex-center {
  justify-content: center;
}

.flex-right {
  justify-content:flex-end;
}

.align-top {
  align-items:flex-start
}

select, .combobox {
  height: 1.4rem;
  line-height:1.4rem;
  width: 100%;
}

.combobox {
  margin-left: 2.5px;
  position: relative;
  cursor:pointer;
}

.combobox i {
  position:absolute;
  top:25%;
  right:6px;
}


.drop-list {
  position: absolute;
  top:100%;
  width: auto;
  background-color: var(--dark);
  inline-size:min-content;
  margin-top: 1px;
  z-index: 100;
  max-height:300px;    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
  user-select:none;
  padding:0.2rem 0;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
}

.drop-list::-webkit-scrollbar {
  display: none;
}


.pop-up {
  background:rgb(9, 9, 36);
  position:fixed;
  padding:10px;
  border-radius:5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin:0 auto;
  color:white;
  display:flex;
  flex-direction:column;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.5);
  z-index:101;
  font-family: montserrat,sans-serif;
}


.fixed-button {
  position: relative;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  text-align: center;
  background: linear-gradient(90deg, #2f2f3d, #0b0b16);
  color: white;
  font-size: 2rem;
  font-weight: 600;
  border-radius: 100px;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  margin: 0px 0.5vw;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
}

.fixed-button:hover {
  transform: scale(1.05);
  transition: all 0.3s ease;
}

.menu-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  position: fixed;
  width:600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "montserrat", sans-serif;
  background: var(--dark3);
  padding: 10px;
  border-radius: 5px;
  z-index: 101;
  user-select: none;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.486);
  transition: all 1s;
  white-space:nowrap;
}

.option {
  padding: 1px 0.2rem;
  z-index: 1;
  white-space:nowrap;
}

.option:hover {
  background-color: lightgrey;
  color: black;
}

textarea {
  padding: 0.2rem;
  width: 100%;
  height: 5rem;
  font-family: "Montserrat", Helvetica, sans-serif;
  color:black;
  background-color:white;
}

input {
  height: 1.4rem;
  width: 100%;
  color: white;
  background: var(--dark);
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  padding: 0.1rem;
}


.checkbox {
  width: 2rem;
  height: 1.4rem;
  background: var(--dark);
  border: 1px solid rgb(118, 118, 118);
  margin-right: 0.25rem;
  font-size:1rem;
}

.checkbox i {
  padding:0.2rem;
}

.shadow {
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.4), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
}

.rounded {
  border-radius: 0.25rem !important;
}

label {
  font-family: "Montserrat", Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}

select, input, .combobox, .drop-list {
  font-family: "Montserrat", Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
  background: var(--dark);
  font-size: 0.8rem;
}


select, .combobox {
  cursor: pointer;
}

.filter select {
  background:white;
  color:black;
}

.f05 {
  font-size: 0.5rem;
}

.tcenter {
  text-align:center;
}

.twhite {
  color:white;
}

.relative {
  position:relative;
}
/* 
.settings-item {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  background: var(--dark);
  transition: all 0.6s ease;
} */

.ml-5 {
  margin-left:5px !important;
}.clear {
  background:transparent;
}

.centered {
  justify-content: center;
  justify-items: center;
  align-items:center;
  align-content:center;
}

.left {
  justify-content: flex-start;
  justify-items: flex-start;
  align-items:flex-start;
  align-content:flex-start;
}

.right {
  justify-content: flex-end;
  justify-items: flex-end;
  align-items:flex-end;
  align-content:flex-end;
}

.left-center {
  justify-content:flex-start;
  align-items:center;
}


.placeholder {
     color:    rgb(130, 130, 130);
     align-self:center;
     font-size:0.9rem;
}

  /* BUTTON */

button {
  border-radius: 10px;
  margin: 10px auto;
  border: 1px solid #ffffff;
  background-color: var(--dark);
  color: #fff;
  font-size: 0.7rem;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  font-family:"Montserrat", sans-serif;
  outline: none;
  cursor:pointer;
  }

button:hover {
  filter: grayscale(50%) opacity(0.7);
}

button:active {
  transform: scale(0.95);
  outline: none;
}

.save-button {
  border: 2px solid black;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.save-button:hover {
  background: black;
  color: white;
  transform: scale(1.05);
  transition: all 0.3s ease;
}

.button-ok:hover {
  background:rgb(0, 97, 21);
}

.button-cancel:hover {
  background:rgb(97, 0, 0);
}

.warning {
  color:red;
}

.buttons-container {
  position: absolute;
  right: -90px;
  top: 0;
  display: flex;
  flex-flow: column nowrap;
  font-family: montserrat, sans-serif;
  height: 100%;
}

.button-standard {
  position: relative;
  padding: 5px;
  background: var(--container);
  color: var(--text);
  border-radius: 5px;
  font-size: 10px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 80px;
  aspect-ratio: 1/1;
  text-align: center;
  border: none;
}

.button-standard i {
  font-size: 2rem;
}

/* TOOLTIP  */
.tooltip-top {
  position:absolute;
  bottom:calc(100% + 0.7rem);
  left: 50%;
  margin-left: -3rem;
  background-color: darkslategray;
  width:6rem;
  min-height:1rem;
  color:#fff;
  text-align:center;
  font-size:0.6rem;
  font-family: "Montserrat", sans-serif;
  border-radius: 2px;
  font-weight:500;
  z-index: 100;
  padding: 5px;
  filter: grayscale(100%) opacity(1) !important;
  transform: scale(1) !important;
  text-shadow:none;
}

.tooltip-top::after {
  content: " ";
  position: absolute;
  top: 100%; 
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: darkslategray transparent transparent transparent;
}

.tooltip-bottom {
  position:absolute;
  top:calc(100% + 0.7rem);
  left: 50%;
  margin-left: -3rem;
  background-color: darkslategray;
  width:6rem;
  min-height:1rem;
  color:#fff;
  text-align:center;
  font-size:0.6rem;
  font-family: "Montserrat", sans-serif;
  border-radius: 2px;
  font-weight:500;
  z-index: 100;
  padding: 5px;
  filter: grayscale(100%) opacity(1) !important;
  transform: scale(1) !important;
}

.tooltip-bottom::before {
  content: " ";
  position: absolute;
  bottom: 100%; 
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color:  transparent transparent darkslategray transparent ;
}


/* OVERLAY */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index:99;
}

.overlay.clear {
  background-color: rgba(0, 0, 0, 0);
}

.overlay.darker {
  background-color: rgba(0, 0, 0, 0.25);
}

.overlay.darkest {
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay.tutorial {
  z-index:1001;
}

/* FONT CLASSES */

.bookmania {
  font-family: "Bookmania", serif !important;
}

.kingscaslon {
  font-family: kings-caslon, serif !important;
}

.montserrat {
  font-family: "Montserrat", sans-serif !important;
  font-variant: small-caps;
}

.montserrat-small {
  font-family: "Montserrat", sans-serif !important;
  font-variant: normal !important;
}

.domlovesmary {
font-family: domlovesmary-text, sans-serif !important;
font-weight: 400;
font-style: normal;
}

.georgia {
  font-family: "Georgia", serif !important;
}


.small-caps {
  font-variant: small-caps !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.bold {
  font-weight:600;
}

.italic {
  font-style: italic !important;
}


/* TRANSITIONS AND ANIMATIONS */
.drop-height-enter-active {
  transition: all 0.5s ease;
  transform-origin: top;
}

.drop-height-leave-active {
  transition: all 0.5s ease;
  transform-origin: top;
}

.drop-height-enter-from,
.drop-height-leave-to {
  transform: scaleY(0);
  transform-origin: top;
}


.tooltip-enter-active {
  transition: all 0.3s ease 0.3s;
}

.tooltip-leave-active {
  transition: all 0s ease;
}

.tooltip-enter-from,
.tooltip-leave-to {
  opacity: 0%;
}

.menu-enter-from,
.menu-leave-to {
  opacity: 0%;
  transform: translateY(-20%);
}

.menu-enter-active,
.menu-leave-active {
  transition: all 0.6s ease;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0%;
}

.fade-height-enter-active, 
.fade-height-leave-active {
  transition: all 0.5s ease;
  max-height:100%;
}

.fade-height-enter-from,
.fade-height-leave-to {
  transition: all 0.5s ease;
  opacity:0%;
  max-height:0px;
}

.card-enter-active {
  transition: all 0.5s ease 0.3s;
  max-width: 100%;
}

.card-leave-active {
  transition: all 0.5s ease;
  max-width: 100%;
}

.card-enter-from,
.card-leave-to {
  opacity: 0%;
  max-width: 0px;
}

.addcard-enter-active {
  transition: all 0.3s ease;
}

.addcard-leave-active {
  transition: all 0s ease;
}

.addcard-enter-from,
.addcard-leave-to {
  display:none;
}

.height-enter-active {
  transition: all 0.3s ease;
}

.height-leave-active {
  transition: all 0.3s ease;
}

.height-enter-from,
.height-leave-to {
  opacity: 0;
  max-height:0px;
}

.glide-enter-active {
  transition: all 0.3s ease;
}

.glide-leave-active {
  transition: all 0.3s ease;
}

.glide-enter-from,
.glide-leave-to {
  height: 0px;
}

.slide-next-enter-active,
.slide-next-leave-active {
  transition: transform 0.3s ease-in-out;
}
.slide-next-enter {
  transform: translate(100%);
}
.slide-next-leave-to {
  transform: translate(-100%);
}

.slide-prev-enter-active,
.slide-prev-leave-active {
  transition: transform 0.5s ease-in-out;
}
.slide-prev-enter {
  transform: translate(-100%);
}
.slide-prev-leave-to {
  transform: translate(100%);
}

.slow-show-enter-active {
  transition: all 0.5s ease 1s;
}

.slow-show-leave-active {
  transition: all 0s ease;
}

.slow-show-enter-from,
.slow-show-leave-to {
  opacity: 0%;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 5px 7px var(--main2);
    filter: grayscale(0%) opacity(1);
  }

  50% {
    box-shadow: 0 0 2px 2px var(--main2);
  }

  100% {
    filter: grayscale(0%) opacity(1);
    box-shadow: 0 0 5px 7px var(--main2);
  }
}

.pulsing {
  animation: pulse 2s infinite;
}

@keyframes pulseIcon {
  0% {
    filter: grayscale(0%) opacity(1);
    color: white;

  text-shadow: -5px -5px 3px var(--main2), 5px -5px 3px var(--main2), -5px 5px 3px var(--main2),
    5px 5px 5px var(--main2);
  }

  50% {

  text-shadow: none;
  }

  100% {
    filter: grayscale(0%) opacity(1);
    color: white;
  text-shadow: -5px -5px 3px var(--main2), 5px -5px 3px var(--main2), -5px 5px 3px var(--main2),
    5px 5px 5px var(--main2);
  }
}

.pulsingIcon {
  animation: pulseIcon 2s infinite;
}

@media print {
  body {
    background-color: #fff;
  }
}

@media only screen and (max-width: 1080px) {
.tooltip-top {
  display:none;
}

h3 {
  font-size:0.8rem;
}

.menu-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  position: fixed;
  width:600px;
  max-width:95vw;
  top: unset;
  bottom:100px;
  left: 2.5%;
  transform: unset;
}
  .fixed-buttons {
    width: 100vw;
    bottom:1rem;
    left:0;
  }

.fixed-button {
  font-size: 2rem;
  font-weight: 600;
  width: 5rem;
  height: 5rem;
}

.combobox {
  height:2rem;
}

input, select {
  height: 2rem;
  padding: 0.1rem;
}

  .buttons-container {
    position: fixed;
    right: unset;
    top: unset;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    z-index: 100;
    background: var(--dark2);
    height: unset;
  }

  .button-standard {
    margin: 0px;
    padding: 10px;
    border-radius: 0px;
    font-size: 12px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    text-align: center;
    position: relative;
    aspect-ratio: unset;
  }

.checkbox {
  width: 2rem;
  height: 2rem;
  font-size:1.5rem;
}

}

</style>