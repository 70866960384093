<template>
  <div class="container">
    <transition name="fade">
      <BaseDialog
        v-if="showPatreonSignup && !premium"
        title="Get Premium Access to use this feature"
        @close="showPatreonSignup = false"
        @okay="showPatreonSignup = false"
      >
        <template #content>
          <b>Not a Patron?</b> Sign up to the
          <a
            href="https://patreon.com/eventyr"
            target="_blank"
            style="color: blue; font-weight: bold"
            >Eventyr Games Patreon</a
          >
          to get a Patron Key and then
          <router-link to="/login">register an account</router-link> to get
          premium access.<br /><br />
          <div v-if="loggedIn">
            <b>Already a Patron?</b>
            Enter your Patron Key to get premium access.
          </div>
          <div class="menu-row flex-center" v-if="loggedIn">
            <input
              type="text"
              class="patreon-connect rounded"
              v-model="patreonKey"
            /><button class="connect-button rounded" @click="connectPatreon()">
              Submit
            </button>
          </div>
        </template>
      </BaseDialog>
    </transition>

    <transition name="fade">
      <BaseDialog
        v-if="showTooManyCardsWarning"
        title="The Collection is Full!"
        @close="showTooManyCardsWarning = false"
        @okay="showTooManyCardsWarning = false"
      >
        <template #content>
          <b>You have too many cards!</b> A collection can hold 100 cards if
          you're a member of the
          <a
            href="https://patreon.com/eventyr"
            target="_blank"
            style="color: blue; font-weight: bold"
            >Eventyr Games Patreon</a
          >
          and 10 cards if you're not. Delete cards to make room for new ones –
          or create and save additional collections by becoming a Patreon
          member.
        </template>
      </BaseDialog>
    </transition>

    <div class="overlay clear" @click="$emit('close')"></div>

    <div class="random-menu rounded">
      <i class="fas fa-times-circle close-button" @click="$emit('close')"></i>
      <div class="menu-row centered">
        <h2 class="title">Select Sources</h2>
      </div>

      <!-- SELECT SOURCES -->

      <div class="menu-row centered">
        <div
          class="filter-element large"
          @mouseover="showTooltipSRD = true"
          @mouseleave="showTooltipSRD = false"
          :class="{ unchecked: enchantments }"
          @click="enchantments ? (enchantments = false) : null"
        >
          <p class="smalltext">Database</p>
          <transition name="tooltip">
            <div class="tooltip-top" v-if="showTooltipSRD">
              Items from the Basic Rules & Other Sources
            </div>
          </transition>
        </div>
        <div
          class="filter-element large"
          :class="{ unchecked: !enchantments }"
          @mouseover="showTooltipEnchantments = true"
          @mouseleave="showTooltipEnchantments = false"
          @click="
            premium
              ? enchantments
                ? null
                : (enchantments = true)
              : (showPatreonSignup = true)
          "
        >
          <p class="smalltext">Enchantments</p>
          <transition name="tooltip">
            <div class="tooltip-top" v-if="showTooltipEnchantments">
              Items composed of random magical properties
            </div>
          </transition>
          <div class="patron-only-2" v-if="!premium && showPatronOnly3">
            Patrons Only
          </div>
        </div>
      </div>

      <!-- SOURCE OPTIONS -->
      <transition name="fade">
        <div class="menu-row centered" v-if="!enchantments">
          <div class="fields" @mouseleave="showSourceFilters = false">
            <div class="menu-row centered">
              <h3>Sources</h3>
            </div>
            <div class="option-checkmarks">
              <div
                v-for="source in sources"
                v-bind:key="source"
                @click="
                  !premium &&
                  (source == 'WGMM' || source == 'MGMM' || source == 'Custom')
                    ? (showPatreonSignup = true)
                    : includedSources.includes(source) &&
                      includedSources.length > 1
                    ? (includedSources = includedSources.filter(
                        (itemSource) => itemSource != source
                      ))
                    : !includedSources.includes(source)
                    ? includedSources.push(source)
                    : null
                "
                class="filter-element"
                :class="{
                  unchecked: !includedSources.includes(source),
                }"
              >
                {{ source }}
              </div>
            </div>
          </div>
        </div></transition
      >

      <div class="menu-row centered parameters-title">
        <h2 class="title">Parameters</h2>
      </div>

      <!-- NUMBER OPTIONS -->

      <div class="fields">
        <div class="menu-row centered inventory-size">
          <h3>Items</h3>
        </div>
        <div class="number-items">
          <div class="dice-options">
            <select
              name="dice"
              v-model="dice"
              class="dice-select"
              placeholder="Roll"
            >
              <option value="0">–</option>
              <option value="4">d4</option>
              <option value="6">d6</option>
              <option value="8">d8</option>
            </select>
            <select
              name="modifier"
              v-model="modifier"
              class="modifier-select"
              v-if="dice != '0'"
            >
              <option value="+">+</option>
              <option value="-">–</option>
            </select>
          </div>
          <input
            type="number"
            class="text-input"
            v-model="nItems"
            min="0"
            max="20"
          />
        </div>
      </div>

      <!-- RARITY OPTIONS -->

      <div class="fields">
        <h3>Rarity</h3>
        <div class="option-checkmarks">
          <div
            @click="
              includedRarities.includes(rarity)
                ? (includedRarities = includedRarities.filter(
                    (itemRarity) => itemRarity != rarity
                  ))
                : !includedRarities.includes(rarity)
                ? includedRarities.push(rarity)
                : null
            "
            class="filter-element"
            v-for="rarity in rarities"
            v-bind:key="rarity"
            :class="{
              unchecked: !includedRarities.includes(rarity),
            }"
          >
            {{ rarity }}
          </div>
        </div>
      </div>

      <!-- TYPE OPTIONS -->

      <div class="fields">
        <h3>Type</h3>
        <div class="option-checkmarks">
          <div
            v-for="type in types"
            v-bind:key="type"
            @click="
              includedTypes.includes(type)
                ? (includedTypes = includedTypes.filter(
                    (itemType) => itemType != type
                  ))
                : !includedTypes.includes(type)
                ? includedTypes.push(type)
                : null
            "
            class="filter-element"
            :class="{ unchecked: !includedTypes.includes(type) }"
          >
            {{ type }}
          </div>
        </div>
      </div>

      <!-- ATTUNEMENT OPTIONS -->

      <div class="fields" @mouseleave="showAttunementFilters = false">
        <h3>Attunement</h3>
        <div class="option-checkmarks">
          <div
            @click="
              includedAttunements.includes(attunement)
                ? (includedAttunements = includedAttunements.filter(
                    (itemAttunement) => itemAttunement != attunement
                  ))
                : !includedAttunements.includes(attunement)
                ? includedAttunements.push(attunement)
                : null
            "
            class="filter-element"
            v-for="attunement in attunements"
            v-bind:key="attunement"
            :class="{
              unchecked: !includedAttunements.includes(attunement),
            }"
          >
            {{ attunement }}
          </div>
        </div>
      </div>
      <transition name="fade">
        <div
          class="menu-row flex-center tcenter f05 twhite"
          v-if="!enchantments"
        >
          <p>Possible Options: {{ itemOptions.length }}</p>
        </div>
      </transition>
      <div class="menu-row flex-center bottom-button" @click="addRandom()">
        Generate <i class="fas fa-dice-d20"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BaseDialog from "./BaseDialog.vue";
export default {
  name: "CardEditorAddRandom",
  emits: ["addRandom", "close"],
  components: { BaseDialog },
  data() {
    return {
      enchantments: false,
      showTooltipRandom: false,
      showTooltipSettings: false,
      showTooltipSRD: false,
      showTooltipEnchantments: false,
      showTooltipWGTMM: false,
      showTooManyCardsWarning: false,
      showPatreonSignup: false,
      showPatronOnly: false,
      showPatronOnly2: false,
      showPatronOnly3: false,
      showNumberOptions: false,
      includedTypes: [
        "Armor",
        "Potion",
        "Ring",
        "Rod",
        "Scroll",
        "Staff",
        "Wand",
        "Weapon",
        "Wondrous Item",
      ],
      includedRarities: [
        "Common",
        "Uncommon",
        "Rare",
        "Very Rare",
        "Legendary",
        "Artifact",
      ],
      includedSources: ["DMG"],
      includedAttunements: ["Requires Attunement", "No Attunement"],
      attunementSelected: true,
      noattunementSelected: true,
      dice: 0,
      modifier: "+",
      attunements: ["Requires Attunement", "No Attunement"],
      types: [
        "Armor",
        "Potion",
        "Ring",
        "Rod",
        "Scroll",
        "Staff",
        "Wand",
        "Weapon",
        "Wondrous Item",
      ],
      rarities: [
        "Common",
        "Uncommon",
        "Rare",
        "Very Rare",
        "Legendary",
        "Artifact",
      ],
      sources: ["DMG", "WGMM", "MGMM", "Custom"],
      nItems: 1,
      number: 1,
      patreonKey: "",
    };
  },
  props: { tutorial: Boolean, tutorialStep: Number },
  mounted() {
    if (this.premium) {
      this.sources = ["DMG", "WGMM", "MGMM", "Custom"];
    }
  },
  methods: {
    filterType(type) {
      if (!this.includedTypes.includes(type)) {
        this.includedTypes.push(type);
      } else {
        this.includedTypes = this.includedTypes.filter(
          (filteredType) => filteredType != type
        );
      }
    },
    filterRarity(rarity) {
      if (!this.includedRarities.includes(rarity)) {
        this.includedRarities.push(rarity);
      } else {
        this.includedRarities = this.includedRarities.filter(
          (filteredRarity) => filteredRarity != rarity
        );
      }
    },
    filterSources(source) {
      if (!this.includedSources.includes(source)) {
        this.includedSources.push(source);
      } else {
        this.includedSources = this.includedSources.filter(
          (filteredSource) => filteredSource != source
        );
      }
    },
    connectPatreon() {
      this.$store.dispatch("patreonConnect", this.patreonKey);
    },
    async addRandom() {
      if (this.itemOptions.length > 0 || this.enchantments) {
        var nItems = 1;
        if (parseInt(this.dice) > 0) {
          if (this.modifier == "+") {
            nItems =
              Math.abs(this.nItems) +
              Math.floor(Math.random() * parseInt(this.dice) + 1);
          } else
            nItems =
              -Math.abs(this.nItems) +
              Math.floor(Math.random() * parseInt(this.dice) + 1);
        } else {
          nItems = this.nItems;
        }
        if (nItems > this.itemOptions.length) {
          nItems = this.itemOptions.length;
        }
        if (this.loggedIn) {
          if (this.cards.length > 55) {
            nItems = 0;
            this.showTooManyCardsWarning = true;
          } else if (this.cards.length + nItems > 55) {
            nItems = 56 - this.cards.length;
          }
        } else if (this.cards.length > 7) {
          nItems = 0;
          this.showTooManyCardsWarning = true;
        } else if (this.cards.length + nItems > 7) {
          nItems = 8 - this.cards.length;
        }

        for (let i = 1; i < nItems + 1; i++) {
          this.$store.dispatch("addCard");
          var newCardID = this.cards[this.cards.length - 1].cardID;
          if (!this.enchantments) {
            this.$store.dispatch("updateCardType", {
              cardID: newCardID,
              cardType: "srd",
            });
            this.$store.dispatch("createRandomItemSRD", {
              cardID: newCardID,
              itemOptions: this.itemOptions,
            });
          } else if (this.enchantments) {
            this.$store.dispatch("updateCardType", {
              cardID: newCardID,
              cardType: "enchantments",
            });
            this.$store.dispatch("createRandomItem", {
              cardID: newCardID,
              nEnchantments1: 1,
              nEnchantments2: 3,
              rarities: this.rarities,
              itemTypes: this.types,
              attunementSelected: this.attunementSelected,
              noattunementSelected: this.noAttunementSelected,
            });
          }
        }
      }
    },
  },
  computed: {
    ...mapState([
      "cards",
      "collections",
      "premium",
      "selectedCollectionID",
      "srd",
      "mgmm",
      "wgmm",
      "userItems",
    ]),
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
    rarity() {
      if (this.rarities.length < 5) {
        var rarity = "Custom";
      } else {
        rarity = "Any";
      }
      return rarity;
    },
    type() {
      if (this.types.length < 9) {
        var type = "Custom";
      } else {
        type = "Any";
      }
      return type;
    },
    attunement() {
      if (this.attunementSelected && this.noattunementSelected) {
        var attunement = "Any";
      } else if (this.attunementSelected) {
        attunement = "Yes";
      } else if (this.noattunementSelected) {
        attunement = "No";
      }
      return attunement;
    },
    itemOptions() {
      var itemOptions = [];
      if (this.includedSources.some((source) => source == "DMG")) {
        itemOptions = itemOptions.concat([...this.srd]);
      }
      if (this.includedSources.some((source) => source == "WGMM")) {
        itemOptions = itemOptions.concat([...this.wgmm]);
      }
      if (this.includedSources.some((source) => source == "MGMM")) {
        itemOptions = itemOptions.concat([...this.mgmm]);
      }
      if (
        this.includedSources.some((source) => source == "Custom") &&
        this.userItems
      ) {
        itemOptions = itemOptions.concat([...this.userItems]);
      }

      itemOptions = itemOptions.filter((item) => item.description != "");
      itemOptions = itemOptions.filter((item) => item.description != null);

      itemOptions = itemOptions.filter((item) =>
        this.includedRarities.includes(item.rarity)
      );
      itemOptions = itemOptions.filter((item) =>
        this.includedTypes.includes(item.type)
      );
      if (!this.attunementSelected) {
        itemOptions = itemOptions.filter((item) => item.attunement == "No");
      }
      if (!this.noattunementSelected) {
        itemOptions = itemOptions.filter((item) => item.attunement != "No");
      }
      return itemOptions;
    },
  },
};
</script>

<style scoped>
.container {
  z-index: 100;
}

.close-button {
  display: none;
}
.random-menu {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 95vw;
  font-family: Montserrat, "sans-serif";
  background-color: var(--dark3);
  padding: 10px;
  z-index: 101;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 75%);
}

.random-menu > div {
  margin: 10px 0px;
  position: relative;
}

.filter-element {
  position: relative;
}
.filter-element.large {
  width: 100%;
  max-height: 30px;
  height: 30px;
  text-transform: uppercase;
}

.option-checkmarks {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

select,
input {
  border-radius: 5px;
  width: unset;
  min-height: 30px;
  min-width: 75px;
  max-width: 100px;
  background-color: white;
  box-shadow: inset 0 0 4px 0 rgb(138 177 198 / 75%);
  border: solid 1px #9c9898;
  box-sizing: border-box;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
}

.fields {
  text-align: center;
}
.number-items {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.number-items .text-input {
  margin-left: 5px;
}

.dice-options {
  display: flex;
  color: black;
}

.dice-select {
  margin-right: 5px;
  width: 100%;
  text-align: center;
  color: black;
}

.modifier-select {
  width: 100%;
  text-align: center;
  color: black;
}

.unchecked {
  background: rgba(255, 255, 255, 0.336);
}

.patron-only {
  font-size: 0.9rem;
  position: absolute;
  top: -10%;
  left: 0%;
  line-height: 1;
  transform: rotate(-7.5deg);
  color: red !important;
  transition: all 0.3s ease;
  font-weight: 800;
  filter: opacity(1) grayscale(0) !important;
}

.patron-only-2 {
  font-size: 0.9rem;
  position: absolute;
  top: 10%;
  left: 20%;
  line-height: 1;
  transform: rotate(-7.5deg);
  color: red !important;
  transition: all 0.3s ease;
  font-weight: 800;
  filter: opacity(1) grayscale(0) !important;
}

.tooltip-top {
  filter: opacity(1) grayscale(0) !important;
}

.bottom-button {
  width: 100%;
  text-align: center;
  background-color: black;
  font-size: 0.7rem;
  color: #ffffff;
  padding: 5px 5px;
  margin: 5px 0px;
  border-radius: 5px;
  transition: all 0.5s ease;
  cursor: pointer;
  font-size: 18px;
  text-transform: uppercase;
  user-select: none;
}

.patreon-connect {
  background-color: var(--dark2);
  height: 40px;
}

.connect-button {
  height: 40px;
  border-radius: 5px;
}

@media only screen and (max-width: 1080px) {
  .close-button {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    font-size: 2rem;
  }
  .small-i {
    font-size: 0.75rem;
  }

  .random-menu {
    background: var(--dark2);
    z-index: 100;
    width: 90vw;
  }

  .menu-container > div {
    margin: 10px 0px;
  }

  .column-menu h3 {
    font-size: 1.5rem;
  }

  .column-option {
    padding: 10px;
    margin: 5px 0px;
    font-size: 1rem;
    border-radius: 5px;
  }
}
</style>
