<template>
  <div class="deities">
    <div class="deity-containers">
      <PantheonEditorDeity v-for="deity, index in deities" :ref="`deityRef-${index}`" :key="deity.id" :deitySource="deity"
        :deityAlignments="deityAlignments" :deityFilters="deityFilters" @deleteDeity="deleteDeity(deity.id)"
        @deityUpdated="setDeityData"></PantheonEditorDeity>
    </div>

    <div class="menu-row centered">
      <div class="generate-button" @click="
        generateDeities('single');
                    ">
        Add Deity <i class="fas fa-plus-circle"></i>
      </div>
      <div class="generate-button" @click="generateDeities('pantheon')" v-if="deities.length > 0">
        Reroll Deities <i class="fas fa-dice-d20"></i>
      </div>
    </div>
  </div>
</template>

<script>
import PantheonEditorDeity from "./PantheonEditorDeity.vue";

export default {
  name: "PantheonEditorDeities",
  components: {
    PantheonEditorDeity,
  },
  emits: ["deitiesGenerated"],
  props: ["deitySelection", "pantheonSize"],
  data() {
    return {
      deityGenerated: false,
      deities: [],
    };
  },
  methods: {
    deleteDeity(id) {
      this.deities = this.deities.filter((deity) => deity.id !== id);
      this.$emit("deitiesGenerated", this.deities);
    },
    setDeityData(deity) {
      const i = this.deities.findIndex((deityToUpdate) => deityToUpdate.id == deity.id)
      this.deities[i] = deity;
      this.$emit("deitiesGenerated", this.deities);
    },
    generateDeities(type) {
      let alignments = [...this.deityAlignments.options];
      let deityCoreDomains = this.coreDivineDomains;
      let deityAdditionalDomains = this.additionalDivineDomains;
      let deitiesToGenerate = type == "single" ? 1 : this.pantheonSize;
      type == "single" ? null : (this.deities = []);

      for (let i = 0; i < deitiesToGenerate; i++) {




        /// SET THE DEITY DOMAINS PARAMETERS IF DEITY SELECTION IS TRUE

        let coreDomains = [];
        let additionalDomains = [];

        if (this.deitySelection && this.deitySelection.domains) {
          if (!deityCoreDomains || deityCoreDomains.length === 0) {
            deityCoreDomains = this.$store.state.pantheonData.coreDivineDomains;
          }
          coreDomains = [];
          coreDomains.push(
            deityCoreDomains[
            Math.floor(Math.random() * deityCoreDomains.length)
            ]
          );
          deityCoreDomains = deityCoreDomains.filter(
            (domain) => domain.name !== coreDomains[0].name
          );
          coreDomains.push(
            deityCoreDomains[
            Math.floor(Math.random() * deityCoreDomains.length)
            ]
          );
          deityCoreDomains = deityCoreDomains.filter(
            (domain) => domain.name !== coreDomains[1].name
          );

          if (!deityAdditionalDomains || deityAdditionalDomains.length === 0) {
            deityAdditionalDomains =
              this.$store.state.pantheonData.additionalDivineDomains;
          }
          additionalDomains = [];
          additionalDomains.push(
            deityAdditionalDomains[
            Math.floor(Math.random() * deityAdditionalDomains.length)
            ]
          );
          deityAdditionalDomains = deityAdditionalDomains.filter(
            (domain) => domain.name !== additionalDomains[0].name
          );
          additionalDomains.push(
            deityAdditionalDomains[
            Math.floor(Math.random() * deityAdditionalDomains.length)
            ]
          );
          deityAdditionalDomains = deityAdditionalDomains.filter(
            (domain) => domain.name !== additionalDomains[1].name
          );
        } else {
          coreDomains = [...this.$store.state.pantheonData.coreDivineDomains];
          additionalDomains = [
            ...this.$store.state.pantheonData.additionalDivineDomains,
          ];
        }

        ////////// START GENERATING THE ACTUAL DEITY //////////

        /// SET VARIABLES

        let deity = {};
        let deityFilters = this.deityFilters;
        let deityName = "";
        let deityTitles = this.$store.state.pantheonData.deityTitles;
        let coreDivineDomains =
          deityCoreDomains.length > 0
            ? deityCoreDomains
            : [...this.$store.state.pantheonData.coreDivineDomains];
        let additionalDivineDomains =
          deityAdditionalDomains.length > 0
            ? deityAdditionalDomains
            : [...this.$store.state.pantheonData.additionalDivineDomains];

        let selectedFilters = [];

        /// SET ALL THE FILTERS RANDOMLY

        for (let filter of deityFilters) {
          let filterOptions = filter.options.filter((option) => option.selected);
          if (filterOptions.length === 0) {
            filterOptions = filter.options;
          }
          let filterName = filter.name;
          let randomOption =
            filterOptions[Math.floor(Math.random() * filterOptions.length)];
          selectedFilters.push({ name: filterName, value: randomOption.value });
        }



        /// SET THE DEITY ORIGIN

        let selectedOrigin = selectedFilters.find(
          (filter) => filter.name === "Origin"
        ).value;

        let deityOrigin = "";

        if (selectedOrigin == "Eternal") {
          deityOrigin = "has existed since the beginning of time.";
        } else if (selectedOrigin == "Created") {
          deityOrigin = "was created by another deity.";
        } else if (selectedOrigin == "Transformed") {
          deityOrigin = "was once mortal before it was transformed into a god ";
        }

        /// SET THE DEITY TRANSFORMATION TYPE (IF APPLICABLE)
        if (selectedOrigin == "Transformed") {
          let selectedTransformation = selectedFilters.find(
            (filter) => filter.name === "Transformation"
          ).value;

          if (selectedTransformation == "Magic") {
            deityOrigin = deityOrigin + "by powerful magic.";
          } else if (selectedTransformation == "Given") {
            deityOrigin = deityOrigin + "by another deity.";
          } else if (selectedTransformation == "Taken") {
            deityOrigin =
              deityOrigin +
              "because it took or stole the power of another deity.";
          }
        }

        deity.origin = deityOrigin;

        /// SET THE DEITY ALIGNMENT

        let deityAlignment = "";

        if (this.deitySelection && this.deitySelection.alignments) {
          if (!alignments || alignments.length === 0) {
            alignments =
              [...this.deityAlignments.options];
          }
        }

        deityAlignment =
          alignments[Math.floor(Math.random() * alignments.length)]
            .value;

        if (this.deitySelection && this.deitySelection.alignments) {
          alignments = alignments.filter(
            (alignment) => alignment.value != deityAlignment
          );
        }

        deity.alignment = deityAlignment;

        /// SET THE DEITY RANK

        let selectedRank = selectedFilters.find(
          (filter) => filter.name === "Divine Rank"
        ).value;

        deity.rank = selectedRank;

        /// SET THE DEITY DOMAINS

        let additionalDomainsN = 0;
        let coreDomainsN = 0;
        let selectedDomains = [];

        let domainRoll = 1;

        if (deity.rank == "Lesser God") {
          domainRoll = Math.floor(Math.random() * 4) + 1;
        } else if (deity.rank == "God") {
          domainRoll = Math.floor(Math.random() * 6) + 1;
        } else if (deity.rank == "Greater God") {
          domainRoll = Math.floor(Math.random() * 8) + 1;
        }

        switch (domainRoll) {
          case 1:
            additionalDomainsN = 1;
            coreDomainsN = 0;
            break;
          case 2:
            additionalDomainsN = 0;
            coreDomainsN = 1;
            break;
          case 3:
            additionalDomainsN = 2;
            coreDomainsN = 0;
            break;
          case 4:
            additionalDomainsN = 1;
            coreDomainsN = 1;
            break;
          case 5:
            additionalDomainsN = 2;
            coreDomainsN = 1;
            break;
          case 6:
            additionalDomainsN = 0;
            coreDomainsN = 2;
            break;
          case 7:
          case 8:
            additionalDomainsN = 1;
            coreDomainsN = 2;
            break;
          default:
            break;
        }

        deity.coreDomains = [];
        deity.additionalDomains = [];
        // coreDivineDomains = [
        //   ...this.$store.state.pantheonData.coreDivineDomains,
        // ];
        // additionalDivineDomains = [
        //   ...this.$store.state.pantheonData.additionalDivineDomains,
        // ];

        for (let i = 0; i < coreDomainsN; i++) {
          if (coreDivineDomains.length === 0) {
            coreDivineDomains = [
              ...this.$store.state.pantheonData.coreDivineDomains,
            ];
          }

          let randomDomain =
            coreDivineDomains[
            Math.floor(Math.random() * coreDivineDomains.length)
            ];
          selectedDomains.push(randomDomain);
          deity.coreDomains.push(randomDomain);
        }

        for (let i = 0; i < additionalDomainsN; i++) {
          if (additionalDivineDomains.length === 0) {
            additionalDivineDomains = [
              ...this.$store.state.pantheonData.additionalDivineDomains,
            ];
          }

          let randomDomain =
            additionalDivineDomains[
            Math.floor(Math.random() * additionalDivineDomains.length)
            ];
          selectedDomains.push(randomDomain);
          deity.additionalDomains.push(randomDomain);
        }
        deity.domains = selectedDomains;

        /// SET THE DEITY NAME


        let title =
          deityTitles[Math.floor(Math.random() * deityTitles.length)].name;

        let primaryDomain = selectedDomains[0];

        let randomNumber = Math.floor(Math.random() * 3) + 1;

        if (randomNumber == 1) {
          primaryDomain.suffixes
            ? (deityName =
              "The " +
              title +
              " " +
              primaryDomain.suffixes[
              Math.floor(Math.random() * primaryDomain.suffixes.length)
              ])
            : (deityName = "The " + title + " of " + primaryDomain.name);
        } else if (randomNumber == 2) {
          primaryDomain.prefixes
            ? (deityName =
              "The " +
              primaryDomain.prefixes[
              Math.floor(Math.random() * primaryDomain.prefixes.length)
              ] +
              " " +
              title)
            : (deityName = "The " + primaryDomain.name + " " + title);
        } else if (randomNumber == 3) {
          primaryDomain.fixed
            ? (deityName =
              primaryDomain.fixed[
              Math.floor(Math.random() * primaryDomain.fixed.length)
              ])
            : (deityName = "The " + primaryDomain.name + " " + title);
        }

        deity.name = deityName;
        /// SET THE DEITY MOTIVATION

        let availableMotivations = [];

        for (let domain of selectedDomains) {
          if (domain.motivations) {
            for (let motivation of domain.motivations)
              availableMotivations.push(motivation);
          }
        }

        deity.motivation =
          availableMotivations[
          Math.floor(Math.random() * availableMotivations.length)
          ];

        deity.id = (1 + (this.deities.length)) * Date.now()

        this.deities.push(deity);
      }


      this.$emit("deitiesGenerated", this.deities);
    },
  },
  computed: {
    additionalDivineDomains() {
      return this.$store.state.pantheonData.additionalDivineDomains;
    },
    coreDivineDomains() {
      return this.$store.state.pantheonData.coreDivineDomains;
    },
    deityFilters() {
      return this.$store.state.pantheonData.deityFilters;
    },
    deityAlignments() {
      return this.$store.state.pantheonData.deityAlignments;
    },
    deityDomainsString() {
      let deityDomains = this.deityDomains.map((domain) => domain.name);

      if (deityDomains.length === 1) {
        return deityDomains[0];
      }

      // Check if array has only two elements
      if (deityDomains.length === 2) {
        return deityDomains.join(" and ");
      }

      // Place commas between each element and 'and' before the last one
      let last = deityDomains.pop();
      return deityDomains.join(", ") + ", and " + last;
    },
    pantheonFilters() {
      return this.$store.state.pantheonData.pantheonFilters;
    },
  },
  mounted() {
    this.selectedFilters = [...this.pantheonFilters];
  },
};
</script>

<style scoped>
.deities {
  width: 800px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  font-family: montserrat, "sans-serif";
  color: whitesmoke;
}

.fa-dice-d20:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.deity-containers {
  width: 800px;
  columns: 2 350px;
  column-gap: 1rem;
}

.generate-button {
  background-color: var(--dark2);
  position: relative;
  color: white;
  font-size: 1.1rem;
  font-family: montserrat, "sans-serif";
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.generate-button:nth-of-type(1) {
  margin-left: 0px;
}

.generate-button:hover {
  transform: scale(1.02);
  transition: all 0.2s ease;
}


/* MEDIA QUERIES */

@media screen and (max-width: 1079px) {

  .deities {
    width: 100%;
  }
  .deity-containers {
    width: 100%;
    columns: 1 350px;
  }
}

</style>