<template>
  <div
    class="image-container"
    @mouseover="showOverlayButtons = true"
    @mouseleave="showOverlayButtons = false"
  >
    <img
      class="npc-image"
      :src="'img/' + type + '_images/' + imageSRC + '.png'"
    />
    <div class="position-buttons" v-if="showOverlayButtons">
      <div class="overlay-button">
        <i class="img-icon fas fa-caret-up"></i>
      </div>
      <div class="overlay-button">
        <i class="img-icon fas fa-caret-down"></i>
      </div>
      <div class="overlay-button">
        <i class="img-icon fas fa-circle"></i>
      </div>
    </div>
    <transition name="fade">
      <div class="menu-row overlay-buttons centered" v-if="showOverlayButtons">
        <div
          class="overlay-button"
          :class="{ deactive: !npcBackActivated }"
          @click="shiftimage('back')"
        >
          <i class="img-icon fas fa-caret-left"></i>
        </div>
        <div class="overlay-button">
          <i class="img-icon fas fa-dice-d20" @click="shuffleImageSRC"></i>
        </div>
        <div
          class="overlay-button"
          @mouseover="showImageFilters = true"
          @mouseleave="showImageFilters = false"
        >
          <i class="img-icon fas fa-filter"></i>
          <transition name="fade">
            <div class="filter-settings" v-if="showImageFilters">
              <div class="option-checkmarks" v-if="type == 'npc'">
                <div
                  class="filter-element"
                  option
                  v-for="race in npcRaces"
                  v-bind:key="race"
                  :class="{
                    unchecked: !includedImageRaces.includes(race),
                  }"
                  @click="
                    includedImageRaces.includes(race) &&
                    includedImageRaces.length > 1
                      ? (includedImageRaces = includedImageRaces.filter(
                          (imageRace) => imageRace != race
                        ))
                      : !includedImageRaces.includes(race)
                      ? includedImageRaces.push(race)
                      : null
                  "
                >
                  {{ race }}
                </div>
              </div>
              <div class="option-checkmarks" v-if="type == 'shop'">
                <div
                  class="filter-element"
                  option
                  v-for="location in shopLocations"
                  v-bind:key="location"
                  :class="{
                    unchecked: !includedShopLocations.includes(location),
                  }"
                  @click="
                    includedShopLocations.includes(location) &&
                    includedShopLocations.length > 1
                      ? (includedShopLocations = includedShopLocations.filter(
                          (imageLocation) => imageLocation != location
                        ))
                      : !includedShopLocations.includes(location)
                      ? includedShopLocations.push(location)
                      : null
                  "
                >
                  {{ location }}
                </div>
              </div>
            </div></transition
          >
        </div>
        <div
          class="overlay-button"
          :class="{ deactive: !npcForwardActivated }"
          @click="shiftimage('forward')"
        >
          <i class="img-icon fas fa-caret-right"></i>
        </div>
      </div></transition
    >
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "NpcImage",
  props: ["type", "npcRace", "shopLocation", "loadedImage"],
  emits: ["setImageSRC"],
  data() {
    return {
      shop: false,
      showImageFilters: false,
      showOverlayButtons: false,
      imagePosX: false,
      imagePosY: false,
      includedShopLocations: [
        "Shop",
        "House",
        "Market stall",
        "Workshop",
        "Laboratory",
        "Smithy",
        "Mansion",
        "Inn",
        "Tavern",
        "Temple",
        "Tower",
        "Castle",
        "Garden",
        "Ship",
        "Cave",
        "Carriage",
        "Hut",
        "Treehouse",
        "Gallery",
        "Ruins",
      ],
      imageSRC: "human_1",
      shopImageSRC: "city1",
      includedImageRaces: [
        "Human",
        "Half-Elf",
        "Half-orc",
        "Orc",
        "High Elf",
        "Wood Elf",
        "Dark Elf",
        "Dwarf",
        "Duergar",
        "Gnome",
        "Halfling",
        "Tiefling",
        "Dragonborn",
      ],
    };
  },
  mounted: function () {
    this.loadedImage
      ? (this.imageSRC = this.loadedImage)
      : this.type == "npc"
      ? (this.imageSRC = "human_1")
      : this.type == "shop" ? (this.imageSRC = "shop_1") : null;
  },
  watch: {
    loadedImage: function (newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        this.imageSRC = this.loadedImage;
      }
    },
    imageSRC: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.$emit("setImageSRC", newValue);
      }
    },
    npcRace: function (newValue, oldValue) {
      if (newValue != oldValue) {
        switch (newValue) {
          case "Human":
            this.includedImageRaces = ["Human"];
            break;
          case "High Elf":
          case "Wood Elf":
            this.includedImageRaces = ["High Elf", "Wood Elf", "Elf"];
            break;
          case "Dark Elf":
            this.includedImageRaces = ["Dark Elf"];
            break;
          case "Half-Elf":
            this.includedImageRaces = ["Human", "Elf"];
            break;
          case "Orc":
          case "Half-Orc":
            this.includedImageRaces = ["Orc"];
            break;
          case "Halfling":
            this.includedImageRaces = ["Halfling"];
            break;
          case "Duergar":
          case "Dwarf":
            this.includedImageRaces = ["Dwarf"];
            break;
          case "Tiefling":
            this.includedImageRaces = ["Tiefling"];
            break;
          case "Gnome":
            this.includedImageRaces = ["Gnome"];
            break;
          case "Dragonborn":
            this.includedImageRaces = ["Dragonborn"];
            break;
          default:
            break;
        }
        this.shuffleImageSRC();
      }
    },
    shopLocation: function (newValue, oldValue) {
      if (newValue != oldValue) {
        switch (newValue) {
          case "Gallery":
          case "House":
          case "Shop":
          case "Mansion":
          case "Inn":
          case "Tavern":
            this.includedShopLocations = ["Shop", "City"];
            break;
          case "Market stall":
            this.includedShopLocations = ["Market", "Merchant", "City"];
            break;
          case "Workshop":
          case "Laboratory":
          case "Smithy":
            this.includedShopLocations = ["Smithy", "Shop"];
            break;
          case "Temple":
            this.includedShopLocations = ["Temple"];
            break;
          case "Tower":
            this.includedShopLocations = ["Tower"];
            break;
          case "Castle":
          case "Ruins":
            this.includedShopLocations = ["Castle"];
            break;
          case "Garden":
            this.includedShopLocations = ["Garden"];
            break;
          case "Ship":
            this.includedShopLocations = ["Ship"];
            break;
          case "Carriage":
            this.includedShopLocations = ["Wagon"];
            break;
          case "Treehouse":
          case "Hut":
          case "Cave":
            this.includedShopLocations = ["Hut"];
            break;
          default:
            break;
        }
        this.shuffleImageSRC();
      }
    },
  },
  methods: {
    shiftimage(direction) {
      const index = this.imageSRCIndex;
      if (index > 0 && direction == "back") {
        this.imageSRC = this.imageSRCS[index - 1].image;
      } else if (direction == "back" && index == 0) { 
        this.imageSRC = this.imageSRCS[this.imageSRCS.length - 1].image;
      } else if (index < this.imageSRCS.length - 1 && direction == "forward") {
        this.imageSRC = this.imageSRCS[index + 1].image;
      } else if (direction == "forward" && index == this.imageSRCS.length - 1) {
        this.imageSRC = this.imageSRCS[0].image;
      }
    },
    shuffleImageSRC() {
      this.imageSRC =
        this.imageSRCS[Math.floor(Math.random() * this.imageSRCS.length)].image;
    },
    filterItem(filteredItem) {
      this.inventory = this.inventory.filter(
        (item) => item.name != filteredItem.name
      );
    },
  },
  computed: {
    ...mapState(["npcRaces", "shopLocations"]),
    imageSRCIndex() {
      if (this.imageSRC && this.imageSRCS) {
        return this.imageSRCS.map((x) => x.image).indexOf(this.imageSRC);
      } else {
        return 0;
      }
    },
    npcBackActivated() {
      return this.imageSRCIndex > 0;
    },
    npcForwardActivated() {
      return this.imageSRCIndex < this.imageSRCS.length - 1;
    },

    imageSRCS() {
      let imgSRCS = [];
      if (this.type == "npc") {
        imgSRCS = this.$store.state.npc_images;
        if (
          this.includedImageRaces &&
          this.includedImageRaces.length != this.npcRaces.length
        ) {
          let includedRaces = this.includedImageRaces.map((race) => {
            return race.toLowerCase();
          });
          imgSRCS = imgSRCS.filter((image) =>
            includedRaces.includes(image.tags[0])
          );
        }
      } else if (this.type == "shop") {
        imgSRCS = this.$store.state.shop_images;
        if (
          this.includedShopLocations &&
          this.includedShopLocations.length != this.shopLocations.length
        ) {
          let includedShops = this.includedShopLocations.map(
            (location) => {
              return location.toLowerCase();
            }
          );
          imgSRCS = imgSRCS.filter((image) =>
            includedShops.includes(image.tags[0])
          );
      }
        }
          return imgSRCS;
    },
  },
};
</script>

<style scoped>
.overlay-buttons {
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.overlay-buttons > div {
  width: 20%;
  aspect-ratio: 1.5/1;
  margin: 0px 1%;
}

.position-buttons {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "up"
    "center"
    "down";
  display:none;
}

.position-buttons .overlay-button {
  margin: 5px;
  font-size: 1.5rem;
  padding: 0px;
  width: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.position-buttons :nth-child(1) {
  grid-area: up;
}
.position-buttons :nth-child(2) {
  grid-area: down;
}
.position-buttons :nth-child(3) {
  grid-area: center;
  font-size: 0.6rem;
}
.position-buttons :nth-child(4) {
  grid-area: right;
}
.position-buttons :nth-child(5) {
  grid-area: down;
  font-size: 0.6rem;
}
.position-buttons :nth-child(6) {
  grid-area: zoom-in;
  font-size: 0.6rem;
}
.position-buttons :nth-child(7) {
  grid-area: zoom-out;
}

.overlay-button {
  background-color: black;
  color: white;
  text-align: center;
  font-family: montserrat, sans-serif;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0 4px 6px #111111;
  border-radius: 5px;
  padding-top: 5px;
  transition: 0.5s;
  font-size:1.5rem;
  display:flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.overlay-button > i,
.overlay-button p {
  width: 100%;
  transition: 0.3s;
}

.overlay-button:hover > i,
.overlay-button:hover p {
  transform: scale(1.1);
  transition: 0.3s;
}

.overlay-button > p {
  font-size: 0.7rem;
  margin-bottom: 0.2rem;
}

.image-container {
  grid-area: npc-image;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  aspect-ratio: 1/1;
}

.image-container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.image-container:hover img {
  transform: scale(1.1);
  transition: 0.3s;
}

.npc-image,
.shop-image {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: all 0.3s ease;
}

img.top {
  object-position: top;
}
img.bottom {
  object-position: bottom;
}

img.right {
  object-position: right;
}

img.left {
  object-position: left;
}

img.center {
  object-position: center;
}

img.top.right {
  object-position: top right;
}

img.top.left {
  object-position: top left;
}

img.bottom.right {
  object-position: bottom right;
}

img.bottom.left {
  object-position: bottom left;
}

.npc-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.filter-settings {
  position: absolute;
  width: 100%;
  bottom: 100%;
  left: -50%;
  padding: 10px 20px;
  transform: translate(50%, 0);
  display: flex;
  flex-flow: row wrap;
}

.option-checkmarks {
  display: flex;
  flex-flow: row wrap;
}

.unchecked {
  filter: opacity(0.3) grayscale(1);
}

.filter-element {
  font-size: 0.8rem;
  padding: 3px;
  max-height: 1.5rem;
  color: black;
}

.filter-element .fas {
  font-size: inherit;
}

/* MEDIA QUERIES */

/* @media only screen and (max-width: 1079px) {
  .position-buttons {
  display: grid;
}
} */
</style>
