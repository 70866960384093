<template>
  <BaseHeader />
  <div class="component-wrapper">
    <div class="collections-container">
      <div class="collection-column items">
        <CollectionItem :opened="opened" :item="item" :index="index" @opened="setOpened"
          v-for="(item, index) in itemCollection" :key="index"></CollectionItem>
        <!-- <div class="item-container-column left">
            <div class="item-header">
              <div class="item-name">{{ item.name }}</div> -->
        <!-- <div class="item-type">{{ item.type }}</div>
                        <div class="item-subtype" v-if="item.subtype">{{ item.subtype }}</div>
                        <div class="item-rarity" :class="item.rarity.toLowerCase().replace(/ /g, '-')">{{ item.rarity }}</div>
                        <div class="item-attunement" v-if="item.attunement && item.attunement != 'No'w">{{ item.attunement }}</div>
                        <div class="item-value">{{ item.value }}</div> -->
        <!-- </div>
            <div class="item-body">
              <div class="item-subheader"> -->
        <!-- <div class="item-attunement"> {{ item.attunement }} </div>
                        <div class="item-rarity" :class="item.rarity.toLowerCase().replace(/ /g, '-')"> {{ item.rarity }}
                        </div>
                        <div class="item-type">{{ item.type }} </div> -->
        <!-- {{ item.subheader }}
              </div>
              <div class="item-description" v-if="item.description">
                {{ item.description }}

              </div>
            </div>
          </div> -->
        <!-- <div class="item-container-column right">
          <img :src="item.src" alt="">
        </div> -->
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BaseHeader from "../Header/BaseHeader.vue";
import CollectionItem from "../ItemCardRelaunch/CollectionItem.vue";

export default {
  name: "ItemCollection",
  components: { BaseHeader, CollectionItem },
  data() {
    return {
      opened: null,
    };
  },
  methods: {
    setOpened(index) {
      this.opened = index;
    },
  },
  computed: {
    ...mapState(["user", "customItems", "userItems"]),
    itemCollection() {
      let itemCollection = [...this.userItems];
      for (let i = 0; i < itemCollection.length; i++) {
        let item = itemCollection[i];
        let typeRarityText = "";


        console.log(item);
        if (item.type == "Armor" || item.type == "Weapon") {
          typeRarityText =
            item.type +
            (item.subtype.startsWith("(") ? " " : " (") +
            item.subtype +
            (item.subtype.endsWith(")") ? ", " : "), ") +
            item.rarity;
        } else if (item.type == "Wondrous item") {
          typeRarityText = "Wondrous Item, " + item.rarity;
        } else {
          typeRarityText = item.type + ", " + item.rarity;
        }

        let attunementText = "";

        if (item.attunement && item.attunement != "No") {
          attunementText = "Requires Attunement";
        } else {
          attunementText = "No Attunement Required";
        }

        itemCollection[i].subheader =
          typeRarityText + " (" + attunementText + ")";

        itemCollection[i].src = "";

        if (
          typeof itemCollection[i].imageURL != "undefined" &&
          itemCollection[i].imageURL != null &&
          itemCollection[i].imageURL.length > 5
        ) {
          itemCollection[i].src = itemCollection[i].imageURL;
        }
        else if (itemCollection[i].imageSRC) {
          if (
            itemCollection[i].selectedType == "Rod" ||
            itemCollection[i].selectedType == "Staff" ||
            itemCollection[i].selectedType == "Wand"
          ) {
            itemCollection[i].src = "/img/item_images/rod_staff_wand/" + itemCollection[i].imageSRC;
          } else {
            itemCollection[i].src =
              "/img/item_images/" +
              itemCollection[i].type.toLowerCase().replace(" ", "_") +
              "/" +
              itemCollection[i].imageSRC
              ;
          }
        }

      }



      return itemCollection;
    },

  },
};
</script>

<style scoped>
.component-wrapper {
  margin-top: 100px;
  max-width: 100lwv;
  min-height: calc(100lvh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.collections-container {
  width: 95vw;
  height: 80vh;
  background-color: var(--dark2);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.collection-column {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}

.item-container {
  font-family: montserrat, sans-serif;
  font-size: 0.8rem;
  width: 400px;
  max-width: 100%;
  max-height: 100px;
  background-color: var(--dark5);
  border-radius: 10px;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 5px 0px;
  padding: 5px 10px;
  color: whitesmoke;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.item-container-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.item-container-column.left {
  width: 80%;
}

.item-container-column.right {
  width: 20%;
}

.item-container img {
  max-height: 75px;
  max-width: 100%;
  transition: all 0.2s ease-in-out;
}

.item-container.open img {
  max-height: 100%;
  transition: all 0.2s ease-in-out;
}

.item-container.open {
  height: auto;
  max-height: 100%;
  transition: all 0.2s ease-in-out;
}

.item-header {
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: Uppercase;
  display: flex;
  flex-flow: row nowrap;
}

.item-header>div {
  margin: 0px 5px 0px 0px;
  padding: 5px;
  border-radius: 2.5px;
  background-color: rgb(75, 75, 75);
  font-size: 0.7rem;
}

.item-header>.item-name {
  padding: 5px 5px 5px 0px;
  background-color: unset;
  font-size: 0.8rem;
}

.item-subheader {
  font-style: italic;
  text-align: left;
}

/* .item-subheader > div {
  margin: 0px 5px 0px 0px;
} */

.item-description {
  margin-top: 5px;
  text-align: left;
}

/* RARITY COLORS */

.common {
  background-color: grey !important;
}

.uncommon {
  background-color: green !important;
}

.rare {
  background-color: blue !important;
}

.very-rare {
  background-color: purple !important;
}

.legendary {
  background-color: orange !important;
}

.artifact {
  background-color: red !important;
}
</style>
