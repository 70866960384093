<template>
  <transition name="menu">
    <div
      class="enchantment-container rounded"
    >
    <div class="overlay" v-if="filters || edit" @click="filters = false, edit = false"></div>
      <select class="enchantment-select rounded" v-model="selectedEnchantment">
        <option
          v-for="enchantment in filteredEnchantments"
          :key="enchantment.name"
        >
          {{ enchantment.name }}
        </option>
      </select>
      <div
        class="menu-btn rounded"
        :class="{ highlight: filters }"
        @click="(filters = !filters), (edit = false)"
      >
        <i class="fas fa-filter"></i>
      </div>

      <div
        class="menu-btn rounded"
        @click="pickRandom"
      >
        <i class="fas fa-random"></i>
      </div>

      <div
        class="menu-btn rounded"
        @click="(edit = !edit), (filters = false)"
        :class="{ highlight: edit }"
        v-if="selectedEnchantment != 'Choose Enchantment'"
      >
        <i class="fas fa-edit"></i>
      </div>
            <div
        class="menu-btn rounded"
        @click="resetEnchantment"
      >
        <i class="fas fa-undo-alt"></i>
      </div>

      <transition name="menu">
        <div v-if="filters" class="shadow rounded card-menu-modal">
          <div class="menu-row">
            <h3>Restrict to item type:</h3>
            <input type="checkbox" class="filter f-type" v-model="fType" />
          </div>
          <div class="menu-row">
            <h3>Rarity</h3>
          </div>
          <div class="menu-row">
            <p>Common:</p>
            <input type="checkbox" class="filter f-common" v-model="fCommon" />
            <p>Uncommon:</p>
            <input
              type="checkbox"
              class="filter f-uncommon"
              v-model="fUncommon"
            />
            <p>Rare:</p>
            <input type="checkbox" class="filter f-rare" v-model="fRare" />
          </div>
          <div class="menu-row">
            <h3>Enchantment Type</h3>
          </div>
          <div class="menu-row">
            <p>Class:</p>
            <input type="checkbox" class="filter f-class" v-model="fClass" />
            <p>Curse:</p>
            <input type="checkbox" class="filter f-curse" v-model="fCurse" />
            <p>Prefix:</p>
            <input type="checkbox" class="filter f-prefix" v-model="fPrefix" />
            <p>Suffix:</p>
            <input type="checkbox" class="filter f-suffix" v-model="fSuffix" />
          </div>
        </div>
      </transition>
           <transition name="menu">
        <div v-if="edit && selectedEnchantment != 'Choose Enchantment'" class="shadow rounded card-menu-modal">
      <EnchantmentEditSelect :id=id
      :cardID="cardID"
      :card="card"></EnchantmentEditSelect>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import EnchantmentEditSelect from "./EnchantmentEditSelect.vue"

export default {
  name: "CardEnchantmentsSelect",
  components: {
    EnchantmentEditSelect
  },
  data() {
    return {
      filters: false,
      edit: false,
      fType: true,
      fCommon: true,
      fUncommon: true,
      fRare: true,
      fClass: true,
      fCurse: true,
      fPrefix: true,
      fSuffix: true,
      selected: false,
    };
  },
  props: {
    id: Number,
    cardID: Number,
    card: Object
  },
  methods: {
    pickRandom() {
      this.selectedEnchantment = this.filteredEnchantments[
        Math.floor(Math.random() * Math.floor(this.filteredEnchantments.length))
      ].name;
    },
    resetEnchantment() {
      this.selectedEnchantment = "Choose Enchantment";
    },
  },
  computed: {
    ...mapState(["itemTypes", "armors", "weapons", "enchantments"]),
    selectedEnchantment: {
      get: function () {
        return this.card.selectedEnchantments[this.id].name;
      },
      set: function (newEnchantment) {
        var selectedEnchantment = {};
        if (newEnchantment != "Choose Enchantment") {
          selectedEnchantment = this.enchantments.find(
            (enchantment) => enchantment.name == newEnchantment
          );
        } else {
          selectedEnchantment = { name: "Choose Enchantment" };
        }
        this.$store.commit("updateEnchantment", {
          cardID: this.cardID,
          index: this.id,
          enchantment: selectedEnchantment,
        });
      },
    },
    filteredEnchantments: function () {
      const card = this.card;
      var filteredEnchantments = [...this.enchantments];
      for (var i = 0; i < card.selectedEnchantments.length; i++) {
        if (
          card.selectedEnchantments[i].name != this.selectedEnchantment &&
          card.selectedEnchantments[i].name != "Choose Enchantment"
        ) {
          filteredEnchantments = filteredEnchantments.filter(
            (enchantment) =>
              enchantment.name != card.selectedEnchantments[i].name
          );
          filteredEnchantments = filteredEnchantments.filter(
            (enchantment) =>
              enchantment.name !=
              card.selectedEnchantments[i].name + ", Greater"
          );
          filteredEnchantments = filteredEnchantments.filter(
            (enchantment) =>
              enchantment.name != card.selectedEnchantments[i].name.slice(0, -9)
          );
        }
      }
      if (this.fType) {
        if (card.selectedType == "Armor") {
          filteredEnchantments = filteredEnchantments.filter(
            (enchantment) => enchantment.armor != "No"
          );
          if (
            typeof card.selectedSubtype != "undefined" &&
            card.selectedSubtype != "Any" &&
            card.selectedSubtype != "" &&        
            this.armors.some(
          (armor) => armor.name == card.selectedSubtype)
          ) {
            if (
              
              this.armors.find((armor) => armor.name == card.selectedSubtype)
                .medium == false &&
              this.armors.find((armor) => armor.name == card.selectedSubtype)
                .heavy == false
            ) {
              filteredEnchantments = filteredEnchantments.filter(
                (enchantment) => enchantment.armor != "MediumHeavy"
              );
            }
            if (
              this.armors.find((armor) => armor.name == card.selectedSubtype)
                .light == false
            ) {
              filteredEnchantments = filteredEnchantments.filter(
                (enchantment) => enchantment.armor != "Light"
              );
            }
          }
        } else if (card.selectedType == "Weapon") {
          filteredEnchantments = filteredEnchantments.filter(
            (enchantment) => enchantment.weapon != "No"
          );
          if (
            typeof card.selectedSubtype != "undefined" &&
            card.selectedSubtype != "Any" &&        
            this.weapons.some(
          (weapon) => weapon.name == card.selectedSubtype)
          ) {
            if (
              this.weapons.find((weapon) => weapon.name == card.selectedSubtype)
                .ranged == false
            ) {
              filteredEnchantments = filteredEnchantments.filter(
                (enchantment) => enchantment.weapon != "Ranged"
              );
            }
            if (
              this.weapons.find((weapon) => weapon.name == card.selectedSubtype)
                .melee == false
            ) {
              filteredEnchantments = filteredEnchantments.filter(
                (enchantment) => enchantment.weapon != "Melee"
              );
            }
            if (
              card.selectedSubtype != "Any" &&
              this.weapons.find((weapon) => weapon.name == card.selectedSubtype)
                .piercing == false &&
              this.weapons.find((weapon) => weapon.name == card.selectedSubtype)
                .slashing == false
            ) {
              filteredEnchantments = filteredEnchantments.filter(
                (enchantment) => enchantment.weapons != "PiercingSlashing"
              );
            }
          }
        } else {
          filteredEnchantments = filteredEnchantments.filter(
            (enchantment) => enchantment.wondrous != "No"
          );
        }
      }

      if (!this.fCommon) {
        filteredEnchantments = filteredEnchantments.filter(
          (enchantment) => enchantment.rarity != "0"
        );
      }
      if (!this.fUncommon) {
        filteredEnchantments = filteredEnchantments.filter(
          (enchantment) => enchantment.rarity != "1"
        );
      }
      if (!this.fRare) {
        filteredEnchantments = filteredEnchantments.filter(
          (enchantment) => enchantment.rarity != "2"
        );
      }
      if (!this.fClass) {
        filteredEnchantments = filteredEnchantments.filter(
          (enchantment) => enchantment.type != "Class"
        );
      }
      if (!this.fCurse) {
        filteredEnchantments = filteredEnchantments.filter(
          (enchantment) => enchantment.type != "Curse"
        );
      }
      if (!this.fPrefix) {
        filteredEnchantments = filteredEnchantments.filter(
          (enchantment) => enchantment.type != "Prefix"
        );
      }
      if (!this.fSuffix) {
        filteredEnchantments = filteredEnchantments.filter(
          (enchantment) => enchantment.type != "Suffix"
        );
      }
      filteredEnchantments.unshift({ name: "Choose Enchantment" });
      return filteredEnchantments;
    },
  },
};
</script>

<style scoped>
.card-menu-modal {
  position: absolute;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  font-family: "Montserrat", sans-serif;
  width: 250px;
  height: auto;
  padding: 5px 10px;
  color: #fff;
  background: #141418;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  transition: var(--transition-speed);
  width: 100%;
  top: 100%;
  padding-bottom: 15px;
  box-shadow: 0 0 5px rgb(0, 0, 0);
  z-index: 1000;
}


.card-menu-modal p {
  font-size:0.6rem;
}

.filter {
  width:0.8rem;
  height:0.8rem;
  margin:0 0.2rem;
}

.enchantment-container {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  color: #31a554;
  background:var(--dark);
  width: 100%;
  /* box-shadow: 0 -2px 20px rgb(0, 0, 0); */
  border: 1px solid rgb(117, 117, 117);
  margin-bottom: 0.4rem;
  height:1.5rem;
}

.enchantment-container:last-of-type {
  margin-bottom:0;
}

.enchantment-select {
  width: 70%;
  height: 100%;
  border: none;
  outline: none;
  transition: all 0.5s ease;
  padding:0.1rem;
}

.menu-btn {
  background:var(--dark2);
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.8rem;
  text-align: center;
  line-height: 1.5rem !important;
  width: 12%;
  height: 100%;
  background: transparent;
  transition: all 0.5s ease;
  cursor: pointer;
  color: #f5fff8;
}

.menu-btn:hover,
.highlight {
  color: var(--main2);
  filter: grayscale(0%) opacity(1);
  background: #141418;
}


.menu-enter-from,
.menu-leave-to {
  opacity: 0%;
}

.menu-enter-active,
.menu-leave-active {
  transition: all 0.6s ease;
}
</style>