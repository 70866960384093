<template>
  <transition name="fade">
    <BaseDialog
      v-if="showTooManyCardsWarning"
      title="The Collection is Full!"
      @close="showTooManyCardsWarning = false"
      @okay="showTooManyCardsWarning = false"
    >
      <template #content>
        <b>You have too many items!</b> A collection can hold 100 items if
        you're a member of the
        <a
          href="https://patreon.com/eventyr"
          target="_blank"
          style="color: blue; font-weight: bold"
          >Eventyr Games Patreon</a
        >
        and 10 items if you're not. Delete cards to make room for new ones – or
        create and save additional collections by becoming a Patreon member.
      </template>
    </BaseDialog>
  </transition>
  <transition name="fade">
    <Tutorial
      v-if="tutorial && !isLoading"
      :tutorial="tutorial"
      :tutorialStep="tutorialStep"
      @tutorialBack="tutorialStep--"
      @endTutorial="tutorial = false"
      @tutorialForward="tutorialStep++"
    ></Tutorial>
  </transition>
  <BaseHeader
    v-if="!printView"
    @startTutorial="(tutorial = true), (tutorialStep = 0)"
  ></BaseHeader>
  <transition name="fade">
    <div
      class="overlay darkest editor-overlay"
      v-if="editingCard"
      @click="closeEditor"
    ></div>
  </transition>
  <div class="main-container" :class="{ print: printView }">
    <div
      class="secondary-container"
      :key="updateKey"
      :class="{ print: printView }"
    >
      <div class="collection-info" v-if="!printView">
        <h2 contenteditable="true" @blur="changeCollectionName">
          {{ this.collectionName }}
        </h2>
        <div
          class="view-element"
          @click="displayMode = 'card'"
          :class="{ active: displayMode == 'card' }"
        >
          <i class="fa-solid fa-cards-blank"></i> Cards
        </div>
        <div
          class="view-element"
          @click="displayMode = 'list'"
          :class="{ active: displayMode == 'list' }"
        >
          <i class="fa-solid fa-list"></i>List
        </div>
        <!-- <div
          class="view-element"
          @click="displayMode = 'page'"
          :class="{ active: displayMode == 'page' }"
        >
          <i class="fa-solid fa-page"></i>Page
        </div> -->
      </div>
      <transition name="fade" mode="out-in">
        <BaseCard
          :selected="true"
          v-if="editingCard"
          :cardID="selectedCardID"
          :tutorial="tutorial"
          :tutorialStep="tutorialStep"
          @saveCards="saveCards()"
          @closeEditor="closeEditor()"
        ></BaseCard>
      </transition>

      <transition name="fade" mode="out-in">
        <draggable
          class="card-list"
          v-if="displayMode == 'list' && !printView"
          v-model="cards"
          @start="drag = true"
          item-key="cardID"
          :class="{ editing: editingCard }"
          :disabled="dragDisabled"
        >
          <template #item="{ element }">
            <ListCard
              :key="element.cardID"
              :cardID="element.cardID"
              :card="element"
              :ref="`listCard-${element.cardID}`"
            ></ListCard>
          </template>
        </draggable>
        <draggable
          class="small-cards"
          :class="{
            print: printView,
            showCards: showCards,
            editing: editingCard,
          }"
          v-model="cards"
          @start="drag = true"
          item-key="cardID"
          v-else-if="displayMode == 'card' || printView"
          :disabled="dragDisabled"
        >
          <template #item="{ element }">
            <SmallCard
              :class="{
                print: printView,
              }"
              :cardType="element.cardType"
              :cardID="element.cardID"
              :card="element"
              :printView="printView"
              :key="element.cardID"
              :tutorial="tutorial"
              :tutorialStep="tutorialStep"
              :printBack="printBack"
              :cardsToShow="cardsToShow"
              :ref="`card-${element.cardID}`"
              @print="printView = true"
            >
            </SmallCard>
          </template>
        </draggable>
      </transition>
      <transition name="fade" mode="out-in">
        <CardEditorButtons
          v-if="!printView"
          @printSheet="printView = !printView"
          @showCollections="showCollections = !showCollections"
          @showRandomMenu="showRandomMenu = !showRandomMenu"
          @addCard="addCard()"
        ></CardEditorButtons
      ></transition>

      <transition name="fade" mode="out-in">
        <div class="print-buttons" v-if="!editingCard && printView">
          <button
            class="print-button"
            @click="printView = false"
            v-if="printView"
          >
            Exit Print
          </button>
          <button class="print-button" @click="printSheet('a4')">
            Print A4
          </button>
          <button class="print-button" @click="printSheet('letter')">
            Print Letter
          </button>
          <button class="print-button" @click="flipCards()">
            <i class="fas fa-redo"></i>Flip Cards
          </button>
        </div>
      </transition>

      <CollectionMenu
        v-if="showCollections"
        @close="showCollections = false"
        @printCollection="printCollection"
      ></CollectionMenu>
      <transition name="fade" mode="out-in">
        <keep-alive>
          <CardEditorAddRandom
            v-if="showRandomMenu"
            @close="showRandomMenu = false"
          ></CardEditorAddRandom>
        </keep-alive>
      </transition>
      <div class="menu-row centered collection-section" v-if="printView">
        <i
          class="fas fa-angle-double-left slide-sheet"
          :class="{ enabled: nSelectedCards > 8 && sheetToShow > 0 }"
          @click="showCards ? slide(-1) : null"
          v-show="sheetToShow > 0"
        ></i>
        <div class="collection-info bottom" v-if="printView">
          <h2>{{ this.collectionName }}</h2>
          <h3>Page {{ sheetToShow + 1 }}/{{ cardSheets }}</h3>
        </div>
        <i
          class="fas fa-angle-double-right slide-sheet"
          :class="{
            enabled:
              premium && nSelectedCards > 8 && sheetToShow < cardSheets - 1,
          }"
          @click="showCards && premium ? slide(1) : null"
          v-show="cardSheets > 1"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCard from "./ItemCardRelaunch/BaseCard.vue";
import SmallCard from "./ItemCardRelaunch/SmallCard.vue";
import BaseHeader from "./Header/BaseHeader.vue";
import { mapState } from "vuex";
import Tutorial from "./Tutorial.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import ListCard from "./ItemCardRelaunch/ListCard.vue";
import CollectionMenu from "./CollectionMenu.vue";
import CardEditorButtons from "./CardEditorButtons.vue";
import CardEditorAddRandom from "./CardEditorAddRandom.vue";
import BaseDialog from "./BaseDialog.vue";
import draggable from "vuedraggable";

export default {
  name: "CardEditor",
  components: {
    draggable,
    BaseCard,
    SmallCard,
    BaseHeader,
    BaseDialog,
    Tutorial,
    ListCard,
    CollectionMenu,
    CardEditorButtons,
    CardEditorAddRandom,
  },
  data() {
    return {
      dragDisabled: false,
      cardCounter: 0,
      printView: false,
      printBack: false,
      a4: false,
      letter: false,
      editorView: true,
      updateKey: 0,
      updateKey2: 0,
      updateKeys: {},
      showNew: false,
      cardList: false,
      smallCards: true,
      showTooltipAdd: false,
      showTooltipAdd2: false,
      showTooltipCollections: false,
      showCollections: false,
      showRandomMenu: false,
      showTooltipPrint: false,
      isLoading: false,
      hideAdd: false,
      tutorial: false,
      tutorialStep: 1,
      sheetToShow: 0,
      transitionName: "fade",
      showCards: false,
      showTooManyCardsWarning: false,
      displayMode: "card",
      collectionChanging: false,
    };
  },
  computed: {
    ...mapState([
      "premium",
      "premiumExpiration",
      "selectedCollectionID",
      "collections",
      "selectedCardID",
      "selectedCards",
      "editingCard",
      "user",
      "tutorialDone",
      "loaded",
    ]),
    cards: {
      get() {
        return this.$store.state.cards;
      },
      set(value) {
        this.$store.commit("saveCards", value);
      },
    },
    nCards() {
      var nCards = 0;
      if (this.cards) {
        nCards = this.cards.length;
      }
      return nCards;
    },
    nSelectedCards() {
      var nSelectedCards = 0;
      if (this.selectedCards) {
        nSelectedCards = this.selectedCards.length;
      }
      return nSelectedCards;
    },
    cardSheets() {
      let cardSheets = this.selectedCards
        ? Math.ceil(this.selectedCards.length / 8)
        : 1;
      if (cardSheets === 0) {
        cardSheets = 1;
      }
      return cardSheets;
    },
    cardsToShow() {
      let cardsToShow = [];
      if (this.selectedCards) {
        cardsToShow =
          window.innerWidth > 1079
            ? this.selectedCards.slice(
                this.sheetToShow * 8,
                (this.sheetToShow + 1) * 8
              )
            : [];
      }
      return cardsToShow;
    },
    blankCards() {
      let blankCards = Array(8 - this.cardsToShow.length).fill("blank");
      return blankCards;
    },
    collectionName() {
      let collectionName = "";
      if (this.selectedCollectionID) {
        collectionName = this.collections.find(
          (collection) => collection.collectionID == this.selectedCollectionID
        ).name;
      }
      return collectionName;
    },
  },
  watch: {
    nSelectedCards: function (newValue, oldValue) {
      if (
        oldValue &&
        oldValue.length < newValue.length &&
        Math.ceil(newValue.length / 8) != this.sheetToShow - 1
      ) {
        console.log("sup");
        this.sheetToShow = Math.ceil(newValue.length / 8) - 1;
      }
    },
    selectedCollectionID: function (newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        // this.isLoading = true;
        // setTimeout(() => {
        this.sheetToShow = 0;
        this.updateCards();
        this.isLoading = false;
        this.collectionChanging = true;
        // }, 1000);
      }
    },
    sheetToShow: function (newValue, oldValue) {
      // this.updateCards();
      if (newValue != oldValue) {
        setTimeout(() => {
          this.showCards = true;
        }, 500);
      }
    },
    nCards: function (newValue, oldValue) {
      if (newValue > oldValue && !this.collectionChanging) {
        console.log("cards changed");
        this.$nextTick(() => {
          window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth", // Add smooth scrolling effect
          });
        });
        this.collectionChanging = false;
      }
    },
  },
  methods: {
    changeCollectionName(event) {
      let collectionName = event.target.innerHTML;
      if (collectionName.length > 0) {
        this.$store.commit("updateCollectionName", {
          collectionID: this.selectedCollectionID,
          collectionName: collectionName,
        });
        this.$store.dispatch("saveCollections");
      } else {
        this.$store.commit("updateCollectionName", {
          collectionID: this.selectedCollectionID,
          collectionName: "Untitled Collection",
        });
      }
    },
    flipCards() {
      for (let card of this.cards) {
        this.$store.commit("updateBack", {
          cardID: card.cardID,
        });
      }
    },
    async slide(direction) {
      const wait = (timeToDelay) =>
        new Promise((resolve) => setTimeout(resolve, timeToDelay));
      if (
        (direction === 1 && this.sheetToShow < this.cardSheets - 1) ||
        (direction === -1 && this.sheetToShow > 0)
      ) {
        this.showCards = false;
        await wait(500);

        direction === 1
          ? (this.transitionName = "slide-next")
          : (this.transitionName = "slide-prev");
        this.sheetToShow = this.sheetToShow + direction;
      }
    },
    addCard() {
      if (this.premium && (!this.cards || this.cards.length < 100)) {
        this.$store.dispatch("addCard");
      } else if (!this.cards || this.cards.length < 10) {
        this.$store.dispatch("addCard");
      } else {
        this.showTooManyCardsWarning = true;
      }
    },
    async saveCards() {
      var collectionID = this.selectedCollectionID;
      this.$store.dispatch("saveCards", { collectionID });
    },
    closeEditor() {
      let selectedCardID = this.selectedCardID;
      this.$store.commit("endEdit");
      this.saveCards();
      if (this.displayMode == "card") {
        this.$nextTick(() => {
          this.$refs[`card-${selectedCardID}`].forceUpdateComponent();
        });
      } else if (this.displayMode == "list") {
        this.$nextTick(() => {
          this.$refs[`listCard-${selectedCardID}`].forceUpdateComponent();
        });
      }
    },
    updateCards() {
      this.updateKey++;
    },
    updateCard(cardID) {
      this.cards[cardID].updateKey++;
    },
    printSheet(format) {
      if (format == "a4") {
        (this.a4 = true), (this.letter = false);
      }
      if (format == "letter") {
        (this.a4 = false), (this.letter = true);
      }
      this.printView = true;
      setTimeout(() => {
        window.print();
      }, 500);
    },
    printCollection() {
      let cardsToSelect = [...this.$store.state.cards];
      this.selectedCards.forEach((cardID) => {
        cardsToSelect = cardsToSelect.filter((card) => card.cardID != cardID);
      });
      cardsToSelect.forEach((card) => {
        this.$store.commit("updateSelectedCards", card.cardID);
      });
      this.printView = true;
    },
    deleteCard(cardID) {
      this.$store.commit("removeCard", { cardID: cardID });
      var collectionID = this.selectedCollectionID;
      this.$store.dispatch("saveCards", { collectionID: collectionID });
    },
    unload(e) {
      if (!this.user.loggedIn) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    },
    confirmLeave() {
      return window.confirm(
        "Do you really want to leave? You have unsaved changes!"
      );
    },
  },
  created() {
    window.addEventListener("beforeunload", this.unload);
  },
  mounted() {
    // var resizeTimer;
    // var self = this;
    // var callUpdate = () => {
    //   clearTimeout(resizeTimer);
    //   resizeTimer = setTimeout(function () {
    //     if (window.innerWidth > 768) {
    //       self.updateCards();
    //       self.dragDisabled = false;
    //     }
    //     if (window.innerWidth < 768) {
    //       self.dragDisabled = true;
    //     }
    //   }, 500);
    // };

    // window.addEventListener("resize", () => callUpdate());

    if (window.innerWidth < 768) {
      this.dragDisabled = true;
    }
    setTimeout(() => {
      this.updateCards();
    }, 500);
    setTimeout(() => {
      this.showCards = true;
    }, 500);
  },
  beforeCreate() {
    if (this.$store.state.loaded == false) {
      this.$store.dispatch("loadCollections").then(() => this.updateCards());
    }
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 80px 0px 0px 0px;
}

.main-container.print {
  padding: 0px;
}

.secondary-container {
  position: relative;
  margin: 20px 0px;
  width: 100%;
  max-width: 1050px;
  display: flex;
  flex-flow: row wrap;
  align-items: top;
  justify-content: center;
}

.small-cards {
  position: relative;
  width: 100%;
  max-width: 1050px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: left;
  z-index: 1;
  transition: all 0.5s ease;
  opacity: 0;
}

.small-cards > div {
  margin: 5px;
}

.small-cards.print {
  height: 700px;
  width: 1000px;
  overflow: hidden;
}

.small-cards.print > div {
  margin: 0px;
}

.printing {
  margin: 0px;
}

.a4 {
  zoom: 96%;
}

.letter {
  zoom: 98%;
}

.print-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 1000px;
  margin-top: 10px;
}

.print-button {
  width: 150px;
  height: 3rem;
  border-radius: 10px;
  margin: 10px;
  border: 1px solid #ffffff;
  background-color: var(--dark);
  filter: grayscale(50%) opacity(0.9);
  color: var(--main2);
  font-size: 12px;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.slider-buttons {
  position: fixed;
  top: 20%;
  width: 100vw;
  display: none;
}

.slide-sheet {
  color: white;
  font-size: 4rem;
  display: flex;
  align-items: center;
  padding: 10px;
  transition: all 0.3s ease;
  filter: opacity(0.5);
}

.slide-sheet.enabled {
  filter: opacity(1);
  cursor: pointer;
  transition: all 0.5s ease;
}

.slide-sheet.enabled:hover {
  transform: scale(1.05);
  transition: all 0.3s ease;
}

.collection-section {
  position: absolute;
  bottom: -6rem;
}

.collection-info {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0px 0px 15px 0px;
  user-select: none;
  width: 100%;
  background-color: var(--dark3);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
}

.collection-info.bottom {
  background-color: unset;
  box-shadow: unset;
  flex-flow: column nowrap;
  align-items: center;
}

.collection-info h2 {
  max-width: 400px;
  overflow: hidden;
  max-height: 25px;
}

.view-element {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px;
  margin: auto 0px;
  border-left: 2px solid rgba(245, 245, 245, 0.5);
  color: whitesmoke;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-element i {
  margin-right: 5px;
  font-size: 0.8rem;
}

.view-element:hover,
.view-element.active {
  color: var(--main1);
  transition: all 0.3s ease;
}

.view-element:nth-of-type(1) {
  margin-left: 20px;
}

.add-card-button {
  width: 100%;
  height: 50%;
  font-size: 5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-card-button.outside {
  width: 200px;
  position: absolute;
  left: 100%;
  bottom: 0;
}

.add-card-button .fas {
  color: white;
  cursor: pointer;
  position: relative;
}

.add-card.enlarge,
.fixed-buttons.enlarge {
  margin: 1.5rem 0 0 0;
  width: 15rem;
  height: 21rem;
}

.add-card.enlarge .fas {
  font-size: 6rem;
  padding: 0 4.5rem;
}

.add-card.mobile {
  margin: 1.5rem auto 0 auto;
}

.view-buttons {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  display: none;
  height: 4rem;
}

.view-buttons button {
  width: 10rem;
  height: 2rem;
  border-radius: 2px;
  margin: 0px;
  border: 1px solid #ffffff;
  background-color: var(--dark);
  filter: grayscale(100%) opacity(0.5);
  color: var(--main2);
  font-size: 0.6rem;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.smallcard:hover,
.print-button:hover,
.view-buttons button:hover,
.view-buttons button.highlight,
.highlight {
  transition: all 0.3s ease;
  filter: grayscale(0%) opacity(1);
  -webkit-box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.5);
}

.fas.highlight {
  box-shadow: none;
}

h3 {
  transition: all 0.3s ease;
  cursor: pointer;
}

h3:hover {
  transition: all 0.3s ease;
  color: var(--main2);
  transform: scale(1.05);
}

.blank-card {
  width: 250px;
  height: 350px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: rgb(2, 0, 36);
  background: linear-gradient(
    128deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(30, 61, 102, 0.2) 0%,
    rgba(41, 101, 115, 0.2) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
}

.showCards {
  opacity: 1;
  transition: all 0.5s ease;
}

.fixed-button-add {
  display: none;
}

.card-list {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}

/* MOBILE BLOCK */

.mobile-block {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  font-size: 2rem;
  color: white;
  z-index: 1000;
  padding: 10%;
}

@media only screen and (max-width: 1080px) {
  /* .mobile-block {
    display: flex;
    text-align: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
  } */

  .collection-section {
    display: none;
  }

  .collection-info {
    margin-top: 70px;
    max-width: 95vw;
  }

  .collection-info h2 {
    font-size: 0.8rem;
    max-height: unset;
  }

  .fixed-button-add {
    display: flex;
  }

  .main-container {
    width: 100%;
    z-index: 900;
    padding-top: 60px;
  }

  .small-cards {
    justify-content: center;
    z-index: unset;
  }

  .card-list {
    display: flex;
    flex-direction: column;
    right: 0;
    width: 100%;
    height: auto;
    padding: 0px 0px 110px 0px;
  }

  .card-list.editing,
  .small-cards.editing {
    display: none;
  }

  .slide-sheet,
  .flex-print-button {
    display: none;
  }

  .main-container {
    padding: 0;
  }

  .secondary-container {
    margin: 0px;
  }

  .editor-overlay {
    display: none;
  }
}

/* PRINT */

@media print {
  .print-buttons {
    display: none;
  }

  .blank-card {
    display: none;
  }

  .main-container,
  .secondary-container {
    padding: 0;
    margin: 0 auto;
  }

  .small-cards.print {
    position: absolute;
    padding: 0px;
    margin: 0px;
    left: 0px;
    top: 0px;
    transform: scale(1);
    height: 700px;
    overflow: hidden;
    box-shadow: none;
  }

  .highlight {
    transform: scale(1);
    box-shadow: none;
  }

  .menu-bar,
  .view-buttons {
    display: none;
  }
}
</style>
