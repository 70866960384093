<template>
  <transition name="fade">
    <BaseDialog
      v-if="deletePrompt"
      title="Are You Sure?"
      :cancellable="true"
      @close="deletePrompt = false"
      @okay="(deletePrompt = false), deleteCollection()"
    >
      <template #content>
        Are you sure you want to delete this collection?
      </template>
    </BaseDialog>
  </transition>
  <div
    @click.self="selectCollection(collectionID)"
    @mouseleave="editing = false"
    class="menu-row space-between collection"
    :class="{
      highlight: selected,
    }"
  >
    <div class="menu-row" v-if="selected && editing">
      <input
        type="text"
        v-model.lazy="collectionName"
        :placeholder="collectionName"
        minlength="1"
        maxlength="25"
        v-on:keyup.enter="editing = false"
      />
    </div>
    <h4 v-else @click.self="selectCollection(collectionID)">
      {{ collectionName }}
      <transition name="fade">
        <div class="tooltip-top" v-if="showTooltipRename">Change Name</div>
      </transition>
    </h4>
    <div
      v-if="selected && !editing"
      :class="{ pulsing: tutorial && tutorialStep == 14 }"
    >
      <transition name="tooltip">
        <div class="tooltip-top" v-if="tutorial && tutorialStep == 14">
          Print or Delete collection
        </div>
      </transition>
      <i
        class="fas fa-print"
        @click="this.$emit('printCollection')"
        :class="{
          highlight: collectionID == selectedCollectionID,
        }"
        @mouseover="showTooltipPrint = true"
        @mouseleave="showTooltipPrint = false"
      >
        <transition name="fade">
          <div class="tooltip-top" v-if="showTooltipPrint">
            Print collection
          </div>
        </transition>
      </i>
      <i
        class="fas fa-trash"
        :class="{
          highlight: collectionID == selectedCollectionID,
        }"
        @click="deletePrompt = true"
        @mouseover="showTooltipDelete = true"
        @mouseleave="showTooltipDelete = false"
      >
        <transition name="fade">
          <div class="tooltip-top" v-if="showTooltipDelete">
            Delete collection
          </div>
        </transition></i
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BaseDialog from "./BaseDialog.vue";
export default {
  name: "Collection",
  components: {
    BaseDialog,
  },
  props: {
    collectionID: Number,
    collectionNumber: Number,
    tutorial: Boolean,
    tutorialStep: Number,
  },
  emits: ["deleteCollection", "printCollection", "loadingCollection"],
  data() {
    return {
      editing: false,
      showButtons: false,
      deletePrompt: false,
      showTooltipRename: false,
      showTooltipPrint: false,
      showTooltipDelete: false,
    };
  },
  computed: {
    ...mapState(["collections", "selectedCollectionID"]),
    selected() {
      return this.collectionID == this.selectedCollectionID;
    },
    collectionName: {
      get() {
        return this.collections[this.collectionNumber].name;
      },
      set(collectionName) {
        if (collectionName.length > 0) {
          this.$store.commit("updateCollectionName", {
            collectionID: this.collectionID,
            collectionName: collectionName,
          });
          this.$store.dispatch("saveCollections");
        } else {
          alert("Your Collection Needs a Name!");
        }
      },
    },
  },
  methods: {
    deleteCollection() {
      var collectionIndex = this.collections.findIndex(
        (collection) => collection.collectionID == this.collectionID
      );
      var newCollectionIndex = 0;
      if (this.collections.length > 1) {
        if (collectionIndex > 0) {
          newCollectionIndex = collectionIndex - 1;
          this.$store.dispatch("updateSelectedCollection", {
            collectionID: this.collections[newCollectionIndex].collectionID,
          });
        } else if (collectionIndex == 0) {
          newCollectionIndex = collectionIndex + 1;
          this.$store.dispatch("updateSelectedCollection", {
            collectionID: this.collections[newCollectionIndex].collectionID,
          });
        }
        this.$store.commit("deleteCollection", {
          collectionID: this.collectionID,
        });
        this.$store.dispatch("saveCollections");
        if (this.$store.state.cards.length == 0) {
          this.$store.commit("loadCards", {
            cards: this.$store.state.collections[newCollectionIndex].cards,
          });
        }
      } else {
        alert("You need a collection!");
      }
    },
    async selectCollection(collectionID) {
      if (this.selected) {
        this.editing = true;
      } else {
        this.$store.dispatch("saveCards", {
          collectionID: this.selectedCollectionID,
        });
        const wait = (timeToDelay) =>
          new Promise((resolve) => setTimeout(resolve, timeToDelay));
        this.$emit("loadingCollection");
        await wait(200);
        this.$store.dispatch("updateSelectedCollection", {
          collectionID: collectionID,
        });
      }
    },
  },
};
</script>

<style scoped>
.fas {
  font-size: 1rem;
  padding: 0 0.25rem;
  color: var(--main2);
  filter: grayscale(100%) opacity(0.9);
  transition: all 500ms ease;
  cursor: pointer;
}

.collection {
  font-size: 0.6rem;
  color: #a7a7a7;
  padding: 10px 5px;
  border-bottom: 1px solid darkgrey;
  position: relative;
  cursor:pointer;
  font-size:0.8rem;
  z-index:100;
}

.collection.highlight {
  background: var(--dark);
  border-radius:5px;
}

.highlight,
.highlight.fas {
  filter: grayscale(0%) opacity(1);
  transition: all 500ms ease;
  color: #ffffff;
  font-weight: 600;
}

.fa-edit:hover,
.fa-print:hover {
  color: var(--main2);
  filter: grayscale(0%) opacity(1);
  transition: all 500ms ease;
}

.fa-trash:hover {
  color: red;
  filter: grayscale(0%) opacity(1);
  transition: all 500ms ease;
}

.okay-click {
  margin: 0 0.25rem;
  height: 1rem;
}

input {
  background: rgba(119, 118, 128, 0.411);
}


@media only screen and (max-width: 767px) {
  .fa-print {
    display:none;
  }
}

</style>