<template>
  <div class="menu-row"><h3>Image:</h3> 
<div class="menu-row">
    <div class="checkbox rounded" @click="image = !image">
      <i :class="{ white: image }" class="fas fa-check"></i>
    </div>
    <transition name="width">
      <select
        class="rounded"
        name="selectImage"
        ref="selectImage"
        v-model="imageSRC"
        v-if="!showLink"
      >
        <option v-for="image in imageChoices" :key="image.index">
          {{ image.replace("./", "") }}
        </option>
      </select>
    </transition>
    <transition name="width">
      <input
        v-if="showLink"
        type="text"
        v-model.lazy="imageURL"
        ref="imageURL"
        placeholder="Import image from URL"
        @blur="showLink = imageURL ? true : false"
      />
    </transition>
    <i
      class="fas fa-link rounded"
      @click="(showLink = !showLink), focusURL()"
      :class="{ highlight: imageURL && imageURL.length > 0 }"
      @mouseover="showTooltipLink = true"
      @mouseleave="showTooltipLink= false"
    ><transition name="tooltip">
    <div class="tooltip-top" v-if="showTooltipLink == true">Add Image URL. If the image doesn't load, try hosting it on a service like imgur.com</div></transition>
    </i>
  </div></div>
</template>

<script>
export default {
  name: "ImageSelect",
  props: ["cardID", "card"],
  data() {
    return {
      showLink: false,
      showTooltipLink: false
    };
  },
  methods: {
    focusURL() {
      setTimeout(() => {
        this.$nextTick(() => this.$refs.imageURL.focus());
      }, 200);
    },
  },
  computed: {
    imageChoices() {
      return this.card.imageChoices;
    },
    image: {
      get() {
        if (this.card.cardBack) {
          return this.card.cardBackImage
        } else return this.card.image;
      },
      set(image) {
        this.$store.commit("updateImage", {
          cardID: this.cardID,
          image: image,
        });
        if (this.image && !this.imageSRC) {
          this.imageSRC = this.imageChoices[
            Math.floor(Math.random() * Math.floor(this.imageChoices.length))
          ].replace("./", "");
        }
      },
    },
    imageURL: {
      get() {
        return this.card.imageURL;
      },
      set(imageURL) {
        this.$store.commit("updateImageURL", {
          cardID: this.cardID,
          imageURL: imageURL,
        });
      },
    },
    imageSRC: {
      get() {
        return this.card.imageSRC;
      },
      set(imageSRC) {
        this.$store.commit("updateImageSRC", {
          cardID: this.cardID,
          imageSRC: imageSRC,
        });
      },
    },
  },
};
</script>

<style scoped>

.filter-element {
    font-size: 0.8rem;
    padding: 3px;
    max-height: 1.5rem;
    color: black;
    margin: 0px 2px;
}

.fa-link {
  margin-left: 0.25rem;
  height: 1.4rem;
  line-height: 1.2rem;
  padding: 0.1rem 0.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  position:relative;
}

.fa-check {
  color: transparent;
  transition: all 0.3s ease;
  cursor: pointer;
}

.fa-check.white {
  color: #fff;
  transition: all 0.3s ease;
}

.fa-link:hover,
.highlight {
  color: var(--main2);
  background-color: var(--dark);
}

.width-enter-active {
  transition: all 0.2s ease;
}

.width-leave-active {
  transition: all 0.2s ease;
}

.width-enter-from,
.width-leave-to {
  width: 0;
}
</style>