<template>
  <div class="menu-row">
    <h3>Name:</h3>
    <input type="text" v-model="customName" :placeholder="placeholder" class="rounded"/>
                      <!-- <i
      class="fas rounded"
       @click="(locked = !locked)"
      :class="{ highlight: locked, 'fa-lock': locked, 'fa-unlock': !locked  }"
    ></i> -->
  </div>
</template>

<script>
export default {
  name: "CardMenuNameSelect",
  props: ["cardID", "card"],
    data() {
    return {
      locked: false
    };
  },
  computed: {
    placeholder() {
      return this.card.itemName
    },
    customName: {
      get() {
        return this.card.customName;
      },
      set(customName) {
        this.$store.commit("updateCustomName", {
          cardID: this.cardID,
          customName: customName,
        });
      },
    },
  },
};
</script>

<style scoped>

.fas {
  margin-left: 0.25rem;
  height: 1.4rem;
  line-height: 1.2rem;
  padding: 0.1rem 0.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fa:hover,
.highlight {
  color: var(--main2);
  background-color: var(--dark);
}


</style>