import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  getDatabase,
  onValue,
  push,
  ref,
  remove,
  set,
} from "firebase/database";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

export default {
  loadItems(context) {
    if (firebase.auth().currentUser) {
      const itemsRef = ref(
        getDatabase(),
        "users/" + context.state.user.data.uid + "/customItems/"
      );
      onValue(itemsRef, (snapshot) => {
        var items = [];
        snapshot.forEach((item) => {
          items.push(item.val());
          context.state.customItems = items;
        });
      });
    }
  },
  loadPantheons(context) {
    if (firebase.auth().currentUser) {
      const pantheonsRef = ref(
        getDatabase(),
        "users/" + context.state.user.data.uid + "/pantheons/"
      );
      onValue(pantheonsRef, (snapshot) => {
        var pantheons = [];
        snapshot.forEach((pantheon) => {
          pantheons.push(pantheon.val());
          context.state.pantheons = pantheons;
        });
      });
    }
  },
  loadShops(context) {
    if (firebase.auth().currentUser) {
      const shopsRef = ref(
        getDatabase(),
        "users/" + context.state.user.data.uid + "/customShops/"
      );
      onValue(shopsRef, (snapshot) => {
        var shops = [];
        snapshot.forEach((shop) => {
          shops.push(shop.val());
          context.state.customShops = shops.sort((a, b) =>
            a.name < b.name ? -1 : b.name > a.name ? 1 : 0
          );
        });
      });
    }
  },
  saveItem(context, newItem) {
    if (context.state.customItems.some((item) => item.name == newItem.name)) {
      context.state.customItems.unshift(newItem);
      const seen = new Set();
      const customItems = context.state.customItems.filter((item) => {
        const duplicate = seen.has(item.name);
        seen.add(item.name);
        return !duplicate;
      });
      context.state.customItems = customItems;
      if (firebase.auth().currentUser) {
        set(
          ref(
            getDatabase(),
            "users/" + context.state.user.data.uid + "/customItems/"
          ),
          customItems
        );
      }
    } else {
      if (firebase.auth().currentUser) {
        set(
          ref(
            getDatabase(),
            "users/" +
              context.state.user.data.uid +
              "/customItems/" +
              newItem.name
          ),
          newItem
        )
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      }
    }
  },
  editShopName(context, newName) {
    context.state.customShops.find(
      (shop) => shop.name == context.state.selectedShop.name
    ).name = newName;
    context.state.selectedShop.name == newName;
    if (firebase.auth().currentUser) {
      set(
        ref(
          getDatabase(),
          "users/" + context.state.user.data.uid + "/customShops/"
        ),
        context.state.customShops
      );
    }
  },
  editShopDescription(context, newDescription) {
    context.state.selectedShop.description = newDescription;
    context.dispatch("saveShop", context.state.selectedShop);
  },
  editShopkeeperDescription(context, newDescription) {
    context.state.selectedShop.shopkeeper = newDescription;
    context.dispatch("saveShop", context.state.selectedShop);
  },
  addToShop(context, { item, shopName }) {
    let shop = context.state.customShops.find((shop) => shop.name == shopName);
    shop.inventory.push(item);
    context.state.selectedShop.inventory = shop.inventory;
    context.dispatch("saveShop", shop);
  },
  removeFromShop(context, itemName) {
    // let shop = context.state.customShops.find(shop => shop.name == context.state.selectedShop.name)
    // shop.inventory = shop.inventory.filter((item) => item.name != itemName)
    context.state.selectedShop.inventory =
      context.state.selectedShop.inventory.filter(
        (item) => item.name != itemName
      );
    let shop = context.state.customShops.find(
      (shop) => shop.name == context.state.selectedShop.name
    );
    shop = context.state.selectedShop;
    context.dispatch("saveShop", shop);
  },
  async saveShop(context, newShop) {
    if (context.state.customShops.some((shop) => shop.name == newShop.name)) {
      context.state.customShops.unshift(newShop);
      const seen = new Set();
      let customShops = context.state.customShops.filter((shop) => {
        const duplicate = seen.has(shop.name);
        seen.add(shop.name);
        return !duplicate;
      });
      customShops = customShops.sort((a, b) =>
        a.name > b.name ? -1 : b.name < a.name ? 1 : 0
      );
      context.state.customShops = customShops;
      if (firebase.auth().currentUser) {
        set(
          ref(
            getDatabase(),
            "users/" + context.state.user.data.uid + "/customShops/"
          ),
          customShops
        );
      }
    } else {
      context.state.customShops.push(newShop);
      if (firebase.auth().currentUser) {
        push(
          ref(
            getDatabase(),
            "users/" + context.state.user.data.uid + "/customShops/"
          ),
          newShop
        )
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      }
    }
    return true;
  },
  deleteItem(context, item) {
    const customItems = context.state.customItems.filter(
      (x) => x.name !== item
    );
    // firebase
    //   .database()
    //   .ref("users/" + context.state.user.data.uid + "/customItems")
    //   .set(customItems);
    set(
      ref(
        getDatabase(),
        "users/" + context.state.user.data.uid + "/customItems/"
      ),
      customItems
    );
    context.state.customItems = customItems;
  },
  deletePantheon(context, pantheonID) {
    const pantheons = context.state.pantheons.filter(
      (pantheon) => pantheon.id !== pantheonID
    );
    context.state.selectedPantheon &&
    context.state.selectedPantheon.id == pantheonID
      ? (context.state.selectedPantheon = null)
      : "";
    if (firebase.auth().currentUser) {
      set(
        ref(
          getDatabase(),
          "users/" + context.state.user.data.uid + "/pantheons/"
        ),
        pantheons
      );
    }
    context.state.pantheons = pantheons;
  },
  deleteShop(context, shopName) {
    const customShops = context.state.customShops.filter(
      (shop) => shop.name !== shopName
    );
    context.state.selectedShop && context.state.selectedShop.name == shopName
      ? (context.state.selectedShop = null)
      : "";
    if (firebase.auth().currentUser) {
      set(
        ref(
          getDatabase(),
          "users/" + context.state.user.data.uid + "/customShops/"
        ),
        customShops
      );
    }
    context.state.customShops = customShops;
  },

  // LOADING ACTIONS

  // LOADING COLLECTIONS FROM FIREBASE

  async loadCollections(context) {
    if (firebase.auth().currentUser) {
      const userId = firebase.auth().currentUser.uid;
      const collectionsRef = ref(
        getDatabase(),
        "users/" + userId + "/collections"
      );
      onValue(collectionsRef, (snapshot) => {
        const collections = snapshot.val();
        if (collections != null && context.state.loaded == false) {
          context.commit("loadCollections", { collections: collections });
        } else if (context.state.loaded == false) {
          context.dispatch("addCollection");
        }
        if (collections == null) {
          const cardsRef = ref(getDatabase(), "users/" + userId + "/cards");
          cardsRef.on("value", (snapshot) => {
            const cards = snapshot.val();
            if (cards != null) {
              context.commit("loadCards", { cards: cards });
              context.dispatch("saveCards", { collectionID: 1 });
            }
          });
        }
      });
    } else if (
      context.state.loaded == false &&
      context.state.collections.length == 0
    ) {
      context.dispatch("addCollection");
    }
    return true;
  },

  // LOADING CARDS FROM FIREBASE

  async loadCards(context, { collectionID }) {
    if (firebase.auth().currentUser) {
      const userId = context.state.user.data.uid;
      const collections = context.state.collections;
      const collection = collections.find(
        (collection) => collection.collectionID == collectionID
      );
      const collectionNumber = collections.indexOf(collection);
      const cardsRef = ref(
        getDatabase(),
        "users/" + userId + "/collections/" + collectionNumber + "/cards"
      );
      onValue(cardsRef, (snapshot) => {
        const cards = snapshot.val();

        if (cards != null) {
          context.commit("loadCards", { cards: cards });
        } else {
          context.dispatch("resetCards");
        }
      });
    }
  },

  // SAVE ACTIONS

  deleteUserItem(context, { item }) {
    var userItems = context.state.userItems.filter((x) => x.name !== item);
    context.commit("SET_USERITEMS", userItems);
    if (firebase.auth().currentUser) {
      try {
        const userID = context.state.user.data.uid;
        if (userID) {
          set(ref(getDatabase(), "users/" + userID + "/userItems/"), userItems);
          return true;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }
  },
  async saveToUserItems(context, item) {
    if (context.state.userItems) {
      var userItems = [...context.state.userItems];
    } else {
      userItems = [];
    }
    if (userItems.some((userItem) => userItem.name == item.name)) {
      const indexOfDuplicate = userItems.findIndex(
        (userItem) => userItem.name == item.name
      );
      userItems[indexOfDuplicate] = item;
    } else {
      userItems.push(item);
    }
    context.commit("SET_USERITEMS", userItems);
    if (firebase.auth().currentUser) {
      try {
        const userID = context.state.user.data.uid;
        if (userID) {
          set(ref(getDatabase(), "users/" + userID + "/userItems/"), userItems);
          return true;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }
    return true;
  },
  async saveCollections(context) {
    if (firebase.auth().currentUser) {
      try {
        const collections = context.state.collections;
        const userID = context.state.user.data.uid;
        if (userID) {
          // firebase
          //   .database()
          //   .ref("users/" + userID + "/collections")
          //   .set(collections);
          set(
            ref(getDatabase(), "users/" + userID + "/collections/"),
            collections
          );
          return true;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }
  },
  async saveCards(context, { collectionID }) {
    const cards = context.state.cards;
    if (firebase.auth().currentUser) {
      try {
        // const premium = context.state.premium;
        // if (premium) {
        const userID = context.state.user.data.uid;
        const collection = context.state.collections.find(
          (collection) => collection.collectionID == collectionID
        );
        const collectionIDs = context.state.collections.map(
          (collection) => collection.collectionID
        );
        const collectionIndex = collectionIDs.indexOf(collectionID);
        const collectionName = collection.name;
        set(
          ref(
            getDatabase(),
            "users/" + userID + "/collections/" + collectionIndex
          ),
          {
            cards: cards,
            collectionID: collectionID,
            name: collectionName,
          }
        );
        context.dispatch("updateSelectedCollection", {
          collectionID: collectionID,
        });
        context.commit("loadCards", { cards: cards });

        return true;
        // } else {
        //   return false;
        // }
      } catch (err) {
        console.log(err);
        return false;
      }
    } else {
      context.state.collections.find(
        (collection) => collection.collectionID == collectionID
      ).cards = cards;
    }
  },

  // ADD CARDS & COLLECTIONS

  async addCollection(context) {
    var collectionID = 1;
    if (
      typeof context.state.collections == "undefined" ||
      context.state.collections.length < 1
    ) {
      context.state.collections = [];
    } else {
      const collectionIDs = context.state.collections.map(
        (collection) => collection.collectionID
      );
      collectionID = Math.max(...collectionIDs) + 1;
    }
    var newCollection = {
      collectionID: collectionID,
      name: "New Collection " + collectionID,
      cards: [
        // {
        //   cardID: 0,
        //   cardType: "Custom",
        //   selectedType: "Armor",
        //   selectedSubtype: "Any",
        //   image: false,
        //   imageChoices: require
        //     .context("@/assets/item_images/armor/", true, /^.*\.png$/)
        //     .keys(),
        //   imageSRC: require
        //     .context("@/assets/item_images/armor/", true, /^.*\.png$/)
        //     .keys()[0],
        //   imageURL: "",
        //   armorBonus: "–",
        //   weaponBonus: "–",
        //   saveBonus: "–",
        //   spellBonus: "–",
        //   itemName: "Armor",
        //   selectedAttunement: "No",
        //   selectedRarity: "Common",
        // },
      ],
    };
    context.state.collections.push(newCollection);
    if (context.state.user.loggedIn) {
      context.dispatch("saveCollections");
      context.dispatch("updateSelectedCollection", {
        collectionID: collectionID,
      });
    } else {
      context.dispatch("updateSelectedCollection", {
        collectionID: collectionID,
      });
    }
    return true;
  },

  /// ADD BLANK CARD

  async addCard(context) {
    try {
      var cardID = 0;
      if (
        typeof context.state.cards == "undefined" ||
        context.state.cards.length < 0 ||
        !context.state.cards[0]
      ) {
        context.state.cards = [];
      } else {
        const cardIDs = context.state.cards.map((card) => card.cardID);
        cardID = Math.max(...cardIDs) + 1;
      }
      var cardType = "Custom";
      var newCard = {
        cardID: cardID,
        cardType: cardType,
        selectedType: "Armor",
        selectedSubtype: "Any",
        image: false,
        imageChoices: require
          .context("@/assets/item_images/armor/", true, /^.*\.png$/)
          .keys(),
        imageSRC: "",
        imageURL: "",
        armorBonus: "–",
        weaponBonus: "–",
        saveBonus: "–",
        spellBonus: "–",
        itemName: "Armor",
        selectedAttunement: "No",
        selectedRarity: "Common",
      };
      context.state.cards.push(newCard);
      if (context.state.user.loggedIn) {
        context.dispatch("saveCards", {
          collectionID: context.state.selectedCollectionID,
        });
      }
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  /// COPY EXISTING CARD

  async copyCard(context, { cardID }) {
    try {
      var newCardID = 0;
      if (
        typeof context.state.cards == "undefined" ||
        context.state.cards.length < 0 ||
        !context.state.cards[0]
      ) {
        context.state.cards = [];
      } else {
        const cardIDs = context.state.cards.map((card) => card.cardID);
        newCardID = Math.max(...cardIDs) + 1;
      }

      var card = context.state.cards.find((card) => card.cardID == cardID);
      var newCard = { ...card, cardID: newCardID };

      context.state.cards.push(newCard);

      if (context.state.user.loggedIn) {
        context.dispatch("saveCards", {
          collectionID: context.state.selectedCollectionID,
        });
      }
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  updateSelectedCollection(context, { collectionID }) {
    if (
      context.state.selectedCollectionID &&
      context.state.selectedCollectionID == collectionID
    ) {
      return false;
    } else {
      context.commit("endEdit");
      if (
        context.state.collections.some(
          (collection) => collection.collectionID == collectionID
        )
      ) {
        context.commit("updateSelectedCollection", {
          collectionID: collectionID,
        });
      } else {
        collectionID = context.state.collections[0].collectionID;
        context.commit("updateSelectedCollection", {
          collectionID: collectionID,
        });
      }
      if (firebase.auth().currentUser) {
        context.dispatch("loadCards", {
          collectionID: collectionID,
        });
      } else {
        context.commit("loadCards", {
          cards: context.state.collections.find(
            (collection) => collection.collectionID == collectionID
          ).cards,
        });
      }
      return true;
    }
  },
  deleteCard(context, { cardID }) {
    context.commit("deleteCard", { cardID: cardID });
    context.dispatch("saveCards", {
      collectionID: context.state.selectedCollectionID,
    });
  },

  checkDateInPast(context, { firstDate, secondDate }) {
    if (firstDate.setHours(0, 0, 0, 0) >= secondDate.setHours(0, 0, 0, 0)) {
      return true;
    }
    return false;
  },
  checkPatreonKey(context, patreonKey) {
    return new Promise((resolve) => {
      var lock = "";
      const lockRef = ref(getDatabase(), "patreonKeys");
      onValue(lockRef, (snapshot) => {
        lock = snapshot.child("adventurer").val();
        resolve(lock == patreonKey);
      });
    });
  },
  patreonConnect(context, patreonKey) {
    const userID = context.state.user.data.uid;
    const lockRef = ref(getDatabase(), "patreonKeys");
    onValue(lockRef, (snapshot) => {
      var lock = snapshot.child("adventurer").val();
      var expiration = snapshot.child("expiration").val();
      if (lock === patreonKey) {
        context.commit("SET_PREMIUM", true);
        context.commit("SET_PREMIUM_EXPIRATION", expiration);
        context.commit("SET_PATREONKEY", patreonKey);
        set(ref(getDatabase(), "users/" + userID + "/premium"), true);
        set(
          ref(getDatabase(), "users/" + userID + "/premiumExpiration"),
          expiration
        );
        set(ref(getDatabase(), "users/" + userID + "/patreonKey"), patreonKey);
      } else {
        context.commit("SET_PREMIUM", false);
        context.commit("SET_PATREONKEY", patreonKey);
        set(ref(getDatabase(), "users/" + userID + "/premium"), false);
        set(ref(getDatabase(), "users/" + userID + "/patreonKey"), patreonKey);
      }
    });
  },
  itemTypeName(context, { cardID }) {
    const card = context.state.cards.find((card) => card.cardID == cardID);
    var selectedType = card.selectedType;
    var selectedSubtype = card.selectedSubtype;
    var itemTypeName = "";
    if (selectedSubtype == "Any" || selectedSubtype == "") {
      if (selectedType == "Wondrous item") {
        itemTypeName = "Item";
      } else {
        itemTypeName = selectedType;
      }
    } else if (selectedSubtype == "Crossbow, Hand") {
      itemTypeName = "Hand Crossbow";
    } else if (selectedSubtype == "Crossbow, Light") {
      itemTypeName = "Light Crossbow";
    } else if (selectedSubtype == "Crossbow, Heavy") {
      itemTypeName = "Heavy Crossbow";
    } else {
      itemTypeName = selectedSubtype;
    }
    return itemTypeName;
  },
  async itemName(context, { cardID }) {
    const card = context.state.cards.find((card) => card.cardID == cardID);
    if (typeof card.customName != "undefined" && card.customName.length > 0) {
      return card.customName;
    } else {
      if (typeof card.selectedItem != "undefined" && card.cardType === "srd") {
        var itemName = card.selectedItem.name;
        if (itemName == "Dragon Scale Mail") {
          itemName = card.selectedOptions[0].name + " Dragon Scale Mail";
        }
        if (itemName.startsWith("Spell Scroll (")) {
          itemName = "Spell Scroll of " + card.selectedOptions[0].name;
        }
        if (itemName.includes("Weapon") && card.selectedSubtype != "Any") {
          itemName = itemName.replace(/Weapon/g, card.selectedSubtype);
        }
        if (itemName.includes("Armor") && card.selectedSubtype != "Any") {
          itemName = itemName.replace(/Armor/g, card.selectedSubtype);
        }
        return itemName;
      } else {
        var prefix = "";
        var suffix = "";
        if (typeof card.selectedEnchantments != "undefined") {
          var selectedEnchantments = card.selectedEnchantments;

          // Define Prefix
          if (
            typeof selectedEnchantments[0] != "undefined" &&
            (selectedEnchantments[0].type == "Prefix" ||
              selectedEnchantments[0].type == "Class")
          ) {
            prefix =
              selectedEnchantments[0].name.replace(", Greater", "") + " ";
          } else if (
            typeof selectedEnchantments[1] != "undefined" &&
            (selectedEnchantments[1].type == "Prefix" ||
              selectedEnchantments[1].type == "Class")
          ) {
            prefix =
              selectedEnchantments[1].name.replace(", Greater", "") + " ";
          } else if (
            typeof selectedEnchantments[2] != "undefined" &&
            (selectedEnchantments[2].type == "Prefix" ||
              selectedEnchantments[2].type == "Class")
          ) {
            prefix =
              selectedEnchantments[2].name.replace(", Greater", "") + " ";
          } else {
            prefix = "";
          }

          // Define Suffix

          if (
            typeof selectedEnchantments[0] != "undefined" &&
            selectedEnchantments[0].type === "Suffix"
          ) {
            suffix =
              " " +
              selectedEnchantments[0].ofthe +
              " " +
              selectedEnchantments[0].name.replace(", Greater", "");
          } else if (
            typeof selectedEnchantments[1] != "undefined" &&
            selectedEnchantments[1].type === "Suffix"
          ) {
            suffix =
              " " +
              selectedEnchantments[1].ofthe +
              " " +
              selectedEnchantments[1].name.replace(", Greater", "");
          } else if (
            typeof selectedEnchantments[2] != "undefined" &&
            selectedEnchantments[2].type === "Suffix"
          ) {
            suffix =
              " " +
              selectedEnchantments[2].ofthe +
              " " +
              selectedEnchantments[2].name.replace(", Greater", "");
          }

          if (prefix == "" && suffix == "") {
            if (
              typeof selectedEnchantments[0] != "undefined" &&
              selectedEnchantments[0].type === "Common"
            ) {
              prefix = selectedEnchantments[0].name + " ";
            } else if (
              typeof selectedEnchantments[1] != "undefined" &&
              selectedEnchantments[1].type === "Common"
            ) {
              prefix = selectedEnchantments[1].name + " ";
            } else if (
              typeof selectedEnchantments[2] != "undefined" &&
              selectedEnchantments[2].type === "Common"
            ) {
              prefix = selectedEnchantments[2].name + " ";
            } else {
              prefix = "";
            }
            if (
              typeof selectedEnchantments[0] != "undefined" &&
              selectedEnchantments[0].type === "Curse"
            ) {
              suffix = " of " + selectedEnchantments[0].name;
            } else if (
              typeof selectedEnchantments[1] != "undefined" &&
              selectedEnchantments[1].type === "Curse"
            ) {
              suffix = " of " + selectedEnchantments[1].name;
            } else if (
              typeof selectedEnchantments[2] != "undefined" &&
              selectedEnchantments[2].type === "Curse"
            ) {
              suffix = " of " + selectedEnchantments[2].name;
            } else {
              suffix = "";
            }
          }
        }

        var itemTypeName = await context.dispatch("itemTypeName", {
          cardID: card.cardID,
        });

        return prefix + itemTypeName + suffix;
      }
    }
  },
  async updateItem(context, { cardID, item }) {
    var selectedAttunement = "No";
    var image = false;
    var selectedRarity = "Common";
    if (
      typeof item.attunement != "undefined" &&
      item.attunement != "No" &&
      item.attunement != " "
    ) {
      selectedAttunement = "Yes";
    }
    if (typeof item.rarity != "undefined") {
      selectedRarity = item.rarity;
    }

    context.commit("updateItem", {
      cardID: cardID,
      item: item,
    });
    context.commit("updateItemName", { cardID: cardID, itemName: "" });
    context.commit("updateCustomName", {
      cardID: cardID,
      customName: item.name,
    });
    context.commit("updateCustomDescription", {
      cardID: cardID,
      customDescription: "",
    });
    if (item.options) {
      context.commit("updateOptions", {
        cardID: cardID,
        options: item.options,
      });

      for (var i = 0; i < item.options.length; i++) {
        context.commit("updateSelectedOption", {
          cardID: cardID,
          optionID: i,
          selectedOption:
            item.options[i][
              [Math.floor(Math.random() * Math.floor(item.options[i].length))]
            ],
        });
      }
    } else {
      context.commit("updateOptions", {
        cardID: cardID,
        options: [],
      });
    }

    if (item.description && item.description.length < 400) {
      image = true;
    }
    context.commit("updateImage", {
      cardID: cardID,
      image: image,
    });
    context.commit("updateItemType", {
      cardID: cardID,
      selectedType: item.type,
    });
    if (
      typeof item.subtype != "undefined" &&
      item.subtype &&
      item.subtype.length > 0
    ) {
      context.commit("updateItemSubtype", {
        cardID: cardID,
        selectedSubtype: item.subtype,
      });
    } else {
      context.commit("updateItemSubtype", {
        cardID: cardID,
        selectedSubtype: "Any",
      });
    }
    context.commit("updateRarity", {
      cardID: cardID,
      rarity: selectedRarity,
    });
    context.commit("updateAttunement", {
      cardID: cardID,
      attunement: selectedAttunement,
    });
    if (selectedAttunement === "Yes") {
      context.commit("updateAttunementBy", {
        cardID: cardID,
        attunementBy: item.attunement
          .replace("Requires attunement", "")
          .replace("Requires Attunement", ""),
      });
    }
    context.commit("updateImageChoices", {
      cardID: cardID,
      selectedType: item.type,
    });
    if (item.imageSRC && item.imageSRC.length > 0) {
      context.commit("updateImageSRC", {
        cardID: cardID,
        imageSRC: item.imageSRC,
      });
    } else {
      if (image) {
        context
          .dispatch("randomImage", { cardID: cardID })
          .then((randomImage) => {
            context.commit("updateImageSRC", {
              cardID: cardID,
              imageSRC: randomImage,
            });
          });
      }
    }
  },
  updateRarity(context, { cardID, rarity }) {
    context.commit("updateRarity", {
      cardID: cardID,
      rarity: rarity,
    });
  },
  async fetchSubscription() {
    const db = getFirestore();
    const subsRef = collection(
      db,
      "customers",
      firebase.auth().currentUser.uid,
      "subscriptions"
    );
    const subsQuery = query(
      subsRef,
      where("status", "in", ["trialing", "active", "past_due", "unpaid"])
    );
    let subscription = await getDocs(subsQuery).then((sub) =>
      sub.docs.length > 0 ? sub.docs[0].data() : null
    );
    return subscription;
  },

  async fetchUser(context, user) {
    context.commit("SET_LOGGED_IN", user !== null);

    if (user) {
      var subscription = await context.dispatch("fetchSubscription");
    }

    if (user) {
      var trialUser = context.state.srdtrial.includes(user.email);
    }

    if (user) {
      context.commit("SET_USER", {
        email: user.email,
        uid: user.uid,
        subscription: subscription,
      });

      const db = getDatabase();
      const userRef = ref(db, "users/" + user.uid);

      onValue(userRef, (snapshot) => {
        var premium = snapshot.child("premium").val();
        var expiration = snapshot.child("premiumExpiration").val();
        var patreonKey = snapshot.child("patreonKey").val();
        var tutorialDone = snapshot.child("tutorialDone").val();
        var expirationDate = new Date(expiration);
        var today = new Date();

        /// COMBINE USERITEMS AND CUSTOMITEMS

        /// CLEAN USERITEMS
        var userItems = snapshot.child("userItems").val()
          ? snapshot.child("userItems").val()
          : [];
        if (userItems) {
          userItems.forEach((item) => {
            if (!item.source) {
              item.source = item.collection
                ? item.collection == "custom"
                  ? "Custom"
                  : item.collection
                : "Custom";
              delete item.collection;
            }
            if (!item.value) {
              item.value = 0;
            }
            if (!item.subvalue) {
              item.subvalue = "";
            }
          });
        }

        /// CLEAN CUSTOMITEMS
        var customItems = snapshot.child("customItems").val();
        var customItemsArray = [];
        customItems
          ? (customItemsArray = Object.keys(customItems).map((key) => {
              return customItems[key];
            }))
          : null;

        if (customItemsArray.length > 0) {
          customItemsArray.forEach((item) => {
            item.description = item.description ? item.description : "";
            item.attunement = item.attunement ? item.attunement : "";
          });
        }

        if (firebase.auth().currentUser) {
          firebase;
          // .database()
          // .ref("users/" + context.state.user.data.uid + "/customItems")
          // .ref.remove();
          remove(
            ref(db, "users/" + context.state.user.data.uid + "/customItems")
          );
        }

        /// COMBINE

        userItems = customItemsArray
          ? userItems.concat(customItemsArray)
          : null;

        if (firebase.auth().currentUser) {
          try {
            const userID = context.state.user.data.uid;
            if (userID) {
              // firebase
              //   .database()
              //   .ref("users/" + userID + "/userItems")
              //   .set(userItems);
              set(ref(db, "users/" + userID + "/userItems"), userItems);
            }
          } catch (err) {
            console.log(err);
          }
        }

        context.commit("SET_USERITEMS", userItems);
        context.dispatch("loadItems");
        context.dispatch("loadPantheons");
        context.dispatch("loadShops");

        let trialExpiration = new Date("2021-09-01");

        if (
          trialUser &&
          context.dispatch("checkDateInPast", {
            firstDate: trialExpiration,
            secondDate: today,
          })
        ) {
          context.commit("SET_PREMIUM", true);
          context.commit("SET_PREMIUM_EXPIRATION", trialExpiration);
        } else {
          context
            .dispatch("checkDateInPast", {
              firstDate: expirationDate,
              secondDate: today,
            })
            .then((notExpired) => {
              if (
                (premium && notExpired) ||
                (subscription && subscription.status == "active")
              ) {
                context.commit("SET_PREMIUM", true);
                context.commit("SET_PREMIUM_EXPIRATION", expiration);
                premium ? context.commit("SET_PATREONKEY", patreonKey) : null;
              } else {
                context.commit("SET_PREMIUM", false);
                context.commit("SET_PREMIUM_EXPIRATION", null);
              }
            });
        }
        context.commit("SET_TUTORIALDONE", tutorialDone);
      });
    }
  },

  resetCards(context) {
    context.state.cards = [];
  },

  // UPDATE FUNCTIONS

  async updateCardType(context, { cardID, cardType }) {
    context.commit("updateCardType", { cardID: cardID, cardType: cardType });
    return true;
  },
  updateBonuses(
    context,
    { cardID, armorBonus, weaponBonus, saveBonus, spellBonus }
  ) {
    context.commit("updateBonus", (cardID, "armorBonus", armorBonus));
    context.commit("updateBonus", (cardID, "armorBonus", weaponBonus));
    context.commit("updateBonus", (cardID, "armorBonus", saveBonus));
    context.commit("updateBonus", (cardID, "armorBonus", spellBonus));
  },
  updateItemTypes(context, { cardID, itemType, itemSubtype }) {
    context.commit("updateItemType", {
      cardID: cardID,
      selectedType: itemType,
    });
    context.commit("updateItemSubtype", {
      cardID: cardID,
      selectedSubtype: itemSubtype,
    });
    context.commit("updateImageChoices", {
      cardID: cardID,
      selectedType: itemType,
    });
    return;
  },

  // RANDOM ACTIONS

  // PICK RANDOM IMAGE

  randomImage(context, { cardID }) {
    const card = context.state.cards.find((card) => card.cardID == cardID);
    const itemType = card.selectedType;
    var itemName = "";
    card.cardType == "enchantments"
      ? (itemName = card.itemName)
      : (itemName = card.selectedItem.name);
    var itemSubtype = card.selectedSubtype;
    const imageChoices = card.imageChoices;
    var imageSRC = "";
    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min);
    }
    if (itemSubtype == "Any") {
      if (itemName.includes("Belt")) {
        itemSubtype = "Belt";
      } else if (
        itemName.includes("Helm") ||
        itemName.includes("Circlet") ||
        itemName.includes("Headband")
      ) {
        itemSubtype = "Helmet";
      } else if (itemName.includes("Hat")) {
        itemSubtype = "Hat";
      } else if (
        itemName.includes("Shoe") ||
        itemName.includes("Slipper") ||
        itemName.includes("Boots")
      ) {
        itemSubtype = "Boots";
      } else if (
        itemName.includes("Cape") ||
        itemName.includes("Cloak") ||
        itemName.includes("Mantle") ||
        itemName.includes("Robe")
      ) {
        itemSubtype = "Cape";
      } else if (
        itemName.includes("Amulet") ||
        itemName.includes("Medallion") ||
        itemName.includes("Brooch") ||
        itemName.includes("Pendant")
      ) {
        itemSubtype = "Amulet";
      } else if (itemName.includes("Gauntlet")) {
        itemSubtype = "Gauntlets";
      } else if (itemName.includes("Glove")) {
        itemSubtype = "Gloves";
      } else if (itemName.includes("Tattoo")) {
        itemSubtype = "Tattoo";
      } else if (itemName.includes("Bracers")) {
        itemSubtype = "Bracers";
      }
    }
    switch (itemType) {
      case "Armor":
        switch (itemSubtype) {
          case "Padded Armor":
            imageSRC = "light_armor_4.png";
            break;
          case "Leather Armor":
            imageSRC = "light_armor_" + getRandomInt(1, 8) + ".png";
            break;
          case "Studded leather":
            imageSRC = "light_armor_" + getRandomInt(1, 8) + ".png";
            break;
          case "Hide Armor":
            imageSRC = "light_armor_" + getRandomInt(1, 8) + ".png";
            break;
          case "Chain shirt":
            imageSRC = "medium_armor_2.png";
            break;
          case "Scale mail":
            imageSRC = "medium_armor_" + getRandomInt(1, 8) + ".png";
            break;
          case "Breastplate":
            imageSRC = "medium_armor_" + getRandomInt(1, 8) + ".png";
            break;
          case "Half plate":
            imageSRC = "medium_armor_" + getRandomInt(1, 8) + ".png";
            break;
          case "Ring mail":
            imageSRC = "heavy_armor_" + getRandomInt(1, 7) + ".png";
            break;
          case "Chain mail":
            imageSRC = "heavy_armor_" + getRandomInt(1, 7) + ".png";
            break;
          case "Splint":
            imageSRC = "heavy_armor_" + getRandomInt(1, 7) + ".png";
            break;
          case "Plate":
            imageSRC = "heavy_armor_" + getRandomInt(1, 7) + ".png";
            break;
          case "Shield":
            imageSRC = "shield_" + getRandomInt(1, 7) + ".png";
            break;
          default:
            imageSRC = imageChoices[
              Math.floor(Math.random() * Math.floor(imageChoices.length))
            ].replace("./", "");
            break;
        }
        break;
      case "Weapon":
        switch (itemSubtype) {
          case "Club":
            imageSRC = "club_" + getRandomInt(1, 3) + ".png";
            break;
          case "Dagger":
            imageSRC = "dagger_" + getRandomInt(1, 7) + ".png";
            break;
          case "Greatclub":
            imageSRC = "club_" + getRandomInt(1, 3) + ".png";
            break;
          case "Handaxe":
            imageSRC = "axe_" + getRandomInt(4, 6) + ".png";
            break;
          case "Javelin":
            imageSRC = "spear_" + getRandomInt(1, 4) + ".png";
            break;
          case "Light Hammer":
            imageSRC = "hammer_" + getRandomInt(1, 3) + ".png";
            break;
          case "Mace":
            imageSRC = "club_" + getRandomInt(1, 3) + ".png";
            break;
          case "Quarterstaff":
            imageSRC = "quarterstaff_" + getRandomInt(1, 3) + ".png";
            break;
          case "Sickle":
            imageSRC = "sickle_" + getRandomInt(1, 5) + ".png";
            break;
          case "Spear":
            imageSRC = "spear_" + getRandomInt(1, 4) + ".png";
            break;
          case "Crossbow, Light":
            imageSRC = "crossbow_" + getRandomInt(1, 3) + ".png";
            break;
          case "Dart":
            imageSRC = "dart_" + getRandomInt(1, 3) + ".png";
            break;
          case "Shortbow":
            imageSRC = "bow_" + getRandomInt(1, 8) + ".png";
            break;
          case "Sling":
            imageSRC = "sling_1.png";
            break;
          case "Battleaxe":
            imageSRC = "axe_" + getRandomInt(1, 6) + ".png";
            break;
          case "Flail":
            imageSRC = "morningstar_1.png";
            break;
          case "Glaive":
            imageSRC = "polearm_" + getRandomInt(1, 4) + ".png";
            break;
          case "Greataxe":
            imageSRC = "axe_" + getRandomInt(1, 7) + ".png";
            break;
          case "Greatsword":
            imageSRC = "sword_" + getRandomInt(1, 6) + ".png";
            break;
          case "Halberd":
            imageSRC = "polearm_" + getRandomInt(1, 4) + ".png";

            break;
          case "Lance":
            imageSRC = "polearm_1.png";
            break;
          case "Longsword":
            imageSRC = "sword_" + getRandomInt(1, 15) + ".png";
            break;
          case "Maul":
            imageSRC = "hammer_" + getRandomInt(1, 3) + ".png";
            break;
          case "Morningstar":
            imageSRC = "morningstar_1.png";
            break;
          case "Pike":
            imageSRC = "polearm_1.png";
            break;
          case "Rapier":
            imageSRC = "sword_12.png";
            break;
          case "Scimitar":
            imageSRC = "sword_7.png";
            break;
          case "Shortsword":
            imageSRC = "sword_" + getRandomInt(1, 3) + ".png";
            break;
          case "Trident":
            imageSRC = "trident_1.png";
            break;
          case "War Pick":
            imageSRC = "sickle_" + getRandomInt(1, 5) + ".png";
            break;
          case "Warhammer":
            imageSRC = "hammer_" + getRandomInt(1, 3) + ".png";
            break;
          case "Crossbow, Hand":
            imageSRC = "crossbow_" + getRandomInt(1, 3) + ".png";
            break;
          case "Crossbow, Heavy":
            imageSRC = "crossbow_" + getRandomInt(1, 3) + ".png";
            break;
          case "Longbow":
            imageSRC = "bow_" + getRandomInt(1, 8) + ".png";
            break;
          default:
            imageSRC = imageChoices[
              Math.floor(Math.random() * Math.floor(imageChoices.length))
            ].replace("./", "");
            break;
        }
        break;
      case "Wondrous Item":
      case "Wondrous item":
        switch (itemSubtype) {
          case "Amulet":
            imageSRC = "amulet_" + getRandomInt(1, 6) + ".png";
            break;
          case "Anklet":
            imageSRC = "gem_1.png";
            break;
          case "Boots":
            imageSRC = "boots_" + getRandomInt(1, 13) + ".png";
            break;
          case "Belt":
            imageSRC = "belt_" + getRandomInt(1, 13) + ".png";
            break;
          case "Bracers":
            imageSRC = "bracers_" + getRandomInt(1, 20) + ".png";
            break;
          case "Brooch":
            imageSRC = "amulet_" + getRandomInt(1, 6) + ".png";
            break;
          case "Cape":
            imageSRC = "cloak_" + getRandomInt(1, 4) + ".png";
            break;
          case "Crown":
            imageSRC = "helmet_1.png";
            break;
          case "Gauntlets":
            imageSRC = "gauntlets_" + getRandomInt(1, 5) + ".png";
            break;
          case "Gloves":
            imageSRC = "bracers_" + getRandomInt(1, 17) + ".png";
            break;
          case "Greaves":
            imageSRC = "gauntlets_" + getRandomInt(1, 5) + ".png";
            break;
          case "Hat":
            imageSRC = "hat_1.png";
            break;
          case "Headband":
            imageSRC = "helmet_" + getRandomInt(1, 5) + ".png";
            break;
          case "Helmet":
            imageSRC = "helmet_" + getRandomInt(1, 5) + ".png";
            break;
          case "Hood":
            imageSRC = "hood_1.png";
            break;
          case "Mask":
            imageSRC = "mask_1.png";
            break;
          case "Mittens":
            imageSRC = "gauntlets_" + getRandomInt(1, 4) + ".png";
            break;
          case "Necklace":
            imageSRC = "gem_1.png";
            break;
          case "Nose Ring":
            imageSRC = "gem_1.png";
            break;
          case "Pants":
            imageSRC = "belt_" + getRandomInt(1, 12) + ".png";
            break;
          case "Robe":
            imageSRC = "cloak_" + getRandomInt(1, 4) + ".png";
            break;

          case "Sandals":
            imageSRC = "boots_" + getRandomInt(1, 13) + ".png";
            break;
          case "Slippers":
            imageSRC = "boots_" + getRandomInt(1, 13) + ".png";
            break;
          case "Tattoo":
            imageSRC = "tattoo_" + getRandomInt(1, 9) + ".png";
            break;
          default:
            imageSRC = imageChoices[
              Math.floor(Math.random() * Math.floor(imageChoices.length))
            ].replace("./", "");
            break;
        }
        break;
      default:
        imageSRC = imageChoices[
          Math.floor(Math.random() * Math.floor(imageChoices.length))
        ].replace("./", "");
        break;
    }
    return imageSRC;
  },

  /// PICK A RANDOM ITEM FROM THE SRD

  createRandomItemSRD(context, { cardID, itemOptions }) {
    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min);
    }

    if (itemOptions == undefined || itemOptions.length == 0) {
      itemOptions = [...context.state.itemsSRD];
    }
    const armors = [...context.state.armors];
    const weapons = [...context.state.weapons];

    const randomItem = { ...itemOptions[getRandomInt(0, itemOptions.length)] };
    if (randomItem.subtype == "Any") {
      var itemSubtype = "";
      switch (randomItem.type) {
        case "Armor":
          itemSubtype = armors[getRandomInt(0, armors.length)].name;
          break;
        case "Weapon":
          itemSubtype = weapons[getRandomInt(0, weapons.length)].name;
          break;
        default:
          break;
      }
      randomItem.subtype = itemSubtype;
    }
    if (randomItem.subtype == "Light, Medium, or Heavy") {
      itemSubtype =
        context.state.armors[getRandomInt(0, context.state.armors.length - 1)]
          .name;
      randomItem.subtype = itemSubtype;
    }

    context.dispatch("updateItem", {
      cardID: cardID,
      item: randomItem,
    });
    context
      .dispatch("itemName", { cardID: cardID })
      .then((itemName) =>
        context.commit("updateItemName", { cardID: cardID, itemName: itemName })
      );
    context.dispatch("itemName", { cardID: cardID }).then((itemName) =>
      context.commit("updateCustomName", {
        cardID: cardID,
        customName: itemName,
      })
    );
  },

  /// CREATE RANDOM ITEM ACTION

  createRandomItem(
    context,
    {
      cardID,
      nEnchantments1,
      nEnchantments2,
      rarities,
      itemTypes,
      attunementSelected,
      noattunementSelected,
    }
  ) {
    var randomEnchantments = context.state.enchantments.map((i) => ({
      ...i,
    }));
    var itemType = "";
    var itemSubtype = "";
    var n = 1;
    var rarity = "";

    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min);
    }

    // Filter Attunement
    if (!attunementSelected) {
      randomEnchantments = randomEnchantments.filter(
        (enchantment) => enchantment.attunement == "No"
      );
    }

    if (!noattunementSelected) {
      randomEnchantments = randomEnchantments.filter(
        (enchantment) => enchantment.attunement != "No"
      );
    }

    // Determine Item Type & SubType
    itemType = itemTypes[getRandomInt(0, itemTypes.length)];
    if (itemType == "Scroll") {
      itemType = "Armor";
    } else if (itemType == "Potion") {
      itemType = "Weapon";
    }
    switch (itemType) {
      case "Armor":
        itemSubtype =
          context.state.armors[getRandomInt(1, context.state.armors.length)]
            .name;
        randomEnchantments = randomEnchantments.filter(
          (enchantment) => enchantment.armor != "No"
        );
        if (
          context.state.armors.find((armor) => armor.name == itemSubtype)
            .medium == false &&
          context.state.armors.find((armor) => armor.name == itemSubtype)
            .heavy == false
        ) {
          randomEnchantments = randomEnchantments.filter(
            (enchantment) => enchantment.armor != "MediumHeavy"
          );
        }
        if (
          context.state.armors.find((armor) => armor.name == itemSubtype)
            .light == false
        ) {
          randomEnchantments = randomEnchantments.filter(
            (enchantment) => enchantment.armor != "Light"
          );
        }
        break;
      case "Weapon":
        itemSubtype =
          context.state.weapons[getRandomInt(1, context.state.weapons.length)]
            .name;
        randomEnchantments = randomEnchantments.filter(
          (enchantment) => enchantment.weapon != "No"
        );
        if (
          context.state.weapons.find((weapon) => weapon.name == itemSubtype)
            .ranged == false
        ) {
          randomEnchantments = randomEnchantments.filter(
            (enchantment) => enchantment.weapon != "Ranged"
          );
        }
        if (
          context.state.weapons.find((weapon) => weapon.name == itemSubtype)
            .melee == false
        ) {
          randomEnchantments = randomEnchantments.filter(
            (enchantment) => enchantment.weapon != "Melee"
          );
        }
        if (
          itemSubtype != "Any" &&
          context.state.weapons.find((weapon) => weapon.name == itemSubtype)
            .piercing == false &&
          context.state.weapons.find((weapon) => weapon.name == itemSubtype)
            .slashing == false
        ) {
          randomEnchantments = randomEnchantments.filter(
            (enchantment) => enchantment.weapons != "PiercingSlashing"
          );
        }
        break;
      case "Wondrous item":
        itemSubtype =
          context.state.wonders[getRandomInt(1, context.state.wonders.length)]
            .name;
        randomEnchantments = randomEnchantments.filter(
          (enchantment) => enchantment.wondrous != "No"
        );
        break;
      default:
        randomEnchantments = randomEnchantments.filter(
          (enchantment) => enchantment.wondrous != "No"
        );
    }

    var uncommonE = randomEnchantments.filter(
      (enchantment) => enchantment.rarity == 1
    );
    var rareE = randomEnchantments.filter(
      (enchantment) => enchantment.rarity == 2
    );
    var commonE = randomEnchantments.filter(
      (enchantment) => enchantment.type == "Common"
    );
    var classE = randomEnchantments.filter(
      (enchantment) => enchantment.type == "Class"
    );
    var curseE = randomEnchantments.filter(
      (enchantment) => enchantment.type == "Curse"
    );
    var prefixE = randomEnchantments.filter(
      (enchantment) => enchantment.type == "Prefix"
    );
    var suffixE = randomEnchantments.filter(
      (enchantment) => enchantment.type == "Suffix"
    );

    // Determine Rarity

    rarity = rarities[getRandomInt(0, rarities.length)];

    // Determine Number of Enchantments

    n = getRandomInt(Number(nEnchantments1), Number(nEnchantments2) + 1);

    switch (rarity) {
      case "Common":
        n = 1;
        break;
      case "Uncommon":
        if (n > 2) {
          n = 2;
        }
        break;
      case "Rare":
        if (n > 2) {
          n = 2;
        }
        break;
      case "Very Rare":
        if (n < 2) {
          n = 2;
        }
        break;
      case "Legendary":
        if (n < 2) {
          n = 2;
        }
        break;
    }

    // Choose Enchantments

    var rEnchantments = [];
    var re1 = [];
    var re2 = [];
    var re3 = [];

    switch (n) {
      case 1:
        switch (rarity) {
          case "Common":
            re1 = [...commonE];
            break;
          case "Uncommon":
            re1 = commonE.concat(uncommonE);
            break;
          case "Rare":
            re1 = commonE.concat(uncommonE, rareE);
            break;
        }
        rEnchantments.push(re1[getRandomInt(0, re1.length)]);
        rEnchantments.push({ name: "Choose Enchantment" });
        rEnchantments.push({ name: "Choose Enchantment" });
        break;
      case 2:
        switch (rarity) {
          case "Uncommon":
            re1 = commonE
              .concat(uncommonE)
              .filter((enchantment) => enchantment.type != "Suffix");
            rEnchantments.push(re1[getRandomInt(0, re1.length)]);
            re2 =
              rEnchantments[0].rarity == 0
                ? curseE
                    .concat(suffixE)
                    .filter((enchantment) => enchantment.rarity != 2)
                : curseE.concat(commonE);
            rEnchantments.push(re2[getRandomInt(0, re2.length)]);
            break;
          case "Rare":
            re1 = commonE
              .concat(uncommonE, rareE)
              .filter((enchantment) => enchantment.type != "Suffix");
            rEnchantments.push(re1[getRandomInt(0, re1.length)]);
            re2 = curseE
              .concat(suffixE)
              .filter(
                (enchantment) =>
                  enchantment.rarity <= 2 - rEnchantments[0].rarity
              );
            rEnchantments.push(re2[getRandomInt(0, re2.length)]);
            break;
          case "Very Rare":
            re1 = commonE.concat(prefixE, classE);
            rEnchantments.push(re1[getRandomInt(0, re1.length)]);
            re2 = curseE.concat(suffixE);
            rEnchantments.push(re2[getRandomInt(0, re2.length)]);
            break;
          case "Legendary":
            re1 = prefixE
              .concat(classE)
              .filter((enchantment) => enchantment.rarity != "1");
            rEnchantments.push(re1[getRandomInt(0, re1.length)]);
            re2 = suffixE.filter((enchantment) => enchantment.rarity != "1");
            rEnchantments.push(re2[getRandomInt(0, re2.length)]);
            break;
        }
        rEnchantments.push({ name: "Choose Enchantment" });
        break;
      case 3:
        switch (rarity) {
          case "Very Rare":
            attunementSelected
              ? (re1 = commonE
                  .concat(prefixE, classE)
                  .filter((enchantment) => enchantment.attunement != "No"))
              : (re1 = commonE.concat(prefixE, classE));
            rEnchantments.push(re1[getRandomInt(0, re1.length)]);
            noattunementSelected
              ? (re2 = prefixE
                  .concat(classE)
                  .filter((enchantment) => enchantment.attunement == "No"))
              : (re2 = prefixE.concat(classE));
            rEnchantments.push(re2[getRandomInt(0, re2.length)]);
            re3 = curseE.concat(suffixE);
            rEnchantments.push(re3[getRandomInt(0, re3.length)]);
            break;
          case "Legendary":
            re1 = prefixE.filter(
              (enchantment) =>
                enchantment.rarity != "1" && enchantment.attunement != "No"
            );
            rEnchantments.push(re1[getRandomInt(0, re1.length)]);
            re2 = prefixE
              .concat(classE)
              .filter(
                (enchantment) =>
                  enchantment.rarity != "1" && enchantment.attunement == "No"
              );
            rEnchantments.push(re2[getRandomInt(0, re2.length)]);
            re3 = suffixE.filter((enchantment) => enchantment.rarity != "1");
            rEnchantments.push(re3[getRandomInt(0, re3.length)]);
            break;
        }
        break;
    }

    var image = false;
    var rarityValue = 0;
    var descriptionLength = 0;
    for (var i = 0; i < rEnchantments.length; i++) {
      if (typeof rEnchantments[i] == "undefined") {
        rEnchantments[i] = { name: "Choose Enchantment" };
      } else if (
        typeof rEnchantments[i].rarity != "undefined" &&
        !isNaN(rEnchantments[i].rarity)
      ) {
        rarityValue = rarityValue + Number(rEnchantments[i].rarity);
        descriptionLength =
          descriptionLength + rEnchantments[i].description.length;
      }
    }

    if (descriptionLength < 500) {
      image = true;
    }

    var goalRarityValue = 0;

    switch (rarity) {
      case "Uncommon":
        goalRarityValue = 1;
        break;
      case "Rare":
        goalRarityValue = 2;
        break;
      case "Very Rare":
        goalRarityValue = 3;
        break;
      case "Legendary":
        goalRarityValue = 7;
        break;
    }

    var eBonus = goalRarityValue - rarityValue;

    context.commit("updateArmorBonus", {
      cardID: cardID,
      bonus: "—",
    });
    context.commit("updateWeaponBonus", {
      cardID: cardID,
      bonus: "—",
    });
    context.commit("updateSaveBonus", {
      cardID: cardID,
      bonus: "—",
    });
    context.commit("updateSpellBonus", {
      cardID: cardID,
      bonus: "—",
    });

    if (eBonus > 3) {
      eBonus = 3;
    }

    if (eBonus > 0) {
      switch (itemType) {
        case "Armor":
          context.commit("updateArmorBonus", {
            cardID: cardID,
            bonus: eBonus,
          });
          break;
        case "Ring":
          context.commit("updateSaveBonus", {
            cardID: cardID,
            bonus: eBonus,
          });
          break;
        case "Rod":
          context.commit("updateSpellBonus", {
            cardID: cardID,
            bonus: eBonus,
          });
          break;
        case "Staff":
          context.commit("updateSpellBonus", {
            cardID: cardID,
            bonus: eBonus,
          });
          break;
        case "Wand":
          context.commit("updateSpellBonus", {
            cardID: cardID,
            bonus: eBonus,
          });
          break;
        case "Weapon":
          context.commit("updateWeaponBonus", {
            cardID: cardID,
            bonus: eBonus,
          });
          break;
        case "Wondrous item":
          if (getRandomInt(0, 1) == 0) {
            context.commit("updateSaveBonus", {
              cardID: cardID,
              bonus: eBonus,
            });
          } else {
            context.commit("updateSpellBonus", {
              cardID: cardID,
              bonus: eBonus,
            });
          }
          break;
      }
    }

    context.commit("updateItemType", {
      cardID: cardID,
      selectedType: itemType,
    });
    context.commit("updateItemSubtype", {
      cardID: cardID,
      selectedSubtype: itemSubtype,
    });
    context.commit("updateEnchantments", {
      cardID: cardID,
      enchantments: rEnchantments,
    });
    context.commit("updateImageChoices", {
      cardID: cardID,
      selectedType: itemType,
    });
    context.commit("updateImage", {
      cardID: cardID,
      image: image,
    });
    context
      .dispatch("itemName", { cardID: cardID })
      .then((itemName) =>
        context.commit("updateItemName", { cardID: cardID, itemName: itemName })
      );

    if (image) {
      context
        .dispatch("randomImage", { cardID: cardID })
        .then((randomImage) => {
          context.commit("updateImageSRC", {
            cardID: cardID,
            imageSRC: randomImage,
          });
        });
    }
  },

  async description(context, cardID) {
    const card = context.state.cards.find((card) => card.cardID == cardID);
    if (typeof card.selectedEnchantments != "undefined") {
      var selectedType = card.selectedType;
      var selectedSubtype = card.selectedSubtype;
      var selectedEnchantments = card.selectedEnchantments;
      var descriptions = [];
      if (
        typeof card.customDescription != "undefined" &&
        card.customDescription.length > 0
      ) {
        descriptions.push(
          "<p>" + card.customDescription.replace(/(\n)+/g, "</p><p>") + " "
        );
      }
      for (var i = 0; i < selectedEnchantments.length; i++) {
        if (
          typeof selectedEnchantments[i].description != "undefined" &&
          selectedEnchantments[i].description != ""
        ) {
          if (typeof selectedEnchantments[i].altName != "undefined") {
            if (selectedEnchantments[i].altName.length > 0) {
              descriptions.push(
                "<p><b><i>" +
                  selectedEnchantments[i].altName +
                  ".</i></b> " +
                  selectedEnchantments[i].description +
                  "</p>"
              );
            } else {
              descriptions.push(
                "<p>" + selectedEnchantments[i].description + "</p>"
              );
            }
          } else {
            descriptions.push(
              "<p><b><i>" +
                selectedEnchantments[i].name +
                ".</i></b> " +
                selectedEnchantments[i].description.replace("<p>", "") +
                "</p>"
            );
          }
        }
      }
      var keyVerb = "carry";
      var keyVerbing = "carrying";
      var plurality1 = "This";
      var plurality2 = "its";
      var plurality3 = "has";
      var plurality4 = "is";
      var plurality5 = "it";

      if (
        selectedType == "Armor" ||
        selectedType == "Ring" ||
        selectedSubtype == "Ring" ||
        selectedSubtype == "Amulet" ||
        selectedSubtype == "Pants" ||
        selectedSubtype == "Cloak" ||
        selectedSubtype == "Robe" ||
        selectedSubtype == "Shirt" ||
        selectedSubtype == "Necklace" ||
        selectedSubtype == "Boots" ||
        selectedSubtype == "Greaves" ||
        selectedSubtype == "Sandals" ||
        selectedSubtype == "Slippers" ||
        selectedSubtype == "Bracers" ||
        selectedSubtype == "Gauntlets" ||
        selectedSubtype == "Gloves" ||
        selectedSubtype == "Mittens" ||
        selectedSubtype == "Cap" ||
        selectedSubtype == "Circlet" ||
        selectedSubtype == "Crown" ||
        selectedSubtype == "Hat" ||
        selectedSubtype == "Headband" ||
        selectedSubtype == "Helmet" ||
        selectedSubtype == "Hood" ||
        selectedSubtype == "Mask" ||
        selectedSubtype == "Anklet" ||
        selectedSubtype == "Bracelet" ||
        selectedSubtype == "Brooch" ||
        selectedSubtype == "Earring" ||
        selectedSubtype == "Nose Ring" ||
        selectedSubtype == "Belt" ||
        selectedSubtype == "Cape"
      ) {
        keyVerb = "wear";
        keyVerbing = "wearing";
      }

      if (
        selectedSubtype == "Pants" ||
        selectedSubtype == "Boots" ||
        selectedSubtype == "Greaves" ||
        selectedSubtype == "Boots" ||
        selectedSubtype == "Greaves" ||
        selectedSubtype == "Sandals" ||
        selectedSubtype == "Slippers" ||
        selectedSubtype == "Bracers" ||
        selectedSubtype == "Gauntlets" ||
        selectedSubtype == "Gloves" ||
        selectedSubtype == "Mittens"
      ) {
        plurality1 = "These";
        plurality2 = "their";
        plurality3 = "have";
        plurality4 = "are";
        plurality5 = "them";
      }

      var thisItem = "";
      // var thisItem = itemTypeName.toLowerCase();
      var descriptionText = descriptions.join("");
      descriptionText = descriptionText
        .replace(/you carry it/g, "you " + keyVerb + " " + plurality5)
        .replace(/you wear it/g, "you " + keyVerb + " " + plurality5)
        .replace(/you carry/g, "you " + keyVerb)
        .replace(/you wear/g, "you " + keyVerb)
        .replace(/carrying it/g, keyVerbing + " " + plurality5)
        .replace(/wearing it/g, keyVerbing + " " + plurality5)
        .replace(/carrying/g, keyVerbing)
        .replace(/wearing/g, keyVerbing)
        .replace(/cast into it/g, "cast into " + plurality5)
        .replace(/stored in it/g, "stored in " + plurality5)
        .replace(
          /this item has/g,
          plurality1.toLowerCase() + " " + thisItem + " " + plurality3
        )
        .replace(
          /this item is/g,
          plurality1.toLowerCase() + " " + thisItem + " " + plurality4
        )
        .replace(
          /this magic item/g,
          plurality1.toLowerCase() + " magic " + thisItem
        )
        .replace(/This magic item/g, plurality1 + " magic " + thisItem)
        .replace(/this item/g, plurality1.toLowerCase() + " " + thisItem)
        .replace(
          /This item has/g,
          plurality1 + " " + thisItem + " " + plurality3
        )
        .replace(
          /This item is/g,
          plurality1 + " " + thisItem + " " + plurality4
        )
        .replace(/This item/g, plurality1 + " " + thisItem)
        .replace(
          /this weapon has/g,
          plurality1.toLowerCase() + " " + thisItem + " " + plurality3
        )
        .replace(
          /this weapon is/g,
          plurality1.toLowerCase() + " " + thisItem + " " + plurality4
        )
        .replace(/this weapon/g, plurality1.toLowerCase() + " " + thisItem)
        .replace(
          /This weapon has/g,
          plurality1 + " " + thisItem + " " + plurality3
        )
        .replace(
          /This weapon is/g,
          plurality1 + " " + thisItem + " " + plurality4
        )
        .replace(/This weapon/g, plurality1 + " " + thisItem)
        .replace(
          /this armor has/g,
          plurality1.toLowerCase() + " " + thisItem + " " + plurality3
        )
        .replace(
          /this armor is/g,
          plurality1.toLowerCase() + " " + thisItem + " " + plurality4
        )
        .replace(/this armor/g, plurality1.toLowerCase() + " " + thisItem)
        .replace(
          /This armor has/g,
          plurality1 + " " + thisItem + " " + plurality3
        )
        .replace(
          /This armor is/g,
          plurality1 + " " + thisItem + " " + plurality4
        )
        .replace(/This armor/g, plurality1 + " " + thisItem)
        .replace(/its charges/g, plurality2 + " charges")
        .replace(/its command word/g, plurality2 + " command word")
        .replace(/item regains/g, thisItem + " regains")
        .replace(/weapon regains/g, thisItem + " regains")
        .replace(/armor regains/g, thisItem + " regains");
      return descriptionText;
    }
  },
  tutorialDone(context) {
    context.commit("SET_TUTORIALDONE", true);
    if (firebase.auth().currentUser) {
      try {
        const userID = context.state.user.data.uid;
        if (userID) {
          // firebase
          //   .database()
          //   .ref("users/" + userID + "/tutorialDone")
          //   .set(true);
          set(ref(getDatabase(), "users/" + userID + "/tutorialDone"), true);
          return true;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }
  },
  savePantheon(context, pantheon) {
    if (
      context.state.pantheons.some((pantheon) => pantheon.id == pantheon.id)
    ) {
      context.state.pantheons.unshift(pantheon);
      const seen = new Set();
      const pantheons = context.state.pantheons.filter((pantheon) => {
        const duplicate = seen.has(pantheon.id);
        seen.add(pantheon.id);
        return !duplicate;
      });
      context.state.pantheons = pantheons;
      if (firebase.auth().currentUser) {
        set(
          ref(
            getDatabase(),
            "users/" + context.state.user.data.uid + "/pantheons/"
          ),
          pantheons
        );
      }
    } else {
      context.state.pantheons.unshift(pantheon);
      if (firebase.auth().currentUser) {
        set(
          ref(
            getDatabase(),
            "users/" + context.state.user.data.uid + "/pantheons/" + pantheon.id
          ),
          pantheon
        )
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      }
    }
  },
};
