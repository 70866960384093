<template>
  <div class="tutorial-container shadow">
    <div class="menu-row topbar space-between">
      <h2>Tutorial</h2>
      <i class="far fa-times-circle" @click="$emit('endTutorial')"></i>
    </div>
    <div class="main-area">
    <h3 class="tcenter">{{ headerText }}</h3>
    <div class="tutorialtext" v-html="bodyText"></div>
    </div>
    <div class="main-area menu-row space-between">
        
      <i class="fas fa-caret-left" @click="tutorialStep > 1 ? $emit('tutorialBack') : ''"></i>
      <h3 v-if="tutorialStep > 0">STEP: {{ tutorialStep }}/13</h3>
      <h3 v-if="tutorialStep == 0 && !loggedIn" @click="signIn" class="link">Click here to log in or sign up</h3>
      <i class="fas fa-caret-right" @click="tutorialStep < 13 ? $emit('tutorialForward') : $emit('endTutorial')"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "SingleCard",
  props: ["tutorial", "tutorialStep"],
  emits: ["tutorialBack", "tutorialForward", "endTutorial"],
  methods: {

    signIn() {
      this.$router.push("/login");
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
    headerText() {
        var t = ""
        switch(this.tutorialStep) {
            case 0:
            t = "Welcome!"
            break;
            case 1:
            t = "Create New Magic Item Card"
            break;
            case 2:
            t = "Create New Magic Item Card"
            break;
            case 3:
            t = "Random Item Settings"
            break;
            case 4:
            t = "Random Item Settings"
            break;
            case 5:
            t = "Random Item Settings"
            break;
            case 6:
            t = "Random Item Settings"
            break;
            case 7:
            t = "Edit Magic Item Card"
            break;
            case 8:
            t = "Edit Magic Item Card"
            break;
            case 9:
            t = "Edit Magic Item Card"
            break;
            case 10:
            t = "Edit Magic Item Card"
            break;
            case 11:
            t = "Edit Magic Item Card"
            break;
            case 12:
            t = "Edit Magic Item Card"
            break;
            case 13:
            t = "Tutorial Done!"
            break;
            default:
            t = ""
        }
        return t
    },
      bodyText() {
        var t = ""
        switch(this.tutorialStep) {
            case 0:
            t = "<p>Welcome to the Eventyr Games Item Card Generator! You can exit this tutorial at any point by clicking the X symbol. <br><br>Please note that some of the features explained in this tutorial require you to be logged in. If you are not logged in, you will not be able to save cards or collections you create.</p>"
            break;
            case 1:
            t = "Create a blank item card by clicking the plus symbol. A collection of cards can contain up to 8 cards."
            break;
            case 2:
            t = "You can also generate random magic items by clicking on the d20 icon."
            break;
            case 3:
            t = "Open up the random settings menu to specify which magic items you want to create."
            break;
            case 4:
            t = "You can choose to select magic items from available databases, such as the basic rules of fifth edition or <a href='https://eventyrgames.com/product/wanderers-guide-to-merchants-magic/' target='_blank' style='text-decoration:none; font-weight:600;color:#8bbbb0;'>Wanderer's Guide to Merchants & Magic.</a>"
            break;
            case 5:
            t = "You can also generate a magic item by randomly combining magical properties from <a href='https://www.dmsguild.com/product/327955/Nybors-Tomes-of-Enchantments-BUNDLE?affiliate_id=193137'  target='_blank' style='text-decoration:none; font-weight:600;color:#8bbbb0;'>Nybor's Tome of Enchantments.</a> With more than 200 properties the possibilities are literally endless."
            break;
            case 6:
            t = "You can further filter which magic items you want to randomly generate by selecting which rarities, types, and attunement options you want to include. You can also create more than one magic item at once, if you need to fill out a dragon's hoard quickly!"
            break;
            case 7:
            t = "Open up the item card editor by clicking on a magic item card. The editor is where you can change what your magic item does, how the card looks, and even print it as a JPG file you can share with others."
            break;
            case 8:
            t = "If you want a starting point, you can choose to either load an item from a database – including the basic rules of fifth edition – or you can generate one using modular magical properties by clicking 'Enchantments'."
            break;
            case 9:
            t = "You can change virtually any aspect of the magic item, including its name, type, rarity, attunement requirement, and description. You can also add an image to your card, either selecting one from an included database or linking an image you have found elsewhere."
            break;
            case 10:
            t = "You can also change the card's appearance, including the gems that adorn it, the font used for headers and body text, and the size of the font."
            break;
            case 11:
            t = "You can save the item you've created to your personal database, so you can quickly load it to a new item card or have it be an option when randomly generating magic items."
            break;
            case 12:
            t = "One of the key features of the item card generator is the option to download the item card as a JPG file that can be shared with others."
            break;
            case 13:
            t = "That's it – the whole tutorial. We hope you'll figure out the rest by trying out the generator for yourself. If you run into issues, you can click the question mark to open up the tutorial again. If you have any questions or suggestions, don't hesitate to reach out to us at jvaleur@eventyrgames.com"
            break;
            default:
            t = ""
        }
        return t
    }
  },
};
</script>

<style scoped>
h2 {
  text-align: left;
}

h3 {
    text-transform:uppercase;
    font-size:1rem;
}

.tutorial-container {
  position: fixed;
  background-color: red;
  width: 50vw;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-flow: column nowrap;
  justify-content: top;
  background-color: var(--dark);
  border-radius: 0.5rem;
  z-index: 1002;
  color: #fff;
  transition: all 0.5s ease-in-out;
}

.topbar {
  background-color: var(--dark2);
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  border-bottom: 2px solid rgb(0, 0, 0);
  padding: 0.5rem;
  position: relative;
}

.main-area {
  padding: 0.25rem 0.5rem;
  transition:all 0.3s ease;
}

.far,
.fas {
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.far:hover,
.fas:hover {
  color: var(--main2);
  transition: all 0.3s ease;
}

.link {
  cursor:pointer;
  transition:all 0.3s ease;
}

.link:hover {
  color:var(--main2);
  transition:all 0.3s ease;
}

.tutorialtext {
  font-family:kingscaslon;
}

.tutorialtext a {
  text-decoration:none;
  font-weight:bold;
  color:#8b0000
}
</style>