<template>
  <div class="component-wrapper">
    <div class="collections-container">
      <div class="collection-block">
        <img class="collection-image" src="@/assets/hero_ooze.jpg"   @click="$router.push('/pantheoncollection')"/>
        <div class="collection-name">
          My Pantheons
        </div>
      </div>
      <!-- <div class="collection-block">
        <img class="collection-image" src="@/assets/hero_items.jpg"  @click="$router.push('/itemcollection')"/>
        <div class="collection-name">
          My Saved Items
        </div>
      </div> -->
      <div class="collection-block" @click="$router.push('/npccollection')">
        <img class="collection-image" src="@/assets/hero_npcs.jpg" />
        <div class="collection-name">
          My Shops & NPCs
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Collections",
  components: {},
  data() {
    return {
      collections: [],
      opened: null,
    };
  },
};
</script>

<style scoped>
.component-wrapper {
  margin-top: 100px;
  max-width: 100lwv;
  min-height: calc(100lvh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.collections-container {
  width: 1200px;
  max-width:100%;
  /* height: 80vh;
 background-color: var(--dark2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75); */
  border-radius: 10px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.collection-block {
  width: 390px;
  max-width: 100%;
  aspect-ratio: 4/3;
  margin: 5px;
  position: relative;
  border-radius: 5%;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
}

.collection-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: all 0.5s ease;
}

.collection-block:hover .collection-image {
  transform: scale(1.1);
}

.collection-name {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.74);
  padding: 10px;
  border-radius: 0px 0px 5px 5px;
  color: white;
  font-family: montserrat, sans-serif;
  transition: all 0.5s ease;
  font-size: 1.2rem;
  text-transform: uppercase;

}
</style>
