<template>
<div class="menu-row rounded">
    <div
      class="menu-item"
    >
      <i class="fas fa-bolt"></i>
        <select name="weaponBonus"  v-model="weaponBonus">
          <option>—</option>
          <option value="1">+1</option>
          <option value="2">+2</option>
          <option value="3">+3</option>
        </select>
    </div>
    <div
      class="menu-item rounded"
    >
      <i class="fas fa-shield-alt"></i>
        <select name="armorBonus" v-model="armorBonus">
          >
          <option>—</option>
          <option value="1">+1</option>
          <option value="2">+2</option>
          <option value="3">+3</option>
        </select>
    </div>

    <div
      class="menu-item"
    >
      <i class="fas fa-heart"></i>
        <select name="saveBonus"  v-model="saveBonus">
          >
          <option>—</option>
          <option value="1">+1</option>
          <option value="2">+2</option>
          <option value="3">+3</option>
        </select>
    </div>
    <div
      class="menu-item rounded"
    >
      <i class="fas fa-magic"></i>
        <select name="spellBonus" class="rounded" v-model="spellBonus">
          >
          <option>—</option>
          <option value="1">+1</option>
          <option value="2">+2</option>
          <option value="3">+3</option>
        </select>
    </div>
    </div>
</template>

<script>
export default {
  name: "BonusesSelect",
  props: ["cardID", "card"],
  data() {
    return {
      weaponShow: false,
      armorShow: false,
      spellShow: false,
      saveShow: false,
    };
  },
  computed: {
    weaponBonus: {
      get() {
        return this.card.weaponBonus;
      },
      set(bonus) {
        this.$store.commit("updateWeaponBonus", {
          cardID: this.cardID,
          bonus: bonus,
        });
      },
    },
    armorBonus: {
      get() {
        return this.card.armorBonus;
      },
      set(bonus) {
        this.$store.commit("updateArmorBonus", {
          cardID: this.cardID,
          bonus: bonus,
        });
      },
    },
    spellBonus: {
      get() {
        return this.card.spellBonus;
      },
      set(bonus) {
        this.$store.commit("updateSpellBonus", {
          cardID: this.cardID,
          bonus: bonus,
        });
      },
    },
    saveBonus: {
      get() {
        return this.card.saveBonus;
      },
      set(bonus) {
        this.$store.commit("updateSaveBonus", {
          cardID: this.cardID,
          bonus: bonus,
        });
      },
    },
  },
};
</script>

<style scoped>

.top {
  margin-bottom:0.5rem;
  background:var(--dark2);
  box-shadow: 0 -2px 20px rgb(0, 0, 0);
}

.menu-item {
  color:#fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 25%;
  background:var(--dark);
  transition: all 0.6s ease;
  display: flex;
  flex-flow: row nowrap;
}

.menu-row {
  
  border: 1px solid rgb(118, 118, 118);
}

.menu-item:hover {
  color: var(--main2);
  filter: grayscale(0%) opacity(1);
  background: #141418;
}

select {
  border: none;
  outline: none;
  background: inherit;
  width: 100%;
  height: 30px;
  text-align:right;
}

select:hover {
  filter: grayscale(0%) opacity(1);
  background: #141418;
  transition: all 0.6s ease;
}

.fas {
  min-width:20px;
  padding: 0.1rem 0.4rem;
  font-size: 0.8rem;
  line-height:1.4rem;
}

@media only screen and (max-width: 600px) {
select {
  height: 30px;
}

.fas {
  line-height: 30px !important;
  padding: 0 10px;
}
}

</style>