export default {
  user(state) {
    return state.user;
  },
  combinedItems(state) {
    let combinedItems = {
    ...state.itemsSRD,
    ...state.itemsWGTMM,
    ...state.items}
    return combinedItems;
  },
  
};
