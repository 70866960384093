<template>
  <div class="menu-row">
    <h3>Attunement:</h3>
    <div class="menu-row flex-center">
      <select class="rounded" v-model="selectedAttunement">
        <option v-if="card.cardType == 'enchantments'">Auto</option>
        <option v-for="choice in attunementChoices" :key="choice.index">
          {{ choice }}
        </option>
      </select>
      <transition name="width">
        <input
          v-if="selectedAttunement == 'Yes'"
          type="text"
          v-model="attunementBy"
          placeholder="by anyone"
          class="rounded"
        />
      </transition>
      <!-- <div class="header-filters">
        <div class="filter-element">Show</div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "AttunementSelect",
  props: ["cardID", "card"],
  data() {
    return {
      attunementChoices: this.$store.state.attunementChoices,
      locked: false,
    };
  },
  computed: {
    selectedAttunement: {
      get() {
        return this.card.selectedAttunement;
      },
      set(attunement) {
        this.$store.commit("updateAttunement", {
          cardID: this.cardID,
          attunement: attunement,
        });
      },
    },
    attunementBy: {
      get() {
        return this.card.attunementBy;
      },
      set(attunementBy) {
        this.$store.commit("updateAttunementBy", {
          cardID: this.cardID,
          attunementBy: attunementBy,
        });
      },
    },
  },
};
</script>

<style scoped>
.menu-row {
  margin-bottom: 0;
}

.header-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 0.5rem;
}

.filter-element {
  font-size: 0.8rem;
  padding: 3px;
  max-height: 1.5rem;
  color: black;
  margin: 0px 2px;
  display: flex;
  flex-flow: row nowrap;
}

.fas {
  margin-left: 0.25rem;
  height: 1.4rem;
  line-height: 1.2rem;
  padding: 0.1rem 0.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fa:hover,
.highlight {
  color: var(--main2);
  background-color: var(--dark);
}

h3:nth-of-type(2) {
  margin-left: 2.5px;
}


input {
  margin-left: 0.25rem;
  width: 200%;
}

.width-enter-active {
  transition: all 0.2s ease;
}

.width-leave-active {
  transition: all 0.2s ease;
}

.width-enter-from,
.width-leave-to {
  width: 0;
}
</style>