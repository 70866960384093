<template>
    <!-- <div
      class="card-type"
      @click="selectCardType('custom')"
      :class="{ highlight: cardType == 'custom' }"
      @mouseover="showCustomTooltip = true"
      @mouseleave="showCustomTooltip = false"
    >
      <h3>Custom</h3>
      <transition name="tooltip">
        <div class="tooltip-top" v-if="showCustomTooltip">
          Create item from scratch
        </div>
      </transition>
    </div> -->
    <div
      class="card-type"
      @click="selectCardType('srd')"
      :class="{ highlight: cardType == 'srd' }"
      @mouseover="showSelectTooltip = true"
      @mouseleave="showSelectTooltip = false"
    >
      <h3>Load Item</h3>
      <transition name="tooltip">
        <div class="tooltip-top" v-if="showSelectTooltip">
          Choose and customize an existing item
        </div>
      </transition>
    </div>
    <div
      class="card-type"
      @mouseover="showEnchantmentsTooltip = true"
      @mouseleave="showEnchantmentsTooltip = false"
      @click="selectCardType('enchantments')
      "
      :class="{ highlight: cardType == 'enchantments' }"
    >
      <transition name="tooltip">
        <div class="tooltip-top" v-if="showEnchantmentsTooltip">
          Create items using Nybor's enchantments
        </div>
      </transition>
      <h3>Enchantments</h3>
    </div>
</template>

<script>

export default {
  name: "CardTypeSelect",
  props: ["cardID", "card"],
  computed: {
    cardType() {
      return this.card.cardType;
    },
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
    email() {
      return this.$store.state.user.data.email;
    },
  },
  data() {
    return {
      customSelected: true,
      enchantmentsSelected: false,
      showCustomTooltip: false,
      showSelectTooltip: false,
      showEnchantmentsTooltip: false,
    };
  },
  methods: {
    selectCardType(cardType) {
      this.$store.commit('updateEditingPreference', { cardID: this.cardID, editingPreference: 'input' })
      this.$store.dispatch("updateCardType", {
        cardID: this.cardID,
        cardType: cardType,
      });
    },
  },
};
</script>

<style scoped>
.menu-row {
  margin-bottom: 10px;
}

.card-type {
  width: 100%;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  color: #fff;
  transition: all 0.3s ease;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 -2px 10px rgb(0, 0, 0);
  transition: all 0.5s ease;
  background-color: var(--dark2);
  filter: grayscale(1) opacity(0.5);
  position: relative;
}

.card-type:not(:first-of-type) {
  margin-left: 2.5%;
}

.card-type:hover {
  transform: scale(1.05);
  color: var(--main2);
  transition: all 0.3s ease;
  filter: grayscale(0) opacity(1);
}

.highlight,
.highlight h3 {
  color: var(--main2);
  transition: all 0.3s ease;
  filter: grayscale(0) opacity(1);
}

.patron-only {
  position: absolute;
  font-size: 1rem;
  top: -10%;
  left: 10%;
  line-height: 1;
  transform: rotate(-7.5deg);
  color: red;
  transition: all 0.3s ease;
  font-weight: 800;
}

.patreon-connect {
  width: 50%;
  height: 1.5rem;
  margin: 10px;
}

button {
  margin:0;
  padding:0.5rem 1rem;
}

.fas {
  transition: all 0.3s ease;
  cursor: pointer;
}

.fas:hover {
  color: var(--main2);
  transform: scale(1.05);
  transition: all 0.3s ease;
}

</style>