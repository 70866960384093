<template>
  <div
    class="price-list"
    :class="{ generator: mode == 'generator' || mode == 'presentation' }">
    <!-- <div class="overlay clear" v-if="selectedItem" @click="selectedItem = null"></div> -->

    <!-- MAIN CONTAINER FOR THIS VIEW -->
    <div class="pricelist-container" v-if="!showPdfModal || hideButtons">
      <ul
        class="item-list"
        v-if="filteredItems && filteredItems.length > 0 && listStyle == 'text'">
        <li
          class="item-list-item"
          v-for="item in filteredItems"
          v-bind:key="item.name">
          {{ item.name }} <i v-if="showType" class="fas fa-shield-alt"></i
          ><i v-if="showRarity" class="fas fa-gem"></i
          ><i
            v-if="item.attunement && item.attunement.length > 1"
            class="fab fa-adn"></i
          ><span
            class="item-src"
            v-if="showSource"
            :class="{
              gray: item.source == 'DMG' || item.source == 'SRD',
              pink: item.source == 'TCOE',
              darkgreen: item.source == 'XGTE',
              teal: item.source == 'MGMM',
              darkblue: item.source == 'WGMM',
              red: item.source == 'Custom',
            }">
            {{ item.source }}</span
          >
        </li>
      </ul>
      <!-- TABLE OF ITEMS -->

      <div
        class="item-table menu-row column"
        :key="updateKey"
        v-else-if="filteredItems && filteredItems.length > 0">
        <!--- TOP ROW -->

        <div class="item header">
          <!--- ITEM NAME HEADER --->
          <div
            class="item-name"
            :class="{
              long: nColumns == 1,
              medium: nColumns == 2,
              small: nColumns == 3,
              tiny: nColumns == 4,
            }"
            @click="
              sortBy == 'nameASC' ? (sortBy = 'nameDESC') : (sortBy = 'nameASC')
            ">
            Name
            <i
              class="fas fa-caret-down"
              :class="{
                highlight: sortBy == 'nameASC' || sortBy == 'nameDESC',
                'fa-caret-up': sortBy == 'nameDESC',
              }"></i>
          </div>

          <!--- ITEM TYPE HEADER --->
          <div
            class="item-type"
            v-if="listStyle == 'table' && showType"
            :class="{
              long: nColumns == 1,
              medium: nColumns == 2,
              small: nColumns == 3,
              tiny: nColumns == 4,
            }">
            Type
            <i
              class="fas fa-caret-down"
              @click="
                sortBy == 'typeASC'
                  ? (sortBy = 'typeDESC')
                  : (sortBy = 'typeASC')
              "
              :class="{
                highlight: sortBy == 'typeASC' || sortBy == 'typeDESC',
                'fa-caret-up': sortBy == 'typeDESC',
              }"></i>
          </div>
          <!--- ITEM RARITY HEADER --->
          <div
            class="item-rarity"
            v-if="listStyle == 'table' && showRarity"
            :class="{
              long: nColumns == 1,
              medium: nColumns == 2,
              small: nColumns == 3,
              tiny: nColumns == 4,
            }">
            Rarity
            <i
              class="fas fa-caret-down"
              @click="
                sortBy == 'rarityASC'
                  ? (sortBy = 'rarityDESC')
                  : (sortBy = 'rarityASC')
              "
              :class="{
                highlight: sortBy == 'rarityASC' || sortBy == 'rarityDESC',
                'fa-caret-up': sortBy == 'rarityDESC',
              }"></i>
          </div>

          <!--- ITEM VALUE HEADER --->

          <div
            class="item-value"
            v-if="showValue"
            :class="{
              long: nColumns == 1,
              medium: nColumns == 2,
              small: nColumns == 3,
              tiny: nColumns == 4,
            }"
            @click="
              sortBy == 'valueASC'
                ? (sortBy = 'valueDESC')
                : (sortBy = 'valueASC')
            ">
            Value
            <i
              class="fas fa-caret-down"
              :class="{
                'fa-caret-up': sortBy == 'valueDESC',
                highlight: sortBy == 'valueASC' || sortBy == 'valueDESC',
              }"></i>
          </div>
        </div>

        <!--- ROWS --->

        <div class="item" v-for="item in filteredItems" v-bind:key="item.name">
          <div
            class="item-name"
            :class="{
              common: item.rarity == 'Common',
              uncommon: item.rarity == 'Uncommon',
              rare: item.rarity == 'Rare',
              'very-rare': item.rarity == 'Very Rare',
              legendary: item.rarity == 'Legendary',
              artifact: item.rarity == 'Artifact',
              long: nColumns == 1,
              medium: nColumns == 2,
              small: nColumns == 3,
              tiny: nColumns == 4,
            }"
            @mouseleave="(showAddShopMenu = null), (selectedItem = null)">
            <PriceListItemModal
              :item="selectedItem"
              v-if="selectedItem && selectedItem.name == item.name">
            </PriceListItemModal>
            <span @click="selectedItem = item">{{ item.name }}</span>
            <i
              v-if="
                showAttunement && item.attunement && item.attunement.length > 1
              "
              class="fab fa-adn"></i
            ><span
              class="item-src"
              v-if="showSource"
              :class="{
                gray: item.source == 'DMG',
                pink: item.source == 'TCOE',
                darkgreen: item.source == 'XGTE',
                teal: item.source == 'MGMM',
                darkblue: item.source == 'WGMM',
                red: item.source == 'Custom',
              }">
              {{ item.source }}</span
            >
            <div class="hover-menu">
              <i
                class="fas fa-trash"
                v-if="item.source == 'Custom'"
                @click="deleteItem(item)"></i>
              <!-- <i
                class="fas fa-trash"
                v-if="mode == 'generator'"
                @click="$emit('filterItem', item)"
              ></i> -->
              <i
                class="fas fa-plus-circle"
                v-if="loggedIn && mode == 'priceList'"
                @click="
                  showAddShopMenu == item.name
                    ? (showAddShopMenu = null)
                    : (showAddShopMenu = item.name)
                ">
                <transition name="fade">
                  <div
                    class="add-shop-menu"
                    v-if="showAddShopMenu == item.name">
                    <h3>Add to Shop</h3>
                    <div
                      v-if="$store.state.customShops.length < 1"
                      @click="$router.push('../shopgenerator')">
                      You have no shops. Click here to create one!
                    </div>
                    <div
                      v-else
                      v-for="shop in $store.state.customShops"
                      v-bind:key="shop.name"
                      @click="addToShop(item, shop.name)">
                      {{ shop.name }}
                    </div>
                  </div>
                </transition>
              </i>
            </div>
            <i
              class="fas fa-trash"
              v-if="$store.state.selectedShop != null && mode == 'presentation'"
              @click="$store.dispatch('removeFromShop', item.name)"></i>

            <div v-if="listStyle == 'condensed'">
              <span
                class="item-subheader"
                :contenteditable="loggedIn"
                @blur="updateItem(item.name, 'type')">
                {{ showType ? item.type : "" }}
              </span>
              <span
                class="item-subheader"
                v-if="showType && item.subtype && showSubtype">
                {{ "&nbsp;" + "(" }}
              </span>
              <span
                class="item-subheader"
                :contenteditable="loggedIn"
                @blur="updateItem(item.name, 'subtype')">
                {{ item.subtype && showSubtype ? item.subtype : "" }}
              </span>
              <span
                class="item-subheader"
                v-if="showType && item.subtype && showSubtype"
                >{{ ")" }}</span
              >
              <span class="item-subheader">
                {{
                  (showType || (showSubtype && item.subtype)) && showRarity
                    ? "," + "&nbsp;"
                    : ""
                }}
              </span>

              <span
                class="item-subheader"
                :contenteditable="loggedIn"
                @blur="updateItem(item.name, 'rarity')">
                {{ showRarity ? item.rarity : "" }}
              </span>
            </div>
          </div>

          <!--- ITEM TYPE COLUMN --->

          <div
            class="item-type"
            v-if="listStyle == 'table' && showType"
            :class="{
              long: nColumns == 1,
              medium: nColumns == 2,
              small: nColumns == 3,
              tiny: nColumns == 4,
            }">
            <span
              :contenteditable="loggedIn"
              @blur="updateItem(item.name, 'type')">
              {{ showType ? item.type : "" }}
            </span>
            <span v-if="showType && item.subtype && showSubtype">
              {{ "&nbsp;" + "(" }}
            </span>
            <span
              :contenteditable="loggedIn"
              @blur="updateItem(item.name, 'subtype')">
              {{ item.subtype && showSubtype ? item.subtype : "" }}
            </span>
            <span v-if="showType && item.subtype && showSubtype">{{
              ")"
            }}</span>
          </div>

          <!--- ITEM RARITY COLUMN --->

          <div
            class="item-rarity"
            v-if="listStyle == 'table' && showRarity"
            :contenteditable="loggedIn"
            @blur="updateItem(item.name, 'rarity')"
            :class="{
              long: nColumns == 1,
              medium: nColumns == 2,
              small: nColumns == 3,
              tiny: nColumns == 4,
            }">
            {{ item.rarity }}
          </div>

          <!--- ITEM VALUE COLUMN --->

          <div
            class="item-value"
            v-if="showValue"
            :class="{
              long: nColumns == 1,
              medium: nColumns == 2,
              small: nColumns == 3,
              tiny: nColumns == 4,
            }">
            <div class="menu-row item-value" v-if="showValue && item.value">
              <div
                :contenteditable="loggedIn"
                ref="value"
                @blur="updateItem(item.name, 'value')">
                {{
                  item.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </div>
              <div>{{ item.subvalue ? "+" : "" }}&nbsp;gp</div>
            </div>
            <div class="menu-row item-value" v-else-if="showValue">
              <div
                :contenteditable="loggedIn"
                ref="value"
                @blur="updateItem(item.name, 'value')">
                No Value
              </div>
            </div>
            <div
              v-if="showSubvalue && item.subvalue"
              class="item-subvalue"
              :contenteditable="loggedIn"
              @blur="updateItem(item.name, 'subvalue')">
              {{ item.subvalue }}
            </div>
          </div>
        </div>
      </div>

      <!-- ADD ITEM BUTTON -->
    </div>
    <transition name="fade">
      <PriceListAddMenu
        @close="showAddItemMenu = false"
        :filteredItems="filteredItems"
        :mode="mode"
        v-if="showAddItemMenu"
        @addToShop="addToShop"></PriceListAddMenu>
    </transition>
    <PriceListButtons
      v-if="mode != 'generator' && hideButtons != true"
      @printPDF="
        mode == 'priceList'
          ? showPdfModal
            ? printPDF()
            : (showPdfModal = true)
          : printPDF()
      "
      @addItem="showAddItemMenu = true"
      @showAttunement="toggleAttunement"
      @showSource="toggleSource"
      @showType="toggleType"
      @showSubtype="toggleSubtype"
      @showValue="toggleValue"
      @showSubvalue="toggleSubvalue"
      @showRarity="toggleRarity"
      @listStyle="changeListStyle"
      :showAttunement="showAttunement"
      :showSource="showSource"
      :showValue="showValue"
      :showSubvalue="showSubvalue"
      :showType="showType"
      :showSubtype="showSubtype"
      :showRarity="showRarity"
      :listStyle="listStyle"
      :mode="mode"></PriceListButtons>
    <div class="pdf-modal" v-if="showPdfModal">
      <PriceListTablePrintPDF
        ref="PriceListTablePrintPDF"
        :filteredItems="filteredItems"
        :listStyle="listStyle"
        :showSource="showSource"
        :showValue="showValue"
        :showRarity="showRarity"
        :showType="showType"
        :showSubtype="showSubtype"
        :showSubvalue="showSubvalue"
        :showAttunement="showAttunement"
        :nColumns="nColumns"
        @endPrintPdfModal="printPdfModal = false"
        @closePrintPdfModal="showPdfModal = false"></PriceListTablePrintPDF>
    </div>
  </div>
</template>

<script>
import PriceListAddMenu from "./PriceListAddMenu.vue";
import PriceListButtons from "./PriceListButtons.vue";
import PriceListItemModal from "./PriceListItemModal.vue";
import PriceListTablePrintPDF from "./PriceListTablePrintPDF.vue";

export default {
  name: "PriceListTable",
  components: {
    PriceListAddMenu,
    PriceListButtons,
    PriceListTablePrintPDF,
    PriceListItemModal,
  },
  props: ["items", "generatorItems", "mode", "npcItems", "hideButtons"],
  emits: ["filterItem", "printNPC", "refreshFilter"],
  data() {
    return {
      listStyle: "condensed",
      itemToSave: "",
      selectedItem: null,
      showAttunement: true,
      showSource: true,
      showType: true,
      showSubtype: true,
      showSubvalue: true,
      showValue: true,
      showRarity: true,
      showAddShopMenu: false,
      showAddItemMenu: false,
      showPdfModal: false,
      value1: null,
      value2: null,
      updateKey: 1,
      includedValues: [0, 50000],
      valueFormat: (v) =>
        `${("" + v)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .replace(/50,000/g, "50,000+")} gp`,
      sortBy: "nameASC",
      rarities: [
        "Common",
        "Uncommon",
        "Rare",
        "Very Rare",
        "Legendary",
        "Artifact",
      ],
    };
  },
  mounted() {
    this.$el.querySelectorAll(".contenteditable").contenteditable = true;
    this.delayedRefresh();
  },
  computed: {
    nColumns() {
      let type = this.showType;
      let rarity = this.showRarity;
      let value = this.showValue;
      const columns = [type, rarity, value];
      if (this.listStyle == "table") {
        const count = columns.filter(Boolean).length;
        return count;
      } else {
        return 1;
      }
    },
    filteredItems() {
      let items = this.items;
      if (this.mode == "presentation") {
        items = this.$store.state.selectedShop.inventory;
      } else if (this.mode == "generator") {
        items = this.generatorItems;
      }
      let filteredItems = items.map(({ value, ...rest }) => ({
        ...rest,
        value: Number(value),
      }));
      switch (this.sortBy) {
        case "nameASC":
          filteredItems = filteredItems.sort((a, b) =>
            a.name < b.name ? -1 : b.name > a.name ? 1 : 0
          );
          break;
        case "nameDESC":
          filteredItems = filteredItems.sort((a, b) =>
            a.name > b.name ? -1 : b.name < a.name ? 1 : 0
          );
          break;
        case "valueASC":
          filteredItems = filteredItems.sort((a, b) =>
            isNaN(a.value) ? -1 : a.value - b.value
          );
          break;
        case "valueDESC":
          filteredItems = filteredItems.sort((a, b) =>
            isNaN(b.value) ? -1 : b.value - a.value
          );
          break;
        case "typeASC":
          filteredItems = filteredItems.sort((a, b) =>
            a.type < b.type ? -1 : b.type > a.type ? 1 : 0
          );
          break;
        case "typeDESC":
          filteredItems = filteredItems.sort((a, b) =>
            a.type > b.type ? -1 : b.type < a.type ? 1 : 0
          );
          break;
        case "rarityASC":
          filteredItems = filteredItems.sort((a, b) =>
            this.rarities.indexOf(a.rarity) < this.rarities.indexOf(b.rarity)
              ? -1
              : this.rarities.indexOf(a.rarity) >
                this.rarities.indexOf(b.rarity)
              ? 1
              : 0
          );
          break;
        case "rarityDESC":
          filteredItems = filteredItems.sort((a, b) =>
            this.rarities.indexOf(a.rarity) > this.rarities.indexOf(b.rarity)
              ? -1
              : this.rarities.indexOf(a.rarity) <
                this.rarities.indexOf(b.rarity)
              ? 1
              : 0
          );
          break;
        case "srcASC":
          filteredItems = filteredItems.sort((a, b) =>
            a.source > b.source ? -1 : b.source < a.source ? 1 : 0
          );
          break;
        case "srcDESC":
          filteredItems = filteredItems.sort((a, b) =>
            a.source < b.source ? -1 : b.source > a.source ? 1 : 0
          );
          break;
        default:
          "";
      }
      return filteredItems;
    },
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
  },

  /// GET THIS TO FIRE ON LAUNCH
  watch: {
    loggedIn(newValue) {
      if (newValue) {
        this.$emit("refreshFilter");
      }
    },
  },
  methods: {
    delayedRefresh() {
      setTimeout(() => {
        this.$emit("refreshFilter");
      }, 2000);
    },
    printPDF() {
      this.mode == "priceList"
        ? this.$refs.PriceListTablePrintPDF.printPdfModal()
        : this.$emit("printNPC");
    },
    addToShop(item, shopName) {
      if (
        !this.filteredItems.some((object) => object.name == item.name) &&
        item.name.length > 0
      ) {
        this.$store.dispatch("addToShop", { item: item, shopName: shopName });
        this.updateKey++;
      }
    },
    changeListStyle(listStyle) {
      this.listStyle = listStyle;
    },
    updateItem(itemName, property) {
      var changedItem = this.filteredItems.find(
        (item) => item.name == itemName
      );
      let value = event.target.innerHTML;
      if (property == "value") {
        value = parseFloat(value.replace(/[^0-9]/g, ""));
        if (isNaN(value)) {
          value = 0;
        }
        console.log(value);
      }
      if (changedItem[property] != value) {
        changedItem.source = "Custom";
        changedItem[property] = value;
        this.$store.dispatch("saveToUserItems", changedItem);
      }
      this.$emit("refreshFilter");
    },
    deleteItem(item) {
      this.$emit("filterItem", item);
      this.$store.dispatch("deleteItem", item.name);
      this.$emit("refreshFilter");
    },
    toggleType(x) {
      this.showType = x;
    },
    toggleSubtype(x) {
      this.showSubtype = x;
    },
    toggleRarity(x) {
      this.showRarity = x;
    },
    toggleValue(x) {
      this.showValue = x;
    },
    toggleSubvalue(x) {
      this.showSubvalue = x;
    },
    toggleSource(x) {
      this.showSource = x;
    },
    toggleAttunement(x) {
      this.showAttunement = x;
    },
  },
};
</script>

<style scoped>
body {
  background-color: var(--dark3);
}

.price-list {
  position: relative;
  width: 80%;
  max-width: 1200px;
  margin: 1vh auto 20px auto;
  font-size: 15px;
  font-family: montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.price-list.generator {
  width: 100%;
  margin: 0px !important;
}

.item-list {
  -webkit-column-count: 2;
  /* Chrome/Opera, Safari */
  -moz-column-count: 2;
  /* Mozilla Firefox */
  column-count: 2;
  color: white;
}

.add-shop-menu {
  position: absolute;
  left: 100%;
  top: -50%;
  transform: translate(0, -50%);
  width: 200px;
  font-family: "montserrat", sans-serif;
  background: #0b0b16;
  color: white;
  border-radius: 5px;
  padding: 5px;
  user-select: none;
  z-index: 1000;
}

.add-shop-menu h3 {
  text-align: center;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
}

.add-shop-menu > div {
  margin-top: 5px;
  padding: 5px;
  transition: all 0.3s ease;
  font-size: 0.8rem;
}

.add-shop-menu > div:hover {
  background: #2f2f3d;
  transition: all 0.3s ease;
}

/* .pricelist-container {
  width: 95%;
  max-width: 1200px;
  margin: 1vh auto 100px auto;
  font-family: montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

h3 {
  margin-bottom: 5px;
}

h4 {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 1rem;
}

.item-table {
  width: 100%;
}

.item-table > div {
  width: 100%;
}

.item {
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  background: white;
  width: 100%;
  text-align: left;
  position: relative;
}

/* .item > div {
  display:flex;
  align-items:center;
} */

.item:nth-of-type(odd) {
  background: rgb(235, 238, 238);
}

.item:last-of-type {
  border-radius: 0px 0px 5px 5px;
}

.item-name {
  font-weight: 600;
  cursor: pointer;
}

.item-subheader {
  padding: 0px;
  font-style: italic;
  font-size: 0.8rem;
  font-weight: 400;
  color: black;
}

.item-source {
  text-align: right;
  justify-content: flex-end;
}

.item-value {
  text-align: right;
  justify-content: flex-end;
  cursor: pointer;
}

.item-subvalue {
  font-size: 0.8rem;
  font-style: italic;
}

.item-value,
.item-rarity,
.item-type,
.item-source {
  flex-grow: 1;
}

/* WIDTHS FOR TABLE COLUMNS */

.long {
  width: 40%;
}

.medium {
  width: 25%;
}

.small {
  width: 20%;
}

.tiny {
  width: 12.5%;
}

.item-name.long {
  width: 60%;
}

.item-name.medium {
  width: 60%;
}

.item-name.small {
  width: 45%;
}

.item-name.tiny {
  width: 50%;
}

/*  */

.header {
  background: #0a0a0a !important;
  color: white;
  position: relative;
  font-weight: 600;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
}

i {
  cursor: pointer;
  padding: 5px;
}

.input-type {
  width: 100px;
}

.input-subtype {
  width: 100px;
}

.input-rarity {
  width: 100px;
}

.input-value {
  width: 30%;
}

.input-subvalue {
  width: 90%;
}

.add-shop {
  font-size: 0.8rem;
}

.item .fas,
.item-name .fas,
.item-name .add-shop {
  opacity: 0;
  transition: all 0.3s ease;
  position: relative;
}

.item .item-src {
  opacity: 1;
  transition: all 0.3s ease;
  position: relative;
}

.item:hover .fas,
.item-name:hover .fas,
.item-name:hover .add-shop,
.item:hover .item-src {
  transition: all 0.3s ease;
  opacity: 1;
}

.header .fas {
  color: white;
  opacity: 1;
}

#source {
  cursor: pointer;
}

.highlight {
  color: rgb(0, 253, 169);
}

.common {
  color: black;
}

.uncommon {
  color: #32a82e;
}

.rare {
  color: #5992d4;
}

.very-rare {
  color: #9810e0;
}

.legendary {
  color: #fea227;
}

.artifact {
  color: #be8972;
}

.fa-adn,
.item-list-item .fas {
  color: black;
  font-size: 0.8rem;
  margin: 0px;
  padding: 0px 2px 1px 5px;
}

input {
  background: white;
  color: black;
  padding: 5px;
}

.input-attunement {
  width: 25px;
}

.add-item-button {
  background: var(--dark);
  color: white;
  border: none;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
  font-family: montserrat, sans-serif;
  padding: 0.5rem;
  text-align: center;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  width: 300px;
  margin: 10px auto;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.add-item-button:hover {
  transform: scale(1.1);
}

.item-src {
  margin-left: 0.2rem;
  font-size: 0.7rem;
}

.gray {
  color: gray;
}

.pink {
  color: palevioletred;
}

.darkgreen {
  color: rgb(1, 158, 1);
}

.darkblue {
  color: darkblue;
}

.teal {
  color: aquamarine;
}

.red {
  color: crimson;
}

.hover-menu {
  position: absolute;
  left: 0;
  bottom: 50%;
  transform: translateX(-100%) translateY(50%);
  color: white !important;
  font-size: 1.3rem;
}

.pdf-modal {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  z-index: 100;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1079px) {
  .price-list {
    width: 100%;
    margin: 0px 0px 80px 0px;
  }

  .item .fas,
  .item-name .fas,
  .item-name .add-shop,
  .item .item-src {
    display: none;
  }

  .fa-caret-down {
    display: inline-block !important;
  }

  .item-name {
    width: 65%;
  }
}
</style>
