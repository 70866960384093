export default {
    cultIdeals: [
        "Ruthless",
        "Eliminating Opposition",
        "Strict Rules",
        "Complete Devoted",
        "Prophecy"
    ],
    cultMethods: [
        "Force & Violence",
        "Sabotage & Destruction",
        "Subterfuge & Intrigue",
        "Assassionation & Infiltration",
        "Propaganda",
    ],
    adjectives: [
        "The Divine",
        "The Shrouded",
        "The Sacred",
        "The Unwavering",
        "The Eternal",
        "The True",
        "The Celestial",
        "The Forbidden",
        "The Crimson",
        "The Consecrated",
        "The Radiant",
        "The Reborn",
        "The Righteous",
        "The Soaring",
        "The Exalted",
        "The Iridescent",
        "The Shadow",
        "The Enigmatic",
        "The Ancient",
        "The Pristine",
        "The Enlightened",
        "The Ascended",
        "The Serpent",
        "The Dreaming",
        "The Cursed",
        "The Crystal",
        "The Holy",
        "The Fervent",
        "The Ascending",
        "The Whispering",
        "The Forsaken",
        "The Silent",
        "The Glorious",
        "The Starlit",
        "The Secret",
        "The Twilight",
        "The Hallowed",
        "The Umbral",
        "The Ethereal",
        "The Veiled",
        "The Ethereal",
        "The Wandering",
        "The Luminous",
        "The Wise",
        "The Dark",
        "The Exiled",
        "The Forsworn",
        "The Venerable"
      ],
      nouns: [
        "Order",
        "Martyrs",
        "Brotherhood",
        "Warriors",
        "Sisterhood",
        "Remnant",
        "Circle",
        "Prophets",
        "Assembly",
        "Arbiters",
        "Conclave",
        "Visionaries",
        "Covenant",
        "Paragons",
        "Fellowship",
        "Pilgrims",
        "Guardians",
        "Sentinels",
        "Society",
        "Initiates",
        "Disciples",
        "Heralds",
        "Children",
        "Reclaimers",
        "Seekers",
        "Oathsworn",
        "Servants",
        "Gurus",
        "Watchers",
        "Wanderers",
        "Acolytes",
        "Devotees",
        "Keepers",
        "Preservers",
        "Legion",
        "Emissaries",
        "Chosen",
        "Inheritors",
        "Faith",
        "Gatekeepers",
        "Path",
        "Stewards",
        "Wayfarers",
        "Exiles",
        "Scribes",
        "Sanctum",
        "Oracles",
        "Luminaries",
        "Pioneers",
        "Communion"
      ],
      descriptors: [
        "of the Dawn",
        "of the Golden Age",
        "of the Dusk",
        "of the Guiding Light",
        "of Eternity",
        "of the Wandering Moon",
        "of the Flame",
        "of the Eternal Cycle",
        "of the Shadows",
        "of the Howling Storm",
        "of the Stars",
        "of the Veil",
        "of the Abyss",
        "of the Astral Plane",
        "of the Forgotten",
        "of the Vision",
        "of Hidden Truths",
        "of the Celestial",
        "of the Moon",
        "of the Unending",
        "of the Crimson Sky",
        "of the Shattered Souls",
        "of the Divine Light",
        "of the Inner Sanctum",
        "of the Winds",
        "of the Sacred Runes",
        "of the Mountain",
        "of the Fading Echoes",
        "of the Endless Sea",
        "of the Timeless Sands",
        "of the Grove",
        "of the Vortex",
        "of the Silent Path",
        "of the Silent Monolith",
        "of the Eternal Sun",
        "of the Mystic Waters",
        "of the Prophecy",
        "of the Burning Heart",
        "of the Broken Chains",
        "of the Frozen Wastes",
        "of the Spheres",
        "of the Undying",
        "of the Verdant Forest",
        "of the Arcane Tower",
        "of the Iron Will",
        "of the Unbroken Circle",
        "of the Boundless Depths",
        "of the Phoenix's Flight",
        "of the Risen Phoenix",
        "of the Cosmic"
      ],
    cultFilters: [
        {
            name: "Type",
            options: [
                {
                    value: "Cult",
                    selected: true,
                },
                {
                    value: "Sect",
                    selected: true,
                },
            ],
        },
        {
            name: "Size",
            options: [
                {
                    value: "Small",
                    selected: true,
                },
                {
                    value: "Medium",
                    selected: true,
                },
                {
                    value: "Large",
                    selected: true,
                },
            ],
        },
        {
            name: "Hierarchy",
            options: [
                {
                    value: "Hierarchical",
                    selected: true,
                },
                {
                    value: "Flat",
                    selected: true,
                },
                {
                    value: "Mixed",
                    selected: true,
                }
            ]
        },
        {
            name: "Organization",
            options: [
                {
                    value: "Centralized",
                    selected: true,
                },
                {
                    value: "Decentralized",
                    selected: true,
                },
                {
                    value: "Mixed",
                    selected: true,
                },
            ],
        },
    ],
    npcFilters: [
        {
            name: "Origin",
            options: [
                {
                    value: "Eternal",
                    selected: true,
                },
                {
                    value: "Created",
                    selected: true,
                },
                {
                    value: "Transformed",
                    selected: true,
                },
            ],
        },
        {
            name: "Transformation",
            options: [
                {
                    value: "Magic",
                    selected: true,
                },
                {
                    value: "Taken",
                    selected: true,
                },
                {
                    value: "Given",
                    selected: true,
                },
            ],
        },
        {
            name: "Divine Rank",
            options: [
                {
                    value: "Lesser God",
                    selected: true,
                },
                {
                    value: "God",
                    selected: true,
                },
                {
                    value: "Greater God",
                    selected: true,
                },
            ],
        },
    ],
    alignments:
    {
        name: "Alignment",
        options: [
            {
                value: "Lawful Good",
                selected: true,
            },
            {
                value: "Neutral Good",
                selected: true,
            },
            {
                value: "Chaotic Good",
                selected: true,
            },
            {
                value: "Lawful Neutral",
                selected: true,
            },
            {
                value: "True Neutral",
                selected: true,
            },
            {
                value: "Chaotic Neutral",
                selected: true,
            },
            {
                value: "Lawful Evil",
                selected: true,
            },
            {
                value: "Neutral Evil",
                selected: true,
            },
            {
                value: "Chaotic Evil",
                selected: true,
            },
        ],

    },
}