export default {
    deityTitles: [
        {
            name: "Lord",
            gender: "Male",
        },
        {
            name: "Lady",
            gender: "Female",
        },
        {
            name: "God",
            gender: "Neutral"
        },
        {
            name: "Goddess",
            gender: "Female"
        },
        {
            name: "Master",
            gender: "Male"
        },
        {
            name: "Mistress",
            gender: "Female"
        },
        {
            name: "Bringer",
            gender: "Neutral"
        },
        {
            name: "Keeper",
            gender: "Neutral"
        },
        {
            name: "King",
            gender: "Male"
        },
        {
            name: "Queen",
            gender: "Female"
        },
        {
            name: "Weaver",
            gender: "Neutral"
        },
        {
            name: "Prince",
            gender: "Male",
        },
        {
            name: "Princess",
            gender: "Female"
        },
        {
            name: "Father",
            gender: "Male",
        },
        {
            name: "Mother",
            gender: "Female"
        }
    ],
    coreDivineDomains: [
        { name: "Chaos", prefixes: ["Anarchy", "Discord", "Entropy", "Mad "], suffixes: ["of Anarchy", "of Chaos", "of Discord", "of Entropy", "of Madness", "of Turmoil"], fixed: ["Discordant One", "Mad One", "Turbulent One"], motivations: ["to sow disorder and inspire creativity", "to challenge order and provoke change", "to embody the randomness and unpredictability of life"] },

        { name: "Darkness", prefixes: ["Dark", "Dusk", "Night", "Shadow"], suffixes: ["of Darkness", "of Dusk", "of Night", "of Shadow", "of the Night", "of the Shadows", "of Twilight"], fixed: ["Dark One"], motivations: ["to rule over the unseen and the secrets of the night", "to comfort and guide those who dwell in darkness", "to represent mystery, hidden truths, and uncertainty"] },

        { name: "Death", prefixes: ["Death", "Doom", "Grave", "Grim", "Rot"], suffixes: ["of Death", "of Doom", "of the Grave", "of the Grim", "of the Rot", "of the Tomb", "of Judgement", "of the Dead", "of Endings", "of Souls", "of Undeath", "of the Afterlife"], fixed: ["The Undying One", "The Death One", "The Grim Reaper", "The Reaper"], motivations: ["to guide souls to the afterlife", "to uphold the cycle of life and death", "to teach the inevitability and acceptance of mortality"] },

        { name: "Fate", prefixes: ["Fate", "Destiny", "Luck"], suffixes: ["of Fate", "of Destiny", "of Luck", "of Fortune", "of the Fates", "of the Future", "of the Past", "of Providence", "of Foresight"], fixed: ["The Fated One", "The Weaver", "The Spinner", "The Loom", "The All-Seeing One"], motivations: ["to weave the threads of destiny", "to guide beings towards their fate", "to uphold the balance between chance and destiny"] },

        { name: "Knowledge", prefixes: ["Lore", "Truth", "Wisdom"], suffixes: ["of Knowledge", "of Lore", "of Truth", "of Wisdom", "of the Mind", "of the Wise", "of the Learned", "of Tomes"], fixed: ["The Scholar", "The Wise One", "The All-Knowing One"], motivations: ["to foster learning and intellectual growth", "to guard and disseminate knowledge", "to encourage curiosity and discovery"] },

        { name: "Life", prefixes: ["Life"], suffixes: ["of Birth", "of Creation", "of Growth", "of Healing", "of Life", "of Vitality"], fixed: ["The Merciful One", "The Healer", "The Merciful"], motivations: ["to uphold the sanctity and vitality of life", "to promote growth and renewal", "to bring healing and comfort to the suffering"] },

        { name: "Light", prefixes: ["Dawn", "Light", "Morning", "Sun"], suffixes: ["of Dawn", "of Light", "of the Sun", "of the Dawn", "of the Light"], fixed: ["The Radiant One", "The Shining One", "The Sunlight"], motivations: ["to bring illumination and enlightenment to all beings", "to vanquish darkness and ignorance", "to inspire hope and courage through light"] },

        { name: "Magic", prefixes: ["Magic", "Mystic", "Spell"], suffixes: ["of Arcana", "of Mysteries", "of Spells", "of Wonders"], fixed: ["The Mage", "The Magician", "The Sorcerer", "The Sorceress", "The Wizard", "The Witch"], motivations: ["to unlock the arcane secrets of the universe", "to uphold the balance between magic and nature", "to guide those who seek magical knowledge"] },

        { name: "Nature", prefixes: ["Nature", "Wild"], suffixes: ["of Animals", "of Beasts", "of Plants", "of Seasons", "of the Weather", "of the Wilderness"], fixed: ["The Wild One", "The Earthmother"], motivations: ["to protect and preserve the natural world", "to ensure the harmony between man and nature", "to guide the cycles of life and growth"] },

        { name: "Order", prefixes: ["Law", "Order", "Justice"], suffixes: ["of Civilization", "of Duty", "of Judge", "of Law", "of Obedience", "of Rules"], fixed: ["The Just One", "The Lawgiver", "The Judge", "The Arbiter"], motivations: ["to establish and maintain laws and order", "to ensure justice prevails", "to guide societies towards harmony and balance"] },

        { name: "Peace", prefixes: ["Peace"], suffixes: ["of Calm", "of Harmony", "of Pacifism", "of Peace", "of Serenity", "of Tranquility"], fixed: ["The Peaceful One", "The Tranquil One", "The Serene One"], motivations: ["to promote harmony and goodwill", "to soothe conflict and hostility", "to inspire serenity and tranquility"] },

        { name: "War", prefixes: ["War", "Battle"], suffixes: ["of Battle", "of Bloodshed", "of Conflict", "of Strife", "of Violence", "of War"], fixed: ["The Warmonger", "The Conqueror", "The Victorious One"], motivations: ["to champion the causes worth fighting for", "to guide warriors in battle", "to uphold the balance between conflict and peace"] },
    ],
    additionalDivineDomains: [
        { name: "Air", synonyms: ["Breath", "Wind", "Flight"], motivations: ["to breathe life into the still and the stagnant", "to spread seeds of change and new ideas", "to inspire all beings to embrace freedom and mobility"] },
        { name: "Animals", synonyms: ["Beasts", "Creatures", "Fauna"], motivations: ["to ensure harmony between human beings and animals", "to defend the rights of all creatures", "to spread the wisdom and strength of animals to the world"] },
        { name: "Art", synonyms: ["Creativity", "Expression", "Aesthetics"], motivations: ["to inspire creation and self-expression in all forms", "to bring beauty and imagination into the world", "to foster understanding through creative dialogue"] },
        { name: "Beauty", synonyms: ["Grace", "Elegance", "Allure"], motivations: ["to spread beauty to every corner of the world", "to instill a love of beauty in all beings", "to protect and enhance the inherent beauty of nature"] },
        { name: "Civilization", synonyms: ["Society", "Culture", "Progress"], motivations: ["to foster development and advancement in societies", "to maintain order and cooperation among groups", "to inspire invention and cultural exchange"] },
        { name: "Courage", synonyms: ["Bravery", "Valor", "Fearlessness"], motivations: ["to inspire heroism and bravery in the face of adversity", "to protect the weak and innocent", "to defeat evil and uphold justice"] },
        { name: "Decay", synonyms: ["Deterioration", "Degradation", "Rot"], motivations: ["to teach the importance of endings and the cycle of life", "to remove the old to make way for the new", "to maintain balance through decay and rejuvenation"] },
        { name: "Destruction", synonyms: ["Annihilation", "Ruin", "Devastation"], motivations: ["to break down barriers and structures, creating room for new growth", "to destroy tyranny and corruption", "to symbolize the power and finality of endings"] },
        { name: "Dreams", synonyms: ["Visions", "Fantasies", "Aspirations"], motivations: ["to inspire creativity and ambition through dreams", "to guide individuals via dreams", "to protect the realm of dreams and nightmares"] },
        { name: "Duty", synonyms: ["Responsibility", "Obligation", "Commitment"], motivations: ["to instill a sense of duty and honor in every soul", "to maintain order and balance", "to protect and uphold sacred and societal laws"] },
        { name: "Earth", synonyms: ["Ground", "Soil", "Terrain"], motivations: ["to keep the earth fertile and abundant", "to maintain balance in all natural life", "to protect and nurture all creatures of the earth"] },
        { name: "Family", synonyms: ["Kinship", "Lineage", "Ancestry"], motivations: ["to strengthen bonds of family and community", "to protect and guide family lines", "to uphold traditions and familial customs"] },
        { name: "Fertility", synonyms: ["Abundance", "Growth", "Fecundity"], motivations: ["to spread abundance and prosperity", "to ensure the growth and survival of all life forms", "to bring balance to the cycle of life and death"] },
        { name: "Fire", synonyms: ["Flame", "Heat", "Combustion"], motivations: ["to ignite the fires of passion and creativity", "to purify and renew through flame", "to control and balance the primal forces of fire"] },
        { name: "Freedom", synonyms: ["Liberty", "Independence", "Autonomy"], motivations: ["to liberate those in bondage", "to advocate for self-determination and autonomy", "to instill the value of independence"] },
        { name: "Glory", synonyms: ["Honor", "Prestige", "Magnificence"], motivations: ["to inspire and reward acts of valor", "to strive for personal and communal greatness", "to spread the story of heroism and honor"] },
        { name: "Honor", synonyms: ["Integrity", "Dignity", "Respect"], motivations: ["to uphold the value of honor in action and word", "to punish dishonor and deceit", "to guide mortals to lead respectable lives"] },
        { name: "Hope", synonyms: ["Optimism", "Faith", "Expectation"], motivations: ["to inspire hope during dark times", "to keep the light of faith burning in the hearts of all beings", "to guard against despair and cynicism"] },
        { name: "Invention", synonyms: ["Innovation", "Creation", "Discovery"], motivations: ["to inspire technological and conceptual breakthroughs", "to foster the spirit of innovation and curiosity", "to guide societies towards progress and enlightenment"] },
        { name: "Justice", synonyms: ["Fairness", "Equity", "Righteousness"], motivations: ["to ensure fairness and justice in all dealings", "to defend the oppressed and punish the oppressor", "to uphold the law and the principles of righteousness"] },
        { name: "Joy", synonyms: ["Happiness", "Delight", "Elation"], motivations: ["to bring joy and happiness to all beings", "to ease suffering and bring laughter", "to inspire celebrations and festivities"] },
        { name: "Law", synonyms: ["Order", "Rules", "Regulations"], motivations: ["to uphold the rule of law and order", "to guide societies to create fair and just laws", "to maintain balance and prevent chaos"] },
        { name: "Loss", synonyms: ["Grief", "Sorrow", "Deprivation"], motivations: ["to comfort and aid those in grief", "to teach the value of loss and the importance of mourning", "to guide souls through the journey of loss and acceptance"] },
        { name: "Love", synonyms: ["Affection", "Passion", "Devotion"], motivations: ["to spread the power of love and affection", "to unite souls in bonds of love", "to heal through the power of love"] },
        { name: "Luck", synonyms: ["Fortune", "Chance", "Fate"], motivations: ["to influence the tides of fortune and chance", "to bestow good luck and ward off misfortune", "to guide individuals through the uncertainties of life"] },
        { name: "Madness", synonyms: ["Insanity", "Lunacy", "Delirium"], motivations: ["to blur the line between sanity and insanity", "to show that madness can lead to revelation or destruction", "to remind everyone of the fragile nature of the mind"] },
        { name: "Mercy", synonyms: ["Compassion", "Clemency", "Leniency"], motivations: ["to instill the virtues of compassion and mercy", "to guide the merciful and punish the merciless", "to remind beings of the power of forgiveness"] },
        { name: "Moon", synonyms: ["Lunar", "Satellite", "Glow"], motivations: ["to guide and protect those who travel by night", "to symbolize the cycles of change and renewal", "to inspire contemplation and introspection"] },
        { name: "Murder", synonyms: ["Homicide", "Killing", "Assassination"], motivations: ["to bring justice to those wrongfully slain", "to maintain the balance of life and death", "to understand and control the darker aspects of mortality"] },
        { name: "Nobility", synonyms: ["Aristocracy", "Dignity", "Eminence"], motivations: ["to uphold the values of dignity and honor", "to guide and protect leaders and rulers", "to ensure the nobility uses its power wisely and justly"] },
        { name: "Pain", synonyms: ["Suffering", "Anguish", "Torment"], motivations: ["to provide solace and strength to those in suffering", "to teach the value of endurance and resilience", "to balance pain and pleasure in the world"] },
        { name: "Plants", synonyms: ["Flora", "Vegetation", "Botany"], motivations: ["to ensure the growth and wellbeing of plant life", "to promote harmony between plants and other forms of life", "to inspire respect for the power and beauty of nature"] },
        { name: "Protection", synonyms: ["Guardianship", "Defense", "Safety"], motivations: ["to protect the weak and innocent", "to shield the world from threats unseen", "to encourage the strong to protect those in need"] },
        { name: "Renewal", synonyms: ["Rebirth", "Restoration", "Revival"], motivations: ["to inspire and facilitate personal and communal renewal", "to guide beings through periods of change and transformation", "to heal the wounds of the past and look towards a brighter future"] },
        { name: "Retribution", synonyms: ["Vengeance", "Punishment", "Reprisal"], motivations: ["to ensure justice is served and debts are paid", "to guide those seeking rightful vengeance", "to deter acts of wrongdoing through fear of retribution"] },
        { name: "Secrets", synonyms: ["Mysteries", "Enigmas", "Concealment"], motivations: ["to guard ancient and powerful secrets", "to inspire curiosity and the pursuit of hidden knowledge", "to maintain the delicate balance between revelation and concealment"] },
        { name: "Strength", synonyms: ["Power", "Might", "Fortitude"], motivations: ["to inspire physical and mental strength", "to guide and protect warriors and champions", "to encourage perseverance in the face of adversity"] },
        { name: "Summer", synonyms: ["Warmth", "Sunlight", "Growth"], motivations: ["to ensure the warmth and vitality of summer", "to foster growth and abundance", "to symbolize the joy and energy of life"] },
        { name: "Sun", synonyms: ["Solar", "Daylight", "Radiance"], motivations: ["to bring light and warmth to the world", "to banish darkness and promote growth", "to guide and protect those who travel by day"] },
        { name: "Tempest", synonyms: ["Storm", "Turbulence", "Fury"], motivations: ["to command the raw forces of the storm", "to punish and cleanse with the fury of the elements", "to reflect the tumultuous nature of change and emotion"] },
        { name: "Time", synonyms: ["Chronology", "Era", "Temporality"], motivations: ["to govern the flow of time and fate", "to remind beings of their mortality", "to preserve the continuum of past, present, and future"] },
        { name: "Travel", synonyms: ["Journey", "Voyage", "Exploration"], motivations: ["to guide and protect travellers", "to inspire the spirit of adventure and discovery", "to connect all paths and destinations"] },
        { name: "Trickery", synonyms: ["Deception", "Illusion", "Manipulation"], motivations: ["to foster cunning and cleverness", "to reveal truth through deception", "to guide and guard thieves, tricksters, and pranksters"] },
        { name: "Truth", synonyms: ["Honesty", "Reality", "Veracity"], motivations: ["to reveal truth and dispel lies", "to guide beings towards wisdom and understanding", "to uphold the sanctity of honesty and sincerity"] },
        { name: "Twilight", synonyms: ["Dusk", "Evening", "Gloom"], motivations: ["to govern the mysteries of dusk and dawn", "to comfort and guide those who dwell in twilight", "to symbolize transitions and transformations"] },
        { name: "Undeath", synonyms: ["Unlife", "Reanimation", "Necromancy"], motivations: ["to guard the boundary between life and death", "to teach the dangers and possibilities of tampering with mortality", "to guide and control the unliving"] },
        { name: "Water", synonyms: ["Aqua", "Hydro", "Liquid"], motivations: ["to govern the flow and purity of water", "to protect sea travellers and aquatic creatures", "to reflect the emotional depth and adaptability of life"] },
        { name: "Wealth", synonyms: ["Prosperity", "Abundance", "Riches"], motivations: ["to promote prosperity and abundance", "to guide and protect those seeking wealth", "to teach the value and dangers of material wealth"] },
        { name: "Wisdom", synonyms: ["Insight", "Knowledge", "Understanding"], motivations: ["to enlighten beings and guide them towards wisdom", "to encourage the pursuit of knowledge", "to provide insight and understanding"] },
        { name: "Winter", synonyms: ["Cold", "Frost", "Hibernate"], motivations: ["to see the whole world frozen over", "to unthaw its frozen heart", "to preserve and protect a secret claimed by ice and snow"] }
    ],
    pantheonFilters: [
        {
            name: "Size",
            options: [
                {
                    value: "Small",
                    selected: true,
                },
                {
                    value: "Medium",
                    selected: true,
                },
                {
                    value: "Large",
                    selected: true,
                },
            ],
        },
        {
            name: "Hierarchy",
            options: [
                {
                    value: "Flat",
                    selected: true,
                },
                {
                    value: "Hierarchical",
                    selected: true,
                },
                {
                    value: "Mixed",
                    selected: true,
                },
            ],
        },
        {
            name: "Organization",
            options: [
                {
                    value: "None",
                    selected: true,
                },
                {
                    value: "Grouped",
                    selected: true,
                },
                {
                    value: "Mixed",
                    selected: true,
                },
            ],
        },
        {
            name: "Relationships",
            options: [
                {
                    value: "None",
                    selected: true,
                },
                {
                    value: "Some",
                    selected: true,
                },
                {
                    value: "Frequent",
                    selected: true,
                },
            ],
        },
    ],
    pantheonNames: [
        { name: "The Old Gods" },
        { name: "The Celestial Council" },
        { name: "The Eternals" },
        { name: "The Divine Conglomerate" },
        { name: "The Ethereal Assembly" },
        { name: "The Pantheon of Twilight" },
        { name: "The Hallowed Ones" },
        { name: "The Starborne Deities" },
        { name: "The Immortal Collective" },
        { name: "The Sages of Infinity" },
        { name: "The Aeonian Keepers" },
        { name: "The Sublime Eternals" },
        { name: "The Transcendent Council" },
        { name: "The Infinite Pantheon" },
        { name: "The Elder Assembly" },
        { name: "The Timeless Enclave" },
        { name: "The Aetherial Lords" },
        { name: "The Guardians of Eternity" },
        { name: "The Luminary Gathering" },
        { name: "The Circle of Divinity" },
        { name: "The Cosmic Synergy" },
        { name: "The Supreme Celestials" },
        { name: "The Immortal Summit" },
        { name: "The Empyrean Court" },
        { name: "The Archaic Powers" },
        { name: "The Astral Pantheon" },
        { name: "The Exalted Choir" },
        { name: "The Eldritch Sovereigns" },
        { name: "The Timeless Pantheon" },
        { name: "The Unseen Presences" },
        { name: "The Divine Synod" },
        { name: "The Sacred Continuum" },
        { name: "The Elysian Enclave" },
        { name: "The Primordial Patrons" },
        { name: "The Gods of the Nexus" },
        { name: "The Everlasting Conclave" },
        { name: "The Sublime Spectrum" },
        { name: "The Cosmic Collective" },
        { name: "The Sacred Spectrum" },
        { name: "The Ascendant Aegis" },
        { name: "The Hallowed Harmony" },
        { name: "The Majestic Myriad" },
        { name: "The Pantheon of Paragons" },
        { name: "The Ethereal Entities" },
        { name: "The Cosmic Chorus" },
        { name: "The Stellar Assembly" },
        { name: "The Omniscient Ones" },
        { name: "The Celestial Sages" },
        { name: "The Shimmering Pantheon" },
        { name: "The Supernal Synod" },
        { name: "The Gods of the Empyrean" },
        { name: "The Timeless Ones" }
    ],    
    deityFilters: [
        {
            name: "Origin",
            options: [
                {
                    value: "Eternal",
                    selected: true,
                },
                {
                    value: "Created",
                    selected: true,
                },
                {
                    value: "Transformed",
                    selected: true,
                },
            ],
        },
        {
            name: "Transformation",
            options: [
                {
                    value: "Magic",
                    selected: true,
                },
                {
                    value: "Taken",
                    selected: true,
                },
                {
                    value: "Given",
                    selected: true,
                },
            ],
        },
        {
            name: "Divine Rank",
            options: [
                {
                    value: "Lesser God",
                    selected: true,
                },
                {
                    value: "God",
                    selected: true,
                },
                {
                    value: "Greater God",
                    selected: true,
                },
            ],
        },
    ],
    deityAlignments:
    {
        name: "Alignment",
        options: [
            {
                value: "Lawful Good",
                selected: true,
            },
            {
                value: "Neutral Good",
                selected: true,
            },
            {
                value: "Chaotic Good",
                selected: true,
            },
            {
                value: "Lawful Neutral",
                selected: true,
            },
            {
                value: "True Neutral",
                selected: true,
            },
            {
                value: "Chaotic Neutral",
                selected: true,
            },
            {
                value: "Lawful Evil",
                selected: true,
            },
            {
                value: "Neutral Evil",
                selected: true,
            },
            {
                value: "Chaotic Evil",
                selected: true,
            },
        ],

    },
}