import { createApp } from "vue";
import App from "./App.vue";
import "./Firebase/firebaseInit.js";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import store from "./store/index.js";
import LoginPage from "./components/LoginPage.vue";
import CardGenerator from "./views/CardGenerator.vue";
import ShopGenerator from "./views/ShopGenerator.vue";
import PriceList from "./views/PriceList.vue";
import WelcomePage from "./views/WelcomePage.vue";
import ItemCollection from "./components/Collections/ItemCollection.vue";
import NpcCollection from "./components/Collections/NpcCollection.vue";
import PantheonCollection from "./components/Collections/PantheonCollection.vue";
import AccountPage from "./views/AccountPage.vue";
import CultGenerator from "./views/CultGenerator.vue";
import PantheonGenerator from "./views/PantheonGenerator.vue";
import MyCreations from "./views/MyCreations.vue";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: WelcomePage,
    },
    { path: "/login", component: LoginPage },
    { path: "/shopgenerator", component: ShopGenerator},
    { path: "/cardgenerator", component: CardGenerator },
    { path: "/pricelist", component: PriceList },
    { path: "/npccollection", component: NpcCollection },
    { path: "/itemcollection", component: ItemCollection },
    { path: "/pantheoncollection", component: PantheonCollection },
    { path: "/cultgenerator", component: CultGenerator },
    { path: "/pantheongenerator", component: PantheonGenerator },
    { path: "/account", component: AccountPage, meta: { requiresAuth: true } },
    { path: "/mycreations", component: MyCreations, meta: { requiresAuth: true } },
  ],
});

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged((user) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!user) {
        next({
          path: "/login",
          query: {
            redirect: to.fullPath,
          },
        });
      } else {
        next();
      }
    } else {
      next();
    }
  });
});

firebase.auth().onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);
});

const app = createApp(App);
app.use(router);
app.use(store);
app.mount("#app");
