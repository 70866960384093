<template>
  <transition name="fade">
    <div>
      <div class="overlay clear" @click="$emit('close')"></div>
      <transition name="fade">
        <BaseDialog v-if="tooManyCollections" title="You Have Too Many Collections" @close="tooManyCollections = false"
          @okay="tooManyCollections = false">
          <template #content>
            You can have 10 collections if you're
            a Premium user and 2 collections if you're not. Delete collections to make room for new ones – or <span
              @click="$router.push('/account')" target="_blank" style="color: blue; font-weight:bold">check out our Premium
              subscriptions here</span>
          </template>
        </BaseDialog>
      </transition>
      <div class="menu-container">

        <div class="menu-row centered">
          <h2>Collections</h2>
        </div>
        <div class="collection-container" :class="{ pulsing: tutorial && tutorialStep == 13 }">
          <Collection v-for="(collection, index) in collections.slice(0, collectionMax)" :collectionNumber="index"
            :key="collection.collectionID" :collectionID="collection.collectionID" :tutorial="tutorial"
            :tutorialStep="tutorialStep" @printCollection="this.$emit('printCollection')"
            @loadingCollection="this.$emit('loadingCollection')">
          </Collection>
        </div>
        <div class="relative">
          <div class="menu-row flex-center rounded add-collection" @click="addCollection()">
            <i class="fas fa-plus-circle"></i>
            <h3>Add Collection</h3>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import Collection from "./Collection.vue";
import BaseDialog from "./BaseDialog.vue";

export default {
  name: "CollectionMenu",
  emits: ["printCollection", "loadingCollection", "close"],
  props: ["tutorial", "tutorialStep"],
  components: {
    Collection,
    BaseDialog,
  },
  data() {
    return {
      tooManyCollections: false,
      patreonKey: "",
      openSidebar: false,
    };
  },
  computed: {
    ...mapState(["cards", "collections", "premium", "selectedCollectionID"]),
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
    collectionMax() {
      if (this.premium) {
        var collectionMax = 10;
      } else {
        collectionMax = 2;
      }
      return collectionMax;
    },
  },
  methods: {
    connectPatreon() {
      this.$store.dispatch("patreonConnect", this.patreonKey);
    },
    async addCollection() {
      if (this.collections.length < this.collectionMax) {
        this.$store.dispatch("saveCards", {
          collectionID: this.selectedCollectionID,
        });
        this.$store.dispatch("addCollection");
        const wait = (timeToDelay) =>
          new Promise((resolve) => setTimeout(resolve, timeToDelay));
        await wait(200);
        this.$store.dispatch("updateSelectedCollection", {
          collectionID:
            this.collections[this.collections.length - 1].collectionID,
        });
      } else {
        this.tooManyCollections = true;
      }
    },
  },
};
</script>

<style scoped>
.menu-container {
  z-index: 100;
}

.fas {
  font-size: 1rem;
  padding: 0 0.25rem;
  color: var(--main2);
  filter: grayscale(100%) opacity(0.9);
  transition: all 0.3s ease;
  cursor: pointer;
}

.menu-buttons {
  margin-top: 1rem;
}

.add-collection {
  margin: 0.25rem 0 0 0;
  background-color: rgb(68, 68, 102);
  padding: 0 0.25rem;
  transition: all 0.5s ease;
  cursor: pointer;
}

.add-collection:hover,
.add-collection:hover .fas {
  filter: grayscale(0%) opacity(1);
  color: var(--main2);
  transition: all 0.5s ease;
}

.option:not(:first-of-type) {
  margin-left: 5px;
}

.option.first {
  margin-left: 0px;
}

.collection-container {
  position: relative;
}

@media only screen and (max-width: 1080px) {}
</style>