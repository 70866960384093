export default {
  updateSelectedCards(state, cardID) {
  state.selectedCards.includes(cardID) ? state.selectedCards.splice(state.selectedCards.indexOf(cardID), 1) : state.selectedCards.push(cardID);
  },
  saveCards(state, cards) {
    state.cards = cards;
  },
  setNoShow(state) {
    state.showGuide = false;
  },
  SET_LOGGED_IN(state, value) {
    state.user.loggedIn = value;
  },
  SET_USER(state, data) {
    state.user.data = data;
  },
  SET_TUTORIALDONE(state, tutorialDone) {
    state.tutorialDone = tutorialDone;
  },
  SET_PREMIUM(state, premium) {
    state.premium = premium;
  },
  SET_PREMIUM_EXPIRATION(state, expiration) {
    state.premiumExpiration = expiration;
  },
  SET_PATREONKEY(state, patreonKey) {
    state.patreonKey = patreonKey;
  },
  SET_USERITEMS(state, userItems) {
    state.userItems = userItems;
  },

  selectNpc(state, shopName) {
    state.selectedShop = state.customShops.find(
      (shop) => shop.name == shopName
    );
    state.npcSelected = true;
  },

  deselectNpc(state) {
    state.npcSelected = false;
  },

  selectPantheon(state, pantheonID) {
    state.selectedPantheon = state.pantheons.find(
      (pantheon) => pantheon.id == pantheonID
    );
  },
  deselectPantheon(state) {
    state.selectedPantheon = null;
  },
  // COLLECTIONS //

  addCollection(state, { collectionID }) {
    if (
      typeof state.collections == "undefined" ||
      state.collections.length < 1
    ) {
      state.collections = [];
    }
    var unnamedNumber = 1;
    if (state.collections.length > 0) {
      unnamedNumber =
        state.collections.filter((collection) =>
          collection.name.startsWith("New Collection")
        ).length + 1;
    }
    var newCollection = {
      collectionID: collectionID,
      name: "New Collection " + unnamedNumber,
      cards: [],
    };
    state.collections.push(newCollection);
    state.selectedCollectionID = collectionID;
  },

  deleteCollection(state, { collectionID }) {
    state.collections = state.collections.filter(
      (collection) => collection.collectionID !== collectionID
    );
  },

  loadCollections(state, { collections }) {
    state.collections = collections;
    state.selectedCollectionID = collections[0].collectionID;
    state.cards = state.collections[0].cards;
  },

  updateSelectedCollection(state, { collectionID }) {
    state.selectedCollectionID = collectionID;
  },

  loaded(state, loaded) {
    state.loaded = loaded;
  },

  addCard(state, { cardID, cardType }) {
    if (typeof state.cards == "undefined" || state.cards.length < 0) {
      state.cards = [];
    }
    var newCard = {
      cardID: cardID,
      cardType: cardType,
      selectedType: "Armor",
      selectedSubtype: "Any",
      image: false,
      imageChoices: require
        .context("@/assets/item_images/armor/", true, /^.*\.png$/)
        .keys(),
      imageSRC: require
        .context("@/assets/item_images/armor/", true, /^.*\.png$/)
        .keys()[0],
      imageURL: "",
      armorBonus: "–",
      weaponBonus: "–",
      saveBonus: "–",
      spellBonus: "–",
    };

    if (cardType == "srd") {
      (newCard.selectedItem = {}),
        (newCard.selectedItem = Object.assign({}, state.itemsSRD[0])),
        (newCard.selectedRarity = "Uncommon"),
        (newCard.selectedAttunement = "No");
      newCard.itemName = "Adamantine Armor";
    } else if (cardType == "enchantments") {
      (newCard.selectedEnchantments = [
        { name: "Choose Enchantment" },
        { name: "Choose Enchantment" },
        { name: "Choose Enchantment" },
      ]),
        (newCard.selectedRarity = "Auto"),
        (newCard.selectedAttunement = "Auto");
      newCard.itemName = "Item";
    } else {
      (newCard.selectedRarity = "Common"), (newCard.selectedAttunement = "No");
      newCard.itemName = "Armor";
    }
    state.cards.push(newCard);
  },
  updateCardType(state, { cardID, cardType }) {
    // var cardToUpdate = state.cards.find((card) => card.cardID == cardID);
    state.cards.find((card) => card.cardID == cardID).cardType = cardType;
    if (cardType == "srd") {
      state.cards.find((card) => card.cardID == cardID).selectedItem = {
        name: "Choose Item",
      };
    } else if (cardType == "enchantments") {
      state.cards.find((card) => card.cardID == cardID).selectedEnchantments = [
        { name: "Choose Enchantment" },
        { name: "Choose Enchantment" },
        { name: "Choose Enchantment" },
      ];
      state.cards.find((card) => card.cardID == cardID).selectedRarity = "Auto";
      state.cards.find((card) => card.cardID == cardID).selectedAttunement =
        "Auto";
    }
    // const i = state.cards.indexOf((card) => card.cardID == cardID)
    // state.cards[i] = cardToUpdate;
  },
  deleteCard(state, { cardID }) {
    state.cards = state.cards.filter((card) => card.cardID !== cardID);
    if (state.selectedCardID == cardID) {
      state.editingCard = false;
    }
  },
  loadCards(state, { cards }) {
    state.cards = cards;
  },
  loadEnchantments(state, { enchantments }) {
    state.customEnchantments = enchantments;
  },

  // UPDATE CARD ATTRIBUTES //
  updateSelected(state, { cardID }) {
    if (
      state.collections.find(
        (collection) => collection.collectionID == state.selectedCollectionID
      ).cards &&
      state.collections.find(
        (collection) => collection.collectionID == state.selectedCollectionID
      ).cards.length > 0 &&
      state.selectedCollection
    ) {
      if (
        cardID &&
        state.collections
          .find(
            (collection) =>
              collection.collectionID == state.selectedCollectionID
          )
          .cards.some((card) => card.cardID == cardID)
      ) {
        state.selectedCardID = state.cards.find(
          (card) => card.cardID == cardID
        );
      } else {
        state.selectedCardID = state.cards[0].cardID;
      }
    }
  },
  updateEnchantments(state, { cardID, enchantments }) {
    state.cards.find((card) => card.cardID == cardID).selectedEnchantments =
      enchantments;
  },
  updateEnchantment(state, { cardID, index, enchantment }) {
    state.cards.find((card) => card.cardID == cardID).selectedEnchantments[
      index
    ] = enchantment;
  },
  updateEditingPreference(state, { cardID, editingPreference }) {
    state.cards.find((card) => card.cardID == cardID).editingPreference =
      editingPreference;
  },
  updateItem(state, { cardID, item }) {
    state.cards.find((card) => card.cardID == cardID).selectedItem = item;
  },
  updateItemName(state, { cardID, itemName }) {
    state.cards.find((card) => card.cardID == cardID).itemName = itemName;
  },
  updateItemDescription(state, { cardID, itemDescription }) {
    state.cards.find((card) => card.cardID == cardID).selectedItem.description =
      itemDescription;
  },
  updateAttunementBy(state, { cardID, attunementBy }) {
    state.cards.find((card) => card.cardID == cardID).attunementBy =
      attunementBy;
  },
  updateOptions(state, { cardID, options }) {
    state.cards.find((card) => card.cardID == cardID).options = options;
  },
  updateSelectedOption(state, { cardID, optionID, selectedOption }) {
    if (
      typeof state.cards.find((card) => card.cardID == cardID)
        .selectedOptions == "undefined"
    ) {
      state.cards.find((card) => card.cardID == cardID).selectedOptions = [];
    }
    state.cards.find((card) => card.cardID == cardID).selectedOptions[
      optionID
    ] = selectedOption;
    var itemDescription = state.cards
      .find((card) => card.cardID == cardID)
      .selectedItem.description.replace(/(\n)+/g, "</p><p>");
    for (
      let i = 0;
      i <
      state.cards.find((card) => card.cardID == cardID).selectedOptions.length;
      i++
    ) {
      itemDescription = itemDescription.replace(
        "[option]",
        state.cards.find((card) => card.cardID == cardID).selectedOptions[i]
          .value
      );
    }
    state.cards.find((card) => card.cardID == cardID).customOptionDescription =
      itemDescription;
  },
  updateItemType(state, { cardID, selectedType }) {
    state.cards.find((card) => card.cardID == cardID).selectedType =
      selectedType;
  },
  updateItemSubtype(state, { cardID, selectedSubtype }) {
    state.cards.find((card) => card.cardID == cardID).selectedSubtype =
      selectedSubtype;
  },
  updateRarity(state, { cardID, rarity }) {
    state.cards.find((card) => card.cardID == cardID).selectedRarity = rarity;
  },
  updateAttunement(state, { cardID, attunement }) {
    state.cards.find((card) => card.cardID == cardID).selectedAttunement =
      attunement;
  },
  updateImageChoices(state, { cardID, selectedType }) {
    var imageChoices = [];
    switch (selectedType) {
      case "Armor":
        imageChoices = require
          .context("@/assets/item_images/armor/", true, /^.*\.png$/)
          .keys();
        break;
      case "Potion":
        imageChoices = require
          .context("@/assets/item_images/potion/", true, /^.*\.png$/)
          .keys();
        break;
      case "Ring":
        imageChoices = require
          .context("@/assets/item_images/ring/", true, /^.*\.png$/)
          .keys();
        break;
      case "Rod":
        imageChoices = require
          .context("@/assets/item_images/rod_staff_wand/", true, /^.*\.png$/)
          .keys();
        break;
      case "Scroll":
        imageChoices = require
          .context("@/assets/item_images/scroll/", true, /^.*\.png$/)
          .keys();
        break;
      case "Staff":
        imageChoices = require
          .context("@/assets/item_images/rod_staff_wand/", true, /^.*\.png$/)
          .keys();
        break;
      case "Weapon":
        imageChoices = require
          .context("@/assets/item_images/weapon/", true, /^.*\.png$/)
          .keys();
        break;
      case "Wand":
        imageChoices = require
          .context("@/assets/item_images/rod_staff_wand/", true, /^.*\.png$/)
          .keys();
        break;
      case "Wondrous Item":
      case "Wondrous item":
        imageChoices = require
          .context("@/assets/item_images/wondrous_item/", true, /^.*\.png$/)
          .keys();
        break;
    }
    state.cards.find((card) => card.cardID == cardID).imageChoices =
      imageChoices;

    state.cards.find((card) => card.cardID == cardID).imageChoices =
      imageChoices;
  },
  updateImage(state, { cardID, image }) {
    state.cards.find((card) => card.cardID == cardID).cardBack
      ? (state.cards.find((card) => card.cardID == cardID).cardBackImage =
          image)
      : (state.cards.find((card) => card.cardID == cardID).image = image);
  },
  updateImageSRC(state, { cardID, imageSRC }) {
    state.cards.find((card) => card.cardID == cardID).imageSRC = imageSRC;
  },
  updateImageURL(state, { cardID, imageURL }) {
    state.cards.find((card) => card.cardID == cardID).imageURL = imageURL;
  },
  updateImageDimensions(state, { cardID, imageDimensions }) {
    state.cards.find((card) => card.cardID == cardID).imageDimensions =
      imageDimensions;
  },
  updateBackImageDimensions(state, { cardID, imageDimensions }) {
    state.cards.find((card) => card.cardID == cardID).backImageDimensions =
      imageDimensions;
  },
  updateArmorBonus(state, { cardID, bonus }) {
    state.cards.find((card) => card.cardID == cardID).armorBonus = bonus;
  },
  updateWeaponBonus(state, { cardID, bonus }) {
    state.cards.find((card) => card.cardID == cardID).weaponBonus = bonus;
  },
  updateSaveBonus(state, { cardID, bonus }) {
    state.cards.find((card) => card.cardID == cardID).saveBonus = bonus;
  },
  updateSpellBonus(state, { cardID, bonus }) {
    state.cards.find((card) => card.cardID == cardID).spellBonus = bonus;
  },
  updateLayout(state, { cardID, layout }) {
    state.cards.find((card) => card.cardID == cardID).cardLayout = layout;
  },
  updateDecor(state, { cardID, decor }) {
    state.cards.find((card) => card.cardID == cardID).cardDecor = decor;
  },
  updateBack(state, { cardID}) {
    state.cards.find((card) => card.cardID == cardID).cardBack = !state.cards.find((card) => card.cardID == cardID).cardBack ;
  },
  updateNameFont(state, { cardID, font }) {
    state.cards.find((card) => card.cardID == cardID).nameFont = font;
  },
  updateNameFontSize(state, { cardID, fontSize }) {
    state.cards.find((card) => card.cardID == cardID).nameFontSize = fontSize;
  },
  updateNameFontStyle(state, { cardID, fontStyle }) {
    state.cards.find((card) => card.cardID == cardID).nameFontStyle = fontStyle;
  },
  updateDescriptionFont(state, { cardID, font }) {
    state.cards.find((card) => card.cardID == cardID).descriptionFont = font;
  },
  updateDescriptionFontSize(state, { cardID, fontSize }) {
    state.cards.find((card) => card.cardID == cardID).descriptionFontSize =
      fontSize;
  },
  updateDescriptionFontStyle(state, { cardID, fontStyle }) {
    state.cards.find((card) => card.cardID == cardID).descriptionFontStyle =
      fontStyle;
  },
  updateTypeFont(state, { cardID, font }) {
    state.cards.find((card) => card.cardID == cardID).typeFont = font;
  },
  updateTypeFontSize(state, { cardID, fontSize }) {
    state.cards.find((card) => card.cardID == cardID).typeFontSize = fontSize;
  },
  updateTypeFontStyle(state, { cardID, fontStyle }) {
    state.cards.find((card) => card.cardID == cardID).typeFontStyle = fontStyle;
  },
  updateAttunementFont(state, { cardID, font }) {
    state.cards.find((card) => card.cardID == cardID).attunementFont = font;
  },
  updateAttunementFontSize(state, { cardID, fontSize }) {
    state.cards.find((card) => card.cardID == cardID).attunementFontSize =
      fontSize;
  },
  updateAttunementFontStyle(state, { cardID, fontStyle }) {
    state.cards.find((card) => card.cardID == cardID).attunementFontStyle =
      fontStyle;
  },

  updateGemsColor(state, { cardID, color }) {
    state.cards.find((card) => card.cardID == cardID).gemsColor = color;
  },
  updateGemsPosition(state, { cardID, position }) {
    state.cards.find((card) => card.cardID == cardID).gemsPosition = position;
  },
  // UPDATE CARD'S CUSTOM ATTRIBUTES //
  updateCustomName(state, { cardID, customName }) {
    state.cards.find((card) => card.cardID == cardID).customName = customName;
  },
  updateCustomDescription(state, { cardID, customDescription }) {
    state.cards.find((card) => card.cardID == cardID).customDescription =
      customDescription;
  },
  updateEnchantmentDescription(state, { cardID, enchantmentDescription }) {
    state.cards.find((card) => card.cardID == cardID).enchantmentDescription =
      enchantmentDescription;
  },
  updateCustomTypeRarity(state, { cardID, typeRarity }) {
    state.cards.find((card) => card.cardID == cardID).customTypeRarity =
      typeRarity;
  },
  updateCustomAttunement(state, { cardID, attunement }) {
    state.cards.find((card) => card.cardID == cardID).customAttunement =
      attunement;
  },
  // UPDATE SELECTED COLLECTION //

  // START EDIT
  editCard(state, { cardID }) {
    state.selectedCardID = cardID;
    state.editingCard = true;
  },

  endEdit(state) {
    state.editingCard = false;
  },

  // UPDATE COLLECTION NAME
  updateCollectionName(state, { collectionID, collectionName }) {
    state.collections.find(
      (collection) => collection.collectionID == collectionID
    ).name = collectionName;
  },
};
