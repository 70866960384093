<template>
  <div class="drop-menu rounded">
    <transition name="fade">
      <div class="menu-row column">
        <div class="menu-row" v-if="0 == 1">
          <button @click="$router.push('/account')">My Account</button>
        </div>
        <h3 v-if="loggedIn && premium && patreonKey">Patreon Key:</h3>
        <div class="menu-row flex-center" @click="showInput = true" v-if="!showInput && loggedIn && premium">
          {{ patreonKey }}
        </div>
        <div class="menu-row">
          <input class="rounded" type="text" v-model.lazy="patreonKey" v-if="showInput" placeholder="Your Patreon Key"
            @blur="showInput = false" />
        </div>
        <div class="menu-row" v-if="loggedIn && !premium" @click="showInput ? connectPatreon() : showInput = true">
          <button>
            Connect to Patreon
          </button>
        </div>
      </div>
    </transition>
    <div class="menu-row" v-if="!loggedIn">
      <button @click="signIn">Log In</button>
    </div>
    <div class="menu-row">
      <button @click="signOut" v-if="loggedIn">Sign Out</button>
    </div>
    <div class="patreon-block">
          <h3 v-if="!premium || !patreonKey">SUPPORT ON</h3>
          <h3 v-if="premium && patreonKey" class="connected">CONNECTED TO</h3>
          <h3 v-if="subscription && subscription.status == 'active'">STRIPE</h3>
          <a href="https://patreon.com/eventyr" target="_blank" v-else><img class="patreon"
              src="@/assets/patreon.png" /></a>
        </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

export default {
  name: "SidebarMenuAccount",
  data() {
    return {
      showInput: false,
    };
  },
  emits: ["hide"],
  watch: {
    premium: function (newValue) {
      if (newValue) {
        this.showInput = false;
      }
    },
  },
  computed: {
    patreonKey: {
      get: function () {
        return this.$store.state.patreonKey;
      },
      set: function (newKey) {
        this.$store.dispatch("patreonConnect", newKey);
      },
    },
    patreonButton() {
      if (this.showInput) {
        var patreonButton = "Connect";
      } else {
        patreonButton = "Connect to Patreon";
      }
      return patreonButton;
    },
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
    email() {
      return this.$store.state.user.data.email;
    },
    premium() {
      return this.$store.state.premium;
    },
    subscription() {
      if (this.loggedIn && this.$store.state.user && this.$store.state.user.data && this.$store.state.user.data.subscription) {
        return this.$store.state.user.data.subscription;
      } else {
        return null;
      }
    },
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit("SET_LOGGED_IN", false);
          this.$store.commit("SET_PREMIUM", false);
          this.$store.commit("SET_USER", null);
          this.$router.go();
        });
    },
    signIn() {
      this.$router.push("/login");
    },
    hide() {
      this.$emit("hide");
    },
    connectPatreon() {
      if (this.showInput) {
        this.$store.dispatch("patreonConnect", this.patreonKey);
      } else {
        this.showInput = true;
      }
    },
  },
};
</script>

<style scoped>
.drop-menu {
  position: absolute;
  width: 300px;
  top:100%;
  z-index: 100;
  background-color: var(--dark2);
}

button {
  padding: 5px 10px;
  font-weight: 600;
  background: white;
  color: black;
  margin: 5px 5px;
  border-radius: 5px;
  width: 100%;
}

input {
  margin: 0px 20px;
}

.patreon-block {
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  padding:10px;
}

a {
  width:75%;
}

img {
  width:100%;
}


@media only screen and (max-width: 1079px) {
  .drop-menu {
    position: relative;
    top: 0;
    width: 100%;
    left: 0;
    font-size: 1.3rem;
  }

  h3 {
    font-size: 1.1rem;
    margin-top: 10px;
  }

  input {
    margin-top: 10px;
    font-size: 1rem;
  }

  button {
    margin: 25px 0px 0px 0px;
    padding: 10px 10px;
    font-size: 1.2rem;
    border-radius: 0px;
    background: rgb(112, 112, 112);
    border: none;
    color: white;
    font-weight: 500;
    height: 4rem;
  }

  .cancel {
    display: none;
  }

}
</style>