<template>
  <div class="editor-container shadow">
    <div class="topbar">
      <div class="close-button">
        <i class="far fa-times-circle" @click="closeEditor()"></i>
      </div>
    </div>
    <div class="editor">
      <CardOutput
        :printing="print"
        :selected="selected"
        :card="card"
        :cardID="cardID"
        :cardType="cardType"
        @printed="print = false"
      ></CardOutput>
      <div class="card-menu rounded">
        <keep-alive>
          <CardMenuSettings
            @printCard="printCard"
            :cardID="cardID"
            :cardType="cardType"
            :card="card"
            :tutorial="tutorial"
            :tutorialStep="tutorialStep"
          ></CardMenuSettings>
        </keep-alive>
        <CardMenuSettingsBottom
          :cardType="cardType"
          :cardID="cardID"
          :card="card"
          :tutorial="tutorial"
          :tutorialStep="tutorialStep"
          @printCard="printCard"
          @flipCard="showBack = !showBack"
          @closeEditor="$emit('closeEditor')"
        ></CardMenuSettingsBottom>
      </div>
    </div>
  </div>
</template>

<script>
import CardOutput from "./CardOutput.vue";
import CardMenuSettings from "./CardMenu/CardMenuSettings.vue";
import CardMenuSettingsBottom from "./CardMenu/CardMenuSettingsBottom.vue";


export default {
  name: "BaseCard",
  components: {
    CardOutput,
    CardMenuSettings,
    CardMenuSettingsBottom,
  },
  props: {
    selected: Boolean,
    cardID: Number,
    tutorial: Boolean,
    tutorialStep: Number,
  },
  emits: ["saveCards", "closeEditor"],
  computed: {
    card() {
      var cardID = this.cardID;
      var card = this.$store.state.cards.find((card) => card.cardID == cardID);
      return card;
    },
    cardType() {
      var cardID = this.cardID;
      var card = this.$store.state.cards.find((card) => card.cardID == cardID);
      return card.cardType;
    },
  },
  methods: {
    closeEditor() {
      this.$emit("closeEditor", this.card);
    },
    printCard() {
      this.print = true;
    },
    saveCards() {
      this.$emit("saveCards");
    },
  },
  data() {
    return {
      print: false,
      text: true,
      random: true,
      standard: false,
      showBack: false,
    };
  },
};
</script>

<style scoped>
.editor-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column nowrap;
  z-index: 900;
  background-color: var(--dark);
  border-radius: 5px;
  padding-bottom: 20px;
}

.editor {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 450px;
  padding: 0 25px;
}

.card-menu {
  display: flex;
  transition: all 0.5s ease;
  width: 320px;
  height: 100%;
  z-index: 1;
  position: relative;
  flex-flow: column nowrap;
  align-content: flex-start;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  margin-left:10px;
}

.topbar {
  background-color: var(--dark2);
  border-radius: 0.5rem 0.5rem 0 0;
  border-bottom: 2px solid #000;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.close-button {
  padding: 0.5rem;
}

.far {
  font-size: 2rem;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.far:hover {
  color: var(--main2);
  transition: all 0.3s ease;
}

@media only screen and (max-width: 1080px) {
  .editor-container {
    position:absolute;
    transform: unset;
    width: 100vw;
    min-height:100vh;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: column nowrap;
    padding: 0;
    border-radius: 0rem;
    background-color:var(--dark2);
  }

  .card-menu {
    margin: unset;
    width: 100%;
  }

  .topbar {
    display: none;
  }

  .editor {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .editor > div {
    margin-right: 0;
  }
}
</style>