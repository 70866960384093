<template>
  <BaseHeader></BaseHeader>
  <BaseDialog v-if="showPatronsOnly" title="Only Patrons can sign up" @okay="showPatronsOnly = false">
    <template #content>
      The Patron Key missing or incorrect. Sign up to the
      <a href="https://patreon.com/eventyr" target="_blank">Eventyr Games Patreon</a>
      to get the newest Patron Key or continue without logging in.
    </template>
  </BaseDialog>
  <BaseDialog v-if="showWrongPassword" title="Email or password not found" @close="showWrongPassword = false, resetDialogue = 'Forgot your password? Type your email and click reset your password and we will send you a link to reset it.', showResetField = true" 
      :noOkay="true">
    <template #content>
      The email or password you entered is incorrect.
<br>
<br>
      {{ resetDialogue}}
      <div class="menu-row" v-if="showResetField"><input type="text" class="inline" placeholder="Your Email" v-model="emailToReset">
      <button @click="resetPassword" class="inline">Reset Password</button></div>
      <div class="menu-row"> {{ errorMessage }}</div>
    </template>
  </BaseDialog>
  <div class="login-register">
    <div class="container" :class="{ 'right-panel-active': active }">
      <div class="form-container sign-up-container">
        <form action="#">
          <h1>Sign up</h1>
          <input type="email" placeholder="Email" v-model="registerInput.email" />
          <input type="password" placeholder="Password" v-model="registerInput.password" />

          <input type="text" placeholder="Patreon Key" v-model="patreonKey" />
          <button @click.stop.prevent="register">Sign Up</button>
        </form>
      </div>
      <div class="form-container sign-in-container">
        <form action="#">
          <h1>Sign in</h1>
          <input type="email" placeholder="Email" v-model="loginInput.email" />
          <input type="password" placeholder="Password" v-model="loginInput.password" />
          <button @click.stop.prevent="login">Sign In</button>
        </form>
      </div>
      <div class="overlay-container">
        <div class="overlay">
          <div class="overlay-panel overlay-left">
            <h1>Already have an account?</h1>
            <p>Sign in to get access to extra features</p>
            <button class="ghost" @click="active = false">Sign In</button>
          </div>
          <div class="overlay-panel overlay-right">
            <h1>Ready to sign up?</h1>
            <p>Sign up and start creating!</p>
            <button class="ghost" @click="active = true">Sign Up</button>
          </div>
        </div>
      </div>
    </div>
    <div class="guest" @click="guestContinue">
      <h3>Continue without logging in</h3>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import BaseHeader from "./Header/BaseHeader.vue";
import BaseDialog from "./BaseDialog.vue";

export default {
  name: "LoginPage",
  components: {
    BaseHeader,
    BaseDialog,
  },
  data() {
    return {
      active: false,
      loginInput: { email: "", password: "" },
      registerInput: {
        email: "",
        password: "",
      },
      patreonKey: "",
      showPatronsOnly: false,
      showResetField: true,
      showWrongPassword: false,
      resetDialogue: "Forgot your password? Type your email and click reset your password and we'll send you a link to reset it.",
      emailToReset: "",
      errorMessage: ""
    };
  },
  methods: {
    async register() {
      this.$store
        .dispatch("checkPatreonKey", this.patreonKey)
        .then((response) => {
          if (response) {
            firebase
              .auth()
              .createUserWithEmailAndPassword(
                this.registerInput.email,
                this.registerInput.password
              )
              .then(
                () => {
                  this.$router.push("/");
                },
                (err) => {
                  alert(err.message);
                }
              );
          } else {
            // this.showPatronsOnly = true;
            firebase
              .auth()
              .createUserWithEmailAndPassword(
                this.registerInput.email,
                this.registerInput.password
              )
              .then(
                () => {
                  this.$router.push("/");
                },
                (err) => {
                  alert(err.message);
                }
              );
          }
        });
    },
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(
          this.loginInput.email,
          this.loginInput.password
        )
        .then(
          () => {
            this.$router.push("/");
          },
          (err) => {
            console.log(err.message)
            this.showWrongPassword = true;
          }
        );
    },
    guestContinue() {
      this.$router.push("/");
    },
    resetPassword() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.emailToReset)
        .then(() => {
          this.resetDialogue = "An email with a reset link has been sent to your email address."
          this.showResetField = false;
          this.errorMessage = false;
        },
        (err) => {
          if(err.code === "auth/missing-email" || err.code === "auth/invalid-email") {
            this.errorMessage = "The email you entered is not registered with us."
          }})
    }
  },
};
</script>

<style scoped>
.login-register {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}

h1 {
  font-weight: bold;
  margin: 0;
}

h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

span {
  font-size: 12px;
}

button {
  border-radius: 20px;
  border: 1px solid #018b14;
  background-color: #07a55b;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
}

button.ghost {
  background-color: transparent;
  border-color: #ffffff;
}

button.inline {
  border-radius:5px;
  margin-left:5px;
  font-size:10px;
  letter-spacing: unset;
  padding: 5px;
  width:50%;
}

form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input {
  background-color: #eee;
  color: black;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 250px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {

  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #055c77;
  background: -webkit-linear-gradient(to right, rgb(141, 18, 172), #0e5483);
  background: linear-gradient(to right, rgb(141, 18, 172), #0e5483);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

.guest {
  cursor: pointer;
  color: #fff;
  transition: all 0.3s ease;
  margin-top:20px;
}

.guest:hover {
  color: var(--main2);
  transition: all 0.3s ease;
}


@media only screen and (max-width: 1080px) {
  .container {
    display: flex;
    flex-flow: column nowrap;
    max-width:90vw;
    min-height:500px;
  }

  .form-container {
    position: absolute;
    top: 0;
    height: 50%;
    width: 100%;
    transition: all 0.6s ease-in-out;
  }

  .sign-in-container {
    left: 0;
    width: 100%;
    z-index: 2;
  }

  .container.right-panel-active .sign-in-container {
    transform: translateY(100%);
  }

  .sign-up-container {
    left: 0;
    width: 100%;
    opacity: 0;
    z-index: 1;
  }

  .container.right-panel-active .sign-up-container {
    transform: translateY(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  }

  @keyframes show {

    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  .overlay-container {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }

  .container.right-panel-active .overlay-container {
    transform: translateY(-100%);
  }

  .overlay {
    background: #055c77;
    background: -webkit-linear-gradient(to right, rgb(141, 18, 172), #0e5483);
    background: linear-gradient(to right, rgb(141, 18, 172), #0e5483);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    top: -100%;
    left: 0;
    height: 200%;
    width: 100%;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
  }

  .container.right-panel-active .overlay {
    transform: translateY(50%);
  }

  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 50%;
    width: 100%;
    transform: translateY(-100%);
    transition: transform 0.6s ease-in-out;
  }

  .overlay-left {
    transform: translateY(-20%);
  }

  .container.right-panel-active .overlay-left {
    transform: translateY(0);
  }

  .overlay-right {
    top: 50%;
    transform: translateY(0);
  }

  .container.right-panel-active .overlay-right {
    transform: translateY(20%);
  }

}
</style>