<template>
  <div class="component-wrapper" :class="{presentation: mode == 'presentation'}">
    <div
      class="overlay clear"
      v-if="showColumnMenu"
      @click="showColumnMenu = false"
    ></div>
    <div class="buttons-container">
      <div class="button-standard" @click="$emit('addItem'), showColumnMenu = false">
        <i class="fas fa-plus"></i>Add Item
      </div>
      <div class="button-standard" @click.self="showColumnMenu = !showColumnMenu">
        <i class="fas fa-columns" @click="showColumnMenu = !showColumnMenu"></i
        >Table Style
        <transition name="drop-height">
          <div class="column-menu" v-if="showColumnMenu">
            <h3>List Style</h3>
            <div
              class="column-option"
              :class="{ selected: listStyle == 'condensed' }"
              @click="$emit('listStyle', 'condensed')"
            >
              Condensed
            </div>
            <div
              class="column-option"
              :class="{ selected: listStyle == 'table' }"
              @click="$emit('listStyle', 'table')"
            >
              Table
            </div>
            <div
              v-if="1 == 9"
              class="column-option"
              :class="{ selected: listStyle == 'text' }"
              @click="$emit('listStyle', 'text')"
            >
              Text
            </div>
            <h3>Information</h3>
                        <div
              class="column-option"
              :class="{ selected: showAttunement }"
              @click="
                showAttunement ? $emit('showAttunement', false) : $emit('showAttunement', true)
              "
            >
              Attunement
            </div>
            <div
              class="column-option"
              :class="{ selected: showType }"
              @click="
                showType ? $emit('showType', false) : $emit('showType', true)
              "
            >
              Type
            </div>
            <div
              class="column-option"
              :class="{ selected: showSubtype }"
              @click="
                showSubtype
                  ? $emit('showSubtype', false)
                  : $emit('showSubtype', true)
              "
            >
              Subtype
            </div>
            <div
              class="column-option"
              :class="{ selected: showRarity }"
              @click="
                showRarity
                  ? $emit('showRarity', false)
                  : $emit('showRarity', true)
              "
            >
              Rarity
            </div>
            <div
              class="column-option"
              :class="{ selected: showValue }"
              @click="
                showValue ? $emit('showValue', false) : $emit('showValue', true)
              "
            >
              Value
            </div>
            <div
              class="column-option"
              :class="{ selected: showSubvalue }"
              @click="
                showSubvalue
                  ? $emit('showSubvalue', false)
                  : $emit('showSubvalue', true)
              "
            >
              Subvalue
            </div>
            <div
              class="column-option"
              :class="{ selected: showSource }"
              @click="
                showSource
                  ? $emit('showSource', false)
                  : $emit('showSource', true)
              "
            >
              Source
            </div>
          </div>
        </transition>
      </div>
      <div id="download-button" class="button-standard" @click="$emit('printPDF')" v-if="mode=='priceList'">
        <i class="fas fa-download"></i>Download PDF
      </div>
    </div>
  </div>
</template>

<script>
// import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: "PriceListButtons",
  // components: {
  //   VueHtml2pdf
  // },
  props: [
    "filteredItems",
    "showAttunement",
    "showSource",
    "showValue",
    "showSubvalue",
    "showRarity",
    "showSubtype",
    "showType",
    "listStyle",
    "mode"
  ],
  emits: [
    "printPDF",
    "showFilters",
    "closeFilters",
    "addItem",
    "showAttunement",
    "showSource",
    "showValue",
    "showSubvalue",
    "showRarity",
    "showSubtype",
    "showType",
    "listStyle",
  ],
  data() {
    return {
      showColumnMenu: false,
    };
  },
};
</script>

<style scoped>
.buttons-container {
  position: absolute;
  right: -90px;
  top: 0;
  display: flex;
  flex-flow: column nowrap;
}

.button-standard {
  position: relative;
  margin: 0px 0px 10px 10px;
  padding: 10px;
  background: var(--dark2);
  color: white;
  border-radius: 5px;
  font-size: 10px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 80px;
  text-align: center;
}

.button-standard i {
  font-size: 1.5rem;
}

/* .button-standard:hover {
  transform: scale(1.05);
  transition: all 0.3s ease;
} */

.column-menu {
  position: absolute;
  bottom: -10px;
  left: -50%;
  transform: scaleY(100%) translateY(100%);
  margin-top: 10px;
  background: var(--dark2);
  z-index: 100;
  border-radius: 5px;
  padding: 5px;
}

.column-option {
  padding: 5px;
  margin: 5px 0px;
  font-size: 1rem;
  filter: opacity(0.5);
  user-select: none;
  border-radius: 5px;
}

.column-menu h3 {
  text-transform:uppercase;
}

.selected {
  font-weight: bold;
  background: var(--dark);
  filter: opacity(1);
}

@media only screen and (max-width: 1079px) {
  .buttons-container {
    position: fixed;
    right: unset;
    top: unset;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    z-index: 100;
  background:var(--dark2);
  }

  .component-wrapper.presentation {
    display:none;
  }

  .button-standard {
    margin: 0px;
    padding: 10px;
    border-radius: 0px;
    font-size: 12px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    text-align: center;
    position:relative;
  }

  #download-button {
    display:none;
  }

  .column-menu {
    position: absolute;
    bottom: unset;
    width:300px;
    left: -100%;
    top: -20px;
    transform: unset;
    transform: translateX(60px) translateY(-100%);
    background: var(--dark2);
    z-index: 100;
  }

.column-menu h3 {
  font-size:1.5rem;
}

  .column-option {
    padding: 10px;
    margin: 5px 0px;
    font-size: 1rem;
    border-radius: 5px;
    z-index: 100;
  }
}
</style>
