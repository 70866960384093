<template>
  <VueDragResize
    ref="draggable"
    :sticks="['tl', 'tr', 'bl', 'br']"
    :w="width"
    :x="left"
    :h="height"
    :y="top"
    :isActive="active"
    :key="updateKey"
    @click.stop=""
    @activated="active = true"
    @deactivated="active = false"
    @resizestop="saveDimensions()"
    @dragstop="saveDimensions()"
    :parentLimitation="true"
    class="draggable"
    ><img :src="imageSRC" class="item-image" ref="image" @load="resizeImage" />
  </VueDragResize>
</template>

<script>
import VueDragResize from "vue-drag-resize";

export default {
  name: "CardOutputImage",
  components: { VueDragResize },
  props: { cardWidth: Number, card: Object },
  data() {
    return {
      width: 150,
      left: 50,
      top: 30,
      height: 150,
      active: false,
      updateKey: 0,
    };
  },
  computed: {
    imageSRC: function () {
      if (
        typeof this.card.imageURL != "undefined" &&
        this.card.imageURL != null &&
        this.card.imageURL.length > 5
      ) {
        return this.card.imageURL;
      }
      if (
        this.card.selectedType == "Rod" ||
        this.card.selectedType == "Staff" ||
        this.card.selectedType == "Wand"
      ) {
        return "/img/item_images/rod_staff_wand/" + this.card.imageSRC;
      } else {
        return (
          "/img/item_images/" +
          this.card.selectedType.toLowerCase().replace(" ", "_") +
          "/" +
          this.card.imageSRC
        );
      }
    },
  },
  methods: {
    async resizeImage() {
      if (this.updateKey == 0) {
        // const wait = (timeToDelay) =>
        //   new Promise((resolve) => setTimeout(resolve, timeToDelay));
        if (this.card.backImageDimensions != null) {
          const d = this.card.backImageDimensions;
          const r = this.cardWidth / d.cardWidth;
          this.top = d.top * r;
          this.left = d.left * r;
          this.width = d.width * r;
          this.height = d.height * r;
        const imageDimensions = {
        width: this.width,
        height: this.height,
        top: this.top,
        left: this.left,
        cardWidth: this.cardWidth,
        }
        this.$store.commit("updateBackImageDimensions", {
        cardID: this.card.cardID,
        imageDimensions: imageDimensions,
      });
        } else {
          this.top = this.cardWidth * 0.18;
          this.left = this.cardWidth * 0.15;
          const ogWidth = this.$refs.image ? this.$refs.image.naturalWidth : 100;
          const ogHeight = this.$refs.image ? this.$refs.image.naturalHeight : 100;
          const ogRatio = ogHeight / ogWidth;
          const nWidth = this.height / ogRatio;
          this.width = nWidth;
        }
        // await wait(100);
        this.updateKey += 1;
      }
    },
    saveDimensions() {
      if (this.updateKey > 0) {
      const imageDimensions = {
        width: isNaN(this.$refs.draggable.width) ? 150 : this.$refs.draggable.width,
        height: isNaN(this.$refs.draggable.height) ?  150: this.$refs.draggable.height,
        top: isNaN(this.$refs.draggable.top) ? 50 : this.$refs.draggable.top,
        left: isNaN(this.$refs.draggable.left) ? 30 : this.$refs.draggable.left,
        cardWidth: this.cardWidth,
      };
      this.$store.commit("updateBackImageDimensions", {
        cardID: this.card.cardID,
        imageDimensions: imageDimensions,
      });
      }
    },
  },
};
</script>

<style>
.draggable {
  position: absolute;
  cursor: pointer;
  left: 20%;
}

.item-image {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  cursor: pointer;
}
</style>