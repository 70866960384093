<template>
  <div class="component-wrapper" :class="{ light: lightMode }">
    <div class="presentation-container" :class="{ card: view == 'card', list: view == 'list' }">
      <div class="buttons-container" id="top-buttons">
        <div class="button-standard" @click="lightMode = !lightMode">
          <i class="fas fa-eye"></i>{{ lightMode ? "Dark Mode" : "Light Mode" }}
        </div>
        <div class="button-standard layout-button" id="layout-button"
          @click="view == 'list' ? changeView('card') : changeView('list')"
          :class="{ card: view == 'card', list: view == 'list' }">
          <div class="layout-square"></div>
          <div class="layout-square"></div>
          <div class="layout-square"></div>
          <div class="button-text">Layout</div>
        </div>
        <div class="button-standard" @click="view == 'list' ? printPDF() : print()" id="print-button">
          <i class="fas fa-print"></i>{{ view == "list" ? "Download PDF" : "Download JPGs" }}

        </div>
        <div class="button-standard mobile-print-button" @click="print()" id="mobile-print-button">
          <i class="fas fa-print"></i>Download JPGs

        </div>
      </div>

      <div class="npc-container" :class="{ card: view == 'card', list: view == 'list' }" ref="npcContainer">
        <div class="npc-image-container" ref="npcImage">
          <NpcImage v-if="npcImageSRC" ref="npcImage" :type="'npc'" :loadedImage="npcImageSRC"
            @setImageSRC="updateNPCImage"></NpcImage>
        </div>
        <div class="npc-description-container" ref="npcDescriptionContainer">
          <h2 v-if="view == 'list' && npcName" contenteditable="true" @blur="updateNPC('npcName'), resize()">{{ npcName }}</h2>
          <div contenteditable="true" ref="npcDescriptionTextarea" class="description-textarea"
            @blur="updateNPC('npcDescription'), resize()" @keyup.enter="resize()"></div>
        </div>
        <div class="npc-traits-container">
          <div ref="npcIdealTextarea" class="trait-textarea" contenteditable="true"
            @blur="updateNPC('npcIdeal'), resize()"></div>
          <div ref="npcFlawTextarea" class="trait-textarea" contenteditable="true" @blur="updateNPC('npcFlaw'), resize()">
          </div>
          <div ref="npcBondTextarea" class="trait-textarea" contenteditable="true" @blur="updateNPC('npcBond'), resize()">
          </div>
        </div>
      </div>
      <div v-if="showShop" class="shop-container" :class="{ card: view == 'card', list: view == 'list' }"
        ref="shopContainer">
        <div class="shop-image-container" ref="shopImage">
          <NpcImage v-if="shopImageSRC" ref="shopImage" :type="'shop'" :loadedImage="shopImageSRC"
            @setImageSRC="updateShopImage"></NpcImage>
        </div>
        <div class="shop-description-container">
          <h2 v-if="view == 'list' && shopName" @blur="updateNPC('name')" contenteditable="true">{{ shopName }}</h2>
          <div ref="shopDescriptionTextarea" class="description-textarea" contenteditable="true"
            @blur="updateNPC('shopDescription'), resize()"></div>
        </div>
        <div class="shop-inventory" v-if="view == 'card'" ref="shopInventory">
          <div class="inventory-table-row item-row" v-for="item in inventory.sort((a, b) =>
            isNaN(a.value) ? -1 : a.value - b.value
          )" :key="item.name">
            <div class="inventory-table-column name">{{ item.name }}</div>
            <div class="inventory-table-column value">{{ item.value }} gp</div>
          </div>
        </div>
      </div>

      <PriceListTable :generatorItems="inventory" :mode="'presentation'" @filterItem="filterItem" class="inventory-table"
        v-if="view == 'list' && showShop" />
    </div>
  </div>
</template>

<script>
import PriceListTable from "../PriceList/PriceListTable.vue"
import * as htmlToImage from "html-to-image";
// import Vue3Html2pdf from "vue3-html2pdf";
import NpcImage from "./NpcImage.vue";

export default {
  name: "NpcPresentation",
  components: {
    // Vue3Html2pdf,
    // PriceListAddMenu,
    PriceListTable,
    NpcImage,
  },
  emits: ['printPDF'],
  props: ["npc"],
  data() {
    return {
      shopNameTextarea: false,
      shopDescriptionTextarea: false,
      npcDescriptionTextarea: false,
      shopDescription: "",
      npcDescription: "",
      npcIdeal: "",
      npcFlaw: "",
      npcBond: "",
      shopName: "",
      npcName: "",
      view: "list",
      npcImageSRC: "",
      shopImageSRC: "placeholder",
      cardShopImageH: null,
      cardNpcImageH: null,
      printMode: false,
      lightMode: false,
      showAddItemMenu: false,
    };
  },
  beforeUnmount() {
    this.$store.commit("deselectNpc");
  },
  watch: {
    selectedShop: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.shopName = this.$store.state.selectedShop.name;
        this.shopDescription = this.$store.state.selectedShop.shopDescription;
        this.npcDescription = this.$store.state.selectedShop.npcDescription;
        this.npcIdeal = this.$store.state.selectedShop.npcIdeal;
        this.npcFlaw = this.$store.state.selectedShop.npcFlaw;
        this.npcBond = this.$store.state.selectedShop.npcBond;
        this.$store.state.selectedShop.npcImageSRC
          ? (this.npcImageSRC = this.$store.state.selectedShop.npcImageSRC)
          : (this.npcImageSRC = "placeholder");
        this.$store.state.selectedShop.shopImageSRC
          ? (this.shopImageSRC = this.$store.state.selectedShop.shopImageSRC)
          : (this.shopImageSRC = "placeholder");
      }
    },
    view: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.$refs.npcImage.style.height = "auto";
        if (this.$refs.shopImage) {
          this.$refs.shopImage.style.height = "auto";
        }
        this.$nextTick;
        this.resize();
      }
    },
  },
  computed: {
    selectedShop() {
      return this.$store.state.selectedShop;
    },
    showShop() {
      if (this.$store.state.selectedShop.shopDescription) {
        return true;
      } else {
        return false;
      }
    },
    textColor() {
      let textColor = this.lightMode ? "#0a0a0a" : "white";
      return textColor;
    },
    backgroundColor() {
      let backgroundColor = this.lightMode ? "white" : "#0a0a0a";
      return backgroundColor;
    },
    inventory() {
      return this.$store.state.selectedShop.inventory;
    },
  },
  methods: {
    addToShop(item, shopName) {
      if (
        item &&
        !this.inventory.some((object) => object.name == item.name) &&
        item.name.length > 0
      ) {
        this.$store.dispatch("addToShop", { item: item, shopName: shopName });
      }
    },
    filterItem(filteredItem) {
      let changedNPC = this.selectedShop;
      changedNPC.inventory = changedNPC.inventory.filter(
        (item) => item.name != filteredItem.name
      );
      this.$store.dispatch("saveShop", changedNPC);
    },
    changeView(view) {
      this.printMode = false;
      this.view = view;
      this.$nextTick(() => this.resize());
    },
    async print() {
      const el = this.$refs.npcContainer;
      const npcName = this.npcName;
      htmlToImage.toJpeg(el, { pixelRatio: 3 }).then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = npcName + ".jpeg";
        link.href = dataUrl;
        link.click();
      });
      if (this.$refs.shopContainer) {
        const le = this.$refs.shopContainer;
        const shopName = this.shopName;
        htmlToImage.toJpeg(le, { pixelRatio: 3 }).then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = shopName + ".jpeg";
          link.href = dataUrl;
          link.click();
        });
      }
    },
    printPDF() {
      this.$emit('printPDF')
    },
    updateNPC(property) {
      let value = event.target.innerHTML;
      let changedNPC = this.selectedShop;
      if (property == 'name') {
        this.$store.dispatch("editShopName", value)
      } else if (changedNPC[property] != value) {
        changedNPC[property] = value;
        this.$store.dispatch("saveShop", changedNPC);
        if (property == 'npcName' && !this.showShop) {
          this.$store.dispatch("editShopName", value)}
      }
    },
    updateNPCImage(imageSRC) {
      let changedNPC = this.selectedShop;
      if (changedNPC.npcImageSRC != imageSRC) {
        changedNPC.npcImageSRC = imageSRC;
        this.$store.dispatch("saveShop", changedNPC);
      }
    },
    updateShopImage(imageSRC) {
      let changedNPC = this.selectedShop;
      if (changedNPC.shopImageSRC != imageSRC) {
        changedNPC.shopImageSRC = imageSRC;
        this.$store.dispatch("saveShop", changedNPC);
      }
    },
    resize() {
      if (
        this.$refs.npcImage &&
        this.$refs.npcFlawTextarea &&
        this.$refs.npcIdealTextarea &&
        this.$refs.npcBondTextarea
      ) {
        if (this.view == "card") {
          this.$refs.npcImage.style.height =
            this.$refs.npcContainer.clientHeight -
            this.$refs.npcDescriptionTextarea.scrollHeight -
            this.$refs.npcFlawTextarea.scrollHeight -
            this.$refs.npcIdealTextarea.scrollHeight -
            this.$refs.npcBondTextarea.scrollHeight -
            30 +
            "px";
        } else if (this.view == "list") {
          this.$refs.npcImage.style.height =
            this.$refs.npcDescriptionTextarea.scrollHeight +
            this.$refs.npcFlawTextarea.scrollHeight +
            this.$refs.npcIdealTextarea.scrollHeight +
            this.$refs.npcBondTextarea.scrollHeight +
            70 +
            "px";
        }
      }
      if (this.$refs.shopImage && this.$refs.shopInventory) {
        if (this.view == "card") {
          this.$refs.shopImage.style.height =
            this.$refs.shopContainer.clientHeight -
            this.$refs.shopDescriptionTextarea.scrollHeight -
            this.$refs.shopInventory.scrollHeight -
            30 +
            "px";
        } else if (this.view == "list") {
          this.$refs.shopImage.style.height =
            this.$refs.shopDescriptionTextarea.scrollHeight + 50 + "px";
        }
      }
    },
  },
  mounted() {
    this.shopName = this.$store.state.selectedShop.name;
    this.shopDescription = this.$store.state.selectedShop.shopDescription;
    this.npcDescription = this.$store.state.selectedShop.npcDescription;
    this.$store.state.selectedShop.npcImageSRC
      ? (this.npcImageSRC = this.$store.state.selectedShop.npcImageSRC)
      : (this.npcImageSRC = "placeholder");
    this.$store.state.selectedShop.shopImageSRC
      ? (this.shopImageSRC = this.$store.state.selectedShop.shopImageSRC)
      : (this.shopImageSRC = "placeholder");
    this.npcIdeal = this.$store.state.selectedShop.npcIdeal;
    this.npcFlaw = this.$store.state.selectedShop.npcFlaw;
    if (this.$refs.shopDescriptionTextarea) {
      this.$refs.shopDescriptionTextarea.innerHTML =
        this.$store.state.selectedShop.shopDescription;
    }
    this.$refs.npcDescriptionTextarea.innerHTML =
      this.$store.state.selectedShop.npcDescription;
    this.$refs.npcBondTextarea.innerHTML =
      this.$store.state.selectedShop.npcBond;
    this.$refs.npcIdealTextarea.innerHTML =
      this.$store.state.selectedShop.npcIdeal;
    this.$refs.npcFlawTextarea.innerHTML =
      this.$store.state.selectedShop.npcFlaw;
    this.$store.state.selectedShop.npcName
      ? (this.npcName = this.$store.state.selectedShop.npcName)
      : null;
    this.$nextTick(() => this.resize());
  },
};
</script>

<style scoped>
.component-wrapper {
  margin: 100px auto 0px auto;
  width: 800px;
}

.light {
  --text: #0a0a0a;
  --container: white;
}

h2 {
  margin-bottom: 10px;
  color: var(--text);
}

h3 {
  color: var(--text);
}

.buttons-container {
  justify-content: flex-start;
  right: -90px;
}

.inventory-container.list {
  overflow: unset;
}

.inventory-container .buttons-container {
  right: -90px;
}

.button-standard {
  margin-bottom: 10px;
}

button {
  border-radius: 5px;
  background-color: var(--dark3);
}

.layout-button.list {
  position: relative;
  display: grid;
  justify-items: center;
  grid-template-columns: 100%;
  grid-template-rows: 25%;
  grid-template-areas:
    "layout-square-1"
    "layout-square-2"
    "layout-square-3"
    "button-text";
}

.layout-button.card {
  position: relative;
  display: grid;
  justify-items: center;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
  grid-template-areas:
    "layout-square-1 layout-square-2"
    "button-text button-text";
}

.layout-square {
  background: var(--text);
  border-radius: 5px;
  width: 100px;
  height: 15px;
  max-width: 80%;
  max-height: 80%;
}

.layout-button.card .layout-square {
  width: 40px;
  height: 50px;
  max-width: 80%;
  max-height: 80%;
}

.layout-square:nth-of-type(1) {
  grid-area: layout-square-1;
}

.layout-square:nth-of-type(2) {
  grid-area: layout-square-2;
}

.layout-square:nth-of-type(3) {
  grid-area: layout-square-3;
}

.button-text {
  grid-area: button-text;
  color: var(--text);
}

.fa-print {
  font-size: 1.5rem;
}

.layout-button.card .layout-square:nth-of-type(3) {
  display: none;
}

.pdf-modal {
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translate(-50%, 0);
  z-index: 100;
  border-radius: 5px;
  width: 800px;
  background-color: var(--dark4);
}

/* STANDARD LOOK */

.presentation-container {
  transition: all 0.3s ease;
  border-radius: 5px;
  /* background: #0a0a0a; */
  /* box-shadow: 0 4px 6px #111111; */
  /* color: white; */
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas:
    "npc-container shop-container"
    "inventory-container inventory-container";
  grid-gap: 20px;
  justify-items: center;
  justify-content: center;
  position: relative;
  width: 800px;
}

.npc-container,
.shop-container,
.inventory-container {
  background-color: var(--container);
  border-radius: 10px;
  font-family: montserrat, Helvetica, Arial, sans-serif;
  color: var(--text);
  font-size: 14px;
  padding: 0px;
  position: relative;
}

.npc-container {
  grid-area: npc-container;
}

.shop-container {
  grid-area: shop-container;
}

.inventory-container {
  grid-area: inventory-container;
}

.description-textarea,
.trait-textarea,
.name-textarea {
  font-family: montserrat, Helvetica, Arial, sans-serif;
  font-size: 14px;
  background-color: inherit;
  color: var(--text);
  border: none;
  padding: 0;
}

.description-textarea.print,
.trait-textarea.print {
  height: auto;
  padding: 2px 0px;
}

.name-textarea {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  grid-area: name;
}

.inventory-container {
  width: 1000px;
  max-width: 90vw;
}

textarea {
  resize: none;
  overflow: hidden;
}

/* CARD LOOK */

.presentation-container.card {
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas: "npc-container shop-container";
  grid-gap: 20px;
  justify-items: center;
  justify-content: center;
}

.npc-container.card,
.shop-container.card,
.inventory-container.card {
  width: 350px;
  height: 490px;
}

.npc-container.card .npc-image-container,
.shop-container.card .shop-image-container {
  grid-area: npc-image;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 200px;
}

.npc-container.card .npc-image-container img,
.shop-container.card .shop-image-container img {
  object-fit: cover;
  width: 100%;
  transition: all 0.3s ease;
}

.npc-container.card .npc-image-container:hover img,
.shop-container.card .shop-image-container:hover img {
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.npc-container.card .npc-description-container,
.npc-container.card .npc-traits-container,
.shop-container.card .shop-description-container,
.shop-container.card .shop-inventory {
  padding: 5px 5px 5px 5px !important;
}

.inventory-container.card {
  padding: 5px !important;
}

.inventory-container.card {
  padding: 10px;
}

.npc-name,
.shop-name {
  position: absolute;
  bottom: 5px;
  font-size: 16px;
  font-family: montserrat, Helvetica, Arial, sans-serif;
  text-shadow: 0px 0px 6px var(--container);
  text-transform: uppercase;
  text-align: center;
}

.button-bottom {
  bottom: -20px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
}

/* LIST VIEW */

.presentation-container.list {
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "npc-container"
    "shop-container"
    "inventory-container";
  grid-gap: 20px;
  justify-items: center;
  justify-content: center;
}

.npc-container.list,
.shop-container.list {
  background-color: var(--container);
  border-radius: 10px;
  overflow: hidden;
  font-family: montserrat, Helvetica, Arial, sans-serif;
  color: var(--text);
  font-size: 14px;
  padding: 0px;
}

.npc-container.list,
.shop-container.list {
  width: 800px;
  display: grid;
  grid-template-columns: 200px auto;
  grid-template-rows: min-content;
  grid-column-gap: 10px;
}

.npc-container.list {
  grid-template-areas:
    "npc-image npc-description"
    "npc-image npc-traits";
}

.shop-container.list {
  grid-template-areas: "shop-image shop-description";
}

.npc-container.list .npc-description-container {
  grid-area: npc-description;
  margin: 5px;
}

.npc-container.list .npc-traits-container {
  grid-area: npc-traits;
  margin: 5px;
}

.shop-container.list .shop-description-container {
  grid-area: shop-description;
  margin: 5px;
}

.npc-container.list .npc-image-container,
.shop-container.list .shop-image-container {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px 0px 0px 10px;
  width: 200px;
  height: 100%;
  margin-right: 10px;
}

.npc-container.list .npc-image-container {
  grid-area: npc-image;
}

.shop-container.list .shop-image-container {
  grid-area: shop-image;
}

.npc-container.list .npc-image-container img,
.shop-container.list .shop-image-container img {
  object-fit: cover;
  width: 100%;
  transition: all 0.3s ease;
}

.npc-container.list .npc-image-container:hover img,
.shop-container.list .shop-image-container:hover img {
  transform: scale(1.1);
  transition: all 0.3s ease;
}

.inventory-container.list {
  padding: 10px;
  width: 800px;
}

.presentation-container.print {
  width: 800px;
  padding: 20px 0px;
  background-color: var(--container);
}

.presentation-container.print .npc-container.list,
.presentation-container.print .shop-container.list,
.presentation-container.print .inventory-container.list {
  width: 800px;
  padding: 0px 25px;
}

/* END LOOK */

.close-container {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  cursor: pointer;
}

.close-container p {
  font-size: 0.8rem;
}

.fa-times {
  margin-left: 5px;
  font-size: 1.5rem;
}

.inventory-table-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}

.inventory-table-row.item-row:nth-of-type(even) {
  background: #65656571 !important;
  border-radius: 2px;
}

.inventory-table-column {
  padding: 2px 5px;
}

.inventory-table-column.name {
  width: 70%;
}

.inventory-table-column.value {
  width: 30%;
  text-align: right;
}

#mobile-print-button {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .component-wrapper {
    margin: 80px 10px 80px 10px;
    width: 95vw;
  }

  .presentation-container.card,
  .presentation-container.list {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "npc-container"
      "shop-container"
      "inventory-container";
    grid-gap: 20px;
    justify-items: center;
    justify-content: center;
    width: 100%;
  }

  .npc-container.list,
  .shop-container.list {
    display: flex;
    flex-flow: column nowrap;
  }

  .npc-container.card,
  .npc-container.list,
  .shop-container.card,
  .shop-container.list,
  .inventory-container.card,
  .inventory-container.list {
    width: 95vw;
    height: unset;
  }

  .npc-container.card .npc-image-container,
  .shop-container.card .shop-image-container,
  .npc-container.list .npc-image-container,
  .shop-container.list .shop-image-container {
    grid-area: npc-image;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    height: 200px !important;
  }

  .inventory-container.list {
    max-width: 100%;
  }

  #layout-button,
  #print-button,
  #inventory-buttons {
    display: none;
  }

  #mobile-print-button {
    display: flex;
  }

  .button-standard {
    margin: 0;
  }
}
</style>
