<template>
  <BaseHeader :shopGenerator="true"/>
  <NpcMenu/>
</template>

<script>

import BaseHeader from "../components/Header/BaseHeader.vue";

import NpcMenu from "../components/NpcGenerator/NpcMenu.vue";

export default {
name: "ShopGenerator",
components: {NpcMenu, BaseHeader}
}
</script>

<style scoped>

</style>