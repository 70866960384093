<template>
  <BaseHeader></BaseHeader>
  <div class="account-container" v-if="user">

    <div class="account-sidebar">
      <div class="sidebar-item account-profile">
        <div class="username">{{ user && user.username ? user.username : user.email }}</div>
        <div class="user-email">{{ user ? user.email : "" }}</div>
      </div>
      <div class="sidebar-item" @click="selectedItem = 'settings'" :class="{ selected: selectedItem == 'settings' }">
        Settings
      </div>
      <div class="sidebar-item" @click="selectedItem = 'subscription'"
        :class="{ selected: selectedItem == 'subscription' }">
        Subscription
      </div>
    </div>
    <div class="account-main">


      <!--  Settings  -->

      

      <!--  Subscription Selection -->

      <div class="subscriptions-container" v-if="selectedItem == 'subscription'">
        <div class="product-container">
          <div class="product-header">
            <h2 class="product-name">Basic</h2>
            <img src="@/assets/hero_dragon.jpg" alt="" class="product-header-image" />
          </div>
          <div class="product-description">
            Without a subscription, you can use all our core features,
            but can only save a limited number of creations.
          </div>
          <div class="product-bullets">
            <div class="product-bullet" v-for="(feature, index) in subscriptionFeatures" :key="index">
              <i class="fas fa-times-circle" v-if="!feature.free" style="color: darkred"></i>
              <i class="fas fa-check-circle" v-if="feature.free" style="color: darkgreen"></i>
              {{ feature.feature }}
            </div>
          </div>
        </div>
        <div class="product-container" v-for="(product, index) in products" :key="index + '-product'">
          <div class="product-header">
            <h2 class="product-name">{{ product.name }}</h2>
            <img src="@/assets/hero_ooze.jpg" alt="" class="product-header-image" />
          </div>
          <div class="product-description">
            As a Premium Subscriber, you have access to all Eventyr Games content and unlimited saves.
          </div>
          <div class="product-bullets">
            <div class="product-bullet" v-for="(feature, index) in subscriptionFeatures" :key="index">
              <i class="fas fa-times-circle" v-if="!feature.premium" style="color: darkred"></i>
              <i class="fas fa-check-circle" v-if="feature.premium" style="color: darkgreen"></i>
              {{ feature.feature }}
            </div>
          </div>
          <div class="prices-container">
            <div v-for="(price, priceIndex) in product.prices" :key="priceIndex + '-price'" class="price-container"
              :class="{ active: price.id == selectedPrice }" @click="selectedPrice = price.id">
              <div class="price-inner-container">
                <div class="price-amount">${{ price.unit_amount / 100 }}</div>
                <div class="price-header"> {{ "/" + price.interval }}</div>
                <!-- <div class="form-check">
            <input type="radio" class="form-check-input" v-model="selectedPrice" :value="price.id" :id="price.id" />

            <label :for="price.id" class="form-check-label">
              {{ price.interval }} {{ price.currency }}
              {{ price.unit_amount / 100 }}
            </label>
          </div> -->
              </div>
            </div>
          </div>
          <button @click="createSub" :disabled="!selectedPrice || isLoading">
            {{ isLoading ? "Loading..." : "Continue to checkout" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from "../components/Header/BaseHeader.vue";
import {
  addDoc,
  getFirestore,
  collection,
  onSnapshot,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { firebaseAuth } from "@/firebase";

export default {
  name: "AccountPage",
  components: {
    BaseHeader,
  },
  data() {
    return {
      isLoading: false,
      products: [],
      selectedPrice: null,
      selectedItem: null,
      subscription: null,
      subscriptionFeatures: [
        {
          feature: "Access to all apps",
          free: true,
          premium: true,
        },
        {
          feature: "SRD 5.1. Content",
          free: true,
          premium: true,
        },
        {
          feature: "Unlimited Saves",
          free: false,
          premium: true,
        },
        {
          feature: "Eventyr Games Content",
          free: false,
          premium: true,
        },
      ],
    };
  },
  mounted() {
    this.fetchProducts();
  },
  computed: {
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },
    user() {
      return this.$store.state.user.data;
    },
  },
  methods: {
    async createSub() {
      this.isLoading = true;
      const db = getFirestore();
      const collectionRef = collection(
        db,
        "customers",
        firebaseAuth.currentUser.uid,
        "checkout_sessions"
      );
      const docRef = await addDoc(collectionRef, {
        price: this.selectedPrice,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();

        if (error) {
          console.error(`An error occured: ${error.message}`);
          this.isLoading = false;
        }
        if (url) {
          window.location.assign(url);
        }
      });
    },
    async fetchProducts() {
      const db = getFirestore();
      const productsRef = collection(db, "products");
      const productsQuery = query(productsRef, where("active", "==", true));
      const productsQuerySnap = await getDocs(productsQuery);
      productsQuerySnap.forEach(async (doc) => {
        const pricesRef = collection(db, "products", doc.id, "prices");
        const pricesQuerySnap = await getDocs(pricesRef);
        this.products.push({
          id: doc.id,
          ...doc.data(),
          prices: pricesQuerySnap.docs.map((price) => {
            return {
              id: price.id,
              ...price.data(),
            };
          }),
        });
        this.selectedPrice = this.products[0].prices[0].id;
      });
    },
  },
};
</script>

<style scoped>
* {
  --textFade: rgba(255, 255, 255, 0.7);
  --text: #ffffff;
}

.account-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  grid-template-areas: "sidebar main";
  width: 90vw;
  margin: 100px auto 0px auto;
  background-color: var(--dark3);
  border-radius: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}

.account-sidebar {
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: var(--dark5);
  border-radius: 15px 0px 0px 15px;
  color: whitesmoke;
  font-family: montserrat, sans-serif;
}

.sidebar-item {
  text-align: left;
  width: 100%;
  padding: 20px 0px;
  color: var(--textFade);
  border-bottom: 1px solid var(--textFade);
  cursor: pointer;
}

.sidebar-item.selected {
  color: var(--text);
  border-bottom: 1px solid var(--text);
}

.username {
  font-size: 1rem;
  font-weight: 600;
  margin: 0px;
  color: whitesmoke;
}

.account-main {
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: var(--dark1);
  border-radius: 0px 15px 15px 0px;
}

.subscriptions-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 600px;
  max-width: 90vw;
  padding: 1rem;
  margin: 100px auto 0px auto;
  font-family: montserrat, sans-serif;
}

.product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 300px;
  padding: 5px;
  margin: 0px 1rem;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  height: 100%;
  color: black;
}

.product-header {
  width: 100%;
  height: 150px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 10px;
}

.product-header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.product-name {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.75);
}

.product-description {
  padding: 5px;
}

.prices-container {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.product-bullets {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px;
}

.product-bullet {
  font-size: 1rem;
  color: black;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.product-bullet i {
  font-size: 1.25rem;
}

.prices-container {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-around;
  padding: 5px;
}

.price-container {
  padding: 5px;
  border: 3px solid var(--main1);
  border-radius: 100%;
  width: 40%;
  margin: 0px 5px;
  text-align: center;
  aspect-ratio: 1/1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.price-container:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}

.price-inner-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: var(--main1);
  color: white;
  border-radius: 100%;
  width: 100%;
  aspect-ratio: 1/1;
  filter: opacity(0.5);
  transition: all 0.3s ease-in-out;
}

.price-container.active .price-inner-container {
  filter: opacity(1);
  transition: all 0.3s ease-in-out;
}

.price-amount {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
}

.price-header {
  width: 100%;
  text-transform: uppercase;
}

button {
  background-color: var(--main1);
}
</style>